import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, ReleasesService, UploadService} from '../../../services';
import {MY_FORMATS, USER_CATEGORY} from '../../../common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {UserModel} from '../../../model';
import {IMatSelect} from '../../../common/interfaces';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-download-statement',
  templateUrl: './download_statement.html',
  styleUrls: ['./download_statement.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadStatementComponent implements OnInit {
  showError: boolean;
  urlStatementZipFile: string;
  searchValue: string;
  _statementDate: string;
  showSpinnerStatement: boolean;
  form: UntypedFormGroup;
  sStatementAllUsers: boolean;
  findUserStatement: UserModel;
  searchListResult: UserModel[] = [];
  sStatementCategory: IMatSelect[] = USER_CATEGORY.slice(0, 2);

  constructor(private upload: UploadService,
              private releaseService: ReleasesService,
              private cdr: ChangeDetectorRef,
              private toastr: AppToastrService
  ) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      user: new UntypedFormControl(''),
      type: new UntypedFormControl(''),

    });
    this.form.get('type').valueChanges.subscribe(resp => {
      this.form.get('user').setValue(null, {emitEvent: false});
    })
    this.form.get('user').valueChanges.subscribe(resp => {
      this.findUserStatement = resp;
    })
  }

  onNotify(e) {
    if (e.type === 'find-user-statement-upload') {
      this.searchListResult = e.data;
    }
    if (e.type === 'select-category') {
      this.showError = null;
      this.searchListResult = [];
    }
  }

  selectMonth(e, passDate) {
    this[passDate] = moment(e.data).format('YYYY/MM');
  }

  downloadStatements(): void {
    this.showSpinnerStatement = true;
    if (!this.form.get('user').value && !this.sStatementAllUsers) {
      return this.toastr.showErrorToast({message: 'You didnt select user'});
    }
    let data: any = {
      createdAt: moment(this._statementDate).format('YYYY/MM'),
      fileFormat: 'pdf',
    };

    if (!this.sStatementAllUsers) {
      data.user = this.form.get('user').value;
    }

    this.releaseService.downloadStatementPDF(data).subscribe(res => {
      this.urlStatementZipFile = res.url;
      this.showSpinnerStatement = false;
      this.cdr.detectChanges();
    }, err => {
      this.showSpinnerStatement = false;
      this.cdr.detectChanges();
      this.toastr.showToastFromError(err);

    });
  }
}

