import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-template',
  template: `
    <div class="main-wrapper">
      <div class="header-page">
        <span>{{header}}</span>
      </div>
      <div class="content-page">
        <ng-content></ng-content>
      </div>
    </div>`,
  styleUrls: ['./page-template.scss'],

})
export class PageTemplateComponent {
  @Input() header: string;

  constructor() {
  }  
}

