import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';

// Consts
import { TYPE_TO_SALE } from '../../../common/constants';
import { NUMBER_POSITIVE } from '@core/common';

// Interfaces
import { BasePromocode } from '@core/interfaces/promocode.interface';
import { EndDayRangeSelectionStrategy } from './range-select.provider';

// Services
import { AppToastrService, UserSettingService } from '../../../services';
import { PromocodeService } from '@core/services/promocode.service';

const validateCustomAmount: ValidatorFn = (fg: FormGroup) => {
  const typeSale = fg.get('typeSale').value;
  const amount = fg.get('amount').value;
  if (typeSale && typeSale === 1 && amount > 100) {
    fg.get('typeSale').setErrors({ amountPercent: true });
    return { amountPercent: true };
  } else {
    fg.get('typeSale').setErrors(null);
  }
  return null;
};

@Component({
  selector: 'app-sale-code-word',
  templateUrl: './sale-code-word.html',
  styleUrls: ['./sale-code-word.scss'],
  providers: [
    UserSettingService,
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: EndDayRangeSelectionStrategy,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaleCodeWordComponent {
  form: FormGroup;
  searchValue: string;
  listTypeSale = TYPE_TO_SALE;
  today: Date = new Date();
  @ViewChild('f') promoNgForm;
  @Output() onSuccess: EventEmitter<BasePromocode> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private promocodeService: PromocodeService,
    private toastr: AppToastrService
  ) {
    this.form = this.fb.group(
      {
        word: new FormControl('', [Validators.required]),
        cap: new FormControl('', [Validators.required]),
        amount: new FormControl('', [
          Validators.required,
          Validators.pattern(NUMBER_POSITIVE),
        ]),
        typeSale: new FormControl('', [Validators.required]),
        isClosed: [false],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
      },
      { validator: validateCustomAmount }
    );
  }

  private reset(): void {
    this.promoNgForm.resetForm();
  }

  setupCode(): void {
    if (this.form.valid) {
      this.promocodeService.addPromocode(this.form.value).subscribe(
        (res) => {
          this.onSuccess.emit(res);
          this.toastr.showToast({ title: 'SUCCESS new code added' });
          this.reset();
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
    }
  }
}
