import { Component, OnInit } from '@angular/core';
import { IArtistEditing } from '@core/model/artists-list.model';
import { AppToastrService, UsersService } from '@core/services';
import { UserModel } from '@core/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-item-view',
  templateUrl: './fans-view.html',
})
export class FansViewComponent implements OnInit{
  slug: string;
  fanItem: UserModel;
  artistData!: IArtistEditing;

  constructor(
    private userService: UsersService,
    private toastr: AppToastrService,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) {
    this.slug = this.route.snapshot.params.slug;
  }

 ngOnInit(): void {
   this.getItem();
 }

  getItem(): void {
    this.userService.getUser(this.slug).subscribe(
      (resp) => {
        this.fanItem = resp;
        if (this.fanItem.country) {
          this.usersService.longNameCountry(this.fanItem.country).then((c) => {
            this.fanItem.country = c;
          });
        }
      },
      (err) => {
        this.toastr.showToastFromError(err);
      }
    );
  }
}
