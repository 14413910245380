import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaderResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, last, tap} from 'rxjs/operators';
import {SettingService} from "@core/services/setting.service";

@Injectable()
export class UploadImageService {
  apiUrl: string;
  contentUrl: string;
  private uploadProgressSource = new Subject<{ loaded: number, total: number }>();
  private uploadResult = new Subject<string>();
  uploadProgress$ = this.uploadProgressSource.asObservable();
  uploadResult$ = this.uploadResult.asObservable();

  constructor(private setting?: SettingService,
              private http?: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }


  convertURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  loadProfileImage(file, params): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/admin/artists/upload-image?${this.serialize(params)}`,
      file
    );
  }

  private serialize(obj): any {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
