<form [formGroup]="form" class="tab">
  <div class="item-row">
    <div class="item-label item-label_pad-20">Is Enabled</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    formControlName="isEnabled"
                    [name]="'Is Enabled'"
                    [id]="'Is Enabled'"
      >
      </mat-checkbox>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="DeTitleEng"
               formControlName="title"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Description</div>
    <div class="item-field">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        [formControl]="form.get('description')"
      ></angular-editor>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Cta button</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="link"
               formControlName="button"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">CTA Button link</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="urlEng"
               formControlName="url"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Open in new window</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    formControlName="targetBlank"
                    [name]="'Is Enabled'"
                    [id]="'Is Enabled'"
      >
      </mat-checkbox>
    </div>
  </div>
</form>

<ng-template [ngIf]="listBlocks?.length">
  <div class="list-slider" *ngFor="let item of listBlocks; let i = index">
    <app-free-text-list-item *ngIf="form?.controls[item?.name]"
                             [index]="i"
                             [form]="form?.controls[item?.name]">
    </app-free-text-list-item>
    <div class="wrapper wrapper-button">
       <button class="button blue default-size" (click)="deleteBlock(i)">Delete slide</button>
    </div>
  </div>
</ng-template>
<button class="button green default-size" (click)="addBlock()">Add Text Block</button>
