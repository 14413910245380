import {Component, OnInit, Input, ContentChild} from '@angular/core';
import {AppToastrService, OpportunityService, UploadService} from '../../../services/index';
import {UploadConfigs} from '../../../interfaces/user.interface';
import {UPLOAD_TOKEN} from "@core/component/upload/upload.token";
import { IUploadImage} from "@core/component/upload/image.interface";

@Component({
  selector: 'app-upload-image-wrapper',
  templateUrl: './upload-image-wrapper.html',
  styleUrls: ['./upload-image-wrapper.scss'],
})
export class UploadImageWrapperComponent implements OnInit {

  @Input() config: UploadConfigs;
  @Input() maintainAspectRatio: boolean;
  @ContentChild(UPLOAD_TOKEN as any, {static: true})
  imageUploadComponent: IUploadImage;

  constructor(private opportunityService: OpportunityService,
              private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
  }

  loadImage(event) {
    const allFormats = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'gif', 'GIF', 'webp', 'WEBP'];
    const extension = this.config.allowFormats ? this.config.allowFormats : allFormats;

    if (extension.indexOf(event.target.files[0].name.split('.').slice(-1)[0]) < 0) {
      let uniqExt = this.config.allowFormats.map(v => v.toLowerCase()).filter((v, i, a) => a.indexOf(v) === i);
      this.toastr.showErrorToast({
        title: `Allowed ${this.config.allowFormats ? uniqExt : 'jpg, png, jpeg, webp, gif'} formats only`
      });
      return false;
    }

    this.imageUploadComponent.uploadImg(event);
  }
}
