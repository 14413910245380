import {Injectable} from '@angular/core';
import {SettingService} from '@core/services/setting.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LandingModel} from '@core/model/landing.model';
import {RequestModel} from '@core/model/request.model';

@Injectable()
export class LandingService {
  apiUrl: string;
  contentUrl: string;

  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }

  getList(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/landing`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new LandingModel().setFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getLanding(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/landing/admin/${data}`).pipe(map((resp: any) => {
      if (resp) {
        return new LandingModel(this.contentUrl).setFromItemApiModel(resp);
      }
    }));
  }
  updateLanding_s(slug, data): Observable<any> {
    return this.http.put(`${this.apiUrl}/landing/admin/${slug}`, data).pipe(map((resp: any) => {
      if (resp) {
        return new LandingModel(this.contentUrl).setFromItemApiModel(resp);
      }
    }));
  }
  saveLanding_s(slug, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/landing/admin`, data).pipe(map((resp: any) => {
      if (resp) {
        return new LandingModel(this.contentUrl).setFromItemApiModel(resp);
      }
    }));
  }
}
