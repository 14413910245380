import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';

//import {StateService} from '@uirouter/core';
import * as moment from 'moment';
import {StorageService} from "../../services";
import {SettingService} from '@core/services/setting.service';



@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class DashboardComponent implements OnInit {
  staticImg: any[] = [];
  projectList: any;
  host: string;
  linked: any;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    initialSlide: 4,
    nextArrow: '',
    prevArrow: '',
  };

  constructor(private storage: StorageService,
              private settingService: SettingService,
             // private stateService: StateService,
             ) {
  }

  ngOnInit() {
  }

  slickInit(e) {

  }

}
