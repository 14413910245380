import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SettingService} from './setting.service';
import {Dictionary} from '../model/dictionary.model';
import { filter } from 'rxjs/operators';


@Injectable()
export class DictionaryService {
  apiUrl: string;
  countries: Dictionary[];
  genres: Dictionary[];
  regions: any;
  genders: Dictionary[];
  public onGetCountries = new Subject<any>();
  private onGetRegions = new Subject<any>();
  private onGetGenders = new Subject<any>();
  private onGetGenres = new Subject<any>();

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }


  getCountries(): Observable<Dictionary[]> {
    this._getData(this.countries, `/dictionary/countries`, this.onGetCountries);
    return this.onGetCountries.asObservable();
  }

  getGenres(): Observable<Dictionary[]> {
    this._getData(this.genres, `/dictionary/genres`, this.onGetGenres);
    return this.onGetGenres.asObservable();
  }

  getGenders(): Observable<Dictionary[]> {
    this._getData(this.genders, `/dictionary/genders`, this.onGetGenders);
    return this.onGetGenders.asObservable();
  }

  getRegions(): Observable<any> {
    this._getData(this.regions, `/dictionary/regions`, this.onGetRegions, 'regions');
    return this.onGetRegions.asObservable();
  }


  private _getData(list, url, subject, type?: string) {
    if (list) {
      setTimeout(() => {
        subject.next(list);
      }, 4);
    } else {
      this.http.get(`${this.apiUrl}${url}`).pipe(filter(Boolean)).subscribe((resp: any) => {
        if (type === 'regions') {
          list = {
            US: resp.US.map(i => ({id: i, name: i})),
            MX: resp.MX.map(i => ({id: i, name: i})),
            CA: resp.CA.map(i => ({id: i, name: i}))
          };
        } else {
          list = resp.map(item => new Dictionary().setFromApiModel(item));
        }
        subject.next(list);
      });
    }
  }
}


