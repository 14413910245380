import {Component, Output, Input, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-popup-confirmation',
  templateUrl: './popup-confirmation.html'
})
export class PopupConfirmationComponent {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;

  closePopup(): void  {
    this.notify.emit({type: 'close'});
  }

  onSubmit(): void {
    this.notify.emit({type: this.config.submitEmit});
  }
}
