<div class="teaser__header">
<div class="section-title">Offer Teaser</div>
<button class="button blue default-size"
        (click)="addColumn()">
  Add column
</button>
</div>
<ng-template [ngIf]="innerProject?.length">
  <div class="teaser__item" *ngFor="let item of innerProject; let i = index">
    <div class="content-item">
    <div class="item-label header-inform-text-grey">Offer Teaser Headline {{i + 1}}</div>
    <div class="item-field">
      <mat-form-field>
        <input matInput
               id="text"
               name="input"
               [(ngModel)]="item.headline"
               [name]='item.headline'
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
    </div>
    <div class="content-item">
    <div class="item-label header-inform-text-grey">Offer Teaser</div>
    <div class="item-field">
      <mat-form-field>
        <input matInput
               id="text"
               name="input"
               [(ngModel)]="item.text"
               [name]="item.text"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
    <div class="content-item">
      <div class="item-label header-inform-text-grey">Icon</div>
      <div class="load-image item-field">
        <app-upload-image
          [config]="imageConfig"
          [value]="item?.icon"
          [maintainAspectRatio]="true"
          (valueChange)="saveImage($event, item)"
          [imgURL]="item?.icon"
        ></app-upload-image>
      </div>
    </div>
    <div class="content-item">
      <div class="item-label header-inform-text-grey"></div>
      <div class="delete-button item-field">
    <button class="button red default-size"
            (click)="deleteColumn(i)">
      Delete column
    </button>
      </div>
    </div>
  </div>
</ng-template>
