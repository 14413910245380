import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {YOUTUBE} from '@core/common';

@Component({
  selector: 'app-add-multi-input',
  templateUrl: './add-multi-input.html'
})
export class AddMultiInputComponent implements OnInit {
  inputCtrl: UntypedFormArray;
  form: UntypedFormGroup;
  list: any[] = [];
  @Input() param;

  constructor(private fb: UntypedFormBuilder) {

  }

  ngOnInit(): any {
    this.inputCtrl = this.fb.array([]);
    this.form = this.fb.group({
      input: this.inputCtrl,
    });
    this.setForm();
  }

  setForm() {
    if (this.param && this.param.length) {
      this.param.forEach((item => {
        this.inputCtrl.push(new UntypedFormControl(item.name ? item.name : item, [Validators.pattern(YOUTUBE)]));
        this.list.push(item);
      }));
    }
  }

  addControlItem(control: any, type: string): void {
    if (control.length < 10) {
      control.push(new UntypedFormControl('', [Validators.pattern(YOUTUBE)]));
    }
    this.list.push({name: ''});
  }

  removeControlItem(index: number, control: any, type: string): void {
    if (control.length >= 0) {
      control.removeAt(index);
    }
  }

}

