import * as moment from 'moment';
import {TYPE_RIGHTS} from "@core/common/recoupable_constant";

export interface IRevenues {
  netAmount: number;
  recoupableAmount: number;
  date: string;
  recoupablePercent: number;
  typeName: string;
  rightsId: number;
  itemId: number;
  time: string;
  rightsView: string;
  _id: string;
  share: number;
}


export class ListRoyaltiesModel {
  constructor() {
  }

  share: any;
  netAmount: number;
  song: string;
  recoupablePercent: number;
  recoupableAmount: number;
  date: string;
  _id: string;
  title: string;
  rightsId: number;
  itemId: number;
  rightsView: string;
  typeName: string;
  time: string;

  setFromApiModel(apiModel): this {
    this._id = apiModel._id;
    this.share = apiModel.share;
    this.netAmount = apiModel.amount;
    this.song = apiModel.song;
    this.rightsId = apiModel.rights;
    this.recoupablePercent = 100;
    this.rightsId = apiModel.rights;
    for (let key in TYPE_RIGHTS) {
      if (TYPE_RIGHTS.hasOwnProperty(key) && apiModel.rights === TYPE_RIGHTS[key].value) {
        this.rightsView = TYPE_RIGHTS[key].name
      }
    }
    this.title = apiModel.title;
    this.recoupableAmount = this.netAmount;
    // this.date = moment(apiModel.date).format('DD.MM.YYYY');
    this.date = apiModel.createdAt;

    this.typeName = 'Royalty earnings';
    this.itemId = 1;
    this.time = apiModel.time;
    return this;
  }
}

export class ListRoyaltyAdvancesModel {
  constructor() {
  }

  number: string;
  title: string;
  recoupablePercent: number;
  netAmount: number;
  recoupableAmount: number;
  left: number;
  date: any;
  dateToShow: string;
  itemId: number;
  typeName: string;
  time: string;

  setFromApiModel(apiModel): this {
    this.netAmount = apiModel.amount * (-1);
    this.title = apiModel.title;
    this.recoupablePercent = 100;
    this.recoupableAmount = this.netAmount;
    this.itemId = 2;
    this.typeName = 'Royalty advances';
    this.dateToShow = moment(apiModel.date).format('DD.MM.YYYY');
    this.time = moment(apiModel.date).format('X000');
    return this;
  }
}

export class RecoupableModel {
  constructor() {
  }

  title: string;
  vat: number;
  netAmount: number;
  date: string;
  dateToShow: string;
  vatAmount: number;
  isProducer: string;
  rights: number;
  recoupablePercent: number;
  recoupableAmount: number;
  typeID: any;
  rightsView: string;
  itemId: number;
  time: string;
  balanceId: any;
  typeName: string;
  _id: string;

  setFromApiModel(apiModel): this {
    this.vatAmount = (apiModel.vat / 100) * apiModel.netAmount;
    this.vat = apiModel.vat;
    this.netAmount = apiModel.netAmount * (-1);
    this.recoupablePercent = Number(apiModel.recoupablePercent);
    this.recoupableAmount = (this.recoupablePercent / 100) * this.netAmount;
    this.title = apiModel.title;
    this.rights = apiModel?.rights?.toString();
    for (let key in TYPE_RIGHTS) {
      if (TYPE_RIGHTS.hasOwnProperty(key) && apiModel.rights === TYPE_RIGHTS[key].value) {
        this.rightsView = TYPE_RIGHTS[key].name
      }
    }
    this.isProducer = apiModel.isProducer ? '2' : '1';
    this._id = apiModel._id;
    this.typeID = apiModel.typeID;
    this.itemId = 3;
    this.typeName = this.typeID === 1 ? 'Advance' : 'Cost';
    this.date = apiModel.date;
    this.dateToShow = apiModel.dateToShow;
    this.time = apiModel.time;
    return this;
  }
}


export class BalanceModel {
  constructor() {
  }

  recoupable: RecoupableModel[];
  advances: ListRoyaltyAdvancesModel[];
  artist: any;
  revenues: IRevenues[];

  setFromApiModel(apiModel): this {
    this.artist = apiModel.artist;
   // this.recoupable = apiModel?.balance?.recoupable ? apiModel.balance.recoupable.map(i => new RecoupableModel().setFromApiModel(i)) : [];
    this.recoupable = apiModel?.balance ? apiModel.balance.map(i => new RecoupableModel().setFromApiModel(i)) : [];

      this.revenues = apiModel.revenues.map(revenue => {

        revenue.netAmount = revenue.amount + (revenue.recoupedAmount || 0);
        revenue.recoupableAmount = revenue.amount + (revenue.recoupedAmount || 0);
        revenue.date = revenue.createdAt;
        revenue.dateToShow = moment(revenue.createdAt).format('DD.MM.YYYY');
        revenue.time = moment(revenue.createdAt).format('x');
        revenue.recoupablePercent = 100;
        revenue.rightsId = revenue.isPublishing ? 2 : 1;
        for (let key in TYPE_RIGHTS) {
          if (TYPE_RIGHTS.hasOwnProperty(key) && revenue.rightsId === TYPE_RIGHTS[key].value) {
            revenue.rightsView = TYPE_RIGHTS[key].name
          }
        }
        revenue.title = revenue?.project?.projectTitle;
        revenue.typeName = 'Royalty earnings';
        revenue.itemId = 1;
        return revenue;
      })


    return this;
  }
}

