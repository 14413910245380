<h4 class="section-title">Payout Settings</h4>
<ng-container *ngIf="payoutSettings">
  <div formGroupName="payoutSettingsForm">
    <div class="content-item">
      <div class="item-label header-inform-text-grey">Transfer of Funds*</div>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="typePayout"
        [disabled]="disabled"
      >
        <mat-radio-button value="fullPayment">Full payment</mat-radio-button>
        <mat-radio-button value="instalments">Instalments</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="content-item" *ngIf="payoutSettingsForm.get('payoutMode')">
      <div class="item-label header-inform-text-grey">Payout*</div>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="payoutMode"
        [disabled]="disabled"
      >
        <mat-radio-button value="manual" selected>Manual</mat-radio-button>
        <mat-radio-button value="auto">Automatic</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="content-item" *ngIf="payoutSettingsForm.get('frequencyNumber')">
      <div class="item-label header-inform-text-grey">Frequency*</div>
      <div class="item-field">
        <mat-form-field class="">
          <mat-label>Enter frequency</mat-label>
          <input
            matInput
            type="number"
            formControlName="frequencyNumber"
            [readonly]="disabled"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Choose range</mat-label>
          <mat-select formControlName="frequencyRange" [disabled]="disabled">
            <mat-option value="day"> days </mat-option>
            <mat-option value="week"> weeks </mat-option>
            <mat-option value="month"> months </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="content-item">
      <div class="item-label header-inform-text-grey">Beneficiary*</div>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="beneficiary"
        [disabled]="disabled"
      >
        <mat-radio-button value="grs">Global Rockstar</mat-radio-button>
        <mat-radio-button value="nonGrs">Other</mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container
      *ngIf="payoutSettingsForm.get('beneficiary').value === 'nonGrs'"
    >
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Fee*</div>
        <mat-form-field class="">
          <mat-label>Fee(%)</mat-label>
          <input
            matInput
            type="number"
            formControlName="fee"
            [readonly]="disabled"
          />
        </mat-form-field>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">
          Select beneficiary*
        </div>
        <app-find-by-name-universal-2
          formControlName="owner"
          class="item-field"
          selectBy="mangoUser"
          [readonly]="disabled"
        >
        </app-find-by-name-universal-2>
      </div>
    </ng-container>
  </div>
</ng-container>
