<div class="wrapper">
  <div class="upload-section" *ngIf="!audioCtrl.value">
    <label class="border-blue">
      <input (change)="uploadAudio($event)" type="file"
             class="desktop-upload"
             maxlength="30"
             name="filefront"/>
      <i class="fas fa-music"></i>
      <span>Upload audio file (.mp3) </span>
      <mat-progress-spinner
        class="upload-progress"
        [color]="'primary'"
        [diameter]="20"
        [mode]="'determinate'"
        [value]="(uploadProgress$ | async)">
      </mat-progress-spinner> 
    </label>
  </div>
  <div class="file-name" *ngIf="audioCtrl.value">
    <audio controls>
      <source [src]="audioCtrl?.value | convertImageUrl" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <div class="flex">
      <div class="icon-cancel" (click)="cancelUpload()"><i
        class="fas fa-times-circle"></i></div>
      <div class="name">{{audioCtrl.value}}</div>
    </div>
  </div>

</div>
