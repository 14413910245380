export interface ICurrency<T>{
  usd: T;
  eur: T;
}

export enum Currency {
  usd = 'usd',
  eur = 'eur'
}

export enum CurrencySign {
  usd = '$',
  eur = '€'
}

