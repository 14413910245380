import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ProjectModel} from '@core/model/project.model';

@Injectable()
export class ProjectFeaturesService {
  public featuredProjects = new BehaviorSubject<number>(0);

  setCount(list: any[] = []){
    let count = list.filter(item => item.isFeatured).length;
    this.featuredProjects.next(count);
  }

  updateFeaturesCount(p: any, status: boolean) {
    let count = this.featuredProjects.getValue();
    let counts = status ? count + 1 : count - 1;
    this.featuredProjects.next(counts);
  }
}
