import {IPermissions, IRoles} from '../interfaces/roles.interface';


export class RolePermission {
  constructor() {
  }

  album_p: IPermissions;
  project_p: IPermissions;
  news_p: IPermissions;
  voucher_p: IPermissions;
  account_p: IPermissions;
  artist_p: IPermissions;
  release_p: IPermissions;
  balance_p: IPermissions;
  edition_p: IPermissions;
  submission_p: IPermissions;
  landing_p: IPermissions;
  setting_p: IPermissions;
  collectible_p: IPermissions;
  label_p: IPermissions;
  fan_p: IPermissions;
  producer_p: IPermissions;
  role_p: IPermissions;

  setModel(apiModel): this {
    apiModel.forEach(item => {
      if (item.value === 'album_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'project_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'voucher_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'setting_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'collectible_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'edition_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'role_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'account_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'artist_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'producer_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'release_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'label_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'balance_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'fan_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'news_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'submission_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
      if (item.value === 'landing_p') {
        this[item.value] = {write: item.write, read: item.read};
      }
    });
    return this;
  }
}

export class RoleModel {
  // isEditMode: boolean;
  // album_p: IPermissions;
  // project_p: IPermissions;
  // voucher_p: IPermissions;
  // edition_p: IPermissions;
  // account_p: IPermissions;
  // artist_p: IPermissions;
  // release_p: IPermissions;
  // balance_p: IPermissions;
  // submission_p: IPermissions;
  // landing_p: IPermissions;
  // setting_p: IPermissions;
  // news_p: IPermissions;
  // collectible_p: IPermissions;
  // role_p: IPermissions;
  // fan_p: IPermissions;
  // label_p: IPermissions;
  // producer_p: IPermissions;
//  name: string;
//  slug: string;

  setFromApiModel(apiModel): any {
    let tObj = {} as IRoles;
    tObj.name = apiModel.name;
    tObj.slug = apiModel.slug;

    if (apiModel.permission && apiModel.permission.length) {
      const obj = new RolePermission().setModel(apiModel.permission);
      for (const key in obj) {
        tObj[key] = obj[key];
      }
    }
    return tObj;
  }

  getApiModel(data: IRoles): any {
    const obj = {
      name: data.name,
      permission: []
    };
    for (const key in data) {
      if (key !== 'name') {
        obj.permission.push({
          value: key,
          write: data[key].write,
          read: data[key].read,
        });
      }
    }
    obj.permission.push({value: 'role_p', write: false});
    obj.permission.push({value: 'label_p', write: false});
    return obj;
  }
}

