<div class="search-block_item">
  <div class="search-block_item_header flex flex-between">
    <span class="bold-style">Category filter</span>
    <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
      <mat-label>Select category</mat-label>
      <mat-select [(ngModel)]="searchService.selectedSearchCategory" name="food" (selectionChange)="loadCategory()">
        <mat-option *ngFor="let cat of config.categoryOptions" [value]="cat">
          {{cat}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search-block_item_container">
    <div class="select__item">
      <mat-form-field *ngIf="searchService.selectedSearchCategory === 'Country'" class="mat-select d-inline"
                      appearance="fill">
        <mat-label>Select category</mat-label>
        <mat-select [(ngModel)]="searchService.selectedFilterCategory">
          <mat-option *ngFor="let cat of categoryList" [value]="cat">
            {{cat.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    <ng-container *ngIf="searchService.selectedFilterCategory">
      <button mat-button (click)="searchService.filterByCategory()"><i class="fas fa-search"></i></button>
      <button mat-button (click)="searchService.resetCategoryFiler()"><i class="fas fa-refresh"></i></button>
    </ng-container>
  </div>
</div>
