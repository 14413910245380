import {Filter} from '../common/interfaces';
import {IFileConfig} from '@core/interfaces/config.interface';
import {HttpParams} from '@angular/common/http';


export class TypeFilter implements Filter {
  paging: {
    page: number,
    offset: number,
    limit: number
  };
  sorting: {
    sortBy: string,
    sortHow: string
  };

  constructor() {
    this.paging = {
      page: 1,
      offset: 0,
      limit: 20,
    };
    this.sorting = {
      sortBy: 'createdAt',
      sortHow: 'desc'
    };
  }
}

export class FileConfig implements IFileConfig {
  buttonName: string;
  url: string;

  constructor(name, url) {
    this.buttonName = name;
    this.url = url;
  };
}

