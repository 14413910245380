import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StorageService} from 'app/services/storage.service';
import {SettingService} from 'app/services/setting.service';
import {Router} from "@angular/router";


@Injectable()
export class AuthenticationService {

  public user: string;
  public isLoggedIn: boolean = false;

  readonly apiUrl: string;

  constructor(private http: HttpClient,
              private storage: StorageService,
              private settings: SettingService,
              private router: Router) {
    this.apiUrl = this.settings.apiUrl;
    this.checkForTiming();
  }

  getToken(auth: any) {
    return this.http.post(`${this.apiUrl}/admin/authtoken`, auth).pipe(map((data: any) => {
      this.processLoginData(data);
      return data;
    }));
  }

  getCurrentUser() {
    return this.storage.user;
  }

  getCurrentUserPermission(): any[] {
    const user = this.storage.user;
    return user && user.permission ? user.permission : [];
  }

  signUp(dataRequest: any) {
    return this.http.post(`${this.apiUrl}/register`, dataRequest).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  sentVerification(param): Observable<any> {
    return this.http.post(`${this.apiUrl}/resend-verification-email`, param).pipe(map((data: any) => {
      return data;
    }));
  }

  private processLoginData(data: any) {
    this.storage.accessToken = data.token.access;
    this.storage.refreshToken = data.token.refresh;
    this.isLoggedIn = true;
    if (data.user) {
      this.storage.user = data.user;
    }
  }

  private processLogoutData(redirect?: boolean) {
    this.storage.accessToken = null;
    this.storage.refreshToken = null;
    this.storage.user = null;
    this.storage.image = null;

    this.router.navigate(['login']);
  }

  logout(redirect?: boolean) {
    this.processLogoutData(redirect);
    localStorage.clear();
  }

  forgotPassword(dataRequest: any) {
    return this.http.put(`${this.apiUrl}/forgot-password`, dataRequest);
  }

  changePassword(data: any) {
    return this.http.put(`${this.apiUrl}/change-password`, data).pipe(map((resp: any) => {
      this.processLoginData(resp);
      return resp;
    }));
  }

  resetPassword(data: any) {
    return this.http.put(`${this.apiUrl}/reset-password`, data).pipe(map((resp: any) => {
      this.processLoginData(resp);
      return resp;
    }));
  }

  checkForTiming() {
    if (this.storage.accessToken) {
      const data = new JwtHelperService().decodeToken(this.storage.accessToken);
      if (!data.exp || data.exp * 1000 <= new Date().getTime()) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  verify(token) {
    return this.http.post(`${this.apiUrl}/verify`, {token}).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  subscribeVisitor(data) {
    return this.http.post(`${this.apiUrl}/register-visitor`, data);
  }
}
