import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";


@Injectable({ providedIn: 'root'})
export class LandingLogicService {
  sliderPositions$ = new BehaviorSubject<any>(null);
  sliderPositionObservable: Observable<any> = this.sliderPositions$.asObservable()
  constructor() {}

  setPosition(value){
    this.sliderPositions$.next(value);
  }
}
