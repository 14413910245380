import {AgreementStatus} from '@core/common/interfaces';

export class OpportunityModel {
  constructor(host) {
    this.imgUrl = host;
  }

  agreementStatus: AgreementStatus;
  imgUrl: string;
  id: string;
  photo: string;
  genre: string[];
  publisher: string;
  writers: string[];
  composers: string[];
  songName: string;
  biography: string;
  aboutSong: string;
  producer: string;
  genresPlay: string[];
  wouldInvest: number;
  playMusic: number;
  producersShare: any[];
  artistsShare: any[];
  becomeFan: number;
  userRating: number;
  videoclip: string;
  slug: string;
  artistShare: number;
  totalInvestorsShares: number;
  producerShare: number;
  labelShareDistribution: number;
  termOfRoyaltiesPublishingRights: number;
  termOfRoyaltiesMasterRights: number;
  projectDuration: number;
//  videoImage: string;
  royaltiesFromMaster: boolean;
  royaltiesFromPublishingRights: boolean;
  projectTitle: string;
  myOffersDetails: string;
  priceForShare: number;
  label: string;
  isPublished: boolean;

  setFromApiModel(apiModel): this {
    this.genre = apiModel.genre;
    this.publisher = apiModel.publisher;
    // if (apiModel.videoImage) {
    //   this.videoImage = apiModel.videoImage.indexOf('project-images') >= 0 ? `${this.imgUrl}/${apiModel.videoImage}` : `${this.imgUrl}/images/${apiModel.videoImage}`;
    // } else {
    //   this.videoImage = null;
    // }

    if (apiModel.photo) {
      this.photo = apiModel.photo.indexOf('project-images') >= 0 ? `${this.imgUrl}/${apiModel.photo}` : apiModel.photo.indexOf('images/') >= 0 ? `${this.imgUrl}/${apiModel.photo}` : `${this.imgUrl}/images/${apiModel.photo}`;
    } else {
      this.photo = null;
    }
    if (apiModel.agreementStatus) {
      this.agreementStatus = apiModel.agreementStatus;
    }
    this.videoclip = apiModel.videoclip ? `${this.imgUrl}/${apiModel.videoclip}` : null;
    this.writers = apiModel.writers.map(item => {
      return {
        name: item.name ? item.name : item,
        user: item.user,
        slug: item.slug
      };
    });
    this.id = apiModel._id;
    this.myOffersDetails = apiModel.myOffersDetails;
    this.genresPlay = apiModel.genresPlay;
    this.composers = apiModel.composers.map(item => {
      return {
        name: item.name ? item.name : item,
        user: item.user,
        slug: item.slug
      };
    });
    this.producersShare = apiModel.producersShare;
    this.songName = apiModel.songName;
    this.biography = apiModel.biography;
    this.producer = apiModel.producer;
    this.aboutSong = apiModel.aboutSong;
    this.artistShare = apiModel.artistShare;
    this.totalInvestorsShares = apiModel.totalInvestorsShares;
    this.producerShare = apiModel.producerShare;
    this.artistsShare = apiModel.artistsShare;
    this.labelShareDistribution = apiModel.labelShareDistribution;
    this.termOfRoyaltiesPublishingRights = apiModel.termOfRoyaltiesPublishingRights;
    this.termOfRoyaltiesMasterRights = apiModel.termOfRoyaltiesMasterRights;
    this.projectDuration = apiModel.projectDuration;
    this.royaltiesFromMaster = apiModel.royaltiesFromMaster;
    this.royaltiesFromPublishingRights = apiModel.royaltiesFromPublishingRights;
    this.projectTitle = apiModel.projectTitle;
    this.priceForShare = apiModel.priceForShare;
    this.isPublished = apiModel.isPublished;
    this.label = apiModel.label;
    this.slug = apiModel.slug;
    if (apiModel.statistic) {
      this.wouldInvest = apiModel.statistic.wouldInvest ? apiModel.statistic.wouldInvest.length : 0;
      this.becomeFan = apiModel.statistic.becomeFan ? apiModel.statistic.becomeFan : 0;
      this.playMusic = 0;
      if (apiModel.statistic.playMusic && apiModel.statistic.playMusic.length) {
        apiModel.statistic.playMusic.forEach(item => {
          this.playMusic += Number(item.count);
        });
      }
      this.userRating = this.playMusic + this.becomeFan + this.wouldInvest;
    }


    return this;
  }

}

