import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {AppToastrService, UsersService} from '../../../../services';
import {TYPE_RIGHTS, TYPE_ARTIST} from "@core/common/recoupable_constant";

type typeCost = 'Advance' | 'Cost'
interface IRecoupable {
  type: typeCost;
  typeID: number;
  isProducer: boolean;
  title: string;
  rights: number;
  netAmount: number;
  vat: number;
  vatAmount: number;
  recoupablePercent: number;
  recoupableAmount: number;
  grossRecoupable: number;
  date: string;
  time: string;
  dateToShow: string;
}

@Component({
  selector: 'app-advances-popup',
  templateUrl: './advances-popup.html'
})
export class AdvancesPopupComponent implements OnInit {

  TYPE_ARTIST: any = TYPE_ARTIST;
  type_rights: any[] = [];
  form: FormGroup;
  submitPressed: boolean;

  @Input() advance: any;
  @Input() balanceId: any;
  @Input() typeID: any;
  @Input() artist: any;
  @Input() settings: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  constructor(private fb: FormBuilder,
              private toastr: AppToastrService,
              private userService: UsersService) {
  }

  ngOnInit(): void {
    this.convertRights();
    this.prepareForm();
  }

  get existRecoupable() {
    return this.advance;
  }

  convertRights(){
    for(let key in TYPE_RIGHTS){
      if(TYPE_RIGHTS.hasOwnProperty(key)){
        this.type_rights.push({
          value: TYPE_RIGHTS[key].value,
          name: TYPE_RIGHTS[key].name
        })
      }
    }
  }

  prepareForm(): void {
    this.form = this.fb.group({
      amount: ['', Validators.required],
      title: ['', Validators.required],
      rights: ['', Validators.required],
      recoupable: ['', Validators.required],
      isProducer: ['1', Validators.required],
      date: ['', Validators.required],
    });
    if (this.existRecoupable) {
      this.setForm();
    }
  }

  setForm(): void {
    this.form = this.fb.group({
      amount: this.advance.netAmount < 0 ? this.advance.netAmount * (-1) : this.advance.netAmount,
      title: this.advance.title,
      rights: this.advance.rights,
      recoupable: this.advance.recoupablePercent,
      isProducer: this.advance.isProducer,
      date: this.advance?.dateToShow,
    });
  }

  onClose(): any {
    this.notify.emit({type: 'close'});
  }

  vatSettings(settings) {
    const dateFrom: string = moment(settings.periodFrom, 'YYYY/MM/DD').format('X000');
    const dateTo: string = moment(settings.periodTo, 'YYYY/MM/DD').format('X000');
    const cdate: string = moment().format('X000');
    const setting_vat: number = settings.vat;
    return {dateFrom, dateTo, cdate, setting_vat};
  }

  getVAT(typeProducer: boolean): number {
    let vat: number = 0;

    const isAustria: boolean = this.artist.country === 'AT';
    const isProducer: boolean = typeProducer;
    const {dateFrom, dateTo, cdate, setting_vat} = this.vatSettings(this.settings);

    if (this.artist.vatNumber && isAustria) {
      if (cdate > dateFrom && cdate < dateTo && !typeProducer) {
        vat = Number(setting_vat);
      } else {
        vat = (isProducer || (!this.artist.isPrivate)) ? 20 : 13;
      }
    }

    return vat;
  }

  onSubmit(): any {

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.toastr.showToastFromError({error: 'Invalid form'});
    }

    if (this.form.valid) {
      let formValue = this.form.getRawValue();
      const vat = this.getVAT(+formValue.isProducer === 2);
      this.submitPressed = true;
      const day = moment(formValue.date).format('YYYY/MM/DD');
      const time = moment().format('HH:mm');
      const data: IRecoupable = {
        type: this.typeID === 1 ? 'Advance' : 'Cost',
        typeID: this.typeID,
        isProducer: formValue.isProducer == TYPE_ARTIST['PRODUCER'].value,
        title: formValue.title,
        netAmount: +formValue.amount,
        vat,
        rights: +formValue.rights,
        vatAmount: (vat / 100) * formValue.amount,
        recoupablePercent: +formValue.recoupable,
        recoupableAmount: (formValue.recoupable / 100) * +formValue.amount,
        grossRecoupable: 0,
        date:  moment(day + '' + time, 'YYYY/MM/DD HH:mm').toISOString(),
        time: moment(day + '' + time, 'YYYY/MM/DD HH:mm').format('X000'),
        dateToShow: moment(formValue.date).toISOString(),
      };

      const SELECTDAY: string = moment(day + '' + time, 'YYYY/MM/DD HH:mm').format('X000');
      const NOW: string = moment().format('X000');

      if (SELECTDAY < NOW) {
        data.date = moment().format('L HH:mm');
        data.time = NOW;
      }

      data.grossRecoupable = data.recoupableAmount + (data.recoupablePercent / 100) * +data.vatAmount;
      const recoupableId = this.advance?._id;

      this.userService[this.existRecoupable ? 'updateArtist' : 'addRecoupable'](data, this.artist, recoupableId)
        .subscribe(() => {
          this.submitPressed = false;
          this.toastr.showToast({title: 'ITEM has been added'});
          this.notify.emit({
            data,
            type: this.existRecoupable ? 'edit' : 'submit'
          });
        }, err => {
          this.submitPressed = false;
          this.toastr.showToastFromError(err);
        });
    }
  }
}
