import {SelectInterface} from "@core/interfaces/select.type";

export const WALLET_TYPE: SelectInterface[] = [
  {id: 1, name: 'Revenue wallet', title: 'deposit'},
  {id: 2, name: 'Refund Wallet', title: 'refund'}
];

export const CURRENCIES: SelectInterface[] = [
  {id: 1, name: 'USD', title: 'usd'},
  {id: 2, name: 'EUR', title: 'eur'}
]
