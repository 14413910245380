import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AppToastrService, UsersService} from '../../../services/index';
import {BehaviorSubject, Observable} from 'rxjs';
import {RequestUserModel} from "@core/model";
import {debounceTime, filter, shareReplay, switchMap} from "rxjs/operators";

type FindBy = 'name' | 'email';

@Component({
  selector: 'app-find-by-name',
  templateUrl: './find-by-name.html',
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindByNameComponent implements OnInit, OnChanges {
  form: UntypedFormGroup;
  private _listSubject = new BehaviorSubject<any[]>([]);
  @Input() slug: any;
  @Input() viewMode: boolean;
  @Input() control: UntypedFormGroup;
  @Input() selectedArtist: any;
  @Input() placeholder: string;
  @Input() type: FindBy;
  @Input() text: any;
  @Input() readonly: boolean = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private userService: UsersService
  ) {

  }

  ngOnInit(): any {
    this.form = new UntypedFormGroup(
      {
        searchValue: new UntypedFormControl(this.text || '')});
    this.form.get('searchValue').valueChanges.pipe(
      filter((v: any) => v !== null && v.length > 1),
      debounceTime(500),
      switchMap((value => {
          return this.searchText(value);
        }),
      ), shareReplay(1)).subscribe(result => {
      this._listSubject.next(result);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /**TODO make global FORMGROUP **/
    if(changes.text && changes.text.currentValue && !changes.text.firstChange){
      const value: string = changes.text.currentValue;
      this.form?.get('searchValue').setValue(value, {emitEvent: false})
    }
  }

  onChangeItem(selectedArtist: any): any {
    if (!this.selectedArtist) {
      this.selectedArtist = {};
    }
    this.selectedArtist = Object.assign(this.selectedArtist, selectedArtist);
    this.form.get('searchValue').setValue(selectedArtist.name, {emitEvent: false})
    this.userService.getArtistProjects(selectedArtist.slug).subscribe(resp => {
      if (this.control) {
        this.control.get('name').setValue(selectedArtist.name);
        this.control.get('slug').setValue(selectedArtist.slug);
        this.control.get('id').setValue(selectedArtist.id);
        this.control.get('user').setValue(selectedArtist.id);
      }
      selectedArtist.projects = resp?.projects || [];
      this.selectedArtist = {...selectedArtist};
      this.notify.emit({type: 'selectedItem', data: selectedArtist});
      this._listSubject.next([]);
    })
  }

  get list$(): Observable<any[]> {
    return this._listSubject.asObservable();
  }

  getArtist() {
    return this.selectedArtist;
  }

  searchText(value): Observable<any> {
    let param_data = {
      select: 'base',
      [this.type === 'email' ? 'email' : 'name']: value
    }
    const request = new RequestUserModel(param_data);
    return this.userService.findUser(request);
  }
}

