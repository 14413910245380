import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AppToastrService, UsersService} from '../../../services/index';
import {IFindUserByParam} from '../../../interfaces/user.interface';
import {RequestUserModel} from '../../../model/index';

@Component({
  selector: 'app-find-user-statement-upload',
  templateUrl: './find-user-statement-upload.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindUserStatementUploadComponent {
  @Input() categories: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  categorySelected = '';
  searchValue: string;
  findUser: boolean;
  searchListResult: any[];

  constructor(private profile: UsersService, private toastr: AppToastrService) {
  }

  onSearch(): void {
    const requestParam: IFindUserByParam = {};
    this.findUser = false;
    requestParam[this.categorySelected] = this.searchValue.trim();
    const request = new RequestUserModel(requestParam);
    this.profile.findUser(request).subscribe(resp => {
      this.searchListResult = resp;
      this.notify.emit({data: resp, type: 'find-user-statement-upload'});
      this.findUser = true;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  selectCategory(): void {
    this.searchValue = null;
    this.findUser = false;
    this.notify.emit({type: 'select-category'});
  }
}

