<div class="popup-wrapper">
  <span class="lnr lnr-cross default-cross" (click)="onCloseItem()"></span>
  <div class="inner">
    <div class="title">
      {{title}}
    </div>
    <div class="body">
      <div class="content">
        <ng-content select="#content"></ng-content>
      </div>
      <div class="footer">
        <ng-content select="#footer"></ng-content>
      </div>
    </div>
  </div>
</div>
