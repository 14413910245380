import {NgModule} from '@angular/core';
import {PopupComponent} from '@core/component/global/popup-items/popup/popup.component';
import {PopupTemplateComponent} from '@core/component/global/popup-items/popup-template/popup-template.component';
import {CommonModule} from "@angular/common";
import {
  PopupConfirmationComponent
} from "@core/component/global/popup-items/popup-confirmation/popup-confirmation.component";

@NgModule({
  declarations: [
    PopupComponent,
    PopupTemplateComponent,
    PopupConfirmationComponent
  ],
  exports: [
    PopupComponent,
    PopupTemplateComponent,
    PopupConfirmationComponent
  ],
  imports: [
    CommonModule
  ],
})

export class PopUpModule {
}
