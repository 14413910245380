import { Component, OnInit } from '@angular/core';
import { AppToastrService } from '../../../services/index';
import { RequestModel } from '@core/model/request.model';
import {
  CollectibleService,
  IEditionList,
} from '@core/services/collectible.service';
import { ConfirmPopup } from '@core/common/interfaces';
import { STATE_NAMES } from '@core/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';


interface Filter {
  paging: {
    page: number;
    offset: number;
    limit: number;
  };
  sorting: {
    sortBy: string;
    sortHow: string;
  };
}

@Component({
  selector: 'app-auction-market-list',
  templateUrl: './auction-market-list.html',
  styleUrls: ['./_auction-market-list.scss'],
})
export class AuctionMarketListComponent implements OnInit {
  isLoaded: boolean;
  total = 1;
  list: IEditionList[];
  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc',
    },
  };
  form: FormGroup;
  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Reject',
    btnCancel: true,
    submitEmit: 'rejectAuction',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to reject?',
  };
  submitBtn: boolean;
  showPopupConfirmation: boolean;
  selectedItem: IEditionList;

  constructor(
    private collectibleService: CollectibleService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: AppToastrService,
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    const request = new RequestModel(this.filter);
    this.collectibleService._getAuctionMarketList(request).subscribe(
      (resp) => {
        this.list = resp.list;
        this.isLoaded = true;
        this.total = resp.total;
      },
      (err) => {
        this.toastr.showToastFromError(err);
      }
    );
  }

  onNotify(e): void {
    if (e.type === 'rejectAuction') {
      this.approveAuction(this.selectedItem, false);
    }
    if (e.type === 'close') {
      this.selectedItem = null;
    }
  }


  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }

  rejectAuction(item) {
    this.form = this.fb.group({
      description: new FormControl(''),
    });

    this.selectedItem = item;
  }

  approveAuction(item: IEditionList, approve?: boolean): any {
    item.submitBtn = true;
    let data = {};
    if (approve) {
      data = {
        editions: [
          {
            uniqueId: item.collectibleEdition.uniqueId,
            internalNumber: 1,
          },
        ],
        walletId: item.collectibleEdition.walletId,
        amount: item.startingPrice,
        endDate: item.endDate,
        startDate: item.startDate,
        status: 'approve',
        isSecondaryOffering: true,
        marketId: item.id,
      };
    } else {
      data = {
        status: 'reject',
        errorDescription: this.form.get('description').value,
      };
    }

    this.collectibleService[approve ? '_saveAuctionMarket' : '_updateMarket'](
      data,
      item.id
    ).subscribe(
      () => {
        this.form.reset();
        this.toastr.showToast({ title: `Status has been changed` });
        this.router.navigate([STATE_NAMES.auctionMarket]).then(() => {
          window.location.reload();
        });
      },
      (err) => {
        this.toastr.showToastFromError(err);
        this.router.navigate([STATE_NAMES.auctionMarket]).then(() => {
          window.location.reload();
        });
      }
    );
  }
}
