<div class="dialog">
  <div class="dialog-wrapper">
    <div class="dialog-header">
      <h2 class="dialog-title">Submission details:</h2>
    </div>

    <div class="dialog-content">
      <div class="item">
        <h3 class="item-name">
          Project info
        </h3>
        <div class="item-content flex-column">
          <div class="item-col-raw">
            <div class="raw-item-filed standard-font">
              ID:
            </div>
            <div class="raw-item-value standard-font value">
              {{submittedSong?.id}}
            </div>
          </div>
          <div class="item-col-raw">
            <div class="raw-item-filed standard-font">
              Artist
            </div>
            <div class="raw-item-value standard-font value">
              {{submittedSong?.artistName}}
            </div>
          </div>
          <div class="item-col-raw">
            <div class="raw-item-filed standard-font">
              Slug:
            </div>
            <div class="raw-item-value standard-font value">
              {{submittedSong?.slug}}
            </div>
          </div>
        </div>
        <div class="item-content genres" *ngIf="!isEdit && submittedSong?.project?.genresPlay?.length">
          <span class="border-box-blue blue" *ngFor="let item of submittedSong?.project?.genresPlay">
            {{item}}
          </span>
        </div>
      </div>
      <app-submission-fields [settings]="settings"
                             [showCancel]="true"
                             *ngIf="isEdit"
                             [submittedSong]="submittedSong"
                             (notify)="onNotify($event)"></app-submission-fields>
      <div class="section-detailing" *ngIf="!isEdit">
        <ng-template ngIf [ngIf]="!isEdit">
          <div class="song-wrapper">
            <div class="item-song">MP3</div>
            <div class="item-song">
              <audio controls>
                <source [src]="submittedSong?.audio" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>

          <div class="item-raw flex">
            <div class="item-producer-col flex-33">
              <span class="standard-font">
                Composers:
              </span>
              <div class="standard-font value">
                <ng-template ngFor let-item [ngForOf]="submittedSong?.project?.composers" let-i="index">
                  {{item?.name}} <br>
                </ng-template>
              </div>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.composers?.length">
                No composers
              </div>
            </div>
            <div class="item-producer-col flex-33">
               <span class="standard-font">
                Lyrics author:
              </span>
              <div class="standard-font value">
                <ng-template ngFor let-item [ngForOf]="submittedSong?.project?.writers" let-i="index">
                  {{item?.name}} <br>
                </ng-template>
              </div>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.writers?.length">
                No authors
              </div>
            </div>
            <div class="item-producer-col flex-33">
              <span class="standard-font">
                Producer:
              </span>
              <div class="standard-font value">
                {{submittedSong?.project?.producer}}
              </div>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.producer">
                None
              </div>
            </div>
          </div>
          <div class="item-raw flex-column">
           <span class="standard-font">
               Banner picture (rectangular)
              </span>
            <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.photo">
              No photo
            </div>
            <div class="photo" *ngIf="submittedSong?.project?.photo">
              <img [src]="submittedSong?.project?.photo">
            </div>
          </div>
          <div class="item-raw flex-column">
           <span class="standard-font">
               About the song
              </span>
            <div class=" standard-font value">
              {{submittedSong?.project?.aboutSong}}
            </div>
            <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.aboutSong">
              Not text yet
            </div>
          </div>
          <div class="item-raw flex-column">
           <span class="standard-font">
               About artist
              </span>
            <div class=" standard-font value">
              {{submittedSong?.project?.biography}}
            </div>
            <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.biography">
              Not text yet
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="isEdit">

        </ng-template>
      </div>
    </div>
    <div class="dialog-footer" *ngIf="!isEdit">
      <div class="footer-content">
        <div class="footer-wrapper">
          <div class="footer-item flex">
            <span>Rating:</span>
            <span class="star-cb-group-popup">
              <input type="radio" id="rating-5" name="rating" [ngModel]="submittedSong.rating" value="5"/>
              <label (click)="checkStar(5)"
                     for="rating-5">5</label>
              <input type="radio" id="rating-4" name="rating" [ngModel]="submittedSong.rating" value="4"/>
              <label (click)="checkStar(4)"
                     for="rating-4">4</label>
              <input type="radio" id="rating-3" name="rating" [ngModel]="submittedSong.rating" value="3"/>
              <label (click)="checkStar(3)"
                     for="rating-3">3</label>
              <input type="radio" id="rating-2" name="rating" [ngModel]="submittedSong.rating" value="2"/>
              <label (click)="checkStar(2)"
                     for="rating-2">2</label>
              <input type="radio" id="rating-1" name="rating" [ngModel]="submittedSong.rating" value="1"/>
              <label (click)="checkStar(1)"
                     for="rating-1">1</label>
              <input type="radio" id="rating-0" name="rating" [ngModel]="submittedSong.rating" value="0"
                     class="star-cb-clear"/>
              <label (click)="checkStar(0)"
                     for="rating-0">0</label>
            </span>
          </div>
          <div *ngIf="status === Status?.UPCOMING" class="footer-item">
            <div class="m20-bottom">
              <mat-checkbox [(ngModel)]="toDoList.author_details" (change)="changeUpcomingStatus()">
                <span [ngClass]="{'warning': !toDoList?.author_details, 'green10': toDoList?.author_details}">Author details requested</span>
              </mat-checkbox>
            </div>

            <div class="m20-bottom">
              <mat-checkbox [(ngModel)]="toDoList.p_agreement_sent" (change)="changeUpcomingStatus()">
                <span [ngClass]="{'warning': !toDoList?.p_agreement_sent, 'green10': toDoList?.p_agreement_sent}">Publishing Agreements sent</span>
              </mat-checkbox>
            </div>

            <div class="m20-bottom">
              <mat-checkbox [(ngModel)]="toDoList.p_agreement_receive" (change)="changeUpcomingStatus()">
                <span [ngClass]="{'warning': !toDoList?.p_agreement_receive, 'green10': toDoList?.p_agreement_receive}">Publishing Agreements received</span>
              </mat-checkbox>
            </div>

            <div class="m20-bottom">
              <mat-checkbox [(ngModel)]="toDoList.a_agreement_sent" (change)="changeUpcomingStatus()">
                <span [ngClass]="{'warning': !toDoList?.a_agreement_sent, 'green10': toDoList?.a_agreement_sent}">Artist Agreement sent</span>
              </mat-checkbox>
            </div>

            <div>
              <mat-checkbox [(ngModel)]="toDoList.a_agreement_receive" (change)="changeUpcomingStatus()">
                <span [ngClass]="{'warning': !toDoList?.a_agreement_receive, 'green10': toDoList?.a_agreement_receive}">Artist Agreement received</span>
              </mat-checkbox>
            </div>

          </div>
        </div>
        <div class="item-comment">
         <span class="standard-font">
              Comment
              </span>
          <div class="standard-font value">
            {{submittedSong?.comment}}
          </div>
        </div>
        <div *ngIf="status !== Status?.REJECTED" class="button-wrapper">
          <button *ngIf="!isEdit && status === Status?.ACCEPTED" type="button" class="button blue-bgc"
                  (click)="remindAuthor()"
          >
            Remind the author
          </button>
          <button type="button" class="button blue" (click)="editSong()"
          >
            Edit project details
          </button>
          <button *ngIf="!isEdit && status === Status?.ACCEPTED" type="button" class="button green"
                  (click)="changeStatus(Status?.UPCOMING)"
                  [disabled]="notValidForm"
          >
            Publish talent
          </button>


          <ng-template [ngIf]="status === Status?.UPCOMING">
            <button type="button" class="button blue" (click)="changeStatus(Status.POSTPONE)"
            >
              Unpublish
            </button>
            <button type="button" class="button green" (click)="changeStatus(Status.OPPORTUNITY)"
            >
              Transfer to opportunity
            </button>
            <button type="button" class="button blue" (click)="changeStatus(Status?.REJECTED)"
            >
             Reject
            </button>
          </ng-template>

          <button type="button" class="button red delete" (click)="deleteSong()">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-popup-confirmation *ngIf="deleteConfig?.btnConfirm" (notify)="onNotify($event)"
                        [config]="deleteConfig"></app-popup-confirmation>
<div class="overlay" (click)="closeDialog()"></div>
