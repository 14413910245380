<div class="content-item" *ngIf="rightHolderGroup?.get('user')">
<app-find-by-name-universal-2
  [formControl]="rightHolderGroup.get('user')"
  class="item-field"
  selectBy="billing"
  [required]="false"
>
</app-find-by-name-universal-2>
</div>
<div class="content-item" *ngIf="rightHolderGroup?.get('company')">
<app-form-input [formControlItem]="rightHolderGroup?.get('company')">Company</app-form-input>
</div>
