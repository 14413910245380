import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-seo-project',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoComponent implements OnInit, OnChanges  {
  childForm: UntypedFormGroup;
  @Input() seo: any;

  constructor(private parentForm: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.childForm = this.parentForm.form;
    this.childForm.addControl('seo', new UntypedFormGroup({
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      keywords: new UntypedFormControl('')
    }))
    if(this.seo){
      this.seoGroup.patchValue(this.seo);
    }
  }

  ngOnChanges(value) {
    if(value.seo && this.childForm){
      this.seoGroup.patchValue(this.seo);
    }
  }
   get seoGroup(){
    return this.childForm.get('seo');
  }
}


