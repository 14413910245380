<app-page-template header="Auction Market">
  <div class="content-body" *ngIf="isLoaded">
    <table class='table table-collectible'>
      <thead>
      <tr>
        <th>#</th>
        <th>
          User
        </th>
        <th>
          Collectible
        </th>
        <th>
          Edition #
        </th>
        <th>
          Start Date
        </th>
        <th>
          End Date
        </th>
        <th>
          Amount
        </th>
        <th class="table-center table-cell table-cell--align">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let item of list; let i = index'>
        <td class="center">{{ i + 1 + filter?.paging?.offset}}</td>
        <td class="center">{{item?.tokenOwner?.contact?.email}}</td>
        <td class="center">{{item?.collectible?.song}}</td>
        <td class="center">{{item?.collectibleEdition?.internalNumber}}</td>
        <td class="center">{{item?.startDate | momentDateHours}}</td>
        <td class="center">{{item?.endDate | momentDateHours}}</td>
        <td class="center">{{item?.startingPrice}}</td>
        <td class="center td__actions">
          <div class="table__actions" *ngIf="!item?.auctionId && item?.status !== 'reject'">
            <button type="button"
                    [disabled]="item?.submitBtn"
                    class="button button-submit" (click)="approveAuction(item, true)">
              Approve
            </button>
            <button type="button"
                    class="button button-reject" (click)="rejectAuction(item)">
              Reject
            </button>
          </div>
          <div class="table__actions" *ngIf="item?.auctionId || item?.status === 'reject'"
               [class.success]="item?.status === 'approve'"
               [class.warning]="item?.status === 'reject'">
            {{item?.status | firstLetterUp}}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > filter?.paging?.limit && isLoaded">
      <app-pagination [total]="total" [paging]="filter?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>
  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>

</app-page-template>
<app-popup-confirmation [config]="popupConfig" *ngIf="selectedItem"
                        (notify)="onNotify($event)">
  <form [formGroup]="form">
    <mat-form-field class="item-field item-field_align">
      <mat-label>Description</mat-label>
      <input matInput
             id="description"
             formControlName="description"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </form>
</app-popup-confirmation>

