<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="title" *ngIf="!advance">{{typeID === 1 ? 'Add Advance' : 'Add Cost'}}</div>
    <div class="title" *ngIf="advance">{{advance?.typeID === 1 ? 'Edit Advance' : 'Edit Cost'}}</div>
    <form class="form" [formGroup]="form">
      <div class="form-row">
        <mat-form-field class="item">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title">
          <mat-error *ngIf="form?.get('title')?.invalid && form?.get('title')?.touched">Required*</mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="item">
          <mat-label>Net Amount</mat-label>
          <input matInput formControlName="amount">
          <mat-error *ngIf="form?.get('amount')?.invalid && form?.get('amount')?.touched">Required*</mat-error>
        </mat-form-field>

        <mat-form-field class="item">
          <mat-label>Recoupable(%)</mat-label>
          <input matInput formControlName="recoupable">
          <mat-error *ngIf="form?.get('recoupable')?.invalid && form?.get('recoupable')?.touched">Required*</mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill" class="item calendar">
          <mat-label>Input date</mat-label>
          <input matInput [matDatepicker]="picker" [formControl]="form?.get('date')">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form?.get('date')?.invalid && form?.get('date')?.touched">Required*</mat-error>
        </mat-form-field>
      </div>
      <section class="example-section bottom-margin-20">
        <mat-radio-group aria-label="Select an option" formControlName="isProducer">
          <mat-radio-button value="{{TYPE_ARTIST['ARTIST'].value}}">{{TYPE_ARTIST['ARTIST'].name}}</mat-radio-button>
          <mat-radio-button value="{{TYPE_ARTIST['PRODUCER'].value}}">{{TYPE_ARTIST['PRODUCER'].name}}</mat-radio-button>
        </mat-radio-group>
      </section>
      <section class="example-section bottom-margin-20">
        <mat-radio-group class="recoupable-rights" aria-label="Select an option" formControlName="rights">
          <mat-radio-button *ngFor="let right of type_rights" value="{{right.value}}">{{right.name}}</mat-radio-button>
        </mat-radio-group>
      </section>
      <div class="buttons-wrapper">
        <button class="button blue text-uppercase large-size"
                (click)="onClose()"> Cancel
        </button>
        <button class="button yellow text-uppercase large-size"
                (click)="onSubmit()"
                [disabled]="!form.valid || submitPressed">
          {{advance ? 'Update' : 'Add'}}
        </button>
      </div>
    </form>
  </div>
</app-popup>
