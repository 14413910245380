import {UploadConfigs} from '@core/interfaces/user.interface';
import { IWsEventType } from '@core/interfaces/websocket.interface';


export const MY_DATE_FORMAT = {
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const STATUS_SUBMISSION = {
  submitted: {
    id: 1,
    text: 'Pending review'
  },
  postpone: {
    id: 2,
    text: 'Postponed'
  },
  accepted: {
    id: 3,
    text: 'Info needed'
  },
  declined: {
    id: 4,
    text: 'Declined'
  },
  projectCreated: {
    id: 5,
    text: 'Project submitted'
  }
};
export const TYPE_TO_SALE = [{
  id: 1,
  text: 'Percent(%)'
},
  {
    id: 2,
    text: 'EURO'
  }
];
export const DEFAULT_SORT_PARAM = {
  sortBy: 'createdAt',
  sortHow: 'desc'
};
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const SOCIAL_VALIDATION = {
  spotify: {
    edit: false
  },
  youtube: {
    edit: false
  },
  soundcloud: {
    edit: false
  },
  instagram: {
    edit: false
  },
  tiktok: {
    edit: false
  },
  dropbox: {
    edit: false
  }
};
export const SONG_ARTIST_PRODUCER_LIST = 10;
export const PAYMENT_TYPE = [{id: 1, name: 'CB/VISA/Mastercard', title: 'visa'}, {id: 2, name: 'Maestro', title: 'maestro'}];
export const DOWNLOAD_TYPE = [{id: 1, name: 'PDF'}, {id: 2, name: 'CSV'}];
export const INVOICE_TYPE = [{id: 1, name: 'Invoices'}, {id: 2, name: 'Cancellations'}, {id: 3, name: 'All'}];

export const VOUCHER_ADMIN = {
  cardDesign: 'default',
  receiver: 'admin',
  message: 'generated by Admin'
};

export enum PROJECT_WALLET_TAB {
  NOT_TRANSFER = 'Not transferred',
  ALL = 'All'
}




export enum PATH_SAVE {
  VIDEO_ALL = 'project-videos',
  VIDEO_NFT = 'project-nft',
  IMAGE_NFT = 'project-nft-images',
  VIDEO_PREVIEW = 'preview-videos',
  VIDEO_NFT_UPDATE = 'nft-videos',
  IMAGE_PATH = 'project-image',
  DOCUMENT_POLICIES = 'documents',
  DOCUMENT_RIGHTS = 'rights',
}

export enum COLLECTIBLE_TYPE {
  FIXED_PRICE = 'fix',
  AUCTION = 'auction',
}

export const DATE_FORMAT = 'YYYY/MM/DD';
export const USER_CATEGORY = [
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'LastName',
    value: 'lastName'
  },
  {
    name: 'mangoWalletId',
    value: 'mangoWalletId'
  }
];
export const DATE_FORMAT_UI: string = 'DD.MM.YYYY';



export const IMAGE_SETTINGS_CARD: UploadConfigs = {
  imageFormat: 'png',
  ratio: 0.65,
  example: true,
  path: PATH_SAVE.IMAGE_PATH,
  allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP']
}

export const WS_EVENT_TYPE: any = {
  royalty_statement_complete: 'royalty_statement_complete',
  royalty_account_complete: 'royalty_account_complete',
  royalty_mangopay_complete: 'royalty_mangopay_complete',
  royalty_tag_complete: 'royalty_tag_complete',
}

