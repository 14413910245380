<app-page-template header="Releases">
  <div class="content-header">
    <div class="content-header-row1">
      <div class="wallet">
        <app-wallet-balance></app-wallet-balance>
        <app-create-pay-in></app-create-pay-in>
      </div>
    </div>
    <div class="content-header-row2">
      <div class="content-header-col">
        <button class="button green default-size" (click)="openRelease()">
          Create New
        </button>
      </div>
      <div class="content-header-col" *ngIf="isLoaded; else spinnerTemplate">
        <div class="wrapper deliver-amount">
          <span class="amount header-inform-text" *ngIf="allowToDistribute"
          >Total revenue amount of matched releases:
          </span>
          <span
            *ngIf="allowToDistribute"
            fundingGoalDirective
            [valueCurrency]="amountToDeliver"
          ></span>
        </div>
        <div class="upload-block">
          <div class="upload-buttons">
            <app-upload-file
              (dateChange)="onNotify($event)"
              [disabledParam]="allowToDistribute || (distService.matchedRelease$ | async)"
              [typeReport]="'radio'"
              [buttonName]="'Upload Publishing Rev (AKM/AUME format)'"
              [url]="'releaseBulkUpload'"
            ></app-upload-file>
            <app-upload-file
              (dateChange)="onNotify($event)"
              [disabledParam]="allowToDistribute || (distService.matchedRelease$ | async)"
              [typeReport]="'fuga'"
              [buttonName]="'Upload Master Rev (Fuga format)'"
              [url]="'releaseBulkUpload'"
            ></app-upload-file>
            <app-upload-file
              (dateChange)="onNotify($event)"
              [typeReport]="'tunecore'"
              [disabledParam]="allowToDistribute || (distService.matchedRelease$ | async)"
              [buttonName]="'Upload Master Rev (Tunecore format)'"
              [url]="'releaseBulkUpload'"
            ></app-upload-file>
          </div>
          <div class="upload-bulk flex">
            <button
              class="button green default-size m20-left button_default_height"

              (click)="transferAll()"
            >
              Distribution
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-header">
    <app-page-header>
      <div class="content-header-row2">
        <app-base-template-search
          (searchByFilter)="searchByFilter($event)"
          [config]="filterConfig"
        ></app-base-template-search>
        <app-filter-range-date
          class="range-filter"
          (searchByFilter)="searchByFilter($event)"
          [config]="filterConfig"
        ></app-filter-range-date>
      </div>
    </app-page-header>
  </div>
  <app-page-content *ngIf="isLoaded">
    <table class="table table-striped table-bordered table-hover dataTable">
      <thead>
      <tr>
        <th>#</th>
        <th

          key="artist"
        >
          <div>Artist</div>
        </th>
        <th

          key="song"
        >
          <div>SongName</div>
        </th>
        <th>Linked release</th>
        <th

          key="date"
          class="table-width-140"
        >
          <div>Release date</div>
        </th>
        <th

          key="revenue"
          class="table-width-140"
        >
          <div>Gross revenue</div>
        </th>
        <th>Take down from stores</th>
        <th class="table-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of list; let i = index">
        <td class="center">{{ i + 1 + filter?.paging?.offset}}</td>
        <td><a class="link-blue">{{item?.artist?.name}}</a></td>
        <td>
          {{(item.isAlbum || item.linkedProject) ? item.projectTitle :
          item?.song?.name}} {{item.linkedProject ? '(Linked Project)' : ''}}
        </td>
        <td>{{item?.linkedProject ? 'Yes' : ''}}</td>
        <td class="text-break">{{item?.date}}</td>
        <td><span>{{(item?.revenue || 0) | currency: 'EUR' }}</span>
          <span *ngIf="item.revenueUsd"> / {{item.revenueUsd | currency: 'USD'}}</span>
        </td>
        <td>{{item?.takenDown ? 'Yes' : 'No'}}</td>
        <td class="table-actions">
          <div class="actions">
            <div class="view actions-item" (click)="openRelease(item, true)">
              <i class="fas fa-file-alt"></i>
            </div>
            <div class="edit actions-item" (click)="openRelease(item)">
              <i class="fa fa-edit"></i>
            </div>

            <div class="delete actions-item" (click)="deleteRelease(item)">
              <i class="fa fa-trash-o"></i>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div
      class="wrapper-pagination-footer"
      *ngIf="total > filter?.paging?.limit && isLoaded"
    >
      <app-pagination
        [total]="total"
        [paging]="filter?.paging"
        (notify)="onPageChange($event)"
      ></app-pagination>
    </div>
  </app-page-content>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</app-page-template>
<ng-template #spinnerTemplate>
  <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>
