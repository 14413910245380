import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import {AuthenticationService} from "@core/services";



@Injectable({
  providedIn: 'root',
})
export class LogoutGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) {}
  canActivate(): UrlTree {
    this.authenticationService.logout();
    return this.router.createUrlTree(['login']);
  }
}
