import { Component, Input } from '@angular/core';
import { IOfferTeaser } from '@core/model/collectible.model';
import { UploadConfigs } from '@core/interfaces/user.interface';
import { PATH_SAVE } from '@core/common';
import { SettingService } from '@core/services/setting.service';

export class OfferTeaser {
  createItem(): IOfferTeaser {
    return {
      headline: '',
      text: '',
      icon: '',
    };
  }
}

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
})
export class TeaserComponent {
  imageConfig: UploadConfigs = {
    imageFormat: 'icon',
    ratio: 1,
    saveFormat: 'png',
    path: PATH_SAVE.IMAGE_PATH,
    allowFormats: [
      'jpg',
      'jpeg',
      'png',
      'PNG',
      'JPG',
      'JPEG',
      'webp',
      'WEBP',
      'svg',
    ],
  };
  innerProject: IOfferTeaser[] = [];

  _project: any;

  get project(): any {
    return this._project;
  }

  @Input() set project(value: any) {
    this._project = value;
    this.innerProject =
      this.project && this.project.offerTeaser ? this.project.offerTeaser : [];
  }

  constructor(private settingService: SettingService) {}

  addColumn(): void {
    if (this.innerProject.length < 3) {
      this.innerProject.push(new OfferTeaser().createItem());
    }
  }

  saveImage(e, item: IOfferTeaser): void {
    item.icon = e.value;
  }

  deleteColumn(index): void {
    if (this.innerProject.length) {
      this.innerProject.splice(index, 1);
    }
  }

  getValue(): IOfferTeaser[] {
    return this.innerProject?.map(this.removeDomainName.bind(this)) || [];
  }

  removeDomainName(singleTeaser: IOfferTeaser): IOfferTeaser {
    const isUrlWithDomain: boolean = singleTeaser.icon?.includes(
      this.settingService.contentUrl
    );

    singleTeaser.icon = isUrlWithDomain
      ? singleTeaser.icon.replace(`${this.settingService.contentUrl}/`, '')
      : singleTeaser.icon;
    return singleTeaser;
  }
}
