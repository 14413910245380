<div class="div-wrapper">
  <ng-template ngFor let-item [ngForOf]="stars" let-i="index">
  <span class="fa-star" [class.checked]="item <= rating"
        [class.fa]="item <= rating"
        [class.far]="item > rating"
  ></span>
  </ng-template>
</div>


