import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'firstLetterUp'})
export class FirstLetterUppercasePipe implements PipeTransform {
  transform(value, format?: string) {
    if (value && typeof value === 'string') {
      return value[0].toUpperCase() + value.slice(1);
    } else {
      return value;
    }
  }
}
