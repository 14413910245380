import * as moment from 'moment';

interface User {
  name: string;
  email: string;
}

interface IUserUpdate {
  email: string;
}
type Status = 'failed' | 'succeeded';


export interface IVoucher {
  owner: string;
  amount: number;
  message: string;
  statusTransaction: Status;
  cardDesign: string;
  receiver: User;
  buyer: User;
  isActivated: boolean;
  voucherCode: string;
  dateActivation: string;
  createdAt: string;
  refund: boolean;
  createdBy: IUserUpdate;
  updatedBy: IUserUpdate;
  left: number;
  id: string;
  refundStatus: string;
}
export class VoucherModel implements IVoucher{
  constructor() {}
  owner: string;
  amount: number;
  message: string;
  statusTransaction: Status;
  cardDesign: string;
  receiver: User;
  buyer: User;
  isActivated: boolean;
  voucherCode: string;
  dateActivation: string;
  createdAt: string;
  refund: boolean;
  createdBy: IUserUpdate;
  updatedBy: IUserUpdate;
  left: number;
  id: string;
  refundStatus: string;

  setFromApiModel(apiModel): this {
    Object.assign(this, apiModel);
    if (apiModel.createdBy) {
      this.createdBy = {
        email: apiModel.createdBy.contact.email
      };
    }
    if (apiModel.updatedBy) {
      this.updatedBy = {
        email: apiModel.updatedBy.contact.email
      };
    }
    if (apiModel.statusTransaction === 'succeeded') {
      this.left = apiModel.isActivated ? apiModel.leftAmount : apiModel.amount;
    }
    this.dateActivation = apiModel.dateActivation ? moment(apiModel.dateActivation, 'MM/DD/YYYY HH:mm').format('DD.MM.YYYY') : null;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY');
    return this;
  }
}

