<app-page-template header="Fans">
  <div class="search-block p20-top flex flex-around">
    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Search</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedSearchCategory" name="food">
            <mat-option *ngFor="let cat of searchFilter" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container range-block">
        <mat-form-field *ngIf="selectedSearchCategory" class="mat-form-field form-field" appearance="fill">
          <input matInput placeholder="Enter request" class="p10-y" [(ngModel)]="searchCategoryValue"
                 (keyup.enter)="searchByCategory()">
        </mat-form-field>
        <ng-container *ngIf="selectedSearchCategory">
          <div class="filter__actions">
            <span (click)="searchByCategory()"><i class="fas fa-search"></i></span>
            <span (click)="resetFilter('Search')"><i class="fas fa-refresh"></i></span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Category filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [formControl]="mainFilter">
            <mat-option *ngFor="let cat of categoryMainFilter" [value]="cat">
              {{cat}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container range-block">
        <div class="select__item">
          <mat-form-field *ngIf="mainFilter.value === 'State'"
                          class="mat-select d-inline" appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="stateFilter" multiple [disabled]="mainFilter.value !== 'State'">
              <mat-option *ngFor="let cat of categoryStateFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Featured'" class="mat-select d-inline"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="featuredFilter">
              <mat-option *ngFor="let cat of categoryFeaturedFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Country'" class="mat-select d-inline"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="countryFilter" multiple>
              <mat-option *ngFor="let cat of categoryCountryFilter" [value]="cat">
                {{cat.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <ng-container *ngIf="mainFilter.value === 'State'
                      || mainFilter.value ==='Featured'
                      ||  mainFilter.value === 'Country'">
          <div class="filter__actions">
            <span (click)="onSelectCategory()"><i class="fas fa-search"></i></span>
            <span (click)="resetFilter('Category')"><i class="fas fa-refresh"></i></span>
          </div>
        </ng-container>
      </div>

    </div>

    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Range filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedRangeFilter" name="food">
            <mat-option *ngFor="let cat of rangeFilter" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container range-block">
        <mat-form-field *ngIf="selectedRangeFilter" appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <ng-container *ngIf="selectedRangeFilter">
          <div class="filter__actions">
            <span (click)="searchByRange()"><i class="fas fa-search"></i></span>
            <span (click)="resetFilter('Range')"><i class="fas fa-refresh"></i></span>
          </div>
        </ng-container>

      </div>
    </div>

  </div>
  <div class="content-body" *ngIf="isLoaded">
    <table class='table table-striped table-bordered table-hover dataTable'>
      <thead>
      <tr>
        <th>#</th>
        <th  key="slug">
          <div>Slug</div>
        </th>
        <th  key="contact.lastName">
          <div>Name</div>
        </th>
        <th>Country</th>
        <th>State</th>
        <th  key="createdAt">
          <div>Created at</div>
        </th>
        <th  key="updatedAt">
          <div>Updated at</div>
        </th>
        <th class="table-center th-actions">Actions</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor='let item of fansList; let i = index'>
        <td class="center">
          {{ i + 1 + filter?.paging?.offset}}
        </td>
        <td><a class="link-blue" (click)="openItem(item)">{{item?.slug }}</a></td>
        <td>{{ item?.name ? item?.name : item?.contact?.lastName + ' ' + item?.contact?.firstName}}</td>

        <td class="text-break">{{item?.country}}</td>
        <td>{{item?.state}}</td>
        <td>{{item?.createdAt}}</td>
        <td class="table-td-160 word-break">{{item?.updatedAt}}</td>
        <td class="th-actions actions">
            <span class="view actions-item" (click)="openItem(item, true)">
              <i class="fas fa-file-alt"></i>
            </span>
          <span class="edit actions-item" (click)="openItem(item)">
              <i class='fa fa-edit'></i>
            </span>
        </td>
      </tr>

      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > filter?.paging?.limit && isLoaded">
      <app-pagination [total]="total" [paging]="filter?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>

  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</app-page-template>
