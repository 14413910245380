import { Component, Input, OnInit } from '@angular/core';
import { MatChipRemove } from '@angular/material/chips';
// Services
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  @Input() toggle: boolean = false;
  list: string[];

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe((list: string[]) => {
      this.list = list;
    });
  }

  removeItem(item: string): void {
    this.notificationService.dismiss(item);
  }
}
