import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Modules
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Modules
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UIRouterModule } from '@uirouter/angular';
import { GlobalTemplateModule } from '@core/component/global/global-template.module';
import { PopUpModule } from '@core/component/global/popup-items/pop-up.module';
import { ShareModule } from '@core/share.module';

// Services
import { UnsoldSharesService } from '@core/services/unsold_shares.service';

// Components
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { PayoutSettingsComponent } from './payout-settings/payout-settings.component';
import {ProjectDurationComponent} from "@core/component/projects-parts/project-duration/project-duration.component";
import {UiModule} from "@core/component/ui/ui.module";

@NgModule({
  exports: [
    ProjectSettingsComponent,
    ProjectDurationComponent,
    PayoutSettingsComponent],
  declarations: [
    ProjectSettingsComponent,
    ProjectDurationComponent,
    PayoutSettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,

    MatCheckboxModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    UiModule,
    GlobalTemplateModule,
    ShareModule,
    UIRouterModule,
    PopUpModule,
  ],
  providers: [DecimalPipe, UnsoldSharesService],
})
export class ProjectSettingsModule {}
