import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'form-item-wrapper',
  templateUrl: './form-item-wrapper.html',
  styleUrls: ['./form-item-wrapper.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormItemWrapperComponent {
  constructor() {
  }
}
