export const REVENUE_GROUPS = [{
  id: 1,
  text: 'All',
},
  {
    id: 2,
    text: 'Investors',
  },
  {
    id: 3,
    text: 'Artists',
  },
  {
    id: 4,
    text: 'Producers',
  }];
