import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {
  AppToastrService, AuthenticationService,
  ReleasesService,
} from '../../../services';
import {RequestModel} from '../../../model/request.model';
import {
  ROUTE_ALIAS,
  STATE_NAMES
} from '../../../common';
import {Dictionary} from '../../../model/dictionary.model';
import {UntypedFormBuilder, FormControl} from '@angular/forms';

import {ReleaseModel} from '@core/model/release.model';
import {TypeFilter} from '@core/model/common.model';
import {PermissionService} from '@core/services/permission.service';
import {ICurrency} from "@core/interfaces/currency.interface";
import {IFilter} from "@core/interfaces/filter.interface";
import {catchError, finalize, switchMap, filter, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import { Router } from '@angular/router';
import {DistributionService} from "@core/pages/releases/service/distribution.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
@UntilDestroy()
@Component({
  selector: 'app-release-list',
  templateUrl: './release-list.html',
  styleUrls: ['./_release-list.scss']
})


export class ReleaseListComponent implements OnInit {
  list: any[];
  host: string;
  isLoaded: boolean;

  total: number;
  deliverRevenue: any;
  countries: Dictionary[];
  existNotDelivering: boolean;
  loadFile: boolean;
  showPopup: boolean;
  revenueData: any;
  releaseRadio: boolean;
  allowToDistribute: boolean;
  amountToDeliver: ICurrency<number>;
  popupData: any = {};
  showPopupDelivery: boolean;
  date = new FormControl(moment());
  filter: IFilter = new TypeFilter();
  filterConfig = {
    search: true,
    searchOptions: ['Song Name', 'Artist Name'],
    category: false,
    range: true,
    rangeOptions: ['Created On', 'UpdatedAt']
  };

  constructor(private authService: AuthenticationService,
              private permissionsService: PermissionService,
              public distService: DistributionService,
              private toastr: AppToastrService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private releaseService: ReleasesService,
  ) {
  }

  ngOnInit(): void {
    this.getList();
    this.getStatusDistribution();
    this.getMatchedReportList();

  }

  openRelease(item?: any, isView?: boolean): void {
    const slug: string = item ? item.slug : 'newItem';
    const isViewMode: any = { viewMode: !!isView };
    this.router.navigate([STATE_NAMES.releases, 'item', slug], {state: isViewMode});
  }

  getMatchedReportList(){
    this.distService.getMatchedCsv().pipe(untilDestroyed(this)).subscribe();
  }

  deleteRelease(item: ReleaseModel): void {
    this.releaseService.deleteReleaseService(item.slug).subscribe(resp => {
      this.toastr.showToast({title: 'SUCCESS: Release was deleted'});
      this.getList();
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  onTypeChange(e): void {
    this.getList();
  }

  searchByFilter(e): void {
    if (e) {
      this.filter = Object.assign(this.filter, e);
      this.getList();
    }
  }

  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }

  redirectToDistribution(): void {
    this.router.navigate([STATE_NAMES.distribution]);
  }

  transferAll(): void {
    this.router.navigate([ROUTE_ALIAS.releaseDistribution]);
  }

  onNotify(e): void {

    if (e.type === 'sorting') {
      this.getList();
    }

    this.showPopupDelivery = false;
  }

  getList(): void {
    this.isLoaded = false;
    const request = new RequestModel(this.filter);
    this.releaseService.getReleases(request)
      .pipe(
        catchError((err) => {
          this.toastr.showToastFromError(err);
          return throwError(err);
        }),
        untilDestroyed(this),
        finalize(() => {
          this.isLoaded = true;
        })
      )
      .subscribe((resp) => {
        this.list = resp.list;
        this.total = resp.total;

      });
  }

  // get isDistributionAllowed(): boolean {
  //   return this.amountToDeliver?.eur > 0 || this.amountToDeliver?.usd > 0  ;
  // }

  private allowDist(resp){
    const hasAmountToDeliver = resp.amountToDeliver &&
      (resp.amountToDeliver.eur > 0 || resp.amountToDeliver.usd > 0);
    const hasStatus = resp.status && Object.keys(resp.status).length > 0;
    this.allowToDistribute = hasAmountToDeliver || hasStatus
  }

  private getStatusDistribution() {
    // this.distService.getStatusDistribution().pipe(
    //   tap((statusDistributionResponse => {
    //     this.allowDist(statusDistributionResponse);
    //     this.amountToDeliver = statusDistributionResponse.amountToDeliver;
    //   })),
    //   filter((resp => !resp.status || !resp.status.step)),
    //   switchMap((resp) => {
    //     return this.distService.getMatchedCsv().pipe(
    //       catchError((err) => {
    //         this.toastr.showToastFromError(err);
    //         return throwError(err);
    //       })
    //     );
    //   }),
    //   catchError((err) => {
    //     this.toastr.showToastFromError(err);
    //     return throwError(err);
    //   })).subscribe()

  }
}
