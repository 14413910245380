import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';
import {UploadService} from '@core/services';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsComponent implements OnInit {
  isEnabled: boolean;
  form: UntypedFormGroup;
  editorConfig: AngularEditorConfig = angularEditorParams;
  listCards = [];
  index = 0;
  @Input() landingCards: any;

  constructor(private fb: UntypedFormBuilder, private uploadService: UploadService) {
  }

  ngOnInit(): any {
    this.form = this.fb.group({
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      isEnabled: new UntypedFormControl(false)
    });
    if (this.landingCards) {
      this.setForm();
    }

  }

  setForm(): void {
    this.form.controls['title'].setValue(this.landingCards.title);
    this.form.controls['isEnabled'].setValue(this.landingCards.isEnabled);
    this.form.controls['description'].setValue(this.landingCards.description);
    this.landingCards.list.forEach(item => {
      this.index += 1;
      let nameControl = 'card' + this.index;
      this.listCards.push({id: this.index, name: nameControl});
      this.form.addControl(nameControl, this.fb.group({
        title: new UntypedFormControl(item.title),
        subTitle: new UntypedFormControl(item.subTitle),
        headline: new UntypedFormControl(item.headline),
        position: new UntypedFormControl(item.position),
        isEnabled: new UntypedFormControl(item.isEnabled),
        targetBlank: new UntypedFormControl(item.targetBlank),
        text: new UntypedFormControl(item.text),
        button: new UntypedFormControl(item.button),
        class: new UntypedFormControl(item.class),
        url: new UntypedFormControl(item.url),
        image: new UntypedFormControl(item.image),
        useImage: new UntypedFormControl(item?.useImage),
      }));
    });
  }

  getListCards() {
    let list = [];
    for (let i = 1; i <= this.listCards.length; i++) {
      let form = this.form.get('card' + i).value;
      form.image = form.image ? form.image : null;
      list.push(this.form.get('card' + i).value);
    }

    return {
      title: this.form.get('title').value,
      description:  this.form.get('description').value,
      isEnabled:  this.form.get('isEnabled').value,
      list: list
    };
  }

  deleteSlide(index: number): void {
    this.listCards.splice(index, 1);
  }

  addSlide() {
    this.index += 1;
    let nameControl = 'card' + this.index;
    this.form.addControl(nameControl, this.fb.group({
      title: new UntypedFormControl(''),
      subTitle: new UntypedFormControl(''),
      text: new UntypedFormControl(''),
      position: new UntypedFormControl(''),
      targetBlank: new UntypedFormControl(false),
      button: new UntypedFormControl(''),
      class: new UntypedFormControl(''),
      headline: new UntypedFormControl(''),
      isEnabled: new UntypedFormControl(false),
      url: new UntypedFormControl(''),
      image: new UntypedFormControl(''),
      useImage: new UntypedFormControl(false),
    }));
    this.listCards.push({id: this.index, name: nameControl});
  }
}

