import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
//import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {UIRouterModule} from '@uirouter/angular';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {MatFormFieldModule} from '@angular/material/form-field';

import {GlobalTemplateModule} from '@core/component/global/global-template.module';
import {ShareModule} from "@core/share.module";
import {PipesModule} from "../../pipes/pipes.module";

import {BurnTokenComponent} from "@core/component/nft-token-component/burn-token/burn-token.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {UiModule} from "@core/component/ui/ui.module";


@NgModule({
  exports: [
    BurnTokenComponent
  ],
  declarations: [
    BurnTokenComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalTemplateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    PipesModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    UiModule,
    MatCheckboxModule,
    MatInputModule,
    ShareModule,
  ],
  providers: [
  ]
})
export class TokenModule {
}
