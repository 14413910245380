<h2 class="content-name">Transfer tokens</h2>
<div class="content__item">
  <p>Transfer from:</p>
  <app-find-by-name-universal
    [type]="'email'"
    [formControlCustom]="owner"
    [placeholder]="'Email'"
    [saveParam]="'slug'"
    [errorText]="'User is not found'"
  ></app-find-by-name-universal>
</div>

<div class="content__item" *ngIf="list?.length">
  <p>Select song</p>
  <mat-form-field class="w-100">
    <mat-label>Song *</mat-label>
    <mat-select [formControl]="songControl">
      <mat-optgroup *ngFor="let project of list" [label]="project.projectTitle">
        <ng-container *ngIf="project?.songs?.length">
          <mat-option
            *ngFor="let song of project.songs; let i = index"
            [value]="song"
          >
            {{ song.name || "Song " + (i + 1) }}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!project?.songs?.length">
          <mat-option [value]="project.song">
            {{ project.song.name || "Song" }}
          </mat-option>
        </ng-container>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>

<div class="content__item">
  <p>Transfer to:</p>
  <app-find-by-name-universal
    [type]="'email'"
    [formControlCustom]="receiver"
    [placeholder]="'Email'"
    [saveParam]="'slug'"
    [errorText]="'User is not found'"
  ></app-find-by-name-universal>
</div>

<div class="content__item">
  <p>Transfer amount. Max amount: {{songControl?.value?.shares || '-'}}</p>
  <mat-form-field>
    <mat-label>Transfer amount *</mat-label>
    <input type="number" matInput [formControl]="shareControl" />
  </mat-form-field>
</div>

<div class="submit-block p20-top">
  <button
    class="button green default-size button-find-tokens"
    (click)="transfer()"
  >
    Transfer tokens
  </button>
</div>
