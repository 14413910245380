import {Component, Output, Input, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-popup-template',
  templateUrl: './popup-template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupTemplateComponent implements OnInit, OnDestroy {
  @Input() canCollapse: boolean;
  @Input() showCross: boolean;
  @Input() title: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit(): void {
    this.changeZIndex();
  }

  onCloseItem(): void {
    this.closeModal.emit();
  }

  ngOnDestroy(): void {
    this.changeZIndex(true);
  }

  changeZIndex(close?: boolean): void {
    const element = document.getElementsByTagName('app-navigation')[0];
    close ? element.classList.remove('change-index') : element.classList.add('change-index');
  }
}
