import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {AuthenticationService, StorageService} from 'app/services';
import {CustomFieldErrorMatcher, EMAIL_PATTERN, STATE_NAMES} from 'app/common';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-page',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})
export class LoginPageComponent implements OnInit {
  form: UntypedFormGroup;
  loginCtrl: UntypedFormControl;
  passwordCtrl: UntypedFormControl;
  submitPressed: boolean;
  user: any;


  constructor(private authenticationService: AuthenticationService,
              private storage: StorageService,
              private router: Router,
              private storageService: StorageService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.user = this.storageService.user;
    if (this.user) {
      this.router.navigate(['artists']);
    }
    this.loginCtrl = this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
    this.passwordCtrl = this.fb.control('', [Validators.required, Validators.minLength(4)]);
    this.form = this.fb.group({
      login: this.loginCtrl,
      password: this.passwordCtrl
    });
  }

  submit() {
    this.submitPressed = true;
    if (this.form.valid) {
      this.authenticationService.getToken({
        email: this.loginCtrl.value.trim(),
        password: this.passwordCtrl.value.trim()
      }).subscribe((resp) => {
        if (resp) {
          this.router.navigate(['artists']);
        }
      }, (err) => {
      });
    }
  }

  submitEnter(e) {
    if (e.which == 13 || e.keyCode == 13 || e.code == 'Enter') {
      this.submit();
    }
  }

  errorMatcher(control: UntypedFormControl) {
    return new CustomFieldErrorMatcher(control, this.submitPressed);
  }
}
