import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppToastrService, OpportunityService} from '../../../services/index';
import {Subscription} from 'rxjs';
import {SongModel, Status} from '../../../model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Filter} from '@core/pages/balances/list/balances-list.component';
import {MatDialog} from '@angular/material/dialog';
import {RequestModel} from '@core/model/request.model';
import * as moment from 'moment';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.html'
})
export class UpcomingComponent implements OnInit, OnDestroy {
  list: SongModel[];
  subscriptionList: Subscription;
  showDialog: boolean;
  form: UntypedFormGroup;
  monthFromControl: UntypedFormControl;
  monthToControl: UntypedFormControl;
  selectedItem: Partial<SongModel>;
  status = Status.UPCOMING;

  // range = new FormGroup({
  //   start: new FormControl(),
  //   end: new FormControl()
  // });
  listFilters: Filter;

  constructor(
    private opportunityService: OpportunityService,
    private toastr: AppToastrService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getList();
  }

  ngOnDestroy(): void {
    this.subscriptionList.unsubscribe();
  }

  prepareForm() {
    this.monthFromControl = this.fb.control('');
    this.monthToControl = this.fb.control('');
    this.form = this.fb.group({
      monthFrom: this.monthFromControl,
      monthTo: this.monthToControl,
    });
  }

  onDateChange(first?: boolean, type?: string): void {
    const filter: any = {};
    if (this.monthFromControl.value) {
      const day = moment(this.monthFromControl.value).format('YYYY/MM/DD');
      filter.start = day;
    }
    if (this.monthToControl.value && this.monthToControl.value >= this.monthFromControl.value) {
      const day = moment(this.monthToControl.value).format('YYYY/MM/DD');
      filter.end = day;
    }
    if(this.monthToControl.value && this.monthToControl.value < this.monthFromControl.value){
      this.toastr.showErrorToast({message:'Incorrect To Date'});
      this.monthToControl.setValue(this.fb.control(''));
    }
    this.getList(filter);
  }
  resetFilter(): void {
    this.monthFromControl.reset();
    this.monthToControl.reset();
    this.getList();
  }
  getList(param?): void {
    let req = {status: this.status};
    if (param) {
      req['dateFrom'] = moment(param.start).format('YYYY/MM/DD');
      req['dateTo'] = moment(param.end).format('YYYY/MM/DD');
    }
    const request = new RequestModel(req);
    this.subscriptionList = this.opportunityService.getSubmissions(request).subscribe(item => {
      this.list = item.opportunities || [];
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  editItem(item: SongModel): void {
    this.showDialog = true;
    this.selectedItem = item;
  }

  onNotify(e): void {
    this.showDialog = false;
    this.selectedItem = {};
    this.getList();
  }

  // onDateChange(e: any, day: string): void {
  //   this.getList();
  // }

  // openDialog(content): void {
  //   const dialogRef = this.dialog.open(content);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.getList(this.range.value);
  //   });
  // }
}
