import {Observable} from "rxjs";
import {InjectionToken} from "@angular/core";

export const API_INTERFACE = new InjectionToken<ApiInterface>('API_INTERFACE');

export interface ApiInterface {
  get<T>(endpoint: string, params?: any): Observable<T>;
  post<T>(endpoint: string, data?: any): Observable<T>;
  put<T>(endpoint: string, data: any): Observable<T>;
}
