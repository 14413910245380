<mat-form-field class="item">
  <mat-label>{{placeholder}}</mat-label>
  <input
    type="text"
    aria-label="User"
    matInput
    [formControl]="findUserService.form.get('searchValue')"
    [matAutocomplete]="AutoComplete"
    [readonly]="readonly"
  />
  <mat-error
    *ngIf="findUserService.form.get('searchValue').hasError('required') && this.required">
    This field is required
  </mat-error>
  <mat-autocomplete
    #AutoComplete="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option
      *ngFor="let item of (findUserService.list$ | async)"
      [value]="item"
      >{{ item?.showDisplayName }}</mat-option
    >
  </mat-autocomplete>
  <mat-error *ngIf="errorText">{{errorText}}</mat-error>
</mat-form-field>
