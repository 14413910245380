<table class='table table-striped table-bordered table-hover dataTable'>
  <thead>
  <tr>
    <th *ngFor="let header of headers">{{ header }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of rows">
    <td *ngFor="let header of headers">{{ row[header] }}</td>
  </tr>
  </tbody>
</table>
