import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {angularEditorParams} from "@core/common/angular-editor-config";

@Component({
  selector: 'app-free-text-list-item',
  templateUrl: './free-text-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeTextListItemComponent {
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() form: any;
  @Input() index: number;
  constructor() {

  }


}

