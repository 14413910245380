<table class='table table-striped table-bordered table-hover dataTable'>
  <thead>
  <tr>
    <ng-container *ngTemplateOutlet="headers"></ng-container>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of list; let i = index">
    <ng-container *ngTemplateOutlet="rows; context: {index: i, $implicit: row}"></ng-container>
  </tr>

  </tbody>
</table>
