<form [formGroup]="form">
  <div formGroupName="fees">
    <form-item-wrapper>
      <form-item-header>Token fees(%)</form-item-header>
      <div class="item-field list-item">
        <mat-form-field>
          <input matInput
                 id="feesAmount"
                 formControlName="feesAmount"
                 type="text" autocomplete="off"
          >
          <mat-error>Token fees has to be equal GRS fees + Artist fees</mat-error>
        </mat-form-field>
      </div>
    </form-item-wrapper>
    <form-item-wrapper>
      <form-item-header>GRS fees(%)</form-item-header>
      <div class="item-field list-item">
        <mat-form-field>
          <input matInput
                 id="grsFeesAmount"
                 formControlName="grs"
                 type="text" autocomplete="off"
          >
          <mat-error>GRS fees has to be equal All GRS fees</mat-error>
        </mat-form-field>
      </div>
    </form-item-wrapper>

    <form-item-wrapper>
      <form-item-header>
        <button class="button blue default-size" (click)="addControlItem('arrayGRSs')">Add GRS user</button>
      </form-item-header>
      <div class="item-field list-item">
        <div formArrayName="arrayGRSs"
             *ngFor="let item of form?.get('fees')?.get('arrayGRSs')?.controls; let i = index">
          <div formGroupName="{{i}}" class="list-artists">
            <app-find-by-name-universal
              [formControlCustom]="item?.get('user')"
              [placeholder]="'User email'"
              [selectParam]="'slug'"
              [type]="'email'"
            >

            </app-find-by-name-universal>
            <mat-form-field class="value-percentage">
              <input matInput
                     id="arrayGRSsValue"
                     placeholder="%"
                     formControlName="percent"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </form-item-wrapper>

    <form-item-wrapper>
      <form-item-header>Artist fees(%)</form-item-header>
      <div class="item-field list-item">
        <mat-form-field>
          <input matInput
                 id="artistFeesAmount"
                 formControlName="artist"
                 type="text" autocomplete="off"
          >
          <mat-error>Artist fees has to be equal All artists fees</mat-error>
        </mat-form-field>
      </div>
    </form-item-wrapper>
    <form-item-wrapper>
      <form-item-header>
        <button class="button blue default-size" (click)="addControlItem('arrayArtists')">Add artist</button>
      </form-item-header>
      <div class="item-field list-item">
        <div formArrayName="arrayArtists"
             *ngFor="let item of form?.get('fees')?.get('arrayArtists')?.controls; let i = index">
          <div formGroupName="{{i}}" class="list-artists">
            <app-find-by-name-universal
              [formControlCustom]="item?.get('user')"
              [placeholder]="'Artist email'"
              [selectParam]="'slug'"
              [type]="'email'"
            >
            </app-find-by-name-universal>
            <mat-form-field class="value-percentage">
              <input matInput
                     id="artistFeesAmountvalue"
                     placeholder="%"
                     formControlName="percent"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </form-item-wrapper>
  </div>

</form>
