<div class="button-wrapper flex m20-bottom add-revenue" *ngIf="!onlyView">
  <div class="table-title">Gross revenue history</div>
  <button
    class="button green default-size"
    *ngIf="!viewMode"
    [disabled]="!releaseItem"
    (click)="addRevenueTable('new')"
  >
    Add revenues from master rights
  </button>
  <button
    class="button green default-size m20-left"
    [disabled]="!releaseItem?.song?.project?.royaltiesFromPublishingRights || !releaseItem"
    *ngIf="!viewMode"
    (click)="addRevenueTable('new', true)"
  >
    Add revenues from publishing rights
  </button>
</div>
<table class="table table-striped table-bordered table-hover dataTable">
  <thead>
    <tr>
      <th width="20%">Date</th>
      <th width="20%">Type</th>
      <th width="20%" *ngIf="releaseItem?.isAlbum">Song Name</th>
      <th width="20%">Amount</th>
      <th width="20%">Title</th>
      <th width="20%">Target group</th>
      <th>Report</th>
      <th width="10%">Actions</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngIf="editRow">
    <td>
      <div class="item">
        <mat-form-field appearance="fill" class="item calendar">
          <input
            matInput
            [matDatepicker]="picker"
            [errorStateMatcher]="errorMatcher(dateCtrl)"
            [formControl]="dateCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </td>
    <td>{{isPublishing ? 'Publishing' : 'Master'}}</td>
    <td *ngIf="releaseItem?.isAlbum">
      <div class="item item-album" *ngFor="let song of releaseItem?.songs">
        {{song?.name}}
      </div>
    </td>
    <td>
      <div class="item" *ngIf="!releaseItem?.isAlbum">
        <mat-form-field class="content-item">
          <input
            matInput
            [errorStateMatcher]="errorMatcher(amountCtrl)"
            [formControl]="amountCtrl"
            id="amount"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="item" *ngIf="releaseItem?.isAlbum ">
        <div
          class="list-controls"
          *ngFor="let control of amountArrCtrl?.controls; let i = index"
        >
          <mat-form-field class="mat-item content-item">
            <input
              matInput
              id="amount2"
              type="text"
              autocomplete="off"
              [formControl]="amountArrCtrl.controls[i]"
            />
          </mat-form-field>
        </div>
      </div>
    </td>
    <td>
      <div class="item" *ngIf="!releaseItem?.isAlbum">
        <mat-form-field class="content-item">
          <input
            matInput
            [formControl]="correctionCtrl"
            id="correction"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="item" *ngIf="releaseItem?.isAlbum">
        <div
          class="list-controls"
          *ngFor="let control of correctionArrCtrl?.controls; let i = index"
        >
          <mat-form-field class="mat-item content-item">
            <input
              matInput
              id="amount2"
              type="text"
              autocomplete="off"
              [formControl]="correctionArrCtrl.controls[i]"
            />
          </mat-form-field>
        </div>
      </div>
    </td>
    <td>
      <div class="item">
        <mat-form-field class="mat-item">
          <mat-label>Type</mat-label>
          <mat-select [formControl]="groupCtrl">
            <mat-option *ngFor="let item of groups" [value]="item.id"
            >{{item.text}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </td>
    <td>
      <ng-template ngIf [ngIf]="releaseItem?.isAlbum">
        <div
          class="item item-song--height"
          *ngFor="let song of releaseItem?.songs; let i = index"
        >
          <div class="center" *ngIf="!song?.reportUrl">
            <label class="upload-button button">
              <input
                (change)="uploadfile($event.target, song, i)"
                type="file"
                class="desktop-upload"
                [disabled]="song.loadFile"
                maxlength="30"
                name="filefront"
              />
              <span>Upload report</span>
            </label>
          </div>
          <div class="url" *ngIf="song?.reportUrl">{{song?.reportUrl}}</div>
        </div>
      </ng-template>
      <ng-template ngIf [ngIf]="!releaseItem?.isAlbum">
        <div class="center">
          <label class="upload-button button" *ngIf="!reportUrl">
            <input
              (change)="uploadfile($event.target)"
              type="file"
              class="desktop-upload"
              [disabled]="loadFile"
              maxlength="30"
              name="filefront"
            />
            <span>Upload report</span>
          </label>
          <div class="url" *ngIf="reportUrl">{{reportUrl}}</div>
        </div>
      </ng-template>
    </td>
    <td>
      <div class="actions" *ngIf="!onlyView">
        <div class="save actions-icon" (click)="saveRevenue()">
          <i class="fas fa-save"></i>
        </div>
        <div class="close actions-icon" (click)="closeRevenue()">
          <i class="fas fa-times-circle"></i>
        </div>
      </div>
    </td>
  </tr>

  <tr *ngFor="let item of releaseItem?.revenueHistory; let i = index">
    <ng-template ngIf [ngIf]="!item?.edit">
      <td>
        <div class="table-vertical-align">{{item?.date}}</div>
      </td>
      <td class="sss">
        {{item?.isPublishing || item?.isRadio ? 'Publishing' : 'Master'}}
      </td>
      <td *ngIf="releaseItem?.isAlbum">{{item?.songName}}</td>
      <td>
        <span>{{item?.amount | currency: "EUR"}}</span>
        <span *ngIf="item?.amountUsd">
            / {{item?.amountUsd | currency: 'USD'}}</span
        >
      </td>
      <td class="text-break">{{item?.correction}}</td>
      <td>{{item?.deliveryGroup ? item?.deliveryGroup?.text : ''}}</td>
      <td>{{item?.report}}</td>
      <td class="col-button">
        <ng-template ngIf [ngIf]="!viewMode && !item?.delivered">
          <div class="actions" *ngIf="!onlyView">
            <div
              class="actions-icon edit"
              (click)="editRevenue(item)"
              *ngIf="!item?.edit"
            >
              <i class="fa fa-edit"></i>
            </div>
            <div
              class="delete actions-icon"
              (click)="deleteRevenue(item, i)"
              *ngIf="!item?.edit"
            >
              <i class="fa fa-trash-o"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngIf [ngIf]="item?.delivered">
          <span>Transferred</span>
        </ng-template>
      </td>
    </ng-template>
    <ng-template ngIf [ngIf]="item?.edit">
      <td>
        <mat-form-field appearance="fill" class="item calendar">
          <input
            matInput
            [matDatepicker]="picker"
            [errorStateMatcher]="errorMatcher(dateCtrl)"
            [formControl]="dateCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
      <td>{{item?.isPublishing ? 'Publishing' : 'Master'}}</td>
      <td *ngIf="releaseItem?.isAlbum">
        <!--<div class="item" *ngFor="let song of releaseItem?.releaseSongs">-->
        <!--{{song.songReleaseName[0]}}-->
        <!--</div>-->
      </td>
      <td>
        <mat-form-field class="content-item">
          <input
            matInput
            [errorStateMatcher]="errorMatcher(amountCtrl)"
            [formControl]="amountCtrl"
            id="amountEdt"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="content-item">
          <input
            matInput
            [formControl]="correctionCtrl"
            id="correctionEdit"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </td>
      <td>
        <div class="item">
          <mat-form-field class="mat-item">
            <mat-label>Type</mat-label>
            <mat-select [formControl]="groupCtrl">
              <mat-option *ngFor="let item of groups" [value]="item.id"
              >{{item.text}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </td>
      <td>
        <label class="upload-button button">
          <input
            (change)="uploadfile($event.target)"
            type="file"
            class="desktop-upload"
            maxlength="30"
            name="filefront"
          />
          <span>Upload report</span>
        </label>
        <div class="url">{{reportUrl}}</div>
      </td>
      <td>
        <div class="actions">
          <div class="save actions-icon" (click)="saveRevenue(item)">
            <i class="fas fa-save"></i>
          </div>
          <div class="close actions-icon" (click)="closeRevenue(item)">
            <i class="fas fa-times-circle"></i>
          </div>
        </div>
      </td>
    </ng-template>
  </tr>
  </tbody>
</table>
<app-popup-upload-revenue
  *ngIf="showPopup"
  [revenue]="popupData"
  (notify)="onNotify($event)"
></app-popup-upload-revenue>
