import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime, filter, shareReplay, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

// Services
import { UsersService } from '../../../services';

@Component({
  selector: 'app-find-producer-by-name',
  templateUrl: './find-producer-by-name.html',
})
export class FindProducerByNameComponent implements OnInit {
  @Input() viewMode: boolean;
  @Input() fControl: FormGroup;
  @Input() name: string;
  @Input() selectedArtist: any;
  @Input() producerWithSlug: any;
  @Input() text: any;
  @Input() placeholder: string;
  @Input() readonly: boolean = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  private _listSubject = new BehaviorSubject<any[]>([]);

  constructor(private userService: UsersService) {}

  ngOnInit(): any {
    if (this.fControl) {
      this.fControl
        .get('name')
        .valueChanges.pipe(
          filter((v: any) => v !== null && v.length > 2),
          debounceTime(500),
          switchMap((value) => {
            return this.searchText(value);
          }),
          shareReplay(1)
        )
        .subscribe((result) => {
          this._listSubject.next(result);
        });
    }
  }

  onChangeItem(selectedArtist: any): any {
    this.fControl.patchValue(selectedArtist, { emitEvent: false });
    this._listSubject.next([]);
  }

  get list$(): Observable<any[]> {
    return this._listSubject.asObservable();
  }

  searchText(value): Observable<any> {
    return this.userService.getProducerByName(value);
  }
}
