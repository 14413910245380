<main class="sliders">
  <div class="item-row">
    <div class="item-label header-inform-text-grey item-label_pad-20"></div>
    <div class="item-field item-field_align">
    <button class="button yellow color-white" (click)="addSlider()">Add Slider</button>
    </div>
  </div>

  <div class="item-row">
    <div class="item-label header-inform-text-grey item-label_pad-20">Is Enabled</div>
    <mat-checkbox class="item-field item-field_align" [(ngModel)]="sliders.isEnabled"
    >
    </mat-checkbox>
  </div>


  <div class="sliders__list" *ngIf="sliders?.list?.length">


    <div class="sliders__item" *ngFor="let item of sliders.list; let i = index">
      <h2>Slider #{{i + 1}}</h2>
      <div class="item-row">
        <div class="item-label header-inform-text-grey item-label_pad-20">Title</div>
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="mainTitle"
                 [(ngModel)]="item.mainTitle"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey item-label_pad-20">Sub Title</div>
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="title"
                 [(ngModel)]="item.title"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey item-label_pad-20">Button title</div>
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="title"
                 [(ngModel)]="item.text"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey item-label_pad-20">Button url</div>
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="title"
                 [(ngModel)]="item.url"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey item-label_pad-20">Slider image</div>
        <app-upload-image [config]="{imageFormat: 'png', ratio: 16 / 6, path: 'landing-images'}"
                          [value]="item.image"
                          [maxWidth]="'200px'"
                          [imgURL]="item?.imageURL"
                          (valueChange)="saveImage($event, item)"
        ></app-upload-image>
      </div>
    </div>
  </div>
</main>

