import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AppToastrService {
  constructor(private toastrService: ToastrService) {
  }

  showToast(data: any, params?: any) {
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});

    this.toastrService.success(data.message, data.title, obj);
  }

  showWarningToast(data: any, params?: any) {
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});

    this.toastrService.warning(data.message, data.title, obj);
  }

  showErrorToast(data: any, params?: any) {
    if (!data.title && !data.message) {
      data.title = 'Something went wrong';
      data.message = 'Please try again or contact support';
    }
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});
    this.toastrService.error(data.message, data.title, obj);
  }

  showToastFromError(err: any, disableTimeOut?: boolean) {
    if (err.error && err.error.modelState) {
      let list = [];
      for (const key in err.error.modelState) {
        if (err.error.modelState.hasOwnProperty(key)) {
          list = list.concat(err.error.modelState[key]);
        }
      }
      this.showErrorToast({
        title: err.error.message,
        message: list.join(' ')
      });
    } else if (err.error && err.error.message) {
      this.showErrorToast({
        title: err.error.message,
        disableTimeOut
      });
    } else if (err.error && err.error.info) {
      this.showErrorToast({
        title: err.error.info,
        // message: err.error.message,
        disableTimeOut
      });
    } else
    {
      this.showErrorToast({});
    }
  }
}
