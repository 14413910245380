import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, UploadService} from '../../../services';
import {DOWNLOAD_TYPE, INVOICE_TYPE, MY_FORMATS} from '../../../common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {RequestModel} from "@core/model/request.model";
interface Idownload {
  typeFile: string;
  dateFrom: string;
  invoiceType?: string;
}
@Component({
  selector: 'app-download-invoice',
  templateUrl: './download_invoice.html',
  styleUrls: ['./download_invoice.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DownloadInvoiceComponent implements OnInit {
  invoiceFileFormat: string;
  downloadType = DOWNLOAD_TYPE;
  invoiceTypeList = INVOICE_TYPE;
  showSpinner: boolean;
  _invoiceDate: string;
  invoiceType: string;
  urlZipFile: string;

  constructor(private upload: UploadService,
              private cdr: ChangeDetectorRef,
              private toastr: AppToastrService
  ) {
  }

  ngOnInit(): void {
  }
  selectMonth(e, passDate) {
    this[passDate] = moment(e.data).format('YYYY/MM');
  }
  downloadInvoices(): void {
    this.showSpinner = true;
    if (this.invoiceFileFormat) {
      const data: Idownload = {
        typeFile: this.invoiceFileFormat.toLowerCase(),
        dateFrom: this._invoiceDate,
      };
      if (this.invoiceType) {
        data.invoiceType = this.invoiceType.toLowerCase();
      }
      const request = new RequestModel(data);
      this.upload.getInvoices(request).subscribe(res => {
        this.showSpinner = false;
        if (data.typeFile === 'csv') {
          const a = document.createElement('a');
          a.href = 'data:attachment/csv,' + encodeURIComponent(res.data);
          a.target = '_blank';
          a.download = 'Invoices_' + data.dateFrom + '.csv';
          a.click();
        } else {
          this.urlZipFile = res.data;
        }
        this.cdr.detectChanges();
      }, err => {
        this.cdr.detectChanges();
        this.toastr.showToastFromError(err);
      });
    }
  }
}

