import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
@Pipe({name: 'momentDateHours'})
export class MomentDatePipeHours implements PipeTransform {
  transform(value, format?: string) {
    if (value) {
      return moment(value).format(format || 'DD.MM.YYYY HH:mm');
    }
  }
}
