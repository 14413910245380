import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';

import {tap} from 'rxjs/operators';
import {AuthenticationService} from '@core/services';


@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private authService: AuthenticationService;

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
    }, (err: any) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.authService = this.injector.get(AuthenticationService);
        this.authService.logout(true);
      }
    }));
  }
}
