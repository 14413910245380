<section class="cropper">
  <ng-container class="crop" *ngIf="!form.get('image').value">
    <image-cropper *ngIf="config?.imageFormat === 'png'"
                   [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="!!maintainAspectRatio"
                   [aspectRatio]="config?.ratio"
                   [disabled]="!!config?.disabled"
                   format="webp"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoadedw($event)"
                   (cropperReady)="cropperReady()"
                   (loadImageFailed)="loadImageFailed($event)"
    ></image-cropper>
    <image-cropper *ngIf="config?.imageFormat === 'icon'"
                   [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="!!maintainAspectRatio"
                   [aspectRatio]="config?.ratio"
                   format="png"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoadedw($event)"
                   (cropperReady)="cropperReady()"
                   (loadImageFailed)="loadImageFailed($event)"
    ></image-cropper>
    <button type="button" class="button yellow" *ngIf="imageLoaded" (click)="savePhoto($event)">
      Crop and Save
    </button>
  </ng-container>
  <ng-container *ngIf="form.get('image').value as imageValue">
    <img class="upl__image" src="{{imageValue  | convertImageUrl: 'images'}}"/>
    <span class="upl__name" >
        <i class="fas fa-times-circle img-close" (click)="onRemove()"></i>
        <span>{{imageValue | convertImageUrl: 'images'}}</span>
    </span>
  </ng-container>
</section>


