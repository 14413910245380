import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AbstractBaseFindService } from '@core/component/find-user-template/abstract-base-find.service';
import { FindUserService } from '@core/component/find-user-template/find-user.service';

type FindBy = 'name' | 'email' | 'contactLastName';

@Component({
  selector: 'app-find-by-name-universal-2',
  templateUrl: './find-by-name-universal2.html',
  styleUrls: ['./find-by-name-universal2.scss'],
  providers: [
    {
      provide: AbstractBaseFindService,
      useClass: FindUserService,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FindByNameUniversal2Component,
      multi: true,
    },
  ],
})
export class FindByNameUniversal2Component
  implements OnInit, ControlValueAccessor
{
  @Input() findBy: FindBy = 'email';

  @Input() selectBy: string = 'bank';

  @Input() errorText: string = null;

  @Input() placeholder = 'Type to search';

  @Input() readonly: boolean = false

  @Input() paramDisplay: string;

  @Input() required: boolean = true;

  onChange: any;

  onTouched: any = () => {};

  value: any;

  constructor(public findUserService: AbstractBaseFindService) {}

  ngOnInit(): void {
    this.findUserService.typeSelect = this.selectBy;
    this.findUserService.paramDisplay = this.paramDisplay;
    this.findUserService.type = this.findBy;
    if (!this.required) {
      this.findUserService.form.get('searchValue').clearValidators();

    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.onChange(event.option.value);
    this.findUserService.form
      .get('searchValue')
      .setValue(this.getUserPropValue(event.option.value, this.findBy));
  }

  writeValue(value: any): void {
    this.findUserService.form
      .get('searchValue')
      .setValue(this.getUserPropValue(value, this.findBy));
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.findUserService.form.get('searchValue').disable();
    } else {
      this.findUserService.form.get('searchValue').enable();
    }
  }

  private getUserPropValue(user: any, prop: FindBy): string {
    if (!user) return '';
    switch (prop) {
      case 'email':
        return user.contact.email;
      case 'name':
        return user.name;
      case 'contactLastName':
        return user.contact.lastName;
      default:
        break;
    }
  }
}
