<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onCloseItem()"></span>
    <div class="title">Deliver information</div>
    <div class="content">
      <div class="summary">
        <div class="item">
          <h4>TOTAL AMOUNT:</h4>
          <p>{{revenue[currency] | currency: currency.toUpperCase() : 'symbol'}}</p>
        </div>
      </div>
      <div class="flex flex-align button-wrapper">
        <button
          class="button blue text-uppercase wide-size"
          (click)="onCloseItem()"
        >
          Cancel
        </button>
        <button
          class="button green text-uppercase wide-size"
          [disabled]="buttonSubmit"
          (click)="onSubmit()"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</app-popup>
