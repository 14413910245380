import * as moment from 'moment';
//need to refactor

export class UsersModel {
  constructor() {
  }

  name: string;
  projects: any[];
  slug: string;
  user: string;
  type: string;
  date: string;
  albumTitle: string;
  id: string;
  social: any;
  projectDetail: any;
  isAlbum: boolean;

  setFromApiModel(apiModel): this {
    this.name = apiModel.name;
    this.id = apiModel._id;
    this.social = apiModel.social;
    this.projectDetail = apiModel.projectDetail;
    this.projects = apiModel.projects;
    this.slug = apiModel.slug;
    this.isAlbum = apiModel.isAlbum;
    this.albumTitle = apiModel.albumTitle;
    this.user = apiModel.user;
    this.type = apiModel.__t;
    return this;
  }
}

