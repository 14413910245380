import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { map, last, tap} from 'rxjs/operators';

@Injectable()
export class UploadService {
  apiUrl: string;
  contentUrl: string;
  private uploadProgressSource = new Subject<{ loaded: number, total: number }>();
  private uploadResult = new Subject<string>();
  uploadProgress$ = this.uploadProgressSource.asObservable();
  uploadResult$ = this.uploadResult.asObservable();
  constructor(private setting?: SettingService,
              private http?: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }

  uploadFileStream(file, options): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.apiUrl}/admin/upload-stream?${this.serialize(options)}`, formData);
  }

  uploadFilePartially(file, type, uploadUrl?, correction?,  options?): Promise<any> {
    let tries = 0;
    const param = {
      offset: 0,
      CHUNK_SIZE: uploadUrl ? (25 * 1024 * 1024) : (5 * 1024 * 1024)
    };
    const time = new Date().getTime();
    return new Promise((resolve, reject) => {
      let i = 0;
      const callback = (err, data, par) => {
        if (!err) {
          let url;
          if (uploadUrl) {
            url = uploadUrl;
          } else {
            url = (file.size <= par.CHUNK_SIZE) ? 'baseUpload' : ((par.offset >= file.size) ? 'partsUpload' : 'partsSave');
          }
          const params: any = {
            type,
            contentType: file.type,
            name: file.name.replace(/ /g, '-').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''),
            time,
            index: i
          };
          if (options) {
            Object.assign(params, options);
          }
          const headerDict = {
            'Content-Type': 'application/octet-stream',
          };
          if (!!correction) {
            console.error('111', correction);
            params.correction = correction;
          }
          const requestOptions: any = {
            headers: new HttpHeaders(headerDict),
          };
          this.http.put(`${this.apiUrl}/admin/${url}?${this.serialize(params)}`, data, requestOptions).subscribe(resp => {
            tries = 0;
            if (par.offset >= file.size) {
              resolve(resp);
            } else {
              i++;
              setTimeout(() => {
                this.findColumnLength(file, callback, par);
              }, 1000);
            }
          }, (err) => {
            if (!uploadUrl) {
              tries++;
              if (tries < 15) {
                setTimeout(() => {
                  i--;
                  callback(null, data, par);
                }, 4000);
              } else {
                reject(err);
              }
            } else {
              reject(err);
            }
          });
        }
      };
      this.findColumnLength(file, callback, param);
    });
  }

  private findColumnLength(file, callback, param?): any {
    const slice = file.slice(param.offset, param.offset + param.CHUNK_SIZE);
    param.offset += param.CHUNK_SIZE;
    callback(null, slice, param);
  }

  private serialize(obj): any {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  uploadFileAudioAdmin(file, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/upload-audio-file?${this.serialize(params)}`, file, {
      reportProgress: true,
      observe: 'events'
    })
     .pipe( tap(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgressSource.next({
          loaded: event.loaded,
          total: event.total
        });
      } else if (event instanceof HttpResponse) {
        this.uploadResult.next((event.body as any).url);
        this.uploadProgressSource.next({
          loaded: 0,
          total: 0
        });
      }
    }), last(), map((res: HttpResponse<any>)=> res.body));
  }

  getShortUrlName(name, params): string {
    if (params === 'url') {
      params = this.contentUrl;
    }
    return name.split(`${params}/`)[1];
  }

  getLongUrlName(name, folder?): string {
    const url = folder ? `${this.contentUrl}/${folder}/${name}` : `${this.contentUrl}/${name}`;
    return url;
  }

  downloadProjectInvoice(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/projects/invoices-csv/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  downloadProjectInvoicePDF(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/projects/invoices-pdf/${slug}`);
    //   .pipe(map((resp: any) => {
    //   if (resp) {
    //     return resp.url;
    //   }
    // }));
  }

  getInvoice(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/projects/get-document-new`, data, {responseType: 'blob'});
  }

  getInvoices(request: any): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/invoices/upload`, {params});
  }

  prepareAudioFile(event: HTMLInputElement): any {
    const files = event.files;
    if (files.item(0)) {
      const file = files.item(0);
      const fileUpload = new FormData();
      fileUpload.append('file', file);
      const sizeBytes = 10 * 1024 * 1024;
      const extension = ['mp3'];
      if (file.size > sizeBytes) {
        return {error: 'File size should be less than 10MB'};
      }
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        return {error: 'Allowed mp3 formats only'};
      }
      return {fileUpload, file};
    }
  }
}
