<div class="wrapper-content">
  <div class="item-edit">
    <mat-form-field class="mat-item input-text-item item-text__margin">
      <mat-label>Song Name*</mat-label>
      <input matInput [formControl]="songNameCtrl">
    </mat-form-field>
  </div>
  <div class="item-edit">
    <mat-form-field>
      <mat-label>Preferred genres (You can select 3 items)*</mat-label>
      <mat-select [formControl]="genresCtrl" multiple (selectionChange)="changedGenres()">
        <mat-option *ngFor="let item of genres" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="item-edit">
    <mat-form-field class="mat-item input-text-item item-text__margin">
      <mat-label>About you (artist)*</mat-label>
      <input matInput [formControl]="aboutArtistCtrl">

    </mat-form-field>
  </div>
  <div class="item-edit">
    <mat-form-field class="mat-item input-text-item item-text__margin">
      <mat-label>About the song*</mat-label>
      <input matInput [formControl]="aboutSongCtrl">
    </mat-form-field>
  </div>
  <div class="item-edit">
    <mat-form-field class="mat-item input-text-item">
      <mat-label>Producer*</mat-label>
      <input matInput [formControl]="producerCtrl">
    </mat-form-field>
  </div>
  <div class="item-edit">
    <mat-form-field class="mat-item input-text-item">
      <mat-label>Label*</mat-label>
      <input matInput [formControl]="labelCtrl">
    </mat-form-field>
  </div>
  <div class="list-item find-user">
    <div class="find-user__wrapper">
      <div class="list-controls" *ngFor="let control of composerCtrl.controls; let i = index">
        <app-find-producer-by-name
          [name]="'Composer'"
          [producerWithSlug]="listComposers && listComposers.length ? listComposers[i] : null"
          [fControl]="composerCtrl.controls[i]"
        ></app-find-producer-by-name>
        <div *ngIf="i > 0" class="remove-control" (click)="removeControl(i, composerCtrl, 'composer')">
          <i class="fas fa-times-circle"></i></div>
      </div>
    </div>
    <div *ngIf="countaProducers?.composer !== 6" class="add" (click)="addAuthor(composerCtrl, 'composer')">
      + ADD ({{6 - countaProducers?.composer}} max)
    </div>
  </div>
  <div class="list-item find-user">
    <div class="find-user__wrapper">
      <div class="list-controls" *ngFor="let control of lyricsAuthorCtrl.controls; let i = index">
        <app-find-producer-by-name
          [name]="'Lyric'"
          [producerWithSlug]="listLyrics && listLyrics.length ? listLyrics[i] : null"
          [fControl]="lyricsAuthorCtrl.controls[i]"
        ></app-find-producer-by-name>
        <div *ngIf="i > 0" class="remove-control" (click)="removeControl(i, lyricsAuthorCtrl, 'lyricsAuthor')">
          <i class="fas fa-times-circle"></i></div>
      </div>
    </div>
    <div class="add" *ngIf="countaProducers?.lyricsAuthor !== 6"
         (click)="addAuthor(lyricsAuthorCtrl, 'lyricsAuthor')">
      + ADD ({{6 - countaProducers?.lyricsAuthor}} max)
    </div>
  </div>
  <div class="item-document">
             <span class="standard-font">
               Song: <br/>
             </span>
    <div class="upload-section" *ngIf="!fileName">
      <label class="border-blue">
        <input (change)="uploadAudio($event.target)" type="file"
               class="desktop-upload"
               maxlength="30"
               name="filefront"/>
        <i class="fas fa-music"></i>
        <span>Upload audio file (.mp3)</span>
      </label>
    </div>
    <span class="file-name" *ngIf="fileName">
            <span class="icon-cancel" (click)="cancelUpload()"><i
              class="fas fa-times-circle"></i></span>{{fileName}}</span>
  </div>
  <div class="item-edit">
    <mat-form-field class="mat-item">
      <mat-label>Comment</mat-label>
      <input matInput [formControl]="commentCtrl">
    </mat-form-field>
  </div>
  <div class="item-edit">
  <span class="standard-font">
               Rating: <br/>
             </span>
    <div class="footer-item">
            <span class="star-cb-group-popup">
              <input type="radio" id="rating-5" name="rating" [ngModel]="submittedSong.rating" value="5"/>
              <label (click)="checkStar(5)"
                     for="rating-5">5</label>
              <input type="radio" id="rating-4" name="rating" [ngModel]="submittedSong.rating" value="4"/>
              <label (click)="checkStar(4)"
                     for="rating-4">4</label>
              <input type="radio" id="rating-3" name="rating" [ngModel]="submittedSong.rating" value="3"/>
              <label (click)="checkStar(3)"
                     for="rating-3">3</label>
              <input type="radio" id="rating-2" name="rating" [ngModel]="submittedSong.rating" value="2"/>
              <label (click)="checkStar(2)"
                     for="rating-2">2</label>
              <input type="radio" id="rating-1" name="rating" [ngModel]="submittedSong.rating" value="1"/>
              <label (click)="checkStar(1)"
                     for="rating-1">1</label>
              <input type="radio" id="rating-0" name="rating" [ngModel]="submittedSong.rating" value="0"
                     class="star-cb-clear"/>
              <label (click)="checkStar(0)"
                     for="rating-0">0</label>
            </span>
    </div>
  </div>
  <app-upload-image [settings]="settings"
                    [control]="imgCtrl"
                    [config]="{imageFormat: 'png', ratio: 16 / 6}"
                    [maintainAspectRatio]="true"
                    [imgURL]="imgCtrl?.value"
  ></app-upload-image>

  <app-social-links-song [socialArtist]="submittedSong?.artistSocial"
                         [listSocials]="listSocials"></app-social-links-song>
</div>
<div class="dialog-footer">
  <div class="footer-content">
    <div class="button-wrapper">
      <button type="button" class="button green" (click)="updateSong()"
              [disabled]="!form.valid || !imgCtrl?.value || !audioFile"
      >
        Update
      </button>
      <button type="button" class="button blue" *ngIf="showCancel" (click)="cancelUpdate()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
