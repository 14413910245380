<div class="item-row">
  <div class="item-label item-label_pad-20">Title</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="albumTitle1"
              [formControl]="form?.controls?.title"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Description</div>
  <div class="item-field">
    <angular-editor
      [placeholder]="'Enter text here...'"
      [config]="editorConfig"
      [formControl]="form?.controls?.description"
    ></angular-editor>
  </div>
</div>



