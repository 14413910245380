<mat-form-field class="mat-form-field form-field" appearance="fill">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>
  <mat-select [formControl]="formControl">
    <mat-option *ngFor="let item of array" [value]="item[selectParam]"
    >{{item[displayParam]}}</mat-option
    >
  </mat-select>
  <mat-error *ngIf="formControl?.hasError('required') && formControl?.invalid">
    This field is required
  </mat-error>
</mat-form-field>
