<div class="search-field" [formGroup]="form">
  <mat-form-field class="example-full-width content-item-w">
    <input
      formControlName="searchValue"
      matInput
      id="autocomplete"
      placeholder="{{placeholder}}"
      type="text" 
      autocomplete="off"
      [readonly]="readonly"
    >
  </mat-form-field>
  <ul class="listDrpDown">
    <li class="autocomplete-item" *ngFor="let item of list$ | async" (click)="onChangeItem(item)">{{item?.name}}
    </li>
  </ul>
<!--  <span class="warning" *ngIf="(list$ | async)?.length === 0">Artist is not found</span>-->
</div>

