import {
  Directive,
  Input, OnInit, ElementRef,
} from '@angular/core';
import {ICurrency} from "@core/interfaces/currency.interface";


const currency: ICurrency<string> = {
  eur: '€{amount}',
  usd: '${amount}',

}

@Directive({
  selector: '[fundingGoalDirective]',
})
export class FundingGoalDirective implements OnInit{
  @Input() valueCurrency: ICurrency<number>;

  constructor(private elementRef: ElementRef){}

  ngOnInit() {
    let html = '';
    for(let key in this.valueCurrency){
      html += `<p style="margin:0">${currency[key].replace('{amount}', this.valueCurrency[key].toString())}</p>`;
    }
    this.elementRef.nativeElement.innerHTML = html;
  }
}
