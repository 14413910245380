<div class="view__wrapper">
  <div class="view__header">
    <h5 class="view__title main-title">
      {{fanItem?.contact?.firstName + ' ' + fanItem?.contact?.lastName}} details
    </h5>
  </div>
  <div class="view__content">
    <table class="table table-striped table-bordered table-hover dataTable">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{fanItem?.id}}</td>
        </tr>
        <tr>
          <td>Slug</td>
          <td>{{fanItem?.slug}}</td>
        </tr>
        <tr>
          <td>Mango User ID</td>
          <td>
            {{fanItem?.mangoUserId ? fanItem?.mangoUserId : 'Not created yet'}}
          </td>
        </tr>
        <tr>
          <td>State</td>
          <td>{{fanItem?.state}}</td>
        </tr>
        <tr>
          <td>Is producer</td>
          <td>{{fanItem?.isProducer ? 'Yes' : 'No'}}</td>
        </tr>
        <tr>
          <td>First name</td>
          <td>{{fanItem?.contact.firstName}}</td>
        </tr>
        <tr>
          <td>Last name</td>
          <td>{{fanItem?.contact.lastName}}</td>
        </tr>
        <!--<tr>-->
        <!--<td>VAT number</td>-->
        <!--<td>{{fanItem?.vatNumber}}</td>-->
        <!--</tr>-->
        <tr>
          <td>Gender</td>
          <td>{{fanItem?.contact.gender}}</td>
        </tr>
        <tr>
          <td>Birthdate</td>
          <td>{{fanItem?.contact.birthdate}}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{fanItem?.contact.email}}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{{fanItem?.billingAddress}}</td>
        </tr>
        <tr>
          <td>Postal code</td>
          <td>{{fanItem?.postalCode}}</td>
        </tr>
        <tr>
          <td>City</td>
          <td>{{fanItem?.contact.city}}</td>
        </tr>
        <tr>
          <td>Region</td>
          <td>{{fanItem?.contact?.region}}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td>{{fanItem?.country}}</td>
        </tr>
        <tr>
          <td>FacebookId</td>
          <td>{{fanItem?.contact.facebookId}}</td>
        </tr>
        <tr>
          <td>Telephone</td>
          <td>{{fanItem?.contact.telephone}}</td>
        </tr>
        <tr>
          <td>Genres like</td>
          <td><p *ngFor="let item of fanItem?.genresLike">{{item}}</p></td>
        </tr>
        <tr>
          <td>Credits</td>
          <td>{{fanItem?.credits}}</td>
        </tr>
        <tr>
          <td>Created at</td>
          <td>{{fanItem?.createdAt}}</td>
        </tr>
        <tr>
          <td>Updated at</td>
          <td>{{fanItem?.updatedAt}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
