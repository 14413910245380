import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder,} from '@angular/forms';
import {shareReplay, tap} from "rxjs/operators";
import {IStatusDistribution} from "@core/interfaces/distribution.interface";
import {API_INTERFACE, ApiInterface} from "@core/interfaces/api.interface";

interface MatchedNamesInterface {
  name: string;
}

interface MatchedReleasesInterface {
  fileName: string;
  mainSlug: string;
  slug: string;
  item: any;
}

interface MatchedCsvInterface {
  date: string;
  exchangeRate: number;
  noMatch: Array<string> | undefined;
  query: MatchedNamesInterface;
  typeReport: string;
  releases: Array<MatchedReleasesInterface>
}

@Injectable()
export class DistributionService {
  public matchedRelease$ = new BehaviorSubject<any>(null);
  public sum$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  public noMatchList$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  constructor(
    private fb: FormBuilder,
    @Inject(API_INTERFACE) private apiService: ApiInterface,
  ) {
  }
  getStatusDistribution(): Observable<IStatusDistribution> {
    return this.apiService.get<IStatusDistribution>(`admin/distribution/status`, {}).pipe(shareReplay(1));
  }
  getDetailDistribution<T>(): Observable<T> {
    return this.apiService.get<T>(`admin/distribution/detail`, {});
  }

  transferStatements(data): Observable<any> {
    return this.apiService.post(`admin/distribution/transfer`, data);
  }

  getMatchedCsv(): Observable<any> {
    return this.apiService.get<MatchedCsvInterface>(`admin/releases/matched`, {}).pipe(
      tap((res => {
        if (Object.keys(res).length !== 0) {
          this.matchedRelease$.next(res);
        } else {
          this.matchedRelease$.next(null);
        }
        if (res && res.noMatch) {
          this.noMatchList$.next(res.noMatch)
        }
      })));
  }

  bulkConfirm(data: any): Observable<any> {
    return this.apiService.post(`admin/releases/bulkConfirm`, data);
  }
  resetData(){
    this.matchedRelease$.next(null);
    this.sum$.next({});
    this.noMatchList$.next([]);
  }

}
