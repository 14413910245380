<div class="view__wrapper">
  <div class="view__header">
    <h5 class="view__title main-title">
      {{ artistData?.contact.firstName }}
      {{ artistData?.contact.lastName }} details
    </h5>
  </div>

  <div class="view__content">
    <table class="table table-striped table-bordered table-hover dataTable">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{ artistData?.id }}</td>
        </tr>
        <tr>
          <td>Slug</td>
          <td>{{ artistData?.slug }}</td>
        </tr>
        <tr>
          <td>First name</td>
          <td>{{ artistData?.contact.firstName }}</td>
        </tr>
        <tr>
          <td>Last name</td>
          <td>{{ artistData?.contact.lastName }}</td>
        </tr>
        <tr>
          <td>Birthdate</td>
          <td>{{ artistData?.contact.birthdate }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ artistData?.contact.email }}</td>
        </tr>
        <tr>
          <td>Billing Address</td>
          <td>{{ artistData?.billingAddress }}</td>
        </tr>
        <tr>
          <td>Postal code</td>
          <td>{{ artistData?.postalCode }}</td>
        </tr>
        <tr>
          <td>City</td>
          <td>{{ artistData?.contact.city }}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td>{{ artistData?.country }}</td>
        </tr>
        <tr>
          <td>Telephone</td>
          <td>{{ artistData?.contact.telephone }}</td>
        </tr>
        <tr>
          <td>Mango User ID</td>
          <td>{{ artistData?.mangoUserId }}</td>
        </tr>
        <tr>
          <td>VAT number</td>
          <td>{{ artistData?.vatNumber }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
