<div class="search-block p20-top flex flex-around">
    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Search</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedSearchCategory" name="food">
            <mat-option *ngFor="let cat of config.searchOptions" [value]="cat">
              {{cat}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container">
        <mat-form-field *ngIf="selectedSearchCategory" class="mat-form-field form-field" appearance="fill">
          <input matInput placeholder="Enter request" class="p10-y" [(ngModel)]="searchCategoryValue"
                 (keyup.enter)="searchByCategory()">
        </mat-form-field>
        <ng-container *ngIf="selectedSearchCategory">
          <button mat-button (click)="searchByCategory()"><i class="fas fa-search"></i></button>
          <button mat-button (click)="resetSearchFiler()"><i class="fas fa-refresh"></i></button>
        </ng-container>
      </div>
    </div>

    <div *ngIf="config.category" class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Category filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [formControl]="mainFilter">
            <mat-option *ngFor="let cat of categoryMainFilter" [value]="cat">
              {{cat}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container">
        <div class="select__item">
          <mat-form-field *ngIf="mainFilter.value === 'State'"
                          class="mat-select d-inline mat-form-select" appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="stateFilter" multiple [disabled]="mainFilter.value !== 'State'">
              <mat-option *ngFor="let cat of categoryStateFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Featured'" class="mat-select d-inline mat-form-select"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="featuredFilter">
              <mat-option *ngFor="let cat of categoryFeaturedFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Country'" class="mat-select d-inline mat-form-select"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="countryFilter" multiple>
              <mat-option *ngFor="let cat of categoryCountryFilter" [value]="cat">
                {{cat.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <ng-container *ngIf="mainFilter.value === 'State'
                      || mainFilter.value ==='Featured'
                      ||  mainFilter.value === 'Country'">
          <button mat-button (click)="onSelectCategory()"><i class="fas fa-search"></i></button>
          <button mat-button><i class="fas fa-refresh" (click)="resetCategoryFiler()"></i></button>
        </ng-container>
      </div>

    </div>

    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Range filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedRangeFilter" name="food">
            <mat-option *ngFor="let cat of config.rangeOptions" [value]="cat">
              {{cat}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container range-block">
        <mat-form-field *ngIf="selectedRangeFilter" appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <ng-container *ngIf="selectedRangeFilter">
          <button mat-button (click)="searchByRange()"><i class="fas fa-search"></i></button>
          <button mat-button (click)="resetRangeFilter()"><i class="fas fa-refresh"></i></button>
        </ng-container>

      </div>
    </div>

  </div>