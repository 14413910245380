import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'form-item-header',
  templateUrl: './form-item-header.html',
  styleUrls: ['./form-item-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormItemHeaderComponent {
  constructor() {
  }
}
