<form [formGroup]="form" class="tab">
  <div class="item-row">
    <div class="item-label item-label_pad-20">Is Enabled</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    formControlName="isEnabled"
                    [name]="'Is Enabled'"
                    [id]="'Is Enabled'"
      >
      </mat-checkbox>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="DeTitleEng"
               formControlName="title"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Description</div>
    <div class="item-field">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        [formControl]="form.get('description')"
      ></angular-editor>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">YouTube link</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="link"
               formControlName="link"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
</form>

