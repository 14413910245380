import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Consts
import { ROUTE_ALIAS } from '../../../common';

// Interfaces
import { Filter } from '@core/common/interfaces';
import { RequestModel } from '../../../model/request.model';

// Services
import { AppToastrService, ProjectService } from '../../../services';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.html',
  styleUrls: ['./_news-list.scss'],
})
export class NewsListComponent implements OnInit {
  list: any[];
  isLoaded: boolean;
  total: number;
  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc',
    },
  };

  constructor(
    private toastr: AppToastrService,
    private projectService: ProjectService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  deleteItem(item: any): void {
    this.projectService.deleteNewsItem(item.slug).subscribe(
      (resp) => {
        this.getList();
        this.toastr.showToast({ title: 'SUCCESS: NewsItem was deleted' });
      },
      (err) => {
        console.log(err);
        this.isLoaded = true;
        this.toastr.showToastFromError(err);
      }
    );
  }

  onTypeChange(e): void {
    this.getList();
  }

  openItem(item?: any): void {
    const slug: string = item ? item.slug : 'newItem';

    this.router.navigate([ROUTE_ALIAS.newsEdit, slug]);
  }

  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }

  onNotify(e): void {
    if (e.type === 'sorting') {
      this.getList();
    }
  }

  getList(): void {
    this.isLoaded = false;
    const request = new RequestModel(this.filter);
    this.projectService.getNewsList(request).subscribe(
      (resp) => {
        this.list = resp.list;
        this.isLoaded = true;
        this.total = resp.total;
      },
      (err) => {
        console.log(err);
        this.isLoaded = true;
        this.toastr.showToastFromError(err);
      }
    );
  }
}
