import {Pipe, PipeTransform} from '@angular/core';
import {SettingService} from "@core/services/setting.service";

@Pipe({name: 'convertImageUrl'})
export class ImageUrlPipe implements PipeTransform {
  contentUrl: string;

  constructor(private setting?: SettingService) {
    this.contentUrl = this.setting.contentUrl;
  }

  transform(value: string, parameter?: string) {
    const isRawUrl: boolean = value?.includes(this.contentUrl);

    if (!value) {
      return `${this.contentUrl}`;
    } else if (isRawUrl) {
      return value;
    } else if (parameter) {
      return `${this.contentUrl}/${parameter}/${value}`;
    } else {
      return `${this.contentUrl}/${value}`;
    }

  }
}
