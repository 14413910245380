<mat-form-field class="mat-form-field form-field" appearance="fill">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>
  <input
    matInput
    id="prop"
    [formControl]="formControl"
    [readonly]="isReadOnly"
    type="text"
  />
  <mat-error *ngIf="formControl?.hasError('required') && formControl?.invalid">
    This field is required
  </mat-error>
</mat-form-field>
