import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  AppToastrService,
  DictionaryService,
  OpportunityService, ProjectService,
  UploadService,
  UsersService
} from '../../../services/index';
import {POSITIVE_NUMBER} from '../../../common/index';
import {ConfirmPopup} from '@core/common/interfaces';
import {ProjectModel} from '@core/model/project.model';
import {ISongShare} from '@core/interfaces/artist-share.interface';
import {SettingService} from '@core/services/setting.service';

@Component({
  selector: 'app-opportunity-song-popup',
  templateUrl: './opportunity-song-popup.html',
  styleUrls: ['./_opportunity-song-popup.scss']
})
export class OpportunitySongPopupComponent implements OnInit {
  @Input() submittedSong: any;
  @Input() isOpportunity: boolean;
  @Input() project: ProjectModel;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  activeTab: number;
  submitPressed: boolean;
  form: UntypedFormGroup;
  fundingGoalCtrl: UntypedFormControl;
  artistShareCtrl: UntypedFormControl;
  investorShareCtrl: UntypedFormControl;
  producerShareCtrl: UntypedFormControl;
  // labelFundingCtrl: UntypedFormControl;
  labelDistCtrl: UntypedFormControl;
  termsPublishingCtrl: UntypedFormControl;
  termsMasterCtrl: UntypedFormControl;
  videoImgCtrl: UntypedFormControl;
  offerDetailsCtrl: UntypedFormControl;
  projectDurationCtrl: UntypedFormControl;
  projectTitleCtrl: UntypedFormControl;
  deleteConfig: ConfirmPopup;
  loadedSearchList: boolean;
  fileName: string;
  VideoValue: any;
  videoFile: any;
  submittedVideo: boolean;
  errorArtistShare: boolean;
  errorProducerShare: boolean;
  genres: any;
  loaded: boolean;
  searchItem: any;
  listArtists: Array<ISongShare> = Array(5).fill('').map((_, i) => {
    return {
      type: 'artistShare' + i,
      name: '',
      user: '',
      share: '',
      slug: '',
      error: false
    };
  });
  listProducers: Array<ISongShare> = Array(5).fill('').map((_, i) => {
    return {
      type: 'producerShare' + i,
      name: '',
      user: '',
      share: '',
      error: false
    };
  });
  activeList: string;
  offerHeight: number;
  isEdit: boolean;
  showErrorPrice: boolean;
  errorTotal: boolean;
  errorShares: boolean;
  disableButton: boolean;
  image: string;
  checkList: boolean;
  imgURL: string;
  totalShare: number;
  priceForShare: number;
  contentUrl: string;
  royalty: {
    publish: boolean,
    master: boolean
  };

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private projectService: ProjectService,
              private settings: SettingService,
              private userService: UsersService,
              private uploadService: UploadService,
              private dictionaryService: DictionaryService,
              private opportunityService: OpportunityService
  ) {
  }

  ngOnInit(): void {
    this.getGenres();
    this.initialForm();
    this.changeForm();
    this.activeTab = 1;
    this.contentUrl = this.settings.contentUrl;
  }

  initialForm(): void {
    this.artistShareCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.investorShareCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.producerShareCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.projectDurationCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    // this.labelFundingCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.labelDistCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.projectTitleCtrl = this.fb.control('', [Validators.required]);
    this.offerDetailsCtrl = this.fb.control('', [Validators.required]);
    this.videoImgCtrl = this.fb.control('');
    this.termsMasterCtrl = this.fb.control('', [Validators.pattern(POSITIVE_NUMBER)]);
    this.termsPublishingCtrl = this.fb.control('', [Validators.pattern(POSITIVE_NUMBER)]);
    this.form = this.fb.group({
      artistShare: this.artistShareCtrl,
      videoImg: this.videoImgCtrl,
      investorShare: this.investorShareCtrl,
      offerDetails: this.offerDetailsCtrl,
      producerShare: this.producerShareCtrl,
      labelDist: this.labelDistCtrl,
      termsPublishing: this.termsPublishingCtrl,
      projectDuration: this.projectDurationCtrl,
      termsMaster: this.termsMasterCtrl,
      projectTitle: this.projectTitleCtrl
    });
    this.setForm();
  }

  setState(state: number): void {
    this.activeTab = state;
  }

  closeDialog(): any {
    this.visibleChange.emit({type: 'close'});
  }

  getGenres(): void {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;
    });
  }


  setForm(): void {
    this.offerDetailsCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.myOffersDetails : null);
    this.artistShareCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.artistShare : null);
    this.investorShareCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.totalInvestorsShares : null);
    this.producerShareCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.producerShare : null);
    this.projectDurationCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.projectDuration : null);
    this.labelDistCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.labelShareDistribution : null);
    this.projectTitleCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.projectTitle : null);
    this.termsMasterCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.termOfRoyaltiesMasterRights : null);
    this.termsPublishingCtrl.setValue(this.submittedSong.project ? this.submittedSong.project.termOfRoyaltiesPublishingRights : null);

    this.royalty = {
      publish: this.submittedSong.project.royaltiesFromPublishingRights || false,
      master: this.submittedSong.project.royaltiesFromMaster || false
    };

    if (this.submittedSong && this.submittedSong.project.myOffersDetails) {
      this.offerHeight = this.submittedSong.project.myOffersDetails.split('\n').length;

    }
    // if (this.submittedSong.project.videoImage) {
    //   this.videoImgCtrl.setValue(this.submittedSong.project.videoImage);
    // }
    if (this.submittedSong.project.videoclip) {
      this.fileName = this.submittedSong.project.videoclip.split('/project-videos/')[1];
    }
    if (this.submittedSong.project.producersShare && this.submittedSong.project.producersShare.length) {
      let needToAddEmpty = Array(5 - this.submittedSong.project.producersShare.length).fill('').map((_, i) => {
        return {user: null, share: 0};
      });
      this.listProducers = [...this.submittedSong.project.producersShare, ...needToAddEmpty];
    }

    if (this.submittedSong.project.artistsShare && this.submittedSong.project.artistsShare.length) {
      let needToAddEmpty = Array(5 - this.submittedSong.project.artistsShare.length).fill('').map((_, i) => {
        return {user: null, share: 0};
      });
      this.listArtists = [...this.submittedSong.project.artistsShare, ...needToAddEmpty];
    }

    this.disableForms();
    this.getStatusButton();
  }

  disableForms(): void {
    if (this.submittedSong.project.isPublished) {
      this.investorShareCtrl.disable();
      this.projectTitleCtrl.disable();
    }
  }

  onChangeItem(selectedArtist: any, index: any, isArtist: boolean,  user: any): any {
    user.loadedSearchList = true;
    user.checkList = false;
    user.searchItem = null;
    this.userService.getUser(selectedArtist.slug).subscribe(resp => {
      if (isArtist) {
        this.listArtists[index].name = selectedArtist.name;
        this.listArtists[index].user = resp.id;
        this.listArtists[index].slug = resp.slug;
        this.listArtists[index].error = !this.listArtists[index].share;
      } else {
        this.listProducers[index].name = selectedArtist.name;
        this.listProducers[index].user = resp.id;
        this.listProducers[index].error = !this.listProducers[index].share;
      }
      this.checkPopulateAllShares();
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  checkPopulateAllShares(): void {
    this.errorShares = false;
    this.listProducers.forEach(item => {
      if (item.error) {
        this.errorShares = true;
      }
    });

    this.listArtists.forEach(item => {

      if (item.error) {
        this.errorShares = true;
      }
    });
  }

  checkShare(list, value): boolean {
    let totalShare = 0;
    list.forEach(item => {
      totalShare += Number(item.share);
    });
    return totalShare !== Number(value);
  }

  checkTotalProdShare(type, item?): void {
    if (type === 'producer') {
      this.errorProducerShare = this.checkShare(this.listProducers, this.producerShareCtrl.value);
    } else {

      this.errorArtistShare = this.checkShare(this.listArtists, this.artistShareCtrl.value);
    }
    item.error = !item.user;
    this.checkPopulateAllShares();
    this.getStatusButton();
  }

  searchText(item,  type: string): void {
    item.loadedSearchList = false;
    item.checkList = false;
    this.loaded = false;
    if (item.name && item.name.length > 1) {
      if (type === 'producer') {
        this.userService.getProducerByName(item.name).subscribe(resp => {
          item.searchItem = resp;
          setTimeout(() => {
            item.checkList = true;
          }, 400);
        }, err => {
          this.toastr.showToastFromError(err);
          setTimeout(() => {
            item.checkList = true;
          }, 400);
        });
      }
      if (type === 'artist') {
        this.userService.getArtistByName(item.name).subscribe(resp => {
          item.searchItem = resp;
          setTimeout(() => {
            item.checkList = true;
          }, 400);
        }, err => {
          this.toastr.showToastFromError(err);
          setTimeout(() => {
            item.checkList = true;
          }, 400);
        });
      }
    }
  }

  updateOpportunity(): void {
    this.submittedSong.isUpdate = true;
    this.publishOpportunity();
  }

  deleteSong(): void {
    this.deleteConfig = {
      btnConfirm: true,
      titleBtnConfirm: 'Delete',
      btnCancel: true,
      submitEmit: 'delete',
      titleBtnCancel: 'Cancel',
      text: 'Are you sure you want to delete song?'
    };
  }

  _deleteSong(): void {
    this.opportunityService.deleteSong(this.submittedSong).subscribe(resp => {
      this.close();
      this.deleteConfig = null;
      this.toastr.showToast({title: 'Song was deleted'});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  publishOpportunity(isPublished?: boolean): void {
    let error = false;
    if (this.videoFile && !this.videoImgCtrl.value) {
      error = true;
    }
    this.getStatusButton();
    if (this.form.valid && !this.disableButton && !error) {
      this.submitPressed = true;
      const producersShare = [];
      const artistShare = [];
      if (this.listProducers && this.listProducers.length) {
        this.listProducers.forEach(item => {
          if (item.name) {
            producersShare.push({
              name: item.name,
              share: item.share,
              user: item.user
            });
          }
        });
      }
      if (this.listArtists && this.listArtists.length) {
        this.listArtists.forEach(item => {
          if (item.name) {
            artistShare.push({
              name: item.name,
              share: item.share,
              slug: item.slug,
              user: item.user
            });
          }
        });
      }
      const data: any = {

        artistShare: this.artistShareCtrl.value,
        totalInvestorsShares: this.investorShareCtrl.value,
        producerShare: this.producerShareCtrl.value,
        labelShareDistribution: this.labelDistCtrl.value,
        termOfRoyaltiesPublishingRights: this.termsPublishingCtrl.value,
        termOfRoyaltiesMasterRights: this.termsMasterCtrl.value,
        projectDuration: this.projectDurationCtrl.value,
        royaltiesFromMaster: this.royalty.master,
        producersShare: producersShare,
        videoclip: this.videoFile,
       // videoImage: this.videoImgCtrl.value ? this.uploadService.getShortUrlName(this.videoImgCtrl.value, 'url') : '',
        royaltiesFromPublishingRights: this.royalty.publish,
        projectTitle: this.projectTitleCtrl.value,
        priceForShare: this.priceForShare,
        artistsShare: artistShare,
        myOffersDetails: this.offerDetailsCtrl.value
      };
      if (this.submittedSong.isUpdate) {
        data.artist = this.submittedSong.artist.id;
        data.soundtrack = this.submittedSong.project.soundtrack;
        data.image = this.submittedSong.project.image;
        data.song = this.submittedSong.song;
        data.myProjectDescription = this.submittedSong.project.aboutSong;
        data.biography = this.submittedSong.project.biography;
        this.projectService._updateProject(data, this.submittedSong.projectSlug).subscribe(resp => {
          if (resp) {
            this.close();
            this.submitPressed = false;
            this.submittedSong.isUpdate = false;
            this.toastr.showToast({title: isPublished ? 'Opportunity published' : 'Opportunity saved'});
          }
        }, err => {
          this.toastr.showToastFromError(err);
        });
      } else {
        data.isPublished = isPublished;
        this.opportunityService.publishOpportunity(data, this.submittedSong).subscribe(resp => {
          if (resp) {
            this.close();
            this.submitPressed = false;
            this.submittedSong.isUpdate = false;
            this.toastr.showToast({title: isPublished ? 'Opportunity published' : 'Opportunity saved'});
          }
        }, err => {
          this.toastr.showToastFromError(err);
        });
      }
    }
  }

  close(): any {
    this.visibleChange.emit({type: 'close'});
  }

  updateProject(): any {
    const data: any = this.project;
    data.isPublished = true;
    data.artist = this.project.artist.id;
   // data.videoImage = this.project.videoImage ? this.project.videoImage : '';
    this.projectService._updateProject(data, this.project.slug).subscribe(resp => {
      this.close();
      this.toastr.showToast({title: 'SUCCESS: Project was published'});
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  getStatusButton(): void {
    this.disableButton = this.errorTotal || this.errorProducerShare || this.errorArtistShare || this.errorShares;
  }

  changeForm(): void {
    this.form.get('producerShare').valueChanges.subscribe(item => {
      if (this.investorShareCtrl.value && this.artistShareCtrl.value && item) {
        const investor = Number(this.investorShareCtrl.value) || 0;
        const producer = Number(item) || 0;
        const artist = Number(this.artistShareCtrl.value) || 0;
        this.totalShare = investor + producer + artist;
        if (this.totalShare !== 100) {
          this.errorTotal = true;
        } else {
          this.errorTotal = false;
        }
        this.getStatusButton();

        let filled = [];
        filled = this.listProducers.filter(re => {
          return re.user;
        });
        if (filled.length) {
          this.errorProducerShare = this.checkShare(this.listProducers, this.producerShareCtrl.value);
        }
      }
    });
    this.form.get('artistShare').valueChanges.subscribe(item => {
      if (this.investorShareCtrl.value && this.producerShareCtrl.value && item) {
        const investor = Number(this.investorShareCtrl.value) || 0;
        const producer = Number(this.producerShareCtrl.value) || 0;
        const artist = Number(item) || 0;
        this.totalShare = investor + producer + artist;
        if (this.totalShare !== 100) {
          this.errorTotal = true;
        } else {
          this.errorTotal = false;
        }
      }

      let filled = [];
      filled = this.listArtists.filter(re => {
        return re.user;
      });
      if (filled.length) {
        this.errorArtistShare = this.checkShare(this.listArtists, this.artistShareCtrl.value);
      }
    });

    this.form.get('investorShare').valueChanges.subscribe(item => {
      if (Number(item) === 0) {
        this.showErrorPrice = true;
      }

      if (Number(this.producerShareCtrl.value) && Number(this.artistShareCtrl.value) && item) {
        const investor = Number(item) || 0;
        const producer = Number(this.producerShareCtrl.value) || 0;
        const artist = Number(this.artistShareCtrl.value) || 0;
        this.totalShare = investor + producer + artist;
        if (this.totalShare !== 100) {
          this.errorTotal = true;
        } else {
          this.errorTotal = false;
        }
      }
    });
  }

  onNotify(e): void {
    if (e.type === 'updated') {
      this.activeTab = 1;
      const obj = JSON.parse(JSON.stringify(e.data));
      const sub = JSON.parse(JSON.stringify(this.submittedSong));
      this.submittedSong = Object.assign(sub, obj);
    } else if (e.type === 'proceed') {
      this.activeTab = 1;
      const obj = JSON.parse(JSON.stringify(e.data));
      const sub = JSON.parse(JSON.stringify(this.submittedSong));
      this.submittedSong = Object.assign(sub, obj);
      this.submittedSong.isUpdate = true;
      this.submittedSong.song.name = e.data.songName;
      this.submittedSong.song.writers = e.data.writers;
      this.submittedSong.song.composers = e.data.composers;
      this.submittedSong.song.producer = e.data.producer;
      this.submittedSong.song.genres = e.data.genresPlay;
      this.submittedSong.song.label = e.data.label;
      this.submittedSong.project.biography = e.data.aboutArtist;
      this.submittedSong.project.aboutSong = e.data.aboutSong;
      this.submittedSong.project.image = e.data.photo;
      this.publishOpportunity();
    }
    if (e.type === 'delete') {
      this._deleteSong();
    }
    if (e.type === 'close') {
      this.deleteConfig = null;
    }
  }

  uploadVideo(event: any): boolean | void {
    this.VideoValue = event.value;
    this.submittedVideo = true;
    const data = this.opportunityService.prepareVideoFile(event);
    if (data.error) {
      this.toastr.showErrorToast({title: data.error});
      this.submittedVideo = false;
      return false;
    }
    this.uploadService.uploadFilePartially(data.file, 'project-videos').then(res => {
      this.videoFile = res.url;
      this.fileName = data.file.name;
      this.submittedVideo = false;
      this.submittedSong.project.videoclip = `${this.contentUrl}/${this.videoFile}`;
      this.toastr.showToast({
        title: 'mp4 has been uploaded'
      });
    }, (err) => {
      this.toastr.showToastFromError(err);
      this.submittedVideo = false;
      this.cancelUpload();
    });
  }

  cancelUpload(): void {
    this.VideoValue = null;
    this.fileName = null;
    this.videoFile = null;
  }
}
