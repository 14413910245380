import {Component} from '@angular/core';


@Component({
  selector: 'app-search-block',
  templateUrl: './search-block.html',
  styleUrls: ['./search-block.scss'],
})
export class SearchBlockComponent  {


  constructor() {

  }
}
