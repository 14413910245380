import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SettingService} from '@core/services/setting.service';
import {RequestModel} from '@core/model/request.model';
import {map, shareReplay} from 'rxjs/operators';
import {RequestTokenModel} from "@core/model/request.-tokenmodel";
import { TransferToken } from '@core/interfaces/token.interface';

@Injectable()
export class TokenService {
  apiUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService,
  ) {
    this.apiUrl = this.setting.apiUrl;
  }


  getTokenDates(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/token/dates`, {params}).pipe(map((resp: any) => {
      return resp;
    }));
  }

  getTokens(request: RequestTokenModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/tokens`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }),shareReplay(1));
  }

  getUserTokens(userSlug: string): Observable <any> {
    return this.http.get(`${this.apiUrl}/admin/tokens/user/${userSlug}`);
  }

  burnTokens(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/tokens/burn`, data);
  }

  transferTokens(data: TransferToken): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/tokens/transfer`, data);
  }
}
