import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ICollectible} from '@core/interfaces/collectible.interface';
import {CollectibleModel, IEditions} from '@core/model/collectible.model';
import {IResponse} from '@core/common/interfaces';

export interface IStartEditionAuction {
  amount: number;
  editions: string;
  dateStart: string;
  dateEnd: string;
}

export interface IEditionList {
  startingPrice: number;
  createdAt: string;
  submitBtn: boolean;
  auctionId: string;
  endDate: string;
  startDate: string;
  id: string;
  status: string;
  collectible: {
    song: string;
  };
  collectibleEdition: {
    internalNumber: number;
    uniqueId: number;
    walletId: string;
  };
  tokenOwner: {
    contact: object;
  };
}

@Injectable()
export class CollectibleService {
  apiUrl: string;
  contentUrl: string;

  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }


  getCollectible(slug): Observable<ICollectible> {
    return this.http.get(`${this.apiUrl}/admin/collectible/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return new CollectibleModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  _deleteCollectibles(slug?): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/admin/collectible/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _mintCollectibles(slug?): Observable<boolean> {
    return this.http.post(`${this.apiUrl}/admin/collectible/${slug}/mint`, {}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
  giveAwayEdition(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/edition/giveaway`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
  _startAuction(body, slug): Observable<IEditions> {
    return this.http.post(`${this.apiUrl}/admin/collectible/${slug}/start-auction`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
  _saveAuctionMarket(body): Observable<boolean> {
    return this.http.post(`${this.apiUrl}/admin/edition/start-auction`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
  _updateMarket(body, id): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/admin/edition/${id}`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _getAuctionMarketList(request): Observable<IResponse<IEditionList[]>> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/editions`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          list: resp.docs,
          total: resp.total
        };
      }
    }));
  }

  _getCollectibles(request): Observable<IResponse<ICollectible[]>> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/collectibles`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          list: resp.docs,
          total: resp.total
        };
      }
    }));
  }

  _updateCollectible(param, slug): Observable<ICollectible> {
    return this.http.put(`${this.apiUrl}/admin/collectible/${slug}`, param).pipe(map((resp: any) => {
      if (resp) {
        return new CollectibleModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  saveCollectible(param): Observable<ICollectible> {
    return this.http.post(`${this.apiUrl}/admin/collectible`, param).pipe(map((resp: any) => {
      if (resp) {
        //  return new OpportunityModel(this.contentUrl).setFromApiModel(resp);
        return resp;
      }
    }));
  }
}
