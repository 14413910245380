import {Pipe, PipeTransform} from '@angular/core';
import {Currency, CurrencySign, ICurrency} from "@core/interfaces/currency.interface";

@Pipe({name: 'formatCurrency'})
export class FormatCurrencyPipe  implements PipeTransform {
  currencySign: ICurrency<string> = CurrencySign;
  transform(value, format: Currency) {
    if (value && format) {
      return this.currencySign[format] + value;
    }
  }
}
