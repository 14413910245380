import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';


@Component({
  selector: 'app-page-content',
  template: `
    <div class="content-body">
      <ng-content></ng-content>
    </div>`,
  styleUrls: ['./_page-content.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageContentComponent {
  constructor() {
  }
}


@Component({
  selector: 'app-page-header',
  template: `
    <div class="content-header">
      <ng-content></ng-content>
    </div>`,
  styleUrls: ['./_page-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
  constructor() {
  }
}
