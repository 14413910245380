import { Injectable } from '@angular/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
} from '@angular/material/datepicker';

@Injectable()
export class EndDayRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  selectionFinished(date: D | null, currentRange: DateRange<D>): DateRange<D> {
    
    /* Add 23 hours 59 min to end date of selection */
    if (currentRange.start) {
      const newDate: Date = date as any;
      newDate.setTime(newDate.getTime() + 86340000);

      return new DateRange<D>(currentRange.start, newDate as any);
    } else {
      return new DateRange<D>(date, null);
    }
  }

  createPreview(
    activeDate: D | null,
    currentRange: DateRange<D>
  ): DateRange<D> {
    if (currentRange.start) {
      return new DateRange<D>(currentRange.start, activeDate);
    } else {
      return new DateRange<D>(activeDate, null);
    }
  }
}
