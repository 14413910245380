<form [formGroup]="form" class="wrapper-component" #f="ngForm">
  <div class="content__row">
    <div class="content__elem content__elem_m-right">
      <mat-form-field class="item-field item-field_align">
        <mat-label>Word</mat-label>
        <input
          matInput
          id="word"
          formControlName="word"
          type="text"
          autocomplete="off"
        />
        <mat-error *ngIf="form.touched">Required</mat-error>
      </mat-form-field>
    </div>
    <div class="content__elem content__elem_m-right">
      <mat-form-field class="item-field item-field_align">
        <mat-label>Amount</mat-label>
        <input
          matInput
          id="amount"
          formControlName="amount"
          type="number"
          autocomplete="off"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>
    <div class="content__elem content__elem_m-right">
      <mat-form-field class="item-field item-field_align">
        <mat-label>Cap</mat-label>
        <input
          matInput
          id="cap"
          formControlName="cap"
          type="number"
          autocomplete="off"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="content__row">
    <div class="content__elem content__elem_m-right">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range *</mat-label>
        <!-- MIN DATE MASK DISABLED FOR TESTS -->
        <!-- <mat-date-range-input [rangePicker]="picker" [min]="today"> -->
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startDate" />
          <input matEndDate placeholder="End date" formControlName="endDate"/>
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>
    <div class="content__elem content__elem_m-right">
      <mat-form-field class="mat-input">
        <mat-label>Promo format</mat-label>
        <mat-select formControlName="typeSale">
          <mat-option *ngFor="let item of listTypeSale" [value]="item.id"
            >{{item.text}}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="form?.errors?.amountPercent"
          >Percent more than 100%</mat-error
        >
      </mat-form-field>
    </div>
    <div class="wrapper-button">
      <button
        class="button green default-size"
        type="submit"
        [disabled]="!form?.valid"
        (click)="setupCode()"
      >
        Add new code
      </button>
    </div>
  </div>
</form>
