<app-page-template header="News">
  <div class="content-header">
    <div class="content-header-row2">
      <div class="content-header-col">
        <button class="button green default-size" (click)="openItem()">Create New</button>
      </div>
    </div>
  </div>
  <div class="content-body" *ngIf="isLoaded">
    <table class='table table-striped table-bordered table-hover dataTable'>
      <thead>
      <tr>
        <th>#</th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="slug">
          <div>Slug</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="title">
          <div> Title</div>
        </th>
        <th>Published</th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="createdAt">
          <div>Created at</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="updatedAt">
          <div>Updated at</div>
        </th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor='let item of list; let i = index'>
        <td class="center">
          {{ i + 1 + filter?.paging?.offset}}
        </td>
        <td><a class="link-blue" (click)="openItem(item)">{{item?.slug }}</a></td>
        <td>{{ item?.title }}</td>
        <td class="text-break">{{item?.isPublished}}</td>
        <td>{{item?.createdAt}}</td>
        <td>{{item?.updateAt}}</td>
        <td class="th-actions">
          <div class="actions">
            <div class="edit actions-item" (click)="openItem(item)">
              <i class='fa fa-edit'></i>
            </div>

            <div class="delete actions-item" (click)="deleteItem(item)">
              <i class='fa fa-trash-o'></i>
            </div>
          </div>
        </td>
      </tr>

      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > filter?.paging?.limit && isLoaded">
      <app-pagination [total]="total" [paging]="filter?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>

  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</app-page-template>

