import {UploadService} from '@core/services';
import {IAuctionMarket, ICollectible, TStatus} from '@core/interfaces/collectible.interface';
import * as moment from 'moment';

export interface IEditions {
  status: string;
  walletId?: string;
  internalNumber: number;
  _id: string;
  auctionId: string;
  uniqueId: number;
}

export interface IOfferTeaser {
  headline: string;
  text: string;
  icon: string;
}

export interface IExistingArtist {
  id: string;
}

export interface ISeo {
  title: string;
  description: string;
  keywords: string;
}

export type Fees = {
  grs: number,
  arrayArtists: any[],
  arrayGRSs: any[],
  artist: number
}

export type typeCollectible = 'fix' | 'auction';

export class CollectibleModel {
  constructor(host?, uploadService?) {
    this.imgUrl = host;
    this.uploadService = uploadService;
  }

  artist: string;
  artistExistId: IExistingArtist;
  uploadService: UploadService;
  id: string;
  imgUrl: string;
  video: string;
  audio: string;
  listEditions: IEditions[];
  slug?: string;
  song: string;
  status: string;
  endDate: string;
  cardColor: string;
  typeCollectible: typeCollectible;
  isPublished: boolean;
  readMoreContent: any;
  biography: string;
  collectionRights: string;
  edition: number;
  myProjectDescription: string;
  teaser: string;
  myOffersDetails: string;
  statusDetail: string;
  startDate: string;
  backgroundImage: string;
  offerTeaser: IOfferTeaser[];
  startingPrice: number;
  imgNFT: string;
  auction: IAuctionMarket;
  videoNFT: string;
  genre: any;
  owner: string;
  seo: ISeo;
  fees: Fees;
  limitedIcon: boolean;
  collectibleImg: string;
  collectionName: string;
  goodiesIcon: boolean;
  cardDescription: string;
  auctionId: string;
  auctionStatus: TStatus;

  setFromApiModel(apiModel): this {
    this.artist = apiModel.artist;
    this.video = apiModel.video;
    this.audio = this.getLongUrlName(apiModel.audio);
    this.song = apiModel.song;
    this.owner = apiModel.owner;
    this.fees = apiModel.fees;
    this.artistExistId = apiModel.artistExistId;
    this.typeCollectible = apiModel.typeCollectible;
    this.listEditions = apiModel.listEditions || [];
    this.slug = apiModel.slug;
    if (apiModel.offerTeaser) {
      this.offerTeaser = apiModel.offerTeaser.map(item => {
        item.icon = item.icon ? item.icon : '';
        return item;
      });
    }
    this.genre = apiModel.genre;
    this.collectionName = apiModel.collectionName || '';
    this.teaser = apiModel.teaser;
    this.seo = apiModel.seo;
    this.cardColor = apiModel.cardColor;
    this.collectionRights = apiModel.collectionRights;
    this.biography = apiModel.biography;
    this.myOffersDetails = apiModel.myOffersDetails;
    this.statusDetail = apiModel.statusDetail;
    this.myProjectDescription = apiModel.myProjectDescription;
    this.limitedIcon = apiModel.limitedIcon;
    this.goodiesIcon = apiModel.goodiesIcon;
    this.cardDescription = apiModel.cardDescription;
    this.readMoreContent = apiModel.readMoreContent;
    this.auction = apiModel.auction;
    if (apiModel.readMoreContent && apiModel.readMoreContent.imgReadMore) {
      this.readMoreContent.imgReadMore = apiModel.readMoreContent.imgReadMore !== 'undefined' ? apiModel.readMoreContent.imgReadMore : null;
    }

    if (apiModel.auctionStatus === 'create') {
      this.auctionStatus = 'Create';
    }
    if (apiModel.auctionStatus === 'running') {
      this.auctionStatus = 'Running';
    }
    if (apiModel.auctionStatus === 'scheduled') {
      this.auctionStatus = 'Scheduled';
    }
    if (apiModel.auctionStatus === 'bidding_is_over') {
      this.auctionStatus = 'Bidding_is_over';
    }
    if (apiModel.auctionStatus === 'fiat_settlement') {
      this.auctionStatus = 'Fiat_settlement';
    }

    this.auctionId = apiModel.auctionId;
    this.isPublished = apiModel.isPublished;
    this.startDate = apiModel.startDate;
    this.endDate = apiModel.endDate;
    this.edition = apiModel.edition;
    this.startingPrice = apiModel.startingPrice;
    this.status = apiModel.status;
    this.backgroundImage = apiModel.backgroundImage ? apiModel.backgroundImage : null;
    this.collectibleImg = apiModel.collectibleImg ? apiModel.collectibleImg : null;
    this.imgNFT = apiModel.imgNFT;
    this.videoNFT = apiModel.videoNFT ? this.getLongUrlName(apiModel.videoNFT) : '';
    return this;
  }

  getLongUrlName(name, folder?): string {
    const url = folder ? `${this.imgUrl}/${folder}/${name}` : `${this.imgUrl}/${name}`;
    return url;
  }

  getFromApiModel(data, project): ICollectible {
    let obj: any = {
      video: data.value.video || '',
      song: data.value.song,
      limitedIcon: data.value.limitedIcon,
      goodiesIcon: data.value.goodiesIcon,
      isPublished: data.value.isPublished,
      edition: data.value.edition,
      genre: data.value.genre,
      seo: project.seo,
      fees: {
        artist: Number(data.value.fees.artist),
        grs: Number(data.value.fees.grs),
        arrayGRSs: data.value.fees.arrayGRSs.map(item => {
          return{user: item.user, percent: Number(item.percent)};
        }),
        arrayArtists: data.value.fees.arrayArtists.map(item => {
          return{user: item.user, percent: Number(item.percent)};
        })
      },
      collectibleImg: data.value.collectibleImg ? data.value.collectibleImg : '',
      collectionName: data.value.collectionName,
      collectionRights: data.value.collectionRights,
      cardColor: data.value.cardColor,
      biography: data.value.biography,
      myProjectDescription: data.value.myProjectDescription,
      myOffersDetails: data.value.myOffersDetails,
      teaser: data.value.teaser || null,
      cardDescription: data.value.cardDescription || null,
      statusDetail: data.value.statusDetail || null,
      readMoreContent: data.value.readMoreContent || {},
      backgroundImage: data.value.backgroundImage ? data.value.backgroundImage : '',
      imgNFT: data.value.imgNFT ? data.value.imgNFT : '',
      videoNFT: data.value.videoNFT || '',
    };
    if (project.offerTeaser && project.offerTeaser.length) {
      obj.offerTeaser = project.offerTeaser.map(item => {
        item.icon = item.icon ? item.icon : '';
        return item;
      });
    }
    if (project.selectedArtist) {
      obj.artist = project.selectedArtist.name;
      obj.artistExistId = project.selectedArtist ? project.selectedArtist.id : null;
    }
    return obj;
  }
}
