import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  content: string;
}
// @Component({
//   selector: 'dialog',
//   styleUrls: ['dialog.component.scss'],
//   templateUrl: 'dialog.component.html',
// })
// export class DialogAnimationsExample {
//   constructor(public dialog: MatDialog) {}

//   openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
//     this.dialog.open(DialogAnimationsExampleDialog, {
//       width: '250px',
//       enterAnimationDuration,
//       exitAnimationDuration,
//     });
//   }
// }

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
