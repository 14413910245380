import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, FormControl} from '@angular/forms';
import {AppToastrService} from '../../../services/index';
import {Observable} from 'rxjs';
import {AbstractBaseFindService} from "@core/component/find-user-template/abstract-base-find.service";
import {FindProjectService} from "@core/component/find-user-template/find-project.service";
import {ProjectSearchInterface} from "@core/interfaces/project-search.interface";


@Component({
  selector: 'app-find-by-name-project',
  templateUrl: './find-by-name-project.html',
  providers: [
    {
      provide: AbstractBaseFindService,
      useClass: FindProjectService
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindByNameProjectComponent implements OnInit {
  form: UntypedFormGroup;
  list$: Observable<ProjectSearchInterface[]>;
  selectedItem: boolean;
  @Input() errorText: string;
  @Input() formControlCustom?: any;
  @Input() selectParam?: any;
  @Input() placeholder = 'Placeholder';
  @Input() text: any;
  @Input() showSongs: boolean = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(public fb: UntypedFormBuilder,
              public toastr: AppToastrService,
              public projectService: AbstractBaseFindService
  ) {
    this.form = this.projectService.form;
    this.list$ = this.projectService.list$
  }

  ngOnInit(): any {
    if (this.formControlCustom && this.formControlCustom.value) {
      this.form.get('selectedItem').setValue(this.formControlCustom.value);
      this.selectedItem = true;
    }
  }

  deleteItem(): void {
    this.selectedItem = false;
  }

  onChangeItem(selected: ProjectSearchInterface, song?: any): any {
    this.selectedItem = true;
    if(song) {
      selected.song = song;
    }

    if(this.formControlCustom){
      this.formControlCustom.setValue(selected[this.selectParam]);
    }

    this.projectService.selectItem(selected);
    this.notify.emit({data: selected});
  }
}
