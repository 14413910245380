<div class="content__row_flex-end content__row">
  <div class="search__elem search__elem_m-right">
    <mat-form-field class="mat-input">
      <mat-label>Search by</mat-label>
      <mat-select [(ngModel)]="categorySelected" name="value7" (selectionChange)="selectCategory()">
        <mat-option *ngFor="let cat of categories" [value]="cat.value">
          {{cat.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__elem search__elem_m-right search__field" *ngIf="categorySelected">
    <mat-form-field class="content-item">
      <mat-label>{{categorySelected}}</mat-label>
      <input matInput
             [(ngModel)]="searchValue"
             id="periodTo"
             type="text" autocomplete="off"
      >
    </mat-form-field>
    <button class="button green default-size" (click)="onSearch()">
     Search
    </button>
<!--    <div  (click)="onSearch()"><i class="fas fa-search"></i></div>-->
  </div>
</div>
<div class="content-result" *ngIf="!searchListResult?.length && searchValue && findUser">
  <span class="error-message">User not found</span>
</div>
