import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractBaseFilterService} from '../../abstract-base-filter.service';
import {FilterReleaseService} from '../../filter-release.service';
import {FilterConfig} from '../../../../common/interfaces';

export class EmptyFilter {
  projectTitle: string;
  name: string;
  artist: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor() {
    this.projectTitle = null;
    this.name = null;
    this.artist = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
  }
}

@Component({
  selector: 'app-base-template-search',
  templateUrl: './base-template-search.html',
  styleUrls: ['./_base-template-search.scss'],
  providers: [
    {
      provide: AbstractBaseFilterService,
      useClass: FilterReleaseService
    }
  ],
})
export class BaseTemplateSearchComponent {
  searchCategoryValue: string;
  filter: EmptyFilter = new EmptyFilter();
  @Input() config: FilterConfig;
  @Output() searchByFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public searchService: AbstractBaseFilterService) {
    this.searchCategoryValue = this.searchService.searchCategoryValue;
    this.searchService.filterObj = this.filter;
    this.searchService.$observableSearchParam.subscribe(resp => {
      this.searchByFilter.emit(resp);
    });

  }

  selectCategory() {
    this.searchService.filterObj = new EmptyFilter();
  }
}

