import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl, ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICurrency } from '@core/interfaces/currency.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {POSITIVE_NUMBER} from "@core/common";
import {logger} from "codelyzer/util/logger";
@UntilDestroy()
@Component({
  selector: 'project-settings-section',
  templateUrl: './project-settings.component.html',
  styleUrls: [
    './project-settings.component.scss',
    './../project-settings.scss',
  ],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  priceForShare: ICurrency<number> = {
    eur: 0,
    usd: 0,
  };
  public form;
  errorPricePerShare: boolean;
  private _destroyed = new Subject<void>();
  enableAssignUnsold: FormControl<boolean> = new FormControl(false);
  @Input() project: any;

  constructor(private controlContainer: ControlContainer) {}


  ngOnInit(): void {

    this.form = this.controlContainer.control;
    this.form.addControl('fundingGoalPerCurrency', new FormGroup({
      usd: new FormControl('', {validators: [Validators.required]}),
      eur: new FormControl('', {validators: [Validators.required]})
    }))
    this.form.addControl('receiverUnsoldShare', new FormControl(''));
    this.form.addControl('amountPercentSale', new FormControl(''));
    this.form.addControl('dedicatedProduction', new FormControl(false));
    this.form.addControl('dedicatedArtwork', new FormControl(false));
    this.form.addControl('dedicatedPromo', new FormControl(false));
    this.form.addControl('displayShare', new FormControl(1, [Validators.min(0.01), Validators.max(100)]));
    this.form.addControl('dedicatedvideoclip', new FormControl(false));
    this.form.addControl('royaltiesMater', new FormControl(false));
    this.form.addControl('royaltiesPublish', new FormControl(false));
    this.form.addControl('termRoyaltiesPublish', new FormControl('', Validators.pattern(POSITIVE_NUMBER)));
    this.form.addControl('termRoyaltiesMater', new FormControl('', Validators.pattern(POSITIVE_NUMBER)));

    this.form.addControl('labelDistribution', new FormControl('', [Validators.required]));
    this.form.addControl('isSale', new FormControl(false));
    this.form.get('receiverUnsoldShare').disable();

    if (this.project) {
      this.patchForm();
    }

    this.changeForm();
    this.validationSaleStatus();
    this.subscribes();
  }

  ngOnDestroy(): void {
    this._destroyed.next(null);
  }

  get fundingGoal() {
    return this.form?.get('fundingGoalPerCurrency') as FormGroup ;
  }

  patchForm() {
    this.fundingGoal.patchValue(this.project.fundingGoalPerCurrency);
    if (this.project.releaseCreated) {
      this.fundingGoal.disable();
      this.form.get('investorShare').disable();
    }

    if (this.project.saleAmount) {
      this.form.get('isSale').setValue(true);
      this.form.get('amountPercentSale').setValue(this.project.saleAmount);
    }
    this.form.get('dedicatedProduction').setValue(this.project.dedicatedForProduction);
    this.form.get('dedicatedArtwork').setValue(this.project.dedicatedForArtwork);
    this.form.get('dedicatedPromo').setValue(this.project.dedicatedForMarketing);
    this.form.get('labelDistribution').setValue(this.project.labelShareDistribution);
    this.form.get('dedicatedvideoclip').setValue(this.project.dedicatedForVideoClip);
    this.form.get('royaltiesMater').setValue(this.project.royaltiesFromMaster);
    this.form.get('displayShare').setValue(this.project.displayShare);
    this.form.get('termRoyaltiesMater').setValue(this.project.termOfRoyaltiesMasterRights);
    this.form.get('royaltiesPublish').setValue(this.project.royaltiesFromPublishingRights);
    this.form.get('termRoyaltiesPublish').setValue(this.project.termOfRoyaltiesPublishingRights);

    if (this.project?.receiverUnsoldShare) {
      const control: AbstractControl = this.form.get('receiverUnsoldShare');

      control.setValue(this.project.receiverUnsoldShare);
    }

    this.calculatePriceForShare();
  }

  changeForm(): void {
    merge(
      this.fundingGoal.valueChanges,
      this.form.get('investorShare').valueChanges,
      this.form.get('amountPercentSale').valueChanges,
      this.form?.get('isSale').valueChanges
    )
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.calculatePriceForShare();
      });
  }

  private subscribes(): void {
    this.enableAssignUnsold.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((enabled: boolean) => {
        const control: AbstractControl = this.form.get('receiverUnsoldShare')

        if (enabled) {
          control.enable();
        } else {
          control.disable();
        }
      });
  }

  calculatePriceForShare() {
    let investorShare = this.form.get('investorShare').value;
    let sale = this.form?.get('isSale')?.value;
    this.priceForShare = sale
      ? {
        usd: this.calculateSale('usd', investorShare),
        eur: this.calculateSale('eur', investorShare),
      }
      : {
        usd: this.priceForSharePerCurrency('usd', investorShare),
        eur: this.priceForSharePerCurrency('eur', investorShare),
      };
  }

  calculateSale(currency: string, investorShare: number) {
    let saleAmount = this.form?.get('amountPercentSale')?.value || 0;
    let pricePerShare = this.priceForSharePerCurrency(currency, investorShare);
    return pricePerShare - (saleAmount * pricePerShare) / 100;
  }

  priceForSharePerCurrency(currency: string, investorShare: number) {
    const pricePerShare = investorShare
      ? this.fundingGoal.get(currency).value / investorShare
      : 0;
    this.validationOfPricePerShare(pricePerShare);
    return pricePerShare;
  }

  private validationOfPricePerShare(num) {
    const numString = num.toString();
    const decimalPart = numString.split('.')[1];

    this.errorPricePerShare = decimalPart && decimalPart.length > 1;
  }

  private validationSaleStatus() {
    this.form.get('isSale').valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('amountPercentSale').setValidators([Validators.required]);
      } else {
        this.form.get('amountPercentSale').clearValidators();
      }
      this.form.get('amountPercentSale').updateValueAndValidity();
    });
  }
}
