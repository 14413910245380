import * as moment from 'moment';
import { LANDING_PAGE } from '@core/common/constants_landing';
import { ISeo } from '@core/common/interfaces';

export interface ISliders {
  isEnabled: boolean;
  title: string;
  list: any[];
}

export interface ILandingText {
  button: string;
  description: string;
  isEnabled: boolean;
  targetBlank: boolean;
  title: string;
  url: string;
  subTitle: string;
  image: string;
  descriptionAdditional: string;
}

export interface IVideo {
  isEnabled: boolean;
  title: string;
  description: string;
  link: string;
}

export interface IMainBlock {
  description: string;
  photo: string;
  title: string;
}

export interface ILandingCards {
  list: any[];
  title: string;
  isEnabled: string;
  description: string;
}

export interface ITextLanguage {
  [key: string]: {
    isEnabled: boolean;
    targetBlank?: boolean;
    description?: string;
    title?: string;
    descriptionAdditional?: string;
    button?: string;
    url?: string;
  };
}

export class LandingModel {
  constructor(host?) {
    this.imgUrl = host;
  }

  video: IVideo;
  slider: ISliders;
  usaSlider: ISliders;
  imgUrl: string;
  title: string;
  _id: string;
  slug: string;
  mainBlock: IMainBlock;
  createdAt: string;
  updatedAt: string;
  config: any;
  text: ILandingText;
  seo: ISeo;
  textLanguage: ITextLanguage;
  cards: ILandingCards;
  isPublished: boolean;

  setFromApiModel(apiModel): this {
    this._id = apiModel._id;
    this.title = apiModel.title;
    this.slug = apiModel.slug;
    this.createdAt = apiModel.createdAt
      ? moment(apiModel.createdAt).format('DD.MM.YYYY HH:mm')
      : null;
    this.updatedAt = apiModel.updatedAt
      ? moment(apiModel.updatedAt).format('DD.MM.YYYY HH:mm')
      : null;
    this.isPublished = apiModel.isPublished;
    return this;
  }

  setFromItemApiModel(apiModel): this {
    this._id = apiModel._id;
    this.title = apiModel.title;
    this.mainBlock = apiModel.mainBlock;
    this.slider = apiModel.slider;
    this.usaSlider = apiModel.usaSlider;
    this.video = apiModel.video;
    this.text = apiModel.text;
    this.mainBlock = apiModel.mainBlock;
    if (apiModel.slider) {
      this.slider.list.forEach(this.setSliderImages);
    }
    if (apiModel.usaSlider) {
      this.usaSlider.list.forEach(this.setSliderImages);
    }
    this.seo = apiModel.seo;
    this.textLanguage = apiModel.textLanguage;
    this.cards = apiModel.cards;
    if (apiModel.mainBlock) {
      this.mainBlock.photo =
        apiModel.mainBlock.photo &&
        apiModel.mainBlock.photo.indexOf('project-images') >= 0
          ? `${apiModel.mainBlock.photo}`
          : null;
    }
    if (apiModel.cards && apiModel.cards.list) {
      this.cards.list.forEach((item) => {
        item.image =
          item.image && item.image.indexOf('project-images') >= 0
            ? `${item.image}`
            : null;
      });
    }
    this.slug = apiModel.slug;
    this.isPublished = apiModel.isPublished;
    if (this.text.image) {
      this.text.image =
        this.text.image.indexOf('project-images') >= 0
          ? `${this.imgUrl}/${this.text.image}`
          : null;
    }
    this.updatedAt = apiModel.updatedAt
      ? moment(apiModel.updatedAt).format('DD.MM.YYYY HH:mm')
      : null;
    return this;
  }

  setSliderImages(item) {
    item.image =
      item.image && item.image !== 'null' ? `${item.image}` : undefined;
    item.imageMobile =
      item.imageMobile && item.imageMobile !== 'null'
        ? `${item.imageMobile}`
        : undefined;
  }
}
