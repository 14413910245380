<div class="wallet-balance"><b>Revenue wallet balance: </b>
  <span class="balance">
    <ng-container *ngIf="(walletBalance$ | async) as walletBalance">
      €{{walletBalance.deposit.eur || 0}} / ${{walletBalance.deposit.usd || 0}}
    </ng-container>
    <ng-container *ngIf="!(walletBalance$ | async)">
      <mat-spinner
        [diameter]="20"
      ></mat-spinner>
    </ng-container>
  </span>
</div>
<div class="wallet-balance"><b>Refund wallet balance: </b>
  <span class="balance">
    <ng-container *ngIf="(walletBalance$ | async) as walletBalance">
      €{{walletBalance.refund.eur || 0}} / ${{walletBalance.refund.usd || 0}}
    </ng-container>
    <ng-container *ngIf="!(walletBalance$ | async)">
      <mat-spinner
        [diameter]="20"
      ></mat-spinner>
    </ng-container>
  </span>
</div>
