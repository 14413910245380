<form class="search-field" [formGroup]="form">
  <mat-form-field class="item">
    <mat-label>{{placeholder}}</mat-label>
    <input
      type="text"
      aria-label="User"
      matInput
      formControlName="searchValue"
      [matAutocomplete]="AutoComplete"
    />
    <mat-autocomplete #AutoComplete="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option
        *ngFor="let item of (list$ | async)"
        [value]="item[saveParam]"
      >{{ item?.showDisplayName }}</mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="!(list$ | async)?.length"
    >{{errorText}}</mat-error
    >
  </mat-form-field>
</form>
