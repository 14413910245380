import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  @Input() list: any;
  @ContentChild('header') headers!: TemplateRef<any>;
  @ContentChild('rows') rows!: TemplateRef<any>;

  constructor() {

  }
}
