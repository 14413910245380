<form formGroupName="seo">
<form-item-wrapper>
  <form-item-header>Title</form-item-header>
  <app-form-input [formControlItem]="childForm.get('seo')?.get('title')">Title</app-form-input>
</form-item-wrapper>
<form-item-wrapper>
  <form-item-header>Description</form-item-header>
  <app-form-input [formControlItem]="childForm.get('seo')?.get('description')">Description</app-form-input>
</form-item-wrapper>
<form-item-wrapper>
  <form-item-header>Keywords</form-item-header>
  <app-form-input [formControlItem]="childForm.get('seo')?.get('keywords')">Keywords</app-form-input>
</form-item-wrapper>
</form>
