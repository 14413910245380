import {Injectable} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RequestModel} from '@core/model/request.model';
import {SettingService} from '@core/services/setting.service';
import {IArtistEditing} from '@core/model/artists-list.model';
import {map} from 'rxjs/operators';
import {UserModel} from '@core/model';

@Injectable()
export class ArtistsService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private setting: SettingService,
  ) {
    this.apiUrl = this.setting.apiUrl;
  }

  getAllArtists(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;

    return this.http.get<any>('');
  }

  getCurrentArtist(slug: string, req?: any): Observable<UserModel> {
    const params = req ? req.getApiModel() : null;
    return this.http.get<UserModel>(`${this.apiUrl}/admin/artists/${slug}`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  editCurrentArtist(slug, body): Observable<UserModel> {
    return this.http.put<any>(`${this.apiUrl}/admin/artists/${slug}`, body).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  deleteArtist(slug): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/admin/users/${slug}`);
  }

  getCurrentProducer(slug): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.apiUrl}/admin/producer/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  editCurrentProducer(slug, body): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/admin/producer/${slug}`, body).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }
}
