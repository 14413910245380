import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.html',
  styleUrls: ['./form-select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSelectComponent {
  formControl: UntypedFormControl;

  @Input() isReadOnly: boolean;
  @Input() array: any[];
  @Input() selectParam: string;
  @Input() displayParam: string;

  @Input() set formControlItem(value) {
    this.formControl = value;
  }
}
