import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { UIRouterModule, UIView } from '@uirouter/angular';
//import { routerConfigFn, STATES } from './app.routes';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule } from '@angular/common/http';

import { ShareModule } from '@core/share.module';
import { WebsocketModule } from '@core/features/websocket/websocket.module';
import { environment } from 'environments/environment';
import {AppRoutingModule} from "@core/app-routing.module";
import {AppComponent} from "@core/app.component";
import {HeaderComponent} from "@core/component/global/header/header.component";
import {NavigationComponent} from "@core/component/global/navigation/navigation.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UiModule} from "@core/component/ui/ui.module";
import {ApiService} from "@core/services/api.service";
import {API_INTERFACE} from "@core/interfaces/api.interface";


@NgModule({
  declarations: [AppComponent, HeaderComponent, NavigationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    UiModule,
    HttpClientModule,
    ShareModule,
    BrowserAnimationsModule,
    // UIRouterModule.forRoot({
    //   states: STATES,
    //   otherwise: '/',
    //   useHash: true,
    //   config: routerConfigFn,
    // }),
    WebsocketModule.config({ url: environment.wsUrl }),
  ],
  providers: [{ provide: API_INTERFACE, useClass: ApiService}],
  //bootstrap: [UIView],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(private injector: Injector){    // Create global Service Injector. add to MoDEL
  //   ServiceLocator.injector = this.injector;
  // }
}
