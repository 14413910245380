import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SettingService} from '@core/services/setting.service';
import {AbstractGetPageListService} from "@core/services/templates/abstractGetPageList.service";
import {RoutingService} from "@core/services";
import {FilterModel} from "@core/component/filter/base-templates/filter.model";
import {UserModel} from "@core/model";
import {PledgesInvestorModel} from "@core/pages/investors/item-view/pledges-investor.model";


@Injectable()
export class InvestorsBaseService implements AbstractGetPageListService {
  apiUrl: string;

  constructor(private http: HttpClient,
              private routingService: RoutingService,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }


  getList(request: FilterModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/investors`, {params})
      .pipe(map((resp: any) => {
        if (resp) {
          return resp;
        }
      }));
  }

  getInvestor(slug){
    return this.http.get(`${this.apiUrl}/admin/investors/${slug}`)
      .pipe(map((resp: any) => {
        if (resp) {
          return {
            user: new UserModel().setFromApiModel(resp.user),
            pledges: resp.pledges.map(item => new PledgesInvestorModel().setFromApiModel(item))
          }
        }
      }));
  }

  openItem(r: string, slug: string) {
    const param: any = {
      slug: slug
    };
    this.routingService.go(r, param);
  }
}


