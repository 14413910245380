import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {RequestModel} from '@core/model/request.model';
import {ReleaseModel} from '@core/model/release.model';
import {IDistributionAmount, IStatusDistribution} from "@core/interfaces/distribution.interface";



@Injectable()
export class ReleasesService {
  apiUrl: string;
  contentUrl: string;
  private subjectRelease$ = new BehaviorSubject<ReleaseModel>({} as ReleaseModel);


  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }

  getReleases(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;

    return this.http.get(`${this.apiUrl}/admin/releases`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new ReleaseModel().setFromApiModel(item)),
          amountToDeliver: resp.amountToDeliver,
          total: resp.total,
          partiallyDelivered: resp.partiallyDelivered,
          existNotDelivering: resp.existNotDelivering,
          walletBalance: resp.walletBalance
        };
      }
    }));
  }

  parsingData(request: RequestModel): Observable<IDistributionAmount> {
    const params = request ? request.getApiModel() : null;
    return this.http.get<IDistributionAmount>(`${this.apiUrl}/admin/distribution/parse`, {params});
  }

  _saveRevenue(data, slug): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/release/${slug}/revenue`, data).pipe(map((resp: any) => {
      if (resp) {
        return new ReleaseModel().setFromApiModel(resp);
      }
    }));
  }

  editRevenueService(data, slug): Observable<ReleaseModel> {
    return this.http.post(`${this.apiUrl}/admin/release/${slug}/revenue`, data).pipe(map((resp: any) => {
      if (resp) {
        return new ReleaseModel().setFromApiModel(resp);
      }
    }));
  }

  deleteSong(slug, id): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/release/${slug}/song/${id}`);
  }

  deleteRevenueService(id, slug): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/release/${slug}/revenue/${id}`);
  }

  deleteReleaseService(slug): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/release/${slug}`);
  }

  deliverRevenue(data: ReleaseModel, slug): Observable<ReleaseModel> {
    return this.http.post(`${this.apiUrl}/admin/release/${slug}/deliver`, data).pipe(map((resp: any) => {
      if (resp) {
        return new ReleaseModel().setFromApiModel(resp);
      }
    }));
  }

  downloadStatementPDF(body): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/statement/download-pdf`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  removeStatements(): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/statements/remove`);
  }



  revenueConfirm(data: any, cancel: boolean): Observable<any> {
    const param = cancel ? `?cancel=${cancel}` : '';
    return this.http.post(`${this.apiUrl}/admin/release/revenueConfirm${param}`, {data});
  }

  addRelease(data: any, slug?: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/release`, data);
  }

  updateRelease(data: any, slug: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/release/${slug}`, {data});
  }

  getReleaseItem(data: string): Observable<ReleaseModel> {
    return this.http.get(`${this.apiUrl}/admin/release/${data}`).pipe(map((resp: any) => {
      if (resp) {
        return new ReleaseModel().setFromApiModel(resp);
      }
    }));
  }


  downloadReleaseCSV(request): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/releases/csv`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  set releaseItemLinked(value) {
    this.subjectRelease$.next(value);
  }

  get releaseItemLinked() {
    return this.subjectRelease$.value;
  }

}


