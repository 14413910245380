import {Component, OnInit} from '@angular/core';
// import {CookieService} from 'ngx-cookie-service';

//import {StateService, TransitionService} from '@uirouter/core';
import {AuthenticationService, StorageService} from "app/services";
import {JwtHelperService} from "@auth0/angular-jwt";
import {SettingService} from '@core/services/setting.service';


@Component({
  selector: 'app-default-master',
  templateUrl: './default-master.html',
  styleUrls: ['./default-master.scss']
})
export class DefaultMasterPageComponent implements OnInit {
  loaded: boolean;
  isMenuCollapsed: boolean;
  user: any;

  constructor(
   // private stateService: StateService,
  //  private setting: SettingService,
  //  private authenticationService: AuthenticationService,
    //private transitionService: TransitionService,
  //  private storageService: StorageService
  ) {
  }

  ngOnInit() {
  //  this.loaded = true;
   // const token = this.storageService.accessToken;
   // this.user = this.storageService.user;
    // if (token) {
    //   const data = new JwtHelperService().decodeToken(token);
    //   if (data.exp && ((data.exp * 1000) - new Date().getTime()) > 0) {
    //     this.loaded = true;
    //   } else {
    //     this.authenticationService.logout();
    //   }
    // } else {
    //   this.authenticationService.logout();
    // }
  }
  onMenuToggle() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }
}
