<div class="content-item">
  <div class="item-label header-inform-text-grey">Official videos</div>
  <div class="item-field list-item">
    <div class="control">
      <div class="list-controls" *ngFor="let control of inputCtrl.controls; let i = index">
        <mat-form-field class="mat-item content-item">
          <input matInput
                 id="amount2"
                 type="text" autocomplete="off"
                 [formControl]="inputCtrl.controls[i]">
        </mat-form-field>
        <div *ngIf="i >= 0" class="remove-control" (click)="removeControlItem(i, inputCtrl, 'composer')">
          <i class="fas fa-times-circle"></i></div>
      </div>
    </div>
    <div *ngIf="inputCtrl?.controls?.length !== 10" class="add" (click)="addControlItem(inputCtrl, 'composer')">
      + ADD ({{10 - composerCtrl?.controls?.length}} max)
    </div>
  </div>
</div>
