import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, map, share, shareReplay} from 'rxjs/operators';
import {RequestModel} from '@core/model/request.model';
import {ProjectModel} from '@core/model/project.model';
import {NewsModel} from '@core/model/news.model';
import {ResponseTotal} from '@core/common/interfaces';
import {SettingsModel} from "@core/model/settings.model";
import {ProjectFeaturesService} from "@core/services/project-features.service";
import {AppToastrService} from "@core/services/toastr.service";
import {PayoutSettings} from '@core/interfaces/payout.interface';

interface ApiModel {
  setFromApiModel(data: any): any;
}

@Injectable()
export class ProjectService {
  apiUrl: string;
  contentUrl: string;
  color: string;
  statusAmount = new BehaviorSubject<boolean>(null);

  constructor(private setting: SettingService,
              private toastr: AppToastrService,
              private projectFeaturesService: ProjectFeaturesService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }


  saveAlbum(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/album`, data);
  }

  saveProject(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/project`, data);
  }

  editAlbum(data, slug?: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/album/${slug}`, data);
  }

  _updateNewsItem(data, slug?: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/news/${slug}`, data);
  }

  setWordCode(data): Observable<SettingsModel> {
    return this.http.put(`${this.apiUrl}/admin/settings`, data).pipe(map((resp: any) => {
      return new SettingsModel().setFromApiModel(resp);
    }));
  }

  checkTotalShare(artistAmount = 0, investorAmount, producerAmount = 0, project?): Observable<any> {
    return new Observable(observer => {
      let isErorrResult: boolean = false;
      const strictEquality: boolean = +artistAmount + +producerAmount !== 100 - Number(investorAmount);
      // const minUnsoldEquality: boolean = +artistAmount + +producerAmount < 100 - Number(investorAmount);
      const withUnsoldAmout: number = (project?.artistShare + project?.producerShare + project?.totalInvestorsShares) || 100;
      // const maxUnsoldEquality: boolean = +artistAmount + +producerAmount + +investorAmount > withUnsoldAmout + (project?.unSoldShare || 0);
      const maxUnsoldEquality: boolean = +artistAmount + +producerAmount + +investorAmount > withUnsoldAmout;
      if (!project || !project.isFunded) {
        isErorrResult = strictEquality;
      } else {
        //isErorrResult = maxUnsoldEquality || minUnsoldEquality;
        isErorrResult = maxUnsoldEquality;
      }

      this.statusAmount.next(isErorrResult);
      observer.next(isErorrResult);
    });
  }

  statusAmountObservable(): Observable<any> {
    return this.statusAmount.asObservable();
  }

  setColorPicker(e): void {
    this.color = e;
  }

  saveNewsItem(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/news`, data);
  }

  deleteNewsItem(slug?: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/news/${slug}`);
  }

  _updateProject(data, slug?: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/project/${slug}`, data);
  }

  _updateProjectPart(slug: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/project/${slug}/field`, data);
  }

  deleteAlbum(slug?: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/album/${slug}`);
  }

  deleteProject(slug?: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/project/${slug}`).pipe(catchError(error => {
      this.toastr.showToastFromError(error);
      return throwError(error);
    }));
  }

  finishProject(slug: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/project/finish`, {slug})
      .pipe(
        catchError(error => {
          this.toastr.showToastFromError(error);
          return throwError(error);
        }));
  }

  getNewsList(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/news`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new NewsModel().setFromApiModel(item)),
          total: resp.total,
        };
      }
    }));
  }


  findValueByCriteria(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/projects`, {params}).pipe(map((resp: any) => {
      if (resp.list) {
        resp.list.map(item => {
          item.showDisplayName = item.projectTitle;
          return item;
        });
        return resp.list;
      }
    }));
  }

  getProject<T extends ApiModel>(data, modelType: { new(): T }): Observable<T> {
    return this.http.get(`${this.apiUrl}/admin/project/${data}`).pipe(
      map((resp: any) => {
        if (resp) {
          const model = new modelType();
          return model.setFromApiModel(resp);
        }
      })
    ) as Observable<T>;
  }

  getProjects<T extends ApiModel>(request: RequestModel, modelType: { new(): T }): Observable<ResponseTotal<T>> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/projects`, {params}).pipe(
      filter((resp: any) => !!resp.list),
      map((resp: any) => {
        const list = resp.list.map(item => {
          const model = new modelType();
          return model.setFromApiModel(item)
        });
        this.projectFeaturesService.setCount(list);
        return {
          list: list,
          total: resp.total,
        };

      }), catchError(error => {
        this.toastr.showToastFromError(error);
        return throwError(error);
      }));
  }

  getAlbums<T extends ApiModel>(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/albums`, {params}).pipe(map((resp: any) => {
      if (resp.list) {
        const list = resp.list.map(item => new ProjectModel().setFromApiModel(item));
        this.projectFeaturesService.setCount(list);
        return {
          list: list,
          total: resp.total,
        };
      }
    }), catchError(error => {
      this.toastr.showToastFromError(error);
      return throwError(error);
    }), shareReplay(1));
  }


  getNewsItem(data): Observable<NewsModel> {
    return this.http.get(`${this.apiUrl}/admin/news/${data}`).pipe(map((resp: any) => {
      if (resp) {
        return new NewsModel().setFromApiModel(resp);
      }
    }));
  }

  updateFeature(slug: string, status: boolean): Observable<ProjectModel> {
    return this.http.put(`${this.apiUrl}/admin/project/${slug}/makeFeatured`, {isFeature: status})
      .pipe(map((resp: any) => {
        if (resp) {
          return new ProjectModel().setFromApiModel(resp);
        }
      }), catchError(error => {
        this.toastr.showToastFromError(error);
        return throwError(error);
      }))
  }


  getPayout(projectSlug: string): Observable<PayoutSettings> {
    return this.http.get<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset/${projectSlug}`
    );
  }

  createPayout(payout: PayoutSettings): Observable<PayoutSettings> {
    return this.http.post<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset`, payout
    );
  }

  updatePayout(projectSlug: string, payout: PayoutSettings): Observable<PayoutSettings> {
    return this.http.put<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset/${projectSlug}`, payout
    );
  }

}
