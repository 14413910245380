import { Component, OnInit, HostBinding } from '@angular/core';
import { PERMISSION, STATE_NAMES } from 'app/common';
import { AuthenticationService } from 'app/services';
import { PermissionService } from '@core/services/permission.service';

declare interface NavItem {
  state: string;
  text: string;
  className: string;
  isVisible?: string | boolean;
}

//TODO: move statenames to child routes from own routes
const navList: NavItem[] = [
  {
    state: STATE_NAMES.dashboard,
    text: 'Dashboard',
    className: 'fa-desktop',
    isVisible: true,
  },
  {
    state: STATE_NAMES.albums,
    text: 'Albums',
    className: 'fa-compact-disc',
  },
  {
    state: STATE_NAMES.artistsList,
    text: 'Artists',
    className: 'fa-users',
  },
  {
    state: STATE_NAMES.producersList,
    text: 'Producers',
    className: 'fa-users',
  },
  {
    state: STATE_NAMES.fans,
    text: 'Fans',
    className: 'fa-user-friends',
  },
  {
    state: STATE_NAMES.investorsList,
    text: 'Investors',
    className: 'fa fa-euro',
  },
  {
    state: STATE_NAMES.opportunity,
    text: 'Projects',
    className: 'fa-guitar',
  },
  {
    state: STATE_NAMES.opportunityLinked,
    text: 'Linked Project',
    className: 'fa-link',
  },
  {
    state: STATE_NAMES.opportunityBundle,
    text: 'Bundle Project',
    className: 'fa-link',
  },
  {
    state: STATE_NAMES.opportunityGrs ,
    text: 'GRS Project',
    className: 'fa-link',
  },
  {
    state: STATE_NAMES.releases,
    text: 'Releases',
    className: 'fa-money-bill-wave',
  },
  {
    state: STATE_NAMES.news,
    text: 'News',
    className: 'fa-file-text',
    isVisible: true,
  },
  {
    state: STATE_NAMES.balances,
    text: 'Balances',
    className: 'fa-money',
  },
  {
    state: STATE_NAMES.collectible,
    // states: [STATE_NAMES.collectibleList, STATE_NAMES.collectibleItem],
    text: 'Collectibles',
    className: 'fa-bitcoin',
  },
  {
    state: STATE_NAMES.auctionMarket,
    // states: [STATE_NAMES.auctionMarket],
    text: 'Auction market',
    className: 'fa-solid fa-comments-dollar',
  },
  {
    state: STATE_NAMES.submissions,
    text: 'Submissions',
    className: 'fa-music',
  },
  {
    state: STATE_NAMES.voucher,
    text: 'Vouchers',
    className: 'fa-ticket',
  },
  {
    state: STATE_NAMES.promocode,
    text: 'Promocodes',
    className: 'fa-diamond',
  },
  {
    state: STATE_NAMES.landing,
    text: 'Landings',
    className: 'fa-window-restore',
  },
  {
    state: STATE_NAMES.settings,
    text: 'Settings',
    className: 'fa-wrench',
  },
  {
    state: STATE_NAMES.accounting,
    text: 'Accounting',
    className: 'fa-file-import',
  },
  {
    state: STATE_NAMES.roles,
    text: 'Roles and Users',
    className: 'fa-user-lock',
  },
  {
    state: STATE_NAMES.coLabel,
    text: 'Co-label',
    className: 'fa-sliders-h',
  },
];

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html',
})
export class NavigationComponent implements OnInit {

  links: NavItem[];

  @HostBinding('class.collapsed') isCollapsed: boolean = false;

  private readonly p_service = this.permissionsService.getPermissions();

  constructor(
    private authService: AuthenticationService,
    private permissionsService: PermissionService
  ) {}

  ngOnInit() {
    if (!this.p_service.length) {
      this.authService.logout();
    }

    this.getListMenu();
  }

  private showMenu(key: string, arr: string[]): boolean {
    let permisison = PERMISSION[key];
    if (Array.isArray(permisison)) {
      let filteredArray =
        arr.filter((n) => {
          return permisison.indexOf(n) !== -1;
        }) || [];
      return !!(filteredArray && filteredArray.length);
    }
    return arr.indexOf(permisison) >= 0;
  }

  getListMenu(): void {
    this.links = navList.map((link: NavItem) => {
      if (link.isVisible !== true) {
        link.isVisible = this.showMenu(link.state, this.p_service);
      }
      return link;
    });
  }
}
