export interface IColabel {
  name: string;
  domain: string;
  country: string;
  isEditMode?: boolean;
}

export enum ColabelStatus {
  SUBMIT = 'submit',
  APPROVE = 'approve',
  NEW = 'new'
}
export type PermissionName = 'albumPermission' | 'projectPermission';
