import {Component, OnInit} from '@angular/core';

import * as moment from 'moment';
import {
  AppToastrService, ReleasesService
} from '../../../services/index';
import {ROUTE_ALIAS, STATE_NAMES} from '@core/common';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ReleaseModel} from '@core/model/release.model';
import {Observable} from 'rxjs';
import {RequestModel} from '@core/model/request.model';
import {map, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

interface SelectArtist {
  projects: any[];
  user: string;
  name: string;
  _id: string;
  slug: string;
}

interface SelectProject {
  name: string;
  project: string;
  projectDetail: any;
  slug: string
}

function ReleasedSongs(value, elem): void {
  this.name = value.name;
  this.songId = value._id;
  this.date = moment(elem.date).format('YYYY/MM/DD');
}

@Component({
  selector: 'app-balances-item',
  templateUrl: './release-item.component.html',
  styleUrls: ['./release-item.component.scss']
})

export class ReleaseItemComponent implements OnInit {
  list: any[];
  text: any;
  selectedArtist: Partial<SelectArtist> = {};
  showPopupConfirmation: boolean;
  form: UntypedFormGroup;
  releaseItem: ReleaseModel;
  songCtrl: UntypedFormControl;
  totalGrossCtrl: UntypedFormControl;
  albumSongCtrl: UntypedFormArray;
  releaseNamesCtrl: UntypedFormControl;
  fileReportCtrl: UntypedFormControl;
  totalGrossDeliveredCtrl: UntypedFormControl;
  dateCtrl: UntypedFormControl;
  selectedProject: SelectProject;
  projectSongs;
  linkedProject$: Observable<any>;
  isSeparateSong = 'album';
  checkItem: boolean;
  showPopup: boolean;
  submitted: boolean;
  loadFile: boolean;
  showSongsTable: boolean;
  disableTransfer: boolean;
  artist: any;
  is_left_song: boolean;
  slug: string;
  params: any;
  viewMode: boolean = false;

  constructor(
    private releaseService: ReleasesService,
    private fb: UntypedFormBuilder,
    private toastr: AppToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.slug = this.route.snapshot.params.slug;
    if (this.router.getCurrentNavigation().extras.state) {
      this.viewMode = this.router.getCurrentNavigation().extras.state.viewMode;
    }
  }

  ngOnInit(): any {
    this.prepareForm();
    if (this.slug && this.slug != 'newItem') {
      this.getRelease(this.slug);
    }
  }

  prepareForm(): void {
    this.songCtrl = this.fb.control('', [Validators.required]);
    this.dateCtrl = this.fb.control('', [Validators.required]);
    this.releaseNamesCtrl = this.fb.control('');
    this.totalGrossCtrl = this.fb.control('');
    this.fileReportCtrl = this.fb.control('');
    this.totalGrossDeliveredCtrl = this.fb.control('');
    this.albumSongCtrl = this.fb.array([]);
    this.form = this.fb.group({
      song: this.songCtrl,
      totalGross: this.totalGrossCtrl,
      fileReport: this.fileReportCtrl,
      totalGrossDelivered: this.totalGrossDeliveredCtrl,
      releaseNames: this.releaseNamesCtrl,
      date: this.dateCtrl,
      albumSongs: this.albumSongCtrl
    });
    if (this.viewMode) {
      this.dateCtrl.disable();
    }
  }

  editDocument(): void {
    this.viewMode = false;
  }

  selectSong(e): void {
    this.selectedProject = this.selectedArtist?.projects.filter(song => song.projectDetail.slug === e.value)[0];

    if (this.selectedProject && this.selectedProject.projectDetail.isAlbum) {
      if (!this.selectedProject.projectDetail.isFunded) {
        this.isSeparateSong = 'song';
      }
    }
    if (this.selectedProject.projectDetail.linkedProject) {
      this.getLinkedRelease(this.selectedProject);
    }
  }

  getLinkedRelease(selectedProject): void {
    const request = new RequestModel({projectId: selectedProject.projectDetail.linkedProject});
    this.linkedProject$ = this.releaseService.getReleases(request).pipe(
      map((resp) => resp.list && resp.list.length ? resp.list[0] : null),
      switchMap(value => {
        if (value) {
          return this.releaseService.getReleaseItem(value.slug);
        }
        return value;
      }),
      tap(release => {
        this.releaseService.releaseItemLinked = release;
        return this.setFormLinkedRelease(release);
      })
    );
    this.linkedProject$.subscribe();
  }

  setFormLinkedRelease(release): void {
    this.releaseNamesCtrl.setValue(release.song.project.song.releaseNames);
    this.dateCtrl.setValue(moment(release.date, 'YYYY/MM/DD').format());
    this.releaseNamesCtrl.disable();
  }

  selectAlbumSong(): void {
    this.dateCtrl.setValidators(this.isSeparateSong === 'song' ? [] : [Validators.required]);
    this.dateCtrl.updateValueAndValidity();
    (this.form.get('albumSongs') as FormArray).clear();
    this.showSongsTable = false;
  }

  transferRevenue(item: ReleaseModel): void {
    this.disableTransfer = true;
    item.date = moment(item.date, 'DD.MM.YYYY').format('YYYY/MM/DD');
    this.releaseService.deliverRevenue(item, this.releaseItem.slug).subscribe(resp => {
      this.releaseItem = resp;
      this.disableTransfer = false;
      this.toastr.showToast({title: 'SUCCESS: Revenue was delivered'});
    }, err => {
      console.log(err);
      this.disableTransfer = false;
      this.toastr.showToastFromError(err);
    });
  }

  getRow(): UntypedFormGroup {
    return this.fb.group({
      song: this.fb.control('', [Validators.required]),
      date: this.fb.control('', [Validators.required])
    });
  }

  removeSong(item, index): void {
    (this.form.get('albumSongs') as UntypedFormArray).controls.splice(index, 1);
    if (!(this.form.get('albumSongs') as UntypedFormArray).controls.length) {
      this.showSongsTable = false;
      this.isSeparateSong = 'album';
    }
  }

  addReleaseSong(): void {
    if (this.isSeparateSong === 'song') {
      this.dateCtrl.setValidators([]);
      this.dateCtrl.updateValueAndValidity();
    } else {
      this.dateCtrl.setValidators([Validators.required]);
      this.dateCtrl.updateValueAndValidity();
    }
    this.showSongsTable = true;
    const mapper: { [key: string]: boolean } = {};
    if (this.releaseItem && this.releaseItem.slug) {
      this.projectSongs = this.releaseItem.songs;
      if (this.releaseItem.songAlreadyReleased) {
        this.releaseItem.songAlreadyReleased.forEach(it => {
          mapper[it.songId.toString()] = true;
        });
        this.projectSongs = this.projectSongs.filter(pr => !mapper[pr._id]);
      }
    } else {
      this.projectSongs = this.selectedProject.projectDetail.songs;
    }
    (this.form.get('albumSongs') as UntypedFormArray).push(this.getRow());
  }

  setForm(): void {
    if (this.releaseItem) {
      this.text = this.releaseItem.artist.name;
      this.songCtrl.setValue(this.releaseItem.song.name);
      this.fileReportCtrl.setValue(this.releaseItem.report);
      this.totalGrossCtrl.setValue(this.releaseItem.revenue);
      this.releaseNamesCtrl.setValue(this.releaseItem.song.project.song.releaseNames);
      if (this.releaseItem.songAlreadyReleased) {
        const mapper = {};
        this.releaseItem.songAlreadyReleased.forEach(item => {
          if (this.releaseItem.songs_with_revenues && this.releaseItem.songs_with_revenues[item.songId]) {
            item.no_delete = true;
          }
          mapper[item.songId] = true;
        });
        const names = this.releaseItem.songs.filter(song => mapper[song._id]).map(s => s.name);
        this.releaseNamesCtrl.setValue(names);
        this.is_left_song = this.releaseItem.songs.length === this.releaseItem.songAlreadyReleased.length;
      }

      this.totalGrossDeliveredCtrl.setValue(this.releaseItem.revenueDelivered);
      this.dateCtrl.setValue(moment(this.releaseItem.date, 'YYYY/MM/DD').format());
      this.checkItem = this.releaseItem.takenDown;
      this.songCtrl.disable();
      this.releaseNamesCtrl.disable();
      this.totalGrossCtrl.disable();
      this.totalGrossDeliveredCtrl.disable();
      this.isSeparateSong = this.releaseItem.songAlreadyReleased && !this.releaseItem.allProjectReleased ? 'song' : 'album';

    }
  }

  deleteSongRelease(song, isFront?): void {
    this.releaseService.deleteSong(this.releaseItem.slug, song.songId).subscribe(resp => {
      this.loadFile = false;
      this.router.navigate([ROUTE_ALIAS.releaseEdit, this.slug], {state: {viewMode: true}});
      this.toastr.showToast({title: 'SUCCESS: Release was saved'});
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  onNotify(e: any): void {
    if (e.type === 'deliverRevenue') {
      this.transferRevenue(e.data);
    }
    if (e.type === 'selectedItem') {
      this.selectedArtist = e.data;
    }
  }

  getRelease(slug): void {
    this.releaseService.getReleaseItem(slug).subscribe(resp => {
      this.releaseItem = resp;
      this.setForm();
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  redirectTolinkedRelease(): void {
    this.router.navigate([ROUTE_ALIAS.releaseEdit,  this.releaseItem.linkedRelease]).then(() => {
      window.location.reload();
    });
  }

  toggleRegime(): void {
    this.viewMode = true;
  }

  saveRelease(): void {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.showToastFromError({message: 'Invalid Form'});
      return;
    }

    const SLUG = this.releaseItem ? this.releaseItem.slug : null;
    this.submitted = true;
    let editReleaseSong;
    if (this.releaseItem) {
      editReleaseSong = {
        name: this.releaseItem.song.name,
        project: this.releaseItem.song.project._id,
        slug: this.releaseItem.song.slug
      };
    }
    const song = (this.selectedArtist && this.selectedArtist.projects) ?
      this.selectedArtist.projects.filter(item => item.slug === this.songCtrl.value)[0] : (this.releaseItem ? editReleaseSong : {});
    const data: any = {
      artist: (this.selectedArtist && this.selectedArtist._id) || (this.releaseItem && this.releaseItem.artist.id),
      date: moment(this.dateCtrl.value).format('YYYY/MM/DD'),
      song,
      report: this.fileReportCtrl.value?.replace(/ /g, '-'),
      takenDown: this.checkItem,
      linkedProject: this.releaseService.releaseItemLinked.song ? this.releaseService.releaseItemLinked.song.slug : null
    };

    if (this.isSeparateSong === 'song') {
      const array = (this.releaseItem && this.releaseItem.slug) ? this.releaseItem.songs : this.selectedProject.projectDetail.songs;

      array.forEach(item => {

        (this.form.get('albumSongs') as UntypedFormArray).value.forEach(element => {
          if (element.song === item._id) {
            data.releasedSongs = [];
            data.releasedSongs.push(new ReleasedSongs(item, element));
          }
        });
      });

      data.releasedSongs = this.releaseItem && this.releaseItem.songAlreadyReleased ? [...this.releaseItem.songAlreadyReleased, ...data.releasedSongs] : data.releasedSongs;
      data.date = moment((this.form.get('albumSongs') as UntypedFormArray).value[0].date).format('YYYY/MM/DD');
    }

    if (
      this.isSeparateSong === "album" &&
      (this.slug === 'newItem' || this.releaseItem?.isAlbum)
    ) {
      data.allProjectReleased = true;
      data.slug = SLUG;
    }

    this.releaseService[this.releaseItem ? 'updateRelease' : 'addRelease'](data, SLUG)
      .subscribe(resp => {
        this.loadFile = false;
        this.submitted = false;
        this.router.navigate([ROUTE_ALIAS.releaseEdit, resp.slug], {state: {viewMode: true}});
        this.toastr.showToast({title: 'SUCCESS: Release was saved'});
      }, err => {
        this.submitted = false;
        this.toastr.showToastFromError(err);
      });
  }

}
