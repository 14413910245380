import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppToastrService, ReleasesService, UploadService} from '../../../services';
import * as moment from 'moment';
import {ReleaseModel} from '../../../model/release.model';
import {SongModel} from '@core/model';
import {CustomFieldErrorMatcher} from '@core/common';
import {REVENUE_GROUPS} from '@core/common/typeInvestors';

interface PopupData {
  amount: number;
  uniqMatches: any;
  exchangeRate: any;
  totalRelease: number;
}

interface Song extends SongModel {
  loadFile: boolean;
  reportUrl: string;
  index: number;
}

@Component({
  selector: 'app-release-revenue',
  templateUrl: './release-revenue.html'
})
export class ReleaseRevenueComponent implements OnInit, OnChanges {
  @Input() releaseItem: any;
  @Input() viewMode: boolean;
  @Input() onlyView: boolean;
  @Input() disableTransfer: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  form: UntypedFormGroup;
  dateCtrl: UntypedFormControl;
  amountCtrl: UntypedFormControl;
  groupCtrl: UntypedFormControl;
  amountArrCtrl: UntypedFormArray;
  correctionArrCtrl: UntypedFormArray;
  correctionCtrl: UntypedFormControl;
  selectedSong: Song;
  editRow: boolean;
  submitPressed: boolean;
  firstTime = true;
  loadFile: boolean;
  popupData: any = {};
  revenueData: any;
  groups = REVENUE_GROUPS;
  defaultReleaseSongs: any;
  isPublishing: boolean;
  showPopup: boolean;
  reportUrl: string;

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private releaseService: ReleasesService,
              private uploadService: UploadService) {

  }

  ngOnInit(): any {
    this.prepareForm();
  }

  ngOnChanges(e): any {
    if (e.releaseItem && e.releaseItem.currentValue) {
      if (this.releaseItem.isAlbum && this.firstTime) {
        this.defaultReleaseSongs = JSON.parse(JSON.stringify(this.releaseItem));
        this.firstTime = false;
      }
    }
  }

  errorMatcher(control: UntypedFormControl, additionalCheck?: boolean) {
    let error: boolean;
    error = !this.dateCtrl.value;
    // if (additionalCheck) {
    //   error = this.repeatPasswordCtrl.value !== this.passwordCtrl.value && this.repeatPasswordCtrl.touched;
    // }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }

  prepareForm(): void {
    this.dateCtrl = this.fb.control('', [Validators.required]);
    this.amountCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'blur'});
    this.amountArrCtrl = this.fb.array([]);
    this.correctionCtrl = this.fb.control('');
    this.groupCtrl = this.fb.control(1);
    this.correctionArrCtrl = this.fb.array([]);
    this.form = this.fb.group({
      date: this.dateCtrl,
      amountArray: this.amountArrCtrl,
      correctionArr: this.correctionArrCtrl,
      amount: this.amountCtrl,
      group: this.groupCtrl,
      correction: this.correctionCtrl
    });
  }

  addRevenueTable(type: string, isPublish?: boolean): void {
    this.editRow = true;
    this.isPublishing = isPublish;
    if (this.releaseItem && this.releaseItem.isAlbum) {
      this.form.reset();
      this.prepareForm();
      this.releaseItem.songs = this.defaultReleaseSongs ? this.defaultReleaseSongs.songs : this.releaseItem.songs;
      if (!this.releaseItem.allProjectReleased) {
        this.releaseItem.songs = this.releaseItem.songs.filter(song => {
          const found = this.releaseItem.songAlreadyReleased.some(rel => {
            return rel.songId === song._id;
          });
          if (found) {
            return song;
          }
        });
      }
      this.releaseItem.songs.forEach(item => {
        this.amountArrCtrl.push(new UntypedFormControl(''));
        this.correctionArrCtrl.push(new UntypedFormControl(''));
      });
    }

    if (this.isPublishing) {
      this.groupCtrl.setValue(2);
      this.groupCtrl.disable();
    } else {
      this.groupCtrl.setValue(1);
      this.groupCtrl.enable();
    }
    this.resetFrom();
  }

  uploadfile(e, song?, index?): any {
    this.loadFile = true;
    const file = e.files[0];
    const data = {
      type: 'release_report',
      typeFile: '',
      url: 'releaseUpload/' + this.releaseItem.slug,
    };
    const extension = ['pdf', 'csv'];
    if (song) {
      this.selectedSong = song;
      this.selectedSong.loadFile = true;
      this.selectedSong.index = index;
      data.url = data.url + '/' + song._id;
    } else {
      data.url = data.url + '/false';
    }

    if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
      this.toastr.showErrorToast({title: 'File extension should be .pdf or .csv'});
      return false;
    }
    let options = {
      typeFile: (file.name.split('.').slice(-1)[0]).toLowerCase(),
      forbidParsingFile: this.isPublishing
    };
    this.uploadService.uploadFilePartially(file, data.type, data.url, null, options).then(res => {
      if (res) {
        if (data.typeFile === 'csv' && !this.isPublishing) {
          this.showPopup = true;
          this.revenueData = res;
          this.popupData = this.calculateData(res);
        } else {
          if (song) {
            song.reportUrl = res.url.replace(/ /g, '-');
          }
          this.dateCtrl.setValue(moment(res.date, 'YYYY/MM/DD').toISOString());
          this.reportUrl = res.url.replace(/ /g, '-');

        }
      }
      this.loadFile = false;
      if (song) {
        this.selectedSong.loadFile = false;
      }
    }, (err) => {
      this.loadFile = false;
      this.toastr.showToastFromError(err);
    });
  }


  resetFrom(): void {
    this.dateCtrl.reset();
    this.amountCtrl.reset();
    this.correctionCtrl.reset();

    this.reportUrl = null;
  }

  editRevenue(item): void {
    this.releaseItem?.revenueHistory.forEach(it => {
      it.edit = false;
    });
    item.edit = true;
    this.setForm(item);
  }

  transferRevenue(item: any): void {
    this.disableTransfer = true;
    item.disabled = true;
    item.deliveryGroup = (item.deliveryGroup && item.deliveryGroup.text) ? item.deliveryGroup.id : item.deliveryGroup;
    this.notify.emit({type: 'deliverRevenue', data: item});
    this.clearForm();
  }

  setForm(item, song?): void {
    this.dateCtrl.setValue(moment(item.date, 'DD.MM.YYYY').toISOString());
    this.amountCtrl.reset(item.amount);
    this.correctionCtrl.reset(item.correction);
    this.groupCtrl.setValue(item.deliveryGroup ? item.deliveryGroup.id : 1);
    this.isPublishing = item.isPublishing;
    if (song) {
      this.amountArrCtrl.controls[song.index].reset(item.amount);
      this.correctionArrCtrl.controls[song.index].reset(item.correction);
    }
  }

  closeRevenue(item?: any): void {
    if (item) {
      item.edit = false;
    }
    if (this.releaseItem.isAlbum && this.defaultReleaseSongs) {
      this.form.reset();
      this.releaseItem.songs = JSON.parse(JSON.stringify(this.defaultReleaseSongs.songs));
      this.prepareForm();
    }
    this.editRow = false;
    this.resetFrom();
  }

  deleteItem(index: number): void {
    if (this.releaseItem.revenueHistory && this.releaseItem.revenueHistory.length) {
      this.releaseItem.revenueHistory.splice(index, 1);
    }
  }

  deleteRevenue(item: ReleaseModel, index: number): void {
    this.releaseService.deleteRevenueService(item._id, this.releaseItem.slug).subscribe(resp => {
      this.toastr.showToast({title: 'SUCCESS: Revenue was deleted'});
      this.deleteItem(index);

    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  calculateData(data: any): PopupData {
    this.popupData.totalRelease = data.length;
    this.popupData.amount = data.amount;
    this.popupData.uniqMatches = data.noMatch;
    this.popupData.exchangeRate = data.exchangeRate;
    return {
      amount: data.amount,
      uniqMatches: data.noMatch,
      exchangeRate: data.exchangeRate,
      totalRelease: 1
    };
  }

  proceedUpload(cancel?: boolean): void {
    this.releaseService.revenueConfirm(this.revenueData, cancel).subscribe(resp => {
      this.loadFile = false;

      if (cancel) {
        this.selectedSong = null;
        const message = {
          error: {
            message: 'FAILED: Data is not saved'
          }
        };
        this.toastr.showToastFromError(message);
      } else {
        const data = {
          amount: this.revenueData.amount,
          date: this.revenueData.date,
        };
        this.setForm(data, this.selectedSong);
        this.reportUrl = resp.url.replace(/ /g, '-');
        if (this.selectedSong) {
          this.selectedSong.loadFile = false;
          this.selectedSong.reportUrl = resp.url.replace(/ /g, '-');
          this.selectedSong = null;
        }
        this.toastr.showToast({title: 'SUCCESS: Data is saved'});
      }

    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  onNotify(e): void {
    if (e.type === 'submit') {
      this.proceedUpload();
    }
    if (e.type === 'cancelUpload') {
      this.proceedUpload(true);
    }
    this.showPopup = false;
  }

  clearForm(): void {
    if (this.releaseItem.isAlbum && this.defaultReleaseSongs) {
      this.releaseItem.songs = JSON.parse(JSON.stringify(this.defaultReleaseSongs.songs));
    }
    this.form.reset();
    this.prepareForm();
  }

  saveRevenue(item?: any): void {
    const dSong = [];
    let checkValidity = false;
    if (this.releaseItem.isAlbum && !item) {
      this.amountCtrl.clearValidators();
      this.amountCtrl.setValidators(null);
      this.amountCtrl.updateValueAndValidity();
      this.releaseItem.songs.forEach((it, index) => {
        if (Number(this.amountArrCtrl.value[index]) > 0) {
          checkValidity = true;
          dSong.push({
            id: it._id,
            songReleaseName: it.releaseNames[0],
            amount: Number(this.amountArrCtrl.value[index]),
            correction: this.correctionArrCtrl.value[index],
            report: it.reportUrl,
          });
        }
      });
    } else {
      checkValidity = true;
    }
    this.submitPressed = true;
    if (!checkValidity) {
      this.toastr.showToastFromError({
        error: {
          message: 'Please populate Amount'
        }
      });
    }
    if (this.form.valid && checkValidity) {
      const {group} = this.form.getRawValue();
      const data: any = {
        amount: Number(this.amountCtrl.value),
        date: moment(this.dateCtrl.value).format('YYYY/MM/DD'),
        correction: this.correctionCtrl.value,
        report: this.reportUrl,
        deliveryGroup: Number(group) || 1,
        songs: dSong || [],
        isPublishing: !!this.isPublishing,
      };
      if (item) {
        data._id = item._id;
      }
      this.releaseService[item ? 'editRevenueService' : '_saveRevenue'](data, this.releaseItem.slug).subscribe(resp => {
        this.submitPressed = false;
        this.clearForm();
        this.releaseItem = resp;
        this.editRow = false;
      }, err => {
        this.submitPressed = false;
        this.editRow = false;
        console.log(err);
        this.toastr.showToastFromError(err);
      });
    }
  }

}
