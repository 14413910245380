import {Component, OnInit, HostListener, Input, Output, EventEmitter, ViewContainerRef, HostBinding} from '@angular/core';
import * as moment from 'moment';
import {UntypedFormControl} from '@angular/forms';

moment.locale('ru');

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.html'
})
export class DatepickerComponent implements OnInit {
  date: string;
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() disablePast: boolean;
  @Input() viewP: string;
  @Input() fromTo: boolean;
  @Input() forFilter = false;
  calendarTitle: string;
  dates: any[];
  currentDate: Date;
  currentTime: number;
  currentDateCheck: string;
  currentDayNum: number;
  valFromTo = [];
  activeTime = 0;
  @HostBinding('class.opened') opened: boolean;
  view = 'day';
  monthsToSelect: any[] = [];
  currentYear: number;
  timeFrom = 0;
  timeTo = 0;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event']) onClick(event) {
    if (this.opened && !this.hasSomeParentTheClass(event.target)) {
      this.opened = false;
      // this.view = 'day';
      // this.date = this.activeTime ? moment(this.activeTime).format('DD.MM.YYYY') : null;
      // this.prepareData();
    }
  }

  constructor(private el: ViewContainerRef) {
  }

  ngOnInit() {
    this.prepareData(this.control ? this.control.value : null);
    this.placeholder = !this.placeholder ? '' : this.placeholder;
  }

  prepareData(date?: string) {
    if (date) {
      if (typeof date === 'object') {
        const st = moment(this.control.value[0], 'DD.MM.YYYY').startOf('day');
        this.timeFrom = +st.startOf('day').format('X000');
        this.currentDate = st.toDate();
        const lst = moment(this.control.value[1], 'DD.MM.YYYY').startOf('day');
        this.timeTo = +lst.startOf('day').format('X000');
        this.date = moment(this.timeFrom).format('DD.MM.YYYY') + ' - ' + moment(this.timeTo).format('DD.MM.YYYY');
      } else {
        const d = moment(date, 'DD.MM.YYYY').startOf('day');
        this.currentDate = d.toDate();
        this.activeTime = +d.startOf('day').format('X000');
      }
    } else {
      this.currentDate = moment().startOf('day').toDate();
    }
    this.currentTime = +moment().startOf('day').format('X000');
    this.currentDateCheck = `${this.currentDate.getMonth()}/${this.currentDate.getFullYear()}`;
    this.currentDayNum = +this.currentDate.getDate();
    this.currentDate.setDate(1);
    this.getDates(this.currentDate);
    this.prepareMonthsToSelect();
  }

  getDates(date: Date) {
    this.calendarTitle = moment(date).format('MMMM YYYY');
    const now = moment(date).startOf('month');
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDayNumber = new Date(year, month, 1).getDay();
    const totalDays = this.getTotalsDays(year, month);
    const totalLast = this.getTotalsDays(year, month - 1);
    const datesMap = [];
    let i = 1 - firstDayNumber;
    let lastItem;
    while (i <= totalDays) {
      if (!datesMap.length || datesMap[datesMap.length - 1].length === 7) {
        datesMap.push([]);
      }
      lastItem = datesMap[datesMap.length - 1];
      if (i <= 0) {
        lastItem.push([totalLast + i, 'last']);
      } else {
        lastItem.push([i, 'current', +now.format('X000')]);
        now.add(1, 'days');
      }
      i++;
    }

    const lastLength = lastItem.length;
    for (let j = 1; j < (8 - lastLength); j++) {
      lastItem.push([j, 'next', +now.format('X000')]);
      now.add(1, 'days');
    }
    this.dates = datesMap;
    if (this.fromTo) {
      this.getActiveCells();
    }
  }

  getTotalsDays(year: number, month: number) {
    return new Date(year, month + 1, 0).getDate();
  }

  changePeriod(isNext?: boolean) {
    if (this.view === 'day') {
      this.changeMonth(isNext);
    } else if (this.view === 'month') {
      this.changeYear(isNext);
    } else if (this.view === 'year') {
      this.changeYearsList(isNext);
    }
    if (this.timeFrom && this.timeTo) {
      this.getActiveCells();
    }
  }

  changeMonth(isNext?: boolean) {
    if (isNext) {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    } else {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    }
    this.getDates(this.currentDate);
  }

  changeYear(isNext?: boolean) {
    if (isNext) {
      this.currentDate.setFullYear(this.currentDate.getFullYear() + 1);
    } else {
      this.currentDate.setFullYear(this.currentDate.getFullYear() - 1);
    }
    this.calendarTitle = this.currentDate.getFullYear().toString();
  }

  changeYearsList(isNext?: boolean) {
    if (isNext) {
      this.currentYear += 12;
    } else {
      this.currentYear -= 12;
    }
    this.calendarTitle = `${this.currentYear - 8} - ${this.currentYear + 3}`;
  }

  getActiveCells() {
    this.dates.forEach(row => {
      row.forEach(items => {
        if ((this.timeFrom < items[2] && items[2] < this.timeTo) && this.timeTo) {
          items.active = true;
        } else {
          items.active = false;
        }
      });
    });
  }

  onDateClick(item, isDisabeld?: boolean) {
    if (!isDisabeld) {
      if (this.fromTo) {
        if (!this.timeFrom) {
          this.timeFrom = (this.timeFrom !== item[2]) ? item[2] : 0;
          this.valFromTo.push(moment(this.timeFrom).format('DD.MM.YYYY'));
        } else if (!this.timeTo) {
          if (item[2] > this.timeFrom) {
            this.timeTo = (this.timeTo !== item[2]) ? item[2] : 0;
            this.getActiveCells();
            this.date = moment(this.timeFrom).format('DD.MM.YYYY') + ' - ' + moment(this.timeTo).format('DD.MM.YYYY');
            this.valFromTo.push(moment(this.timeTo).format('DD.MM.YYYY'));
            this.opened = false;
            this.control.setValue(this.valFromTo || null);
          }
          // this.view = 'day';
        } else {
          this.timeFrom = (this.timeFrom !== item[2]) ? item[2] : 0;
          this.timeTo = 0;
          this.getActiveCells();
        }

      } else {
        this.activeTime = (this.activeTime !== item[2]) ? item[2] : 0;
        let val;
        if (this.activeTime) {
          this.date = moment(this.activeTime).format('DD.MM.YYYY');
          val = this.date;
          this.opened = false;
        }
        if (this.control) {
          this.control.setValue(val || null);
        }
        this.dateChange.emit(val || null);
        this.view = 'day';
      }
    }
  }

  onFocus() {
    this.opened = true;
  }

  hasSomeParentTheClass(element) {
    const allowedClasses = ['item month', 'item year'];
    if (this.el.element.nativeElement.isEqualNode(element) || allowedClasses.indexOf(element.className) >= 0) {
      return true;
    } else if (element.tagName.toLowerCase() === 'html') {
      return false;
    } else {
      return element.parentNode && this.hasSomeParentTheClass(element.parentNode);
    }
  }

  changeView() {
    if (this.view === 'day') {
      this.view = 'month';
      this.calendarTitle = moment(this.currentDate).format('YYYY');
    } else if (this.view === 'month') {
      this.currentYear = +this.currentDate.getFullYear();
      this.calendarTitle = `${this.currentYear - 8} - ${this.currentYear + 3}`;
      this.view = 'year';
    }
  }

  private prepareMonthsToSelect() {
    this.monthsToSelect = [
      {value: '01', text: 'Янв'},
      {value: '02', text: 'Фев'},
      {value: '03', text: 'Мар'},
      {value: '04', text: 'Апр'},
      {value: '05', text: 'Май'},
      {value: '06', text: 'Июн'},
      {value: '07', text: 'Июл'},
      {value: '08', text: 'Авг'},
      {value: '09', text: 'Сен'},
      {value: '10', text: 'Окт'},
      {value: '11', text: 'Ноя'},
      {value: '12', text: 'Дек'}
    ];
  }

  onMonthClick(month: string, e) {
    e.stopPropagation();
    const date = moment(`01/${month}/${this.currentDate.getFullYear()}`, 'DD.MM.YYYY');
    this.calendarTitle = date.format('MMMM YYYY');
    this.currentDate = date.toDate();
    this.getDates(this.currentDate);
    this.view = 'day';
  }

  onYearClick(year: number, e) {
    e.stopPropagation();
    const date = moment(`01/01/${year}`, 'MM/DD/YYYY');
    this.calendarTitle = date.format('MMMM YYYY');
    this.currentDate = date.toDate();
    this.getDates(this.currentDate);
    this.view = 'month';
  }

  resetData() {
    this.activeTime = 0;
    this.date = null;
  }
}
