<div class="crop">
  <label class="button yellow crop__upload">
    <input class="desktop-upload" type="file" (change)="fileChangeEvent($event)"/>
    <span>Choose the picture </span>
  </label>
  <image-cropper *ngIf="config?.imageFormat === 'png'"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="!!maintainAspectRatio"
    [aspectRatio]="config?.ratio"
    [disabled]="!!config?.disabled"
    format="webp"
    (imageCropped)="imageCroppedw($event)"
    (imageLoaded)="imageLoadedw($event)"
    (cropperReady)="cropperReadyw()"
    (loadImageFailed)="loadImageFailedw($event)"
  ></image-cropper>
  <image-cropper *ngIf="config?.imageFormat === 'icon'"
                 [imageChangedEvent]="imageChangedEvent"
                 [maintainAspectRatio]="!!maintainAspectRatio"
                 [aspectRatio]="config?.ratio"
                 format="png"
                 (imageCropped)="imageCroppedw($event)"
                 (imageLoaded)="imageLoadedw($event)"
                 (cropperReady)="cropperReadyw()"
                 (loadImageFailed)="loadImageFailedw($event)"
  ></image-cropper>
  <button type="button" class="button yellow" *ngIf="imageLoaded" (click)="savePhoto()">
    Save picture
  </button>
</div>
