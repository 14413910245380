import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-selector',
  templateUrl: './selector.html',
  styleUrls: ['./_selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorComponent {
  @Input() inputCtrl: AbstractControl;
  @Input() value: any;
  @Input() list: any[];
  constructor() {}
}
