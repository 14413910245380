import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {
  AppToastrService, DictionaryService, ProjectService, RoutingService, UploadService
} from '../../../services/index';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfirmPopup, IUploadConfig, SelectedArtist} from '@core/common/interfaces';
import {CollectibleModel, IEditions} from '@core/model/collectible.model';
import {
  COLLECTIBLE_TYPE,
  CustomFieldErrorMatcher,
  FILE_SAVE_CONFIG,
  IMAGE_SETTINGS_CARD,
  NUMBER_POSITIVE,
  PATH_SAVE,
  STATE_NAMES
} from '@core/common';
import {CollectibleService, IStartEditionAuction} from '@core/services/collectible.service';
import {ICollectible} from '@core/interfaces/collectible.interface';
import {UploadConfigs} from '@core/interfaces/user.interface';
import {ReadMoreSectionComponent} from '@core/component/projects-parts/read-more-section/read-more-section.component';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Dictionary} from '@core/model/dictionary.model';
import {Subscription} from 'rxjs';
import {angularEditorParams} from '@core/common/angular-editor-config';
import {TeaserComponent} from '@core/component/projects-parts/teaser/teaser.component';
import { IGIFimages } from '@core/interfaces/config.interface';

type selectType = 'png' | 'gif';

@Component({
  selector: 'app-collectible-item',
  templateUrl: './collectibles-item.html',
  styleUrls: ['./_collectibles-item.scss']
})
export class CollectiblesItemComponent implements OnInit, OnDestroy {
  slug: string;
  configUploadVideo: IUploadConfig = {
    typeDocument: 'video',
    uploadText: 'Upload video file (.mp4, webm)',
    path: PATH_SAVE.VIDEO_ALL
  };
  selectTypeNFTimage: selectType = 'png';
  imageNFTConfig: UploadConfigs = {
    imageFormat: 'png',
    ratio: 0.6,
    path: PATH_SAVE.VIDEO_NFT,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP'],
  };
  imageNFTConfigGIF: UploadConfigs = FILE_SAVE_CONFIG.NFT_GIF;
  collectibleImage = {
    imageFormat: 'png',
    ratio: 16 / 6,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP']
  };
  backgroundConfig = IMAGE_SETTINGS_CARD;
  videoConfig = {
    path: PATH_SAVE.VIDEO_NFT,
  };
  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Mint',
    btnCancel: true,
    submitEmit: 'mintSong',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to mint song?'
  };
  showPopupConfirmation: boolean;
  showStartAuction: boolean;
  submitted: boolean;
  isLoaded: boolean;
  artistName: string;

  showGiveAway: any;
  collectibleItem: Partial<ICollectible>;
  form: UntypedFormGroup;
  textBiographyCtrl: UntypedFormControl;
  imgNFTCtrl: UntypedFormControl;
  videoNFTCtrl: UntypedFormControl;
  offerDetailsCtrl: UntypedFormControl;
  collectibleImgCtrl: UntypedFormControl;
  limitedIconCtrl: UntypedFormControl;
  collectionRightsCtrl: UntypedFormControl;
  goodiesIconCtrl: UntypedFormControl;
  typeCollectibleCtrl: UntypedFormControl;
  textAlbumCtrl: UntypedFormControl;
  selectTypeBackgroundCtrl: UntypedFormControl;
  videoCtrl: UntypedFormControl;
  isPublishedCtrl: UntypedFormControl;
  collectionNameCtrl: UntypedFormControl;
  genresCtrl: UntypedFormControl;
  songCtrl: UntypedFormControl;
  editionCtrl: UntypedFormControl;
  listEditions: IEditions[];
  auctionStatus: boolean;
  submitPressed: boolean;
  genres: Dictionary[];
  $subCollectible: Subscription;
  editorConfig: AngularEditorConfig = angularEditorParams;
  selectedArtist: SelectedArtist = {
    user: '',
    name: '',
    id: ''
  };
  nftGIFcover: IGIFimages;
  @ViewChild(ReadMoreSectionComponent, {static: false}) readMoreData: ReadMoreSectionComponent;
  @ViewChild(TeaserComponent, {static: false}) teaserComponent: TeaserComponent;

  constructor(private routingService: RoutingService,
              private fb: UntypedFormBuilder,
              private projectService: ProjectService,
              private dictionaryService: DictionaryService,
              private uploadService: UploadService,
             // private stateService: StateService,
              private collectibleService: CollectibleService,
              private toastr: AppToastrService
  ) {
    // const slug = stateService.params.slug;
    // if (slug !== 'newItem') {
    //   this.slug = slug;
    // }
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getGenres();
    if (this.slug) {
      this.getItem();
    } else {
      this.collectibleItem = {} as ICollectible;
    }
  }

  ngOnDestroy(): void {
    if (this.$subCollectible) {
      this.$subCollectible.unsubscribe();
    }
  }

  getGenres(): void {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;
    });
  }

  setForm(data?): void {
    this.form.patchValue(data);
    this.artistName = data.artist;
  }

  errorMatcher(control: UntypedFormControl): CustomFieldErrorMatcher {
    let error;
    const showError = this.submitPressed;
    return new CustomFieldErrorMatcher(control, showError, error);
  }

  prepareForm(): void {
    this.videoCtrl = this.fb.control('');
    this.offerDetailsCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'blur'});
    this.typeCollectibleCtrl = this.fb.control(COLLECTIBLE_TYPE.FIXED_PRICE);
    this.textBiographyCtrl = this.fb.control('', {validators: [Validators.required]});
    this.selectTypeBackgroundCtrl = this.fb.control('', [Validators.required]);
    this.songCtrl = this.fb.control('', [Validators.required]);
    this.isPublishedCtrl = this.fb.control('');
    this.textAlbumCtrl = this.fb.control('');
    this.collectionNameCtrl = this.fb.control('');
    this.videoNFTCtrl = this.fb.control('');
    this.collectibleImgCtrl = this.fb.control('', [Validators.required]);
    this.collectionRightsCtrl = this.fb.control('');
    this.genresCtrl = this.fb.control('', [Validators.required]);
    this.limitedIconCtrl = this.fb.control(false);
    this.goodiesIconCtrl = this.fb.control(false);
    this.imgNFTCtrl = this.fb.control('', [Validators.required]);
    this.editionCtrl = this.fb.control('', [Validators.required, Validators.pattern(NUMBER_POSITIVE)]);

    this.form = this.fb.group({
      video: this.videoCtrl,
      imgNFT: this.imgNFTCtrl,
      videoNFT: this.videoNFTCtrl,
      myOffersDetails: this.offerDetailsCtrl,
      isPublished: this.isPublishedCtrl,
      collectionName: this.collectionNameCtrl,
      collectionRights: this.collectionRightsCtrl,
      limitedIcon: this.limitedIconCtrl,
      goodiesIcon: this.goodiesIconCtrl,
      collectibleImg: this.collectibleImgCtrl,
      genre: this.genresCtrl,
      biography: this.textBiographyCtrl,
      song: this.songCtrl,
      myProjectDescription: this.textAlbumCtrl,
      typeCollectible: this.typeCollectibleCtrl,
      edition: this.editionCtrl,
      backgroundImage: this.selectTypeBackgroundCtrl,
      fees: this.fb.group({
        feesAmount: [0],
        arrayArtists: this.fb.array([]),
        arrayGRSs: this.fb.array([]),
        artist: [0],
        grs: [0],
      })
    });
    this.projectService.setColorPicker(null);
  }

  getItem(): void {
    this.$subCollectible = this.collectibleService.getCollectible(this.slug).subscribe(resp => {
      if (resp) {
        this.slug = resp.slug;
        this.collectibleItem = resp;
        this.listEditions = resp.listEditions;
        this.setForm(resp);
      }
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  saveCollectible(): void {
    if (!this.videoCtrl.value) {
      return this.toastr.showErrorToast({message: 'Please add Video'});
    }
    if (!this.form.get('cardColor').value) {
      return this.toastr.showErrorToast({message: 'Please add Card color'});
    }

    this.submitPressed = true;

    if (this.form.valid) {
      const projectData: any = {
        offerTeaser: this.teaserComponent.getValue(),
        selectedArtist: this.selectedArtist
      };
      if (this.nftGIFcover) {
        projectData.nft = {
          imageURL: this.nftGIFcover.imgURL,
          imageGIFURL: this.nftGIFcover.imageCoveringURL
        };
      }
      projectData.selectedArtist.name = this.selectedArtist?.name || this.collectibleItem?.artist
      projectData.selectedArtist.id = this.selectedArtist?.id || this.collectibleItem?.artistExistId?.id;
      const data: ICollectible = new CollectibleModel(null, this.uploadService).getFromApiModel(this.form, projectData);
      this.collectibleService[this.slug ? '_updateCollectible' : 'saveCollectible'](data, this.slug).subscribe(resp => {
        if (resp) {
          this.routingService.go(STATE_NAMES.collectibleItem, {
            slug: resp.slug || this.slug
          }, true);
          this.toastr.showToast({title: 'SUCCESS: Project was saved'});
        }
      }, err => {
        this.submitPressed = false;
        this.toastr.showToastFromError(err);
      });
    } else {
      this.submitPressed = false;
      return this.toastr.showErrorToast({message: 'Form is not valid'});
    }
  }

  onClickGiveAway(item) {
    this.showGiveAway = item;
  }

  onNotify(e): void {
    if (e.type === this.popupConfig.submitEmit) {
      this.proceedMintSong();
    }
    if (e.type === 'startAuction') {
      this.startAuction(e.data);
    }
    if (e.type === 'transferEdition') {
      this.getItem();
    }
    this.showStartAuction = false;
    this.showGiveAway = null;
    this.showPopupConfirmation = false;
  }

  startAuction(data: IStartEditionAuction): void {
    this.submitted = true;

    const body = {
      editions: this.collectibleItem.listEditions,
      amount: data.amount,
      startDate: data.dateStart,
      endDate: data.dateEnd
    };
    this.collectibleService._startAuction(body, this.slug).subscribe(() => {
      this.routingService.go(STATE_NAMES.collectibleItem, {slug: this.slug}, true);
      this.toastr.showToast({title: 'SUCCESS: Auction is started'});
    }, err => {
      this.submitted = false;
      this.toastr.showToastFromError(err);
    });
  }

  proceedMintSong(): void {
    this.isLoaded = true;
    this.collectibleService._mintCollectibles(this.slug).subscribe(() => {
      this.isLoaded = false;
      this.toastr.showToast({title: 'SUCCESS: Project was sent to mint'});
      this.routingService.go(STATE_NAMES.collectibleItem, {slug: this.slug}, true);
    }, err => {
      this.isLoaded = false;
      this.toastr.showToastFromError(err);
    });
  }

  mintSong(): void {
    if(this.form.valid){
      if (this.listEditions || !this.listEditions.length) {
        this.showPopupConfirmation = true;
      }
    } else {
      let message = {message: 'Form is invalid'}
      this.toastr.showToastFromError({error: message});
      this.form.markAllAsTouched();
    }
  }

  createAuction(): void {
    if (this.listEditions && this.listEditions.length) {
      this.listEditions = this.listEditions.filter(item => !item.auctionId);
      this.showStartAuction = true;
    }
  }

  nftImageGIF(e): void {
    this.nftGIFcover = e;
  }
}
