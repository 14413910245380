<div class="section-title">Goodies content</div>
<form [formGroup]="formReadGroup">
  <div class="content-item">
    <div class="item-label header-inform-text-grey">Image</div>
    <div class="load-image item-field">
      <app-upload-image
        [config]="imageReadMoreConfig"
        [control]="formReadGroup.get('imgReadMore')"
        [maintainAspectRatio]="true"
        [imgURL]="formReadGroup.get('imgReadMore').value"
      ></app-upload-image>
    </div>
  </div>
  <div class="content-item">
    <div class="item-label header-inform-text-grey">Title</div>
    <div class="item-field">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        [formControl]="formReadGroup.get('title')"
      ></angular-editor>
    </div>
  </div>
  <div class="content-item">
    <div class="item-label header-inform-text-grey">Text</div>
    <div class="item-field">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        [formControl]="formReadGroup.get('text')"
      ></angular-editor>
    </div>
  </div>
</form>
