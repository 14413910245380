import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-nft-fee',
  templateUrl: './nft-fee.html',
  styleUrls: ['./_nft-fee.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NftFeeComponent implements OnInit {
  inputCtrl: FormArray;
  totalArtistFees$ = new BehaviorSubject<any>(0);
  totalGrsFees$ = new BehaviorSubject<any>(0);
  @Input() form: FormGroup;

  _data: any;

  get data(): any {
    return this._data;
  }

  @Input() set data(value: any) {
    this._data = value;
    if (this.data && this.data.fees) {
      this.populateForm();
    }
  }

  ngOnInit(): any {
    this.form
      .get('fees')
      .get('grs')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const artistFeesAmount = this.form.get('fees').get('artist').value;
        const changeAmount = Number(artistFeesAmount) + Number(value);
        this.form
          .get('fees')
          .get('grs')
          .setErrors(
            this.totalGrsFees$.getValue() &&
              this.totalGrsFees$.getValue() !== Number(value)
              ? { incorrect: true }
              : null
          );
        this.form.get('fees').get('feesAmount').setValue(changeAmount);
      });

    this.form
      .get('fees')
      .get('artist')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const grsFeesAmount = this.form.get('fees').get('grs').value;
        const changeAmount = Number(grsFeesAmount) + Number(value);
        this.form
          .get('fees')
          .get('artist')
          .setErrors(
            this.totalArtistFees$.getValue() &&
              this.totalArtistFees$.getValue() !== Number(value)
              ? { incorrect: true }
              : null
          );
        this.form.get('fees').get('feesAmount').setValue(changeAmount);
      });

    this.form
      .get('fees')
      .get('feesAmount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const artistFeesAmount = this.form.get('fees').get('artist').value;
        const grsFeesAmount = this.form.get('fees').get('grs').value;
        if (
          Number(artistFeesAmount) + Number(grsFeesAmount) !==
          Number(value)
        ) {
          this.form
            .get('fees')
            .get('feesAmount')
            .setErrors({ incorrect: true });
        }
      });

    this.form
      .get('fees')
      .get('arrayGRSs')
      .valueChanges.pipe(
        map((val1) => {
          let total = 0;
          val1.forEach((item) => {
            total += Number(item.percent);
          });
          const grsFeesAmount = this.form.get('fees').get('grs').value;
          this.totalGrsFees$.next(total);
          this.form
            .get('fees')
            .get('grs')
            .setErrors(
              total !== Number(grsFeesAmount) ? { incorrect: true } : null
            );
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.form
      .get('fees')
      .get('arrayArtists')
      .valueChanges.pipe(
        map((val2) => {
          let total = 0;
          val2.forEach((item) => {
            total += Number(item.percent);
          });
          const artistFeesAmount = this.form.get('fees').get('artist').value;
          this.totalArtistFees$.next(total);
          this.form
            .get('fees')
            .get('artist')
            .setErrors(
              total !== Number(artistFeesAmount) ? { incorrect: true } : null
            );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private populateForm(): void {
    this.data.fees.arrayGRSs.forEach((item) => {
      this.listArray('arrayGRSs').push(
        new FormGroup({
          user: new FormControl(item.user),
          percent: new FormControl(item.percent),
        })
      );
    });
    this.data.fees.arrayArtists.forEach((item) => {
      this.listArray('arrayArtists').push(
        new FormGroup({
          user: new FormControl(item.user),
          percent: new FormControl(item.percent),
        })
      );
    });

    this.totalArtistFees$.next(this.data.artist);
    this.totalGrsFees$.next(this.data.grs);
  }

  listArray(type) {
    return (this.form.get('fees').get(type) as FormArray) || [];
  }

  addControlItem(type: string): void {
    this.listArray(type).push(
      new FormGroup({
        user: new FormControl(''),
        percent: new FormControl(''),
      })
    );
  }
}
