import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

// Services
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '@core/services';
import {IBlockedStatus} from "@core/common/interfaces";



declare type MintRequestType = 'user' | 'project';

@Component({
  selector: 'mint-request',
  templateUrl: './mint-request.component.html',
})
export class MintRequestComponent implements OnInit {
  user: FormControl = new FormControl('');

  userMintBlocked: boolean = false;

  projectMintBlocked: boolean = true;

  constructor(
    private usersService: UsersService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this.user.valueChanges.pipe(filter(Boolean)).subscribe((slug: string) => {
      this.getMintStatus(slug, 'user').subscribe((isBlocked) => {
        if (isBlocked.statusBlocked) {
          this.showBlockedToast(isBlocked.message);
        }
        this.userMintBlocked = isBlocked.statusBlocked;
      });
    });
  }

  onSelectedProject(event: any): void {
    const slug: string = event.data.slug;

    this.getMintStatus(slug, 'project').subscribe((isBlocked) => {
      if (isBlocked.statusBlocked) {
        this.showBlockedToast(isBlocked.message);
      }
      this.projectMintBlocked = isBlocked.statusBlocked;
    });
  }

  private showBlockedToast(errorMessage?: string): void {
    this.toastr.error(errorMessage ? errorMessage : 'Mint is requested or not required', '', {
      positionClass: 'toast-bottom-right',
    });
  }

  getMintStatus(slug: string, type: MintRequestType): Observable<IBlockedStatus> {
    return this.usersService.getMintStatus(slug, type);
  }

  requestMint(type: MintRequestType): void {
    this.usersService.mintTokens(this[type].value, type).subscribe(
      () => {
        this.toastr.success('Request successful', '', {
          positionClass: 'toast-bottom-right',
        });
        this[type].reset();
        this[`${type}MintBlocked`] = false;
      },
      (err) => {
        this.toastr.error(err?.message || 'Filed', '', {
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }
}
