import {ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {ControlContainer, FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {AppToastrService, OpportunityService, UploadService} from '../../../services/index';
import {PATH_SAVE} from '../../../common/index';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.html',
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadVideoComponent implements OnInit {
  @Input() control?: UntypedFormControl;
  @Input() project: any;
  @Input() config: any;
  public form;
  videoFile: string;
  submittedVideo: boolean;
  VideoValue: any;

  constructor(private opportunityService: OpportunityService,
              private uploadService: UploadService,
              private cdr: ChangeDetectorRef,
              private controlContainer: ControlContainer,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.setValue();
  }


  uploadVideo(event: any): boolean | void {
    this.VideoValue = event.value;
    this.submittedVideo = true;
    const data = this.opportunityService.prepareVideoFile(event);
    if (data.error) {
      this.toastr.showErrorToast({title: data.error});
      this.submittedVideo = false;
      return false;
    }
    let type = this.config && this.config.path ? this.config.path : PATH_SAVE.VIDEO_ALL;
    this.uploadService.uploadFilePartially(data.file, type).then(res => {
      this.formControl.setValue(res.url);
      this.submittedVideo = false;
      this.cdr.detectChanges();
      this.toastr.showToast({
        title: 'mp4 has been uploaded'
      });
    }, (err) => {
      this.toastr.showToastFromError(err);
      this.submittedVideo = false;
      this.cancelUpload();
    });
  }

  cancelUpload(): void {
    this.VideoValue = null;
    // this.fileName = null;
    this.videoFile = null;
    this.formControl.reset();
  }


  private initForm() {
    this.form = this.controlContainer.control;
    if (!this.control) {
      this.form.addControl('video', new UntypedFormControl('', [Validators.required]));
    }
  }

  private setValue() {
    if (this.project?.video) {
      this.formControl.setValue(this.project?.video)
    }
  }

  get formControl() {
    return this.control ? this.control : this.form.get('video') as UntypedFormControl;
  }

  public getGif(value: string) {
    return value ? value.toLowerCase().endsWith(".gif") : false;
  }
}
