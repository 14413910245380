<form class="search-field" [formGroup]="form">
  <mat-form-field
    class="example-full-width content-item-w"
    *ngIf="!selectedItem"
  >
    <input
      matInput
      id="autocomplete"
      formControlName="searchValue"
      placeholder="{{placeholder}}"
      type="text"
      autocomplete="off"
    />
  </mat-form-field>
  <mat-form-field
    class="example-full-width content-item-w"
    *ngIf="selectedItem"
  >
    <input
      matInput
      id="selectValue"
      formControlName="selectedItem"
      placeholder="{{placeholder}}"
      type="text"
      autocomplete="off"
    />
  </mat-form-field>
  <div class="trash" *ngIf="selectedItem" (click)="deleteItem()">
    <i class="fa fa-trash-o"></i>
  </div>
  <ul class="listDrpDown" *ngIf="!selectedItem" [class.songs]="showSongs">
    <li *ngFor="let item of list$ | async">
      <p
        *ngIf="!showSongs"
        (click)="onChangeItem(item)"
        class="autocomplete-item"
      >
        {{item?.showDisplayName + (item?.linkedProject ? ' (Linked project)' :
        '')}}
      </p>
      <ng-container *ngIf="showSongs">
        <span class="autocomplete-item__title">
          {{item?.showDisplayName + (item?.linkedProject ? ' (Linked project)' :
          '')}}
        </span>
        <ul>
          <ng-container *ngIf="item?.songs?.length">
            <li
              class="autocomplete-item autocomplete-item__song"
              *ngFor="let song of item.songs; let i = index"
              (click)="onChangeItem(item, song)"
            >
              {{ song.name || "Song " + (i + 1) }}
            </li>
          </ng-container>
          <ng-container *ngIf="!item?.songs?.length">
            <li
              (click)="onChangeItem(item, item.song)"
              class="autocomplete-item autocomplete-item__song"
            >
              {{ item.song.name || "Song" }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </li>
  </ul>
  <span class="warning" *ngIf="(list$ | async)?.length === 0"
    >{{errorText}}</span
  >
</form>
