import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { AcceptedSongPopupComponent } from '@core/component/submission/accepted-song-popup/accepted-song-popup.component';
import { AdvancesPopupComponent } from '@core/component/global/popup-items/advances-popup/advances-popup.component';
import { DatepickerComponent } from '@core/component/global/datepicker/datepicker.component';
import { FilterComponent } from '@core/component/filter/filter.component';

import { OpportunitySongPopupComponent } from '@core/component/submission/opportunity-song-popup/opportunity-song-popup.component';
import { PopupCollectibleAuctionComponent } from '@core/component/global/popup-items/popup-collectible-auction/popup-collectible-auction.component';
import { PopupDeliverRevenueComponent } from '@core/component/global/popup-items/popup-deliver-revenue/popup-deliver-revenue.component';
import { PopupGenerateVoucherComponent } from '@core/component/global/popup-items/popup-generate-voucher/popup-generate-voucher';
import { PopupUploadRevenueComponent } from '@core/component/global/popup-items/popup-upload-revenue/popup-upload-revenue.component';

import { SocialLinksSongComponent } from '@core/component/global/social-links-song/social-links-song.component';

import { StarsReadonlyComponent } from '@core/component/global/stars-readonly/stars-readonly.component';
import { SubmissionFieldsComponent } from '@core/component/submission-fields/submission-fields.component';
import { SubmittedSongPopupComponent } from '@core/component/submission/submitted-song-popup/submitted-song-popup.component';

import { TextBlockComponent } from '@core/component/landing-tabs/text-block/text-block.component';
import { UploadFileComponent } from '@core/component/upload/upload-file/upload-file.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { PipesModule } from '../pipes/pipes.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SlidersComponent } from '@core/component/global/sliders/sliders.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddMultiInputComponent } from '@core/component/parts/add-multi-input/add-multi-input.component';
import { PickColorComponent } from '@core/component/pick-color/pick-color.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SliderComponent } from '@core/component/landing-tabs/slider/slider.component';
import { SliderItemComponent } from '@core/component/landing-tabs/slider-item/slider-item.component';
import { LandingMultiLangTextComponent } from '@core/component/landing-tabs/landing-multi-lang-text/landing-multi-lang-text.component';
import { LandingVideoComponent } from '@core/component/landing-tabs/landing-video/landing-video.component';
import { LandingFreeTextComponent } from '@core/component/landing-tabs/landing-free-text/landing-free-text.component';
import { CardsComponent } from '@core/component/landing-tabs/cards/cards.component';
import { CardComponent } from '@core/component/landing-tabs/card/card.component';
import { LandingBlockComponent } from '@core/component/landing-tabs/block/landing-block.component';

import { GlobalTemplateModule } from '@core/component/global/global-template.module';
import { PopUpModule } from '@core/component/global/popup-items/pop-up.module';
import { PopupGiveAwayComponent } from '@core/component/global/popup-items/popup-give-away/popup-give-away.component';
import { NftFeeComponent } from '@core/component/collectible/nft-fee/nft-fee.component';
import { ShareModule } from '@core/share.module';
import { FreeTextListItemComponent } from '@core/component/landing-tabs/free-text-list-item/free-text-list-item.component';
import { TokenModule } from '@core/component/nft-token-component/token.module';

import { NotificationAlertComponent } from './global/notification/notification-alert/notification-alert.component';
import { NotificationListComponent } from './global/notification/notification-list/notification-list.component';
import { ConfirmDialogComponent } from './global/dialog/confirm-dialog.component';
// Forms
import {UploadModule} from "@core/component/upload/upload.module";
import {UiModule} from "@core/component/ui/ui.module";
// Modules
import { MaterialModule } from '@core/material.module';

@NgModule({
  exports: [
    TextBlockComponent,
    AdvancesPopupComponent,
    SubmittedSongPopupComponent,
    AcceptedSongPopupComponent,
    OpportunitySongPopupComponent,
    StarsReadonlyComponent,
    DatepickerComponent,
    PopupUploadRevenueComponent,

    FilterComponent,
    PopupGenerateVoucherComponent,
    UploadFileComponent,
    SlidersComponent,
    PopupDeliverRevenueComponent,
    PopupCollectibleAuctionComponent,
    PopupDeliverRevenueComponent,
    AddMultiInputComponent,
    PickColorComponent,

    SliderComponent,
    SliderItemComponent,
    LandingMultiLangTextComponent,
    LandingVideoComponent,
    LandingFreeTextComponent,
    CardsComponent,
    CardComponent,
    LandingBlockComponent,
    PopupGiveAwayComponent,
    NftFeeComponent,

    FreeTextListItemComponent,
    NotificationAlertComponent,
    NotificationListComponent,
    ConfirmDialogComponent,

  ],
  declarations: [
    TextBlockComponent,
    SubmittedSongPopupComponent,
    AdvancesPopupComponent,
    AcceptedSongPopupComponent,
    OpportunitySongPopupComponent,
    StarsReadonlyComponent,

    DatepickerComponent,
    PopupUploadRevenueComponent,
    FilterComponent,
    PopupGenerateVoucherComponent,
    SocialLinksSongComponent,
    SubmissionFieldsComponent,
    SlidersComponent,
    UploadFileComponent,
    PopupDeliverRevenueComponent,
    PopupCollectibleAuctionComponent,
    PopupDeliverRevenueComponent,
    AddMultiInputComponent,
    PickColorComponent,
    SliderComponent,
    SliderItemComponent,
    LandingMultiLangTextComponent,
    LandingVideoComponent,
    LandingFreeTextComponent,
    CardsComponent,
    CardComponent,
    LandingBlockComponent,
    PopupGiveAwayComponent,
    FreeTextListItemComponent,
    NftFeeComponent,
    NotificationAlertComponent,
    NotificationListComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    GlobalTemplateModule,
    UploadModule,
    MatSelectModule,
    MatButtonModule,
    PipesModule,
    AngularEditorModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatInputModule,
    ColorPickerModule,
    UiModule,
    ShareModule,
    PopUpModule,
    TokenModule,
    MaterialModule,
  ],
  providers: [DecimalPipe],
})
export class ComponentsModule {}
