import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestUserModel} from '../../model';
import {map} from 'rxjs/operators';
import {AbstractFindNameService} from './abstractFindName.service';
import {Observable} from 'rxjs';
import {SettingService} from '@core/services/setting.service';


@Injectable()
export class BaseService implements AbstractFindNameService{
  apiUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }


  getList(request: RequestUserModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/user-find`, {params})
      .pipe(map((resp: any) => {
        if (resp) {
          resp.map(item => {
            item.showDisplayName = item.contact.email;
            return item;
          });
          return resp;
        }
      }));
  }
}


