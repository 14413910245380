<form class="search-field" [formGroup]="fControl">
  <mat-form-field class="example-full-width content-item-w">
    <input
      matInput
      formControlName="name"
      id="autocomplete"
      type="text" 
      autocomplete="off"
      [placeholder]="placeholder"
      [readonly]="readonly"
    >
  </mat-form-field>
  <ul class="listDrpDown">
    <li class="autocomplete-item" *ngFor="let item of (list$ | async)" (click)="onChangeItem(item)">{{item.name}}
    </li>
  </ul>
</form>
