<div class="wrapper">
  <form class="inner" [formGroup]="form">
    <div class="item" [class.error]="loginCtrl.errors && submitPressed">
      <i class="far fa-user"></i>
      <input type="text" placeholder="Login" class="input login" [formControl]="loginCtrl" (keyup.enter)="submitEnter($event)">
    </div>
    <div class="item" [class.error]="passwordCtrl.errors && submitPressed" >
      <i class="fas fa-key"></i>
      <input type="password" placeholder="Password" class="input password" [formControl]="passwordCtrl" (keyup.enter)="submitEnter($event)">
    </div>
    <button type="button" class="button yellow" (click)="submit()">Log In</button>
  </form>
</div>
