import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ControlContainer, FormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";

@Component({
  selector: 'app-pick-color',
  templateUrl: './pick-color.html',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
})
export class PickColorComponent implements OnInit, OnChanges {
  childForm: UntypedFormGroup;
  @Input() color: string;
  constructor(private parentForm: FormGroupDirective
  ) {}

  ngOnInit() {
    this.childForm = this.parentForm.form;
    this.childForm.addControl('cardColor', new UntypedFormControl('', [Validators.required]));
    if(this.color){
      this.controlColor.patchValue(this.color);
    }
  }

  get controlColor(){
    return this.childForm.get('cardColor');
  }

  ngOnChanges(changes: any): void {
    if (changes.color.currentValue && this.childForm) {
      this.controlColor.patchValue(changes.color.currentValue)
    }
  }

  selectColor(e): void {
    this.controlColor.setValue(this.color)
  }
}
