import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective, ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import {DATE_FORMAT_UI} from "@core/common";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {AMPM, HOURS} from "@core/common/days";
import * as moment from "moment";
import {IProjectDuration} from "@core/common/project-interfaces";



const DATE_CONFIG = {
  display: {
    dateInput: DATE_FORMAT_UI,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-project-duration',
  templateUrl: './project-duration.component.html',
  styleUrls: ['./project-duration.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATE_CONFIG},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}}],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDurationComponent implements OnInit {
  childForm: UntypedFormGroup;
  hours: string[] = HOURS;
  @Input() projectDurationTime: IProjectDuration;

  constructor(private parentForm: FormGroupDirective, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.childForm = this.parentForm.form;
    this.childForm.addControl('projectDurationTime', this.createEmptyGroup());
    if (this.projectDurationTime) {
      let update = {
        startDate: moment(this.projectDurationTime.startDate),
        endDate: moment(this.projectDurationTime.endDate),
        startTime: this.getHour(this.projectDurationTime.startDate),
        endTime: this.getHour(this.projectDurationTime.endDate),
      }
      this.childForm.get('projectDurationTime').patchValue(update);
    }
  }

  getHour(date: string): string{
   let hour = moment(date).hour();
   return hour < 10 ? '0' + hour : hour.toString();
  }

  createEmptyGroup(): UntypedFormGroup {
    return this.fb.group({
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', [Validators.required, this.dateValidator()]],
      endTime: ['', Validators.required],
    });
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((!control?.parent?.get('startDate')?.value || !control?.parent?.get('endDate')?.value)) {
        return null;
      }
      const startDate = control.parent.get('startDate').value;
      const endDate = control.parent.get('endDate').value;
      let isAfter = startDate.isAfter(endDate);
      if (isAfter) {
        return {endDateBeforeStartDate: true};
      }
      return null;
    }
  }
}


