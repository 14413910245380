<form formGroupName="projectDurationTime">
  <div class="content-item">
    <div class="item-label header-inform-text-grey">Project Duration*</div>
    <div class="item-field">
      <div class="date-wrapper">
        <mat-form-field appearance="fill" class="item calendar">
          <mat-label>Start project</mat-label>
          <mat-error *ngIf="childForm?.get('projectDurationTime')?.get('startDate')?.errors?.required">Required</mat-error>
          <input matInput [matDatepicker]="picker1"
                 formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mat-select date-select" appearance="fill">
          <mat-label>Start time(hour)</mat-label>
          <mat-error *ngIf="childForm?.get('projectDurationTime')?.get('startTime')?.errors?.required">Required</mat-error>
          <mat-select formControlName="startTime">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="date-wrapper">
        <mat-form-field appearance="fill" class="item calendar">
          <mat-label>End project</mat-label>
          <mat-error *ngIf="childForm?.get('projectDurationTime')?.get('endDate')?.errors?.required"
          >Required</mat-error
          >
          <mat-error *ngIf="childForm?.get('projectDurationTime')?.get('endDate')?.errors?.endDateBeforeStartDate"
          >End date before Start Date</mat-error>
          <input matInput [matDatepicker]="picker2"
                 formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mat-select date-select" appearance="fill">
          <mat-label>End time(hour)</mat-label>
          <mat-error *ngIf="childForm?.get('projectDurationTime')?.get('endTime')?.errors?.required">Required</mat-error>
          <mat-select formControlName="endTime">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
