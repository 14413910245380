import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Filter, FilterConfig} from '@core/common/interfaces';
import {IArtistEditing} from '@core/model/artists-list.model';
import * as moment from 'moment';
import {DictionaryService} from '@core/services';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() config: FilterConfig;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  selectedSearchCategory: string = '';
  searchCategoryValue: string = '';
  selectedRangeFilter: string = '';

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc'
    },
    state: null,
    country: null,
    featured: null,
    name: null,
    createdFrom: null,
    createdTo: null,
    updatedFrom: null,
    updatedTo: null,
    email: null,
  };

  total: number;

  mainFilter = new UntypedFormControl();
  stateFilter = new UntypedFormControl();
  featuredFilter = new UntypedFormControl();
  countryFilter = new UntypedFormControl();

  categoryMainFilter: string[] = ['State', 'Featured', 'Country'];

  categoryStateFilter = [
    {value: 'pending', viewValue: 'Pending'},
    {value: 'verified', viewValue: 'Verified'},
    {value: 'deleted', viewValue: 'Deleted'}
  ];

  categoryFeaturedFilter = [
    {value: 'true', viewValue: 'True'},
    {value: 'false', viewValue: 'False'}
  ];

  categoryCountryFilter = [];
  artistData: IArtistEditing[];

  constructor(
    private dictionaryService: DictionaryService
  ) {
  }

  ngOnInit(): void {
    this.config.filter = this.filter;
    this.dictionaryService.getCountries();
    this.dictionaryService.onGetCountries
      .subscribe(resp => {
        this.categoryCountryFilter = resp;
      });
  }

  searchByCategory(): void {

    if (this.selectedSearchCategory && this.searchCategoryValue) {
      this.filter.name = null;
      this.filter.projectTitle = null;
      if (this.selectedSearchCategory === 'Artist Name') {
        this.filter.name = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Album Title') {
        this.filter.projectTitle = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Project Title') {
        this.filter.projectTitle = this.searchCategoryValue;
      }
      this.notify.emit({filter: this.filter});
    }

  }

  searchByRange(): void {
    if (this.selectedRangeFilter === 'UpdatedAt') {
      this.filter.updatedFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.updatedTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.createdFrom = null;
      this.filter.createdTo = null;
    } else {
      this.filter.createdFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.createdTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.updatedFrom = null;
      this.filter.updatedTo = null;
    }
    this.notify.emit({filter: this.filter});
  }


  onSelectCategory() {
    delete this.filter.state;
    delete this.filter.country;
    delete this.filter.featured;

    if (this.mainFilter.value === 'State') {
      const arr = this.stateFilter.value.map(item => {
        return item.value;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = arr;
    }
    if (this.mainFilter.value === 'Featured') {
      this.filter[this.mainFilter.value.toLowerCase()] = this.featuredFilter.value.value;
    }
    if (this.mainFilter.value === 'Country') {
      const country = this.countryFilter.value.map(item => {
        return item.id;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = country;
    }
  }

  resetSearchFiler(): void {
    this.searchCategoryValue = null;
    this.selectedSearchCategory = null;
    this.filter.projectTitle = null;
    this.filter.name = null;
    this.notify.emit({filter: this.filter});
  }

  resetCategoryFiler(): void {
    // this.mainFilter.setValue(null);
    // this.stateFilter.setValue(null);
    // this.featuredFilter.setValue(null);
    // this.countryFilter.setValue(null);
    // this.filter.state = null;
    // this.filter.country = null;
    // this.filter.featured = null;
  }

  resetRangeFilter(): void {
    this.selectedRangeFilter = null;
    this.range.get('start').patchValue(null);
    this.range.get('end').patchValue(null);
    this.filter.updatedFrom = null;
    this.filter.updatedTo = null;
    this.filter.createdFrom = null;
    this.filter.createdTo = null;
    this.notify.emit({filter: this.filter});
  }

}
