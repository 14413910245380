import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';

// Interfaces
import { IWsData } from '@core/interfaces/websocket.interface';
// Consts
import { WS_EVENT_TYPE } from '@core/common';

// Services
import { WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notifications: string[] = [];

  private removed: string[] = [];

  private notificationStream: BehaviorSubject<string[]> = new BehaviorSubject(
    []
  );

  private distributionStream: Subject<void> = new Subject();

  distributionStream$: Observable<void> =
    this.distributionStream.asObservable();

  constructor(private wsService: WebsocketService) {
    this.wsService
      .on(WS_EVENT_TYPE.royalty_statement_complete)
      .pipe(
        tap(() => {
          this.distributionStream.next();
        })
      )
      .subscribe((data: IWsData) => {
        this.add(
          `1st royalty distribution step complete(for ${
            data.users || 0
          } users)`
        );
      });
    this.wsService
      .on(WS_EVENT_TYPE.royalty_account_complete)
      .pipe(
        tap(() => {
          this.distributionStream.next();
        })
      )
      .subscribe((data: IWsData) => {
        this.add(
          `3st royalty distribution step complete(for ${
            data.users || 0
          } users)`
        );
      });

    this.wsService
      .on(WS_EVENT_TYPE.royalty_mangopay_complete)
      .pipe(
        tap(() => {
          this.distributionStream.next();
        })
      )
      .subscribe((data: IWsData) => {
        const messageSucces: string = `2nd royalty distribution step complete(for ${
          data.users || 0
        } users)`;
        const messageError: string = data?.err_transactions
          ? ` Failed: ${data?.err_transactions}`
          : '';

        this.add(messageSucces + messageError);
      });

    this.wsService
      .on(WS_EVENT_TYPE.royalty_tag_complete)
      .pipe(
        tap(() => {
          this.distributionStream.next();
        })
      )
      .subscribe(() => {
        this.add('4rd royalty distribution step complete');
      });
  }

  notifications$: Observable<string[]> = this.notificationStream
    .asObservable()
    .pipe(filter<string[]>(Boolean), shareReplay(1, 500));

  dismiss(notification: string): void {
    this.remove(notification, true);
  }

  dismissAll(): void {
    this.notifications = [];
    this.removed = [];
    this.notificationStream.next([]);
  }

  private add(notification: string): void {
    this.notifications = [...this.notifications, notification];
    this.notificationStream.next(this.notifications);
  }

  private remove(notification: string, removeForThisSession: boolean): void {
    const index: number = this.notifications.indexOf(notification);

    if (~index) {
      this.notifications = [
        ...this.notifications.slice(0, index),
        ...this.notifications.slice(index + 1),
      ];

      if (removeForThisSession) {
        this.removed = [...this.removed, notification];
      }

      this.notificationStream.next(this.notifications);
    }
  }

  private isPresent(notification: string): boolean {
    return this.notifications.includes(notification);
  }

  private isRemovedForThisSession(notification: string): boolean {
    return this.removed.includes(notification);
  }
}
