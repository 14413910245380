import { PATH_SAVE } from './constants';

export const FILE_SAVE_CONFIG: any = {
  NFT_GIF: {
    imageFormat: 'gif',
    ratio: 1,
    path: PATH_SAVE.IMAGE_NFT,
    allowFormats: ['gif', 'GIF'],
  },
};
