<div class="content__row content__row_flex-end">
  <div class="content__elem content__elem_m-right">
    <mat-form-field class="mat-input">
      <mat-label>File format</mat-label>
      <mat-select
        [(ngModel)]="invoiceFileFormat"
      >
        <mat-option *ngFor="let item of downloadType" [value]="item.name">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-pick-date
    (selectDate)="selectMonth($event, '_invoiceDate')"></app-pick-date>
  <div class="content__elem content__elem_m-right">
    <mat-form-field class="mat-input">
      <mat-label>Type</mat-label>
      <mat-select
        [(ngModel)]="invoiceType"
        [disabled]="invoiceFileFormat !== 'PDF'"
      >
        <mat-option *ngFor="let item of invoiceTypeList" [value]="item.name">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="content__elem content__elem_m-right">
    <button class="button green default-size" [disabled]="showSpinner"
            (click)="downloadInvoices()"> Download
    </button>
  </div>
  <div class="content__elem flex-align">
    <div class="content__spinner spinner">
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
    </div>
  </div>
</div>
<div class="content__row url" *ngIf="urlZipFile">
          <span>File Zip has been generated. You can
            download it from <a href="{{urlZipFile}}" class="link-blue" target="_blank">link</a></span>
</div>
