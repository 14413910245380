import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AppToastrService, DictionaryService, OpportunityService, UploadService} from '../../../services/index';
import {SongModel, Status} from '../../../model/index';
import * as moment from 'moment';
import {AgreementStatus, ConfirmPopup} from '@core/common/interfaces';
import {SettingService} from '@core/services/setting.service';

interface SocialItems {
  [key: string]: string;
}

@Component({
  selector: 'app-accepted-song-popup',
  templateUrl: './accepted-song-popup.html',
  styleUrls: ['./_accepted-song-popup.scss']
})
export class AcceptedSongPopupComponent implements OnInit {
  @Input() submittedSong: SongModel;
  @Input() status: Status;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  listSocials: SocialItems = {};
  form: UntypedFormGroup;
  deleteConfig: ConfirmPopup;
  isEdit: boolean;
  genres: any;
  fileName: string;
  AudioValue: any;
  audioFile: string;
  contentUrl: string;
  image: string;
  imgURL: string;
  notValidForm: boolean;
  countaProducers = {
    lyricsAuthor: 0,
    composer: 0
  };
  Status = Status;

  // TODO change for real data
  toDoList: AgreementStatus = {
    author_details: false,
    p_agreement_sent: false,
    p_agreement_receive: false,
    a_agreement_sent: false,
    a_agreement_receive: false
  };

  constructor(private fb: UntypedFormBuilder,
              private uploadService: UploadService,
              private toastr: AppToastrService,
              private settings: SettingService,
              private dictionaryService: DictionaryService,
              private opportunityService: OpportunityService
  ) {


  }

  ngOnInit(): void {
    this.getGenres();
    this.checkValidity();
    this.contentUrl = this.settings.contentUrl;
    if (this.submittedSong && this.submittedSong.project && this.submittedSong.project.agreementStatus) {
      this.toDoList = this.submittedSong.project.agreementStatus;
    }
  }


  closeDialog(): any {
    this.visibleChange.emit({type: 'close'});
  }

  checkValidity(): void {
    let notValid = false;
    if (this.submittedSong.project) {
      const project = this.submittedSong.project;
      if (!project.producer || !project.label || !project.photo || !project.aboutSong || !project.songName) {
        notValid = true;
      }
    } else {
      notValid = true;
    }
    this.notValidForm = notValid;
  }

  changeUpcomingStatus(): void {
    this.opportunityService._changeUpcomingStatus(this.toDoList, this.submittedSong.project).subscribe(resp => {
      if (resp) {
        this.toastr.showToast({title: 'Status Updated'});
      }
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  onNotify(e): void {
    if (e.type === 'closeEdit') {
      this.isEdit = false;
    }
    if (e.type === 'updated') {
      const obj = JSON.parse(JSON.stringify(e.data));
      const sub = JSON.parse(JSON.stringify(this.submittedSong));
      this.submittedSong = Object.assign(sub, obj);
      this.submittedSong.rating = e.data.rating;
      this.submittedSong.audio = `${this.contentUrl}/project-soundtracks/${this.audioFile}`;
      this.isEdit = false;
      this.checkValidity();
    }
    if (e.type === 'delete') {
      this._deleteSong();
    }
    if (e.type === 'close') {
      this.deleteConfig = null;
    }
  }

  getGenres(): void {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;

    });

  }

  checkStar(item): void {
    this.submittedSong.rating = item.toString();
  }

  changeStatus(statusItem: string): any {
    const data = {
      status: statusItem,
      publishedUpcoming: moment()
    };
    this.opportunityService.changeStatusSubmissions(data, this.submittedSong).subscribe(resp => {
      if (resp) {
        this.toastr.showToast({title: 'Status has been changed'});
        this.closeDialog();
      }
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  _deleteSong(): void {
    this.opportunityService.deleteSong(this.submittedSong).subscribe(resp => {
      this.closeDialog();
      this.deleteConfig = null;
      this.toastr.showToast({title: 'Song was deleted'});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  deleteSong(): void {
    this.deleteConfig = {
      btnConfirm: true,
      titleBtnConfirm: 'Delete',
      btnCancel: true,
      submitEmit: 'delete',
      titleBtnCancel: 'Cancel',
      text: 'Are you sure you want to delete song?'
    };
  }

  editSong(): void {
    this.isEdit = true;
    this.fileName = this.submittedSong.audio ? this.submittedSong.audio.split('/project-soundtracks/')[1] : null;
    this.audioFile = this.submittedSong.audio ? this.submittedSong.audio.split('/project-soundtracks/')[1] : null;
  }

  remindAuthor(): void {
    this.opportunityService.remindAuthor(this.submittedSong).subscribe(resp => {
      if (resp) {
        this.toastr.showToast({title: 'Request was sent'});
      }
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  cancelUpload(): void {
    this.AudioValue = null;
    this.fileName = null;
    this.audioFile = null;
  }
}
