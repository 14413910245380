import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

// Consts
import { ROUTE_ALIAS } from '../../../common';

// Interfaces
import { RequestModel } from '../../../model/request.model';
import { Dictionary } from '../../../model/dictionary.model';

// Services
import {
  AppToastrService,
  DictionaryService,
  OpportunityService,
  UsersService,
} from '../../../services';

export interface Filter {
  paging: {
    page: number;
    offset: number;
    limit: number;
  };
  name: string;
  type: string;
  from: string;
  to: string;
  country: string;
}

@Component({
  selector: 'app-balance-list',
  templateUrl: './balances-list.html',
  styleUrls: ['./_balances-list.scss'],
})
export class BalancesListComponent implements OnInit {
  list: any[];
  host: string;
  listFilters: Filter;
  searchName: string;
  isLoaded: boolean;
  submitPressed: boolean;
  monthFromControl: FormControl;
  monthToControl: FormControl;
  countryCtrl: FormControl;
  typeCtrl: FormControl;
  form: FormGroup;
  total: number;
  selectAllItem: boolean;
  countries: Dictionary[];
  atLeastOneSelected: boolean;
  loadFile: boolean;
  types = [
    { id: 1, name: 'Artist' },
    { id: 2, name: 'Producer' },
  ];
  defaultFilters: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    name: '',
    from: '',
    to: '',
    country: '',
    type: '',
  };

  constructor(
    private userService: UsersService,
    private toastr: AppToastrService,
    private fb: FormBuilder,
    private opportunityService: OpportunityService,
    private router: Router,
    private dictionaryService: DictionaryService
  ) {
    this.listFilters = Object.create(this.defaultFilters, {});
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getList();
  }

  openItemBalance(item?: any): void {
    this.router.navigate([
      ROUTE_ALIAS.balancesEdit,
      item ? item.slug : 'newItem',
    ]);
  }

  getCountries(): void {
    this.dictionaryService.getCountries().subscribe(
      (resp) => {
        this.countries = resp;
        this.prepareCountry();
      },
      (err) => {
        console.log(err);
        this.toastr.showToastFromError(err);
      }
    );
  }

  selectAllItems(): void {
    this.list.forEach((item) => {
      item.isSelectedItem = this.selectAllItem;
    });
  }

  prepareCountry(): void {
    this.list.forEach((item) => {
      item.countryLong = this.countries.find((c) => c.id === item.country);
    });
  }

  prepareForm(): void {
    this.monthFromControl = this.fb.control('');
    this.monthToControl = this.fb.control('');
    this.countryCtrl = this.fb.control('');
    this.typeCtrl = this.fb.control('');
    this.form = this.fb.group({
      monthFrom: this.monthFromControl,
      monthTo: this.monthToControl,
      country: this.countryCtrl,
    });
  }

  onTypeChange(e): void {
    this.listFilters.type = e;
    this.getList();
  }

  onDateChange(first?: boolean, type?: string): void {
    const time = moment().format('HH:mm');
    if (this.monthFromControl.value) {
      const day = moment(this.monthFromControl.value).format('YYYY/MM/DD');
      this.listFilters.from = moment(
        day + '' + time,
        'YYYY/MM/DD HH:mm'
      ).format('YYYY/MM/DD');
    }

    if (
      this.monthToControl.value &&
      this.monthToControl.value >= this.monthFromControl.value
    ) {
      const day = moment(this.monthToControl.value).format('YYYY/MM/DD');
      this.listFilters.to = moment(day + '' + time, 'YYYY/MM/DD HH:mm').format(
        'YYYY/MM/DD'
      );
    }
    if (
      this.monthToControl.value &&
      this.monthToControl.value < this.monthFromControl.value
    ) {
      this.toastr.showErrorToast({ message: 'Incorrect To Date' });
      this.monthToControl.setValue(this.fb.control(''));
    }
    this.getList();
  }

  resetFilter(): void {
    this.listFilters = Object.create(this.defaultFilters, {});
    this.monthFromControl.reset();
    this.countryCtrl.reset();
    this.monthToControl.reset();
    this.typeCtrl.reset();
    this.searchName = null;
    this.getList();
  }

  onPageChange(pageNumber: number): void {
    this.listFilters.paging.page = pageNumber + 1;
    this.listFilters.paging.offset = pageNumber * this.listFilters.paging.limit;
    this.getList();
  }

  searchText(text: string, type: string): void {
    if (text && text.length > 2) {
      this.listFilters[type] = text;
      this.getList();
    } else if (!text.length) {
      this.listFilters[type] = '';
      this.getList();
    }
  }

  onCountryChange(e: any): void {
    this.listFilters.country = e;
    this.getList();
  }

  selectItem(item: any): void {
    item.isSelectedItem = !!item.isSelectedItem;
    this.atLeastOneSelected = false;
    this.list.forEach((val) => {
      if (val.isSelectedItem) {
        this.atLeastOneSelected = true;
      }
    });
  }

  downloadCSV(): void {
    this.loadFile = true;
    const selectedArtist = this.list
      .filter((item) => item.isSelectedItem)
      .map((val) => val.id);
    this.opportunityService
      .downloadBalanceFile(selectedArtist)
      .subscribe((res) => {
        const blob = new Blob([res], {
          type: 'application/zip',
        });
        this.loadFile = false;
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  getList(): void {
    const data: any = {
      limit: this.listFilters.paging.limit,
      page: this.listFilters.paging.page,
    };
    if (this.searchName && this.listFilters.name) {
      data.name = this.listFilters.name;
    }
    if (this.listFilters.type) {
      data.type = this.listFilters.type;
    }
    if (this.listFilters.country) {
      data.country = this.listFilters.country;
    }
    if (this.listFilters.from) {
      data.dateFrom = this.listFilters.from;
    }
    if (this.listFilters.to) {
      data.dateTo = this.listFilters.to;
    }
    this.isLoaded = false;
    data.balance = true;
    const request = new RequestModel(data);
    this.userService.getArtistWithBalance(request).subscribe(
      (resp) => {
        this.list = resp.list;
        this.isLoaded = true;
        this.total = resp.total;
        if (!this.countries || !this.countries.length) {
          this.getCountries();
        } else {
          this.prepareCountry();
        }
      },
      (err) => {
        console.log(err);
        this.isLoaded = true;
        this.toastr.showToastFromError(err);
      }
    );
  }
}
