<app-page-template header="Accounting">
  <div class="wrapper">
    <div class="content-page-block content-page-block_padding">
      <h2 class="content-name">Download invoices</h2>
      <div class="content__item">
       <app-download-invoice></app-download-invoice>
      </div>
    </div>

    <div class="content-page-block content-page-block_padding content-page-block_border">
      <h2 class="content-name">Download Statement</h2>
      <div class="content__item">
        <app-download-statement></app-download-statement>
      </div>
    </div>
    <div class="content-page-block content-page-block_border">
      <app-burn-token></app-burn-token>
    </div>
    
    <div class="content-page-block content-page-block_border">
      <mint-request></mint-request>
    </div>

    <div class="content-page-block content-page-block_border">
      <transfer-tokens></transfer-tokens>
    </div>

    <div class="content-page-block content-page-block_padding content-page-block_border">
      <h2 class="content-name">Withdraw money</h2>
      <div class="content__item">
        <div class="content__search search">
          <span class="bold-style">Select User</span>

          <app-find-user-statement-upload [categories]="sWithdrawCategory"
                         (notify)="onNotify($event)"></app-find-user-statement-upload>
        </div>
        <div class="content-result" *ngIf="searchListResult && searchListResult?.length">
          <span class="bold-style">User:</span>
          <table class='table table-striped table-bordered table-hover dataTable'>
            <thead>
            <tr>
              <th></th>

              <th>Last Name</th>
              <th>MangoWalletId</th>
              <th>Email</th>
              <th>Left Amount</th>
              <th>Amount to Withdraw</th>
              <th class="table-center th-actions">Actions</th>
            </tr>
            </thead>
            <tbody>
            <ng-template ngFor let-item [ngForOf]="searchListResult" let-i="index">
              <tr>
                <td class="center">
                  <mat-checkbox class="example-margin"
                                [name]="'all' + i"
                                [id]="'all' + i"
                                (change)="selectItem(item)"
                                [(ngModel)]="item.checkItem">
                  </mat-checkbox>
                </td>

                <td class="center">{{item?.contact.lastName}}</td>
                <td class="center">{{item?.mangoWalletId}}</td>
                <td class="center">{{item?.contact.email}}</td>
                <td class="center">{{item?.revenues.left}}</td>
                <td class="center">
                  <mat-form-field class="content-item">
                    <mat-label>Amount</mat-label>
                    <input matInput
                           [(ngModel)]="item.amountWithdraw"
                           id="amountWithdraw"
                           type="text" autocomplete="off"
                    >
                  </mat-form-field>
                </td>
                <td class="th-actions actions">
                  <button class="button green default-size"
                          (click)="withdrawMoney(item)"
                          [disabled]="!item.amountWithdraw || !item.checkItem || !bankSelected"
                  >
                    Withdraw
                  </button>
                </td>
              </tr>
              <tr *ngFor='let bank of item.bankAccounts; let j = index'>
                <td></td>
                <td class="center">
                  <mat-checkbox class="example-margin"
                                [name]="'bank'+ i + j"
                                [id]="'bank'+ i + j"
                                [disabled]="!item.checkItem"
                                (change)="selectBank(bank, item)"
                                [(ngModel)]="bank.checkItem">
                  </mat-checkbox>
                </td>
                <td class="center" colspan="5">
                  bankAccount: {{bank.bankId}}
                </td>
              </tr>
            </ng-template>
            </tbody>
          </table>
          <span class="error-message" *ngIf="showError">User does not have bankId</span>
        </div>
      </div>
    </div>
    <div class="content-page-block content-page-block_border">
      <h2 class="content-name">Project's MangoPay wallet</h2>
      <app-projects-wallets></app-projects-wallets>
    </div>
  </div>

</app-page-template>
