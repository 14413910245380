import {Component, OnInit, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import {AppToastrService, OpportunityService, UploadService} from '../../../services';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {LandingModel} from '../../../model/landing.model';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.html'
})
export class TextBlockComponent implements OnInit {
  form: UntypedFormGroup;
  titleCtrl: UntypedFormControl;
  subTitleCtrl: UntypedFormControl;
  imgCtrl: UntypedFormControl;
  buttonNameCtrl: UntypedFormControl;
  buttonURLCtrl: UntypedFormControl;
  landingTextCtrl: UntypedFormControl;
  landingTextQuotesCtrl: UntypedFormControl;
  isPublished = false;
  editorConfig: AngularEditorConfig = {
    minHeight: '100px',
    editable: true,
    height: 'auto',
    maxHeight: 'auto',
    enableToolbar: true,
    showToolbar: true,
    sanitize: true,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'lineHeight-10%',
        class: 'lineHeight10',
        tag: 'p',
      },
      {
        name: 'lineHeight-20%',
        class: 'lineHeight20',
        tag: 'p',
      },
      {
        name: 'lineHeight-40%',
        class: 'lineHeight40',
        tag: 'p',
      },
      {
        name: 'lineHeight-60%',
        class: 'lineHeight60',
        tag: 'p',
      },
      {
        name: 'lineHeight-80%',
        class: 'lineHeight80',
        tag: 'p',
      },
      {
        name: 'lineHeight-100%',
        class: 'lineHeight100',
        tag: 'p',
      }
    ],
  };
  @Input() landingItem: LandingModel;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private opportunityService: OpportunityService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.prepareForm();
    if (this.landingItem) {
      this.setForm();
    }
  }

  prepareForm(): void {
    this.landingTextCtrl = this.fb.control('');
    this.titleCtrl = this.fb.control('');
    this.buttonNameCtrl = this.fb.control('');
    this.buttonURLCtrl = this.fb.control('');
    this.subTitleCtrl = this.fb.control('');
    this.imgCtrl = this.fb.control('');
    this.landingTextQuotesCtrl = this.fb.control('');
    this.form = this.fb.group({
      title: this.titleCtrl,
      buttonName: this.buttonNameCtrl,
      buttonURL: this.buttonURLCtrl,
      image: this.imgCtrl,
      subTitle: this.subTitleCtrl,
      landingText: this.landingTextCtrl,
      landingTextQuotes: this.landingTextQuotesCtrl,
    });

  }

  setForm(): void {
    this.titleCtrl.setValue(this.landingItem.text.title);
    this.subTitleCtrl.setValue(this.landingItem.text.subTitle);
    this.imgCtrl.setValue(this.landingItem.text.image);
    this.buttonNameCtrl.setValue(this.landingItem.text.button);
    this.buttonURLCtrl.setValue(this.landingItem.text.url);
    this.landingTextCtrl.setValue(this.landingItem.text.description);
    this.landingTextQuotesCtrl.setValue(this.landingItem.text.descriptionAdditional);
  }

  saveBlock() {
    if (this.form.valid) {
      this.notify.emit({data: this.form});
    }
  }
}
