
export const angularEditorParams = {
  minHeight: '100px',
  editable: true,
  height: 'auto',
  maxHeight: 'auto',
  enableToolbar: true,
  showToolbar: true,
  sanitize: true,

  customClasses: [
  {
    name: 'quote',
    class: 'quote',
  },
  {
    name: 'lineHeight-10%',
    class: 'lineHeight10',
    tag: 'p',
  },
  {
    name: 'lineHeight-20%',
    class: 'lineHeight20',
    tag: 'p',
  },
  {
    name: 'lineHeight-40%',
    class: 'lineHeight40',
    tag: 'p',
  },
  {
    name: 'lineHeight-60%',
    class: 'lineHeight60',
    tag: 'p',
  },
  {
    name: 'lineHeight-80%',
    class: 'lineHeight80',
    tag: 'p',
  },
  {
    name: 'lineHeight-100%',
    class: 'lineHeight100',
    tag: 'p',
  }
],
};
