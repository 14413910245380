<div class="upload__image-wrapper">
  <label class="button yellow crop__upload">
    <input class="desktop-upload" type="file" (change)="loadImage($event)"/>
    <span>Choose the picture </span>
  </label>
</div>
<section>
  <ng-content></ng-content>
</section>

