import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import { filter } from 'rxjs/operators';

import {AbstractBaseFindService} from "@core/component/find-user-template/abstract-base-find.service";
import {FindUserService} from "@core/component/find-user-template/find-user.service";


type FindBy = 'name' | 'email' | 'contactLastName';

@Component({
  selector: 'app-find-by-name-universal',
  templateUrl: './find-by-name-universal.html',
  providers: [
    {
      provide: AbstractBaseFindService,
      useClass: FindUserService
    }
  ],
})
export class FindByNameUniversalComponent implements OnInit, OnChanges {
  form: UntypedFormGroup;
  list$: Observable<any>;
  @Input() type: FindBy;
  @Input() errorText: string;
  @Input() formControlCustom?: UntypedFormControl;

  /**  @Input() saveParam  - definition of the parameter by which it will be selected onSelect click **/

  @Input() saveParam?: string;

  /**  @Input() paramDisplay  - param to show in DropDown list **/

  @Input() paramDisplay?: string;

  @Input() select?: string = 'base';
  @Input() placeholder = 'Placeholder';
  @Input() text: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(public userService: AbstractBaseFindService) {
    this.form = this.userService.form;
    this.list$ = this.userService.list$;
  }

  ngOnInit(): any {
    this.userService.typeSelect = this.select;
    this.userService.type = this.type;
    this.userService.paramDisplay = this.paramDisplay;

    if(this.formControlCustom) {
      this.formControlCustom.value && this.form.get('searchValue').setValue(this.formControlCustom.value);
      // Reset form if custom control has been reseted
      this.formControlCustom.valueChanges.pipe(filter(value => value === null)).subscribe(() => {
        if(this.formControlCustom.valid && this.formControlCustom.pristine) {
          this.form.reset();
        }
      })
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.type && changes.type.currentValue !== changes.type.previousValue){
      this.userService.type = changes.type.currentValue;
    }
  }

  onOptionSelected(event){
    const selectedValue = event.option.value;
      if( this.formControlCustom){
        this.formControlCustom.setValue(selectedValue);
      }
  }
}
