<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="closePopup()"></span>
    <img src="./assets/images/bell.png" class="image">
    <div class="block-title title-global title-warning">Voucher</div>
    <!--<div class="text">Are you sure you want to delete it?<br>-->
    <!--</div>-->
    <div class="content m20-bottom">
      <mat-form-field *ngIf="!code">
        <mat-label>Amount</mat-label>
        <input matInput
               [formControl]="amountCtrl"
               id="Labelfunding"
               type="text" autocomplete="off"
        >
      </mat-form-field>
      <div class="text-important">
          {{code}}
      </div>
    </div>
    <div class="flex flex-align button-wrapper">
      <button class="button blue text-uppercase large-size" (click)="closePopup()">
       {{code ? 'Ok' : 'Cancel'}}
      </button>
      <button class="button red-btn text-uppercase large-size" *ngIf="!code"
              [disabled]="!form?.valid || submitted" (click)="onSubmit()">
        Generate
      </button>
    </div>

  </div>
</app-popup>

