<app-page-template header="Collectible">
  <app-page-header>
    <button class="button green default-size" (click)="openItem()">Create New</button>
  </app-page-header>
  <app-page-content *ngIf="isLoaded">
    <table class='table table-collectible'>
      <thead>
      <tr>
        <th>#</th>
        <th>
          Artist
        </th>
        <th>
          Song
        </th>
        <th>
          Editions
        </th>
        <th>
          Minted
        </th>
        <th>
          Auction Status
        </th>
        <th>
          Created At
        </th>
        <th class="table-center table-cell table-cell--align">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let item of list; let i = index'>
        <td class="center">{{ i + 1 + filter?.paging?.offset}}</td>
        <td class="center">{{item?.artist}}</td>
        <td class="center">{{item?.song}}</td>
        <td class="center">{{item?.edition}}</td>
        <td class="center">{{item?.status === 'minted' ? 'Yes' : 'No'}}</td>
        <td class="center">{{item?.auctionStatus}}</td>
        <td class="center">{{item?.createdAt | momentDateHours}}</td>
        <td class="center td__actions">
          <div class="table__actions">
            <div class="edit actions-item" (click)="openItem(item)">
              <i class='fa fa-edit'></i>
            </div>
            <div class="edit actions-item trash" *ngIf="item?.status !== 'confirmed'" (click)="deleteItem(item)">
              <i class='fa fa-trash-o'></i>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > filter?.paging?.limit && isLoaded">
      <app-pagination [total]="total" [paging]="filter?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>
  </app-page-content>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>

</app-page-template>
<app-popup-confirmation [config]="popupConfig" *ngIf="showPopupConfirmation"
                        (notify)="onNotify($event)"></app-popup-confirmation>

