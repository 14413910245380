<div class="tab">
  <form [formGroup]="form">
  <div class="item-row">
    <div class="item-label item-label_pad-20">Is Enabled</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    [name]="'Is Enabled'"
                    formControlName="isEnabled"
                    [id]="'Is Enabled'"
                    >
      </mat-checkbox>
    </div>
  </div>

  <div class="item-row">
    <div class="item-label item-label_pad-20">Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="albumTitle1"
               formControlName="title"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>

  <button class="button green default-size" (click)="addSlide()">Add Slide</button>
    <div class="list-slider" *ngFor="let control of sliderArray?.controls; let i = index">
      <app-slider-item-component *ngIf="control"
                                 [index]="i"
                                 [form]="control"></app-slider-item-component>
      <button class="button blue default-size" (click)="deleteSlide(i)">Delete slide</button>
    </div>
  </form>
</div>

