import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-formcontrol-array',
  templateUrl: './formcontrol-array.component.html',
  styleUrls: ['./formcontrol-array.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
})
export class FormcontrolArrayComponent implements OnInit {
  childForm: UntypedFormGroup;
  @Input() data: any;
  @Input() firstDelete: boolean;
  @Input() titleArray: string;

  constructor(private parentForm: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.childForm = this.parentForm.form;
    this.childForm.addControl(this.titleArray, new UntypedFormArray([]));

    if (this.data && this.data[this.titleArray]) {
      this.data[this.titleArray].forEach(item => {
        this.addControlValue(item);
      })
    } else {
      this.addControlValue();
    }
  }

  ngOnChanges(value) {
    if (value.data && this.childForm) {
      if (this.data && this.data[this.titleArray]) {
        this.data[this.titleArray].forEach(item => {
          this.addControlValue(item);
        })
      }
    }
  }

  get formArrayList() {
    return this.childForm.get(this.titleArray) as UntypedFormArray;
  }

  removeControl(index) {
    if (this.formArrayList.controls.length !== 1 || this.firstDelete) {
      this.formArrayList.removeAt(index);
    }

  }

  addControlValue(item?) {
    this.formArrayList.push(new UntypedFormControl(item || null));
  }
}

