import {NgModule} from '@angular/core';
import {PageTemplateComponent} from './page-template.component';
import {PageContentComponent, PageHeaderComponent} from '@core/pages/page-template/page-content/page-content.component';

@NgModule({
  imports: [],
  declarations: [
    PageTemplateComponent,
    PageContentComponent,
    PageHeaderComponent
  ],
  exports: [
    PageTemplateComponent,
    PageContentComponent,
    PageHeaderComponent
  ]
})

export class PageTemplateModule {
}
