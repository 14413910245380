<app-page-template header="Balances">
  <div class="content-header">
    <div class="filter filter-search">
      <div class="button-wrapper">
        <button class="button green default-size" (click)="openItemBalance()">Create Balance</button>
        <button class="button blue-bgc default-size" [disabled]="!atLeastOneSelected" (click)="downloadCSV()">Download CSV</button>
        <mat-spinner  *ngIf="loadFile" [diameter]="20"></mat-spinner>
      </div>
    </div>
    <div class="filter filter-date">
      <div class="item-search-wrapper">
        <div class="item-search">
          <mat-form-field class="mat-item ">
            <mat-label>Name</mat-label>
            <input matInput
                   [(ngModel)]="searchName"
                   id="autocomplete"
                   type="text" autocomplete="off"
                   (ngModelChange)="searchText(searchName, 'name')"
            >
          </mat-form-field>
        </div>
        <div class="item-search">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select
              (valueChange)="onTypeChange($event)"
              [formControl]="typeCtrl">
              <mat-option *ngFor="let item of types" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="item-search">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select
              [formControl]="countryCtrl"
              (valueChange)="onCountryChange($event)">
              <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="date-wrapper">
        <div class="date-from">
          <mat-form-field appearance="fill" class="item calendat">
            <mat-label>From</mat-label>
            <input matInput [matDatepicker]="picker1" [formControl]="monthFromControl"
                   (dateChange)="onDateChange($event, 'from')">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date-from">
          <mat-form-field appearance="fill" class="item calendat">
            <mat-label>To</mat-label>
            <input matInput [matDatepicker]="picker2" [formControl]="monthToControl"
                   (dateChange)="onDateChange($event, 'to')">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="filter-reset" (click)="resetFilter()">
        Reset filter <span
        class="lnr lnr-cross"></span>
      </div>
    </div>
  </div>
  <div class="content-body"  *ngIf="isLoaded">
    <!--<section class="table-content">-->
    <table class='table table-striped table-bordered table-hover dataTable' *ngIf="list?.length">
      <thead>
      <tr>
        <th>
          <mat-checkbox class="example-margin" (change)="selectAllItems()"
                        [name]="'all'"
                        [id]="'all'"
                        [(ngModel)]="selectAllItem">

          </mat-checkbox>
        </th>
        <th>Name</th>
        <th>Type</th>
        <th>Country</th>
        <th>Created Date</th>
        <th>Input Date by Admin</th>
        <th>Last Update</th>
        <th>Current Balance</th>
        <th>Current Balance (Updated)</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let item of list; let i = index'>
        <td class="center">
          <mat-checkbox class="example-margin" (change)="selectItem(item)"
                        [name]="'IsProduced_' + item.slug"
                        [id]="'IsProduced_' + item.slug"
                        [(ngModel)]="item.isSelectedItem">

          </mat-checkbox>
          <!--<input type="checkbox"-->
          <!--(change)="selectItem(item)"-->
          <!--[name]="'IsProduced_' + item.slug"-->
          <!--[id]="'IsProduced_' + item.slug"-->
          <!--[(ngModel)]="item.isSelectedItem">-->
          <!--<label [for]="'IsProduced_' + item.slug"></label>-->
        </td>
        <td><a class="link-blue" (click)="openItemBalance(item)">{{item?.name}}</a></td>
        <td>{{item?.isProducer ? 'Producer' : 'Artist'}}</td>

        <td class="text-break">{{item?.countryLong?.name}}</td>
        <td>{{item?.balance?.createdAt | momentDate}}</td>
        <td>{{item?.balance?.createdDate}}</td>
        <td>{{item?.updatedAt}}</td>
        <td><span class="amount-balance" [class.warning]="item?.balance?.grossBalance < 0">{{item?.balance?.grossBalance | number: '.2-2'}}</span>
        </td>
        <td>{{(item?.recoupableAmount - item?.recoupedAmount) | number: '.2-2'}}</td>
        <td>
          <div class="actions">
            <div class="edit" (click)="openItemBalance(item)">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > listFilters?.paging?.limit && isLoaded">
      <app-pagination [total]="total" [paging]="listFilters?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>
    <!--</section>-->
  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</app-page-template>

