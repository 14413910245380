import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {POSITIVE_NUMBER} from "@core/common";

@Component({
  selector: 'app-slider-component',
  templateUrl: './slider.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit {
  isEnabled: boolean;
  form: FormGroup;
  @Input() landingItemSlider: any;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): any {
    this.form = this.fb.group({
      title: new FormControl(''),
      isEnabled: new FormControl(false),
      slider: new FormArray([])
    });
    if (this.landingItemSlider) {
      this.setForm();
    }

  }
  get sliderArray(){
    return this.form.get('slider') as FormArray;
  }
  setForm(): void {
    this.form.controls['title'].setValue(this.landingItemSlider.title);
    this.form.controls['isEnabled'].setValue(this.landingItemSlider.isEnabled);
    this.landingItemSlider.list.forEach((item, index) => {
      this.sliderArray.push(new FormGroup({
          mainTitle: new FormControl(item.mainTitle),
          title: new FormControl(item.title),
          text: new FormControl(item.text),
          url: new FormControl(item.url2),
          text2: new FormControl(item.text2),
          url2: new FormControl(item.url),
          position: new FormControl(item.position || index + 1),
          image: new FormControl(item.image),
          imageMobile: new FormControl(item.imageMobile),
          targetBlank: new FormControl(item.targetBlank),
          logged: new FormControl(item.allowToShow.logged),
          visitors: new FormControl(item.allowToShow.visitors)
      }))
    });
  }

  addSlide() {
    this.sliderArray.push(new FormGroup({
      mainTitle: new FormControl(''),
      title: new FormControl(''),
      text: new FormControl(''),
      url: new FormControl(''),
      position: new FormControl(null, [Validators.pattern(POSITIVE_NUMBER)]),
      image: new FormControl(''),
      imageMobile: new FormControl(''),
      targetBlank: new FormControl(false),
      logged: new FormControl(false),
      visitors: new FormControl(false)
    }))
  }

  deleteSlide(index: number): void {
    if (this.sliderArray.length >= 0) {
      this.sliderArray.removeAt(index);
    }
  }

  getListCards() {
    let list = [];
    list = this.form.value.slider;
    list.forEach(item => {
      item.allowToShow = {
        logged: item.logged,
        visitors: item.visitors
      };
    })
     list = list.sort((a,b) => a.position - b.position);
    return {
      title: this.form.get('title').value,
      isEnabled: this.form.get('isEnabled').value,
      list: list
    };
  }
}

