import 'reflect-metadata';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountingRoutingModule } from './accounting-routing.module';

// Modules
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from '@core/material.module';
import { PageTemplateModule } from '@core/pages/page-template/page-template.module';
import { GlobalTemplateModule } from '@core/component/global/global-template.module';
import { PopUpModule } from '@core/component/global/popup-items/pop-up.module';
import { TokenModule } from '@core/component/nft-token-component/token.module';
import { ShareModule } from '@core/share.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { UiModule } from '@core/component/ui/ui.module';

// Components
import { AccountingComponent } from './accounting.component';
import { DownloadInvoiceComponent } from './download-invoices/download_invoice.component';
import { DownloadStatementComponent } from './download-statement/download_statement.component';
import { WalletProjectsListsComponent } from './wallet-projects-lists/wallet-projects-lists.component';
import { WalletListsComponent } from '@core/pages/accounting/wallet-lists/wallet-lists.component';
import { MintRequestComponent } from './mint-request/mint-request.component';
import { TransferTokensComponent } from './transfer-tokens/transfer-tokens.component';

@NgModule({
  exports: [
    WalletProjectsListsComponent,
    WalletListsComponent,
    MintRequestComponent,
    TransferTokensComponent,
    DownloadInvoiceComponent,
    DownloadStatementComponent,
  ],
  declarations: [
    AccountingComponent,
    WalletProjectsListsComponent,
    WalletListsComponent,
    MintRequestComponent,
    TransferTokensComponent,
    DownloadInvoiceComponent,
    DownloadStatementComponent,
    TransferTokensComponent,
    DownloadInvoiceComponent,
    DownloadStatementComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountingRoutingModule,
    MaterialModule,
    UiModule,
    GlobalTemplateModule,
    PopUpModule,
    PageTemplateModule,
    ToastrModule.forRoot(),
    PipesModule,
    TokenModule,
    ShareModule,
  ],
  providers: [],
})
export class AccountingModule {
  constructor() {}
}
