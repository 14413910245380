import {Component, HostListener, Input, OnInit, ViewContainerRef} from '@angular/core';
//import {StateService, TransitionService} from '@uirouter/angular';
import {StorageService, AuthenticationService, RoutingService, UploadService} from 'app/services';
import {STATE_NAMES} from 'app/common';
import {ISliders} from '@core/model/landing.model';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.html'
})

export class SlidersComponent {
  @Input() sliders: ISliders;

  constructor(private uploadService: UploadService) {
  }

  get slider(): ISliders {
    return this.sliders;
  }

  saveImage(e, item) {
    item.imageURL = e.value;
    item.image = this.uploadService.getShortUrlName(e.value, 'url');
  }

  addSlider() {
    this.sliders.list.push({
      title: '',
      mainTitle: '',
      image: '',
      text: '',
      url: '',
      targetBlank: ''
    });
  }
}
