import {Injectable} from '@angular/core';
import {AbstractBaseFindService} from "./abstract-base-find.service";
import {ProjectService} from "../../services";
import {RequestModel} from "../../model/request.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {debounceTime, filter, switchMap} from "rxjs/operators";


@Injectable()

export class FindProjectService implements AbstractBaseFindService {
  public form: UntypedFormGroup;
  public paramDisplay: string;
  public type: string;
  public typeSelect: string;
  public list$: Observable<any>;
  public list: any;
  constructor(private projectService: ProjectService, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      selectedItem: ['', [Validators.required]],
      searchValue: ['', [Validators.required]],
    });
    this.list$ = this.form.get('searchValue').valueChanges.pipe(
      filter((v: any) => v !== null && v.length > 2),
      debounceTime(200),
      switchMap((value => {
          return this.searchText(value);
        }),
      ));
  }

  getList(model: RequestModel) {
      return this.projectService.findValueByCriteria(model);
  }
  makeEmpty(){
    this.list$ = null;
  }
  searchText(value): Observable<any> {
    return this.getList(new RequestModel({projectTitle: value, allTypes: true}));
  }

  selectItem(selected) {
    this.form.get('selectedItem').setValue(selected.showDisplayName);
    this.form.get('searchValue').setValue('');
  }
}

