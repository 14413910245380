import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestModel } from '@core/model/request.model';

import { IWallet } from '@core/interfaces/payout.interface';
import { PaginatedList } from '@core/common/interfaces';
@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  apiUrl: string;

  constructor(private setting: SettingService, private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
  }

  getFundedProjects(request: RequestModel): Observable<PaginatedList<IWallet>> {
    const params = request ? request.getApiModel() : null;
    return this.http
      .get(`${this.apiUrl}/admin/projects/funded`, { params })
      .pipe(
        map((resp: PaginatedList<IWallet>) => {
          if (resp) {
            return resp;
          }
        })
      );
  }

  transferWalletAmount(data: IWallet): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/projects/funded`, data).pipe(
      map((resp: any) => {
        if (resp) {
          return resp;
        }
      })
    );
  }
}
