// TODO move commented child routes to alias const
export const STATE_NAMES = {
  accounting: 'accounting',
  albums: 'albums',
  app: 'app',
  artistsList: 'artists',

  auctionMarket: 'auction-market',
  balances: 'balances',
  coLabel: 'co-label',
  collectible: 'collectible',
  collectibleItem: 'collectible-item',
  collectibleList: 'collectible-list',
  dashboard: 'dashboard',
  distribution: 'distribution',
  fansEdit: 'investors-edit',
  fans: 'fans',
  fansView: 'investors-view',
  home: 'home',
  investorItemView: 'investor-new-item-view',
  investorsList: 'investors',
  opportunityLinked: 'opportunity-linked',
  opportunityGrs: 'opportunity-grs',
  opportunityBundle: 'opportunity-bundle',
  landing: 'landing',
  login: 'login',
  news: 'news',
  opportunityAccepted: 'opportunity-accepted',
  opportunitySubmitted: 'opportunity-submitted',
  producersEdit: 'producers-edit',
  producersList: 'producers',
  producersView: 'producers-view',
  projectLinkedItem: 'project-linked-item',
  projectLinkedList: 'project-linked-list',
  opportunity: 'opportunity',
  projectView: 'project-view',
  promocode: 'promocode',
  releases: 'releases',
  roles: 'roles',
  settings: 'settings',
  submissions: 'submissions',
  voucher: 'voucher',
};

export const ROUTE_ALIAS = {
  albumEdit: `${STATE_NAMES.albums}/edit`,
  albumView: `${STATE_NAMES.albums}/view`,
  releaseDistribution: `${STATE_NAMES.releases}/distribution`,
  releaseEdit: `${STATE_NAMES.releases}/item`,
  projectEdit: `${STATE_NAMES.opportunity}/edit`,
  projectView: `${STATE_NAMES.opportunity}/view`,
  opportunityLinkedEdit: `${STATE_NAMES.opportunityLinked}/edit`,
  opportunityLinkedView: `${STATE_NAMES.opportunityLinked}/view`,
  opportunityBundleEdit: `${STATE_NAMES.opportunityBundle}/edit`,
  opportunityBundleView: `${STATE_NAMES.opportunityBundle}/view`,
  opportunityGrsEdit: `${STATE_NAMES.opportunityGrs}/edit`,
  opportunityGrsView: `${STATE_NAMES.opportunityGrs}/view`,
  fansEdit: `${STATE_NAMES.fans}/edit`,
  fansView: `${STATE_NAMES.fans}/view`,
  investorView: `${STATE_NAMES.investorsList}/view`,
  newsEdit: `${STATE_NAMES.news}/edit`,
  balancesEdit: `${STATE_NAMES.balances}/edit`,
  promocodeView: `${STATE_NAMES.promocode}/view`,
  promocodeEdit: `${STATE_NAMES.promocode}/edit`,
  landingItem: `${STATE_NAMES.landing}/item`
};

export const PERMISSION = {
  accounting: 'account_p',
  albums: 'album_p',
  artists: 'artist_p',
  'auction-market': 'edition_p',
  balances: 'balance_p',
  'co-label': 'role_p',
  'collectible': 'collectible_p',
  'investors': 'fan_p',
  'fans': 'fan_p',
  //'linkedProject': 'album_p',
  landing: 'landing_p',
  'news': 'news_p',
  'producers': 'producer_p',

  opportunity: ['project_p', 'label_p'],
  'opportunity-linked': ['project_p', 'label_p'],
  'opportunity-bundle': 'album_p',
  'opportunity-grs': 'project_p',
  promocode: 'voucher_p',
  releases: 'release_p',
  roles: 'role_p',
  settings: 'setting_p',
  submissions: 'submission_p',
  voucher: 'voucher_p',
};
