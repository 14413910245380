<input [ngClass]="date || !forFilter ? 'input-text': 'input-text-empty'" type="text" [(ngModel)]="date" (focus)="onFocus()" readonly
       [placeholder]=placeholder *ngIf="!control || fromTo">
<input [ngClass]="control?.value || !forFilter ? 'input-text': 'input-text-empty'" type="text" [formControl]="control" (focus)="onFocus()" readonly
       [placeholder]=placeholder *ngIf="control && !fromTo">
<span class="lnr lnr-calendar-full" (click)="onFocus()"></span>
<section class="calendar-wrapper" [class.opened]="opened">
  <header class="header">
    <div class="lnr lnr-chevron-left" (click)="changePeriod()"></div>
    <div class="title" (click)="changeView()">{{calendarTitle}}</div>
    <div class="lnr lnr-chevron-right" (click)="changePeriod(true)"></div>
  </header>
  <ng-template ngIf [ngIf]="view === 'day'">
    <div class="date-row day-title">
      <div class="item" *ngFor="let item of ['S','M','T','W','T','F','S']">
        {{item}}
      </div>
    </div>
    <div class="date-row" *ngFor="let row of dates">
      <div class="item" *ngFor="let item of row" [class.next]="item[1]==='next'" [class.last]="item[1]==='last'"
           [class.disabled]="item[2] < currentTime && disablePast" [class.current]="item[2] === currentTime"
           [class.active]= "item[2] === activeTime || item.active"
           [class.activeStart]= "item[2] === timeFrom"
           [class.activeLast]= "item[2] === timeTo"
           (click)="onDateClick(item, (item[1] === 'last' || (item[2] < currentTime && disablePast)))">
        {{item[0]}}
      </div>
    </div>
  </ng-template>
  <ng-template ngIf [ngIf]="view === 'month' || viewP === 'month'">
    <div class="date-row row-wrapped">
      <div class="item month" *ngFor="let month of monthsToSelect" (click)="onMonthClick(month.value, $event)">
        {{month.text}}
      </div>
    </div>
  </ng-template>
  <ng-template ngIf [ngIf]="view === 'year'">
    <div class="date-row row-wrapped">
      <div class="item month" *ngFor="let month of monthsToSelect; let i = index"
           (click)="onYearClick(currentYear - 8 + i, $event)">
        {{currentYear - 8 + i}}
      </div>
    </div>
  </ng-template>
</section>
