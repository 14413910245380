<form [formGroup]="form">
  <div class="item-row">
    <div class="item-label item-label_pad-20">Image</div>
    <div class="item-field">
      <app-upload-image [config]="{imageFormat: 'png', ratio: 16 / 6}"
                        [control]="form?.controls?.image"
                        [imgURL]="form?.controls?.image?.value"
      ></app-upload-image>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Use image or icon?</div>
    <div class="item-field">
      <mat-radio-group formControlName="useImage">
        <mat-radio-button [value]="false"
                          class="m20-right"
                          name="Icon">
          Icon
        </mat-radio-button>
        <mat-radio-button [value]="true"
                          name="Image">
          Image
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="item-row">
    <div class="item-label item-label_pad-20">Icon</div>
    <div class="item-field">
      <div class='col-sm-10 icons-selector'>
        <label>
          <input type="radio" value="fa-poll" formControlName="class">
          <span class="icon fas fa-poll"></span>
        </label>
        <label>
          <input type="radio" value="fa-chart-area" formControlName="class">
          <span class="icon fas fa-chart-area"></span>
        </label>
        <label>
          <input type="radio" value="fa-cloud-download-alt" formControlName="class">
          <span class="icon fas fa-cloud-download-alt"></span>
        </label>
        <label>
          <input type="radio" value="fa-cloud-upload-alt" formControlName="class">
          <span class="icon fas fa-cloud-upload-alt"></span>
        </label>
        <label>
          <input type="radio" value="fa-bug" formControlName="class">
          <span class="icon fas fa-bug"></span>
        </label>
        <label>
          <input type="radio" value="fa-headphones" formControlName="class">
          <span class="icon fas fa-headphones"></span>
        </label>
        <label>
          <input type="radio" value="fa-microphone" formControlName="class">
          <span class="icon fas fa-microphone"></span>
        </label>
        <label>
          <input type="radio" value="fa-shipping-fast" formControlName="class">
          <span class="icon fas fa-shipping-fast"></span>
        </label>
        <label>
          <input type="radio" value="fa-map-marker-alt" formControlName="class">
          <span class="icon fas fa-map-marker-alt"></span>
        </label>
        <label>
          <input type="radio" value="fa-play-circle" formControlName="class">
          <span class="icon fas fa-play-circle"></span>
        </label>
        <label>
          <input type="radio" value="fa-pause-circle" formControlName="class">
          <span class="icon far fa-pause-circle"></span>
        </label>
        <label>
          <input type="radio" value="fa-trash-alt" formControlName="class">
          <span class="icon fas fa-trash-alt"></span>
        </label>
        <label>
          <input type="radio" value="fa-chart-line" formControlName="class">
          <span class="icon fas fa-chart-line"></span>
        </label>
        <label>
          <input type="radio" value="fa-edit" formControlName="class">
          <span class="icon fas fa-edit"></span>
        </label>
        <label>
          <input type="radio" value="fa-facebook" formControlName="class">
          <span class="icon fab fa-facebook"></span>
        </label>
        <label>
          <input type="radio" value="fa-twitter" formControlName="class">
          <span class="icon fab fa-twitter"></span>
        </label>
        <label>
          <input type="radio" value="fa-arrow-up" formControlName="class">
          <span class="icon fas fa-arrow-up"></span>
        </label>
        <label>
          <input type="radio" value="fa-arrow-down" formControlName="class">
          <span class="icon fas fa-arrow-down"></span>
        </label>
        <label>
          <input type="radio" value="fa-clock" formControlName="class">
          <span class="icon far fa-clock"></span>
        </label>
        <label>
          <input type="radio" value="fa-credit-card" formControlName="class">
          <span class="icon fas fa-credit-card"></span>
        </label>
        <label>
          <input type="radio" value="fa-user-circle" formControlName="class">
          <span class="icon far fa-user-circle"></span>
        </label>
        <label>
          <input type="radio" value="fa-calendar-alt" formControlName="class">
          <span class="icon far fa-calendar-alt"></span>
        </label>
        <label>
          <input type="radio" value="fa-bars" formControlName="class">
          <span class="icon fas fa-bars"></span>
        </label>
        <label>
          <input type="radio" value="fa-info-circle" formControlName="class">
          <span class="icon fas fa-info-circle"></span>
        </label>
        <label>
          <input type="radio" value="fa-check" formControlName="class">
          <span class="icon fas fa-check"></span>
        </label>
        <label>
          <input type="radio" value="fa-info" formControlName="class">
          <span class="icon fas fa-info"></span>
        </label>
        <label>
          <input type="radio" value="fa-pause" formControlName="class">
          <span class="icon fas fa-pause"></span>
        </label>
        <label>
          <input type="radio" value="fa-play" formControlName="class">
          <span class="icon fas fa-play"></span>
        </label>
        <label>
          <input type="radio" value="fa-chart-bar" formControlName="class">
          <span class="icon fas fa-chart-bar"></span>
        </label>
        <label>
          <input type="radio" value="icon-grs-logo" formControlName="class">
          <span class="icon icon-grs-logo"></span>
        </label>
        <label>
          <input type="radio" value="fa-cog" formControlName="class">
          <span class="icon fas fa-cog"></span>
        </label>
        <label>
          <input type="radio" value="fa-search" formControlName="class">
          <span class="icon fas fa-search"></span>
        </label>
        <label>
          <input type="radio" value="fa-wallet" formControlName="class">
          <span class="icon fas fa-wallet"></span>
        </label>
        <label>
          <input type="radio" value="fa-star" formControlName="class">
          <span class="icon fas fa-star"></span>
        </label>
        <label>
          <input type="radio" value="fa-award" formControlName="class">
          <span class="icon fas fa-award"></span>
        </label>
        <label>
          <input type="radio" value="fa-question-circle" formControlName="class">
          <span class="icon fas fa-question-circle"></span>
        </label>
        <label>
          <input type="radio" value="fa-arrow-circle-up" formControlName="class">
          <span class="icon fas fa-arrow-circle-up"></span>
        </label>
        <label>
          <input type="radio" value="fa-paypal" formControlName="class">
          <span class="icon fab fa-paypal"></span>
        </label>
        <label>
          <input type="radio" value="fa-cc-jcb" formControlName="class">
          <span class="icon fab fa-cc-jcb"></span>
        </label>
        <label>
          <input type="radio" value="fa-music" formControlName="class">
          <span class="icon fas fa-music"></span>
        </label>
        <label>
          <input type="radio" value="fa-facebook-f" formControlName="class">
          <span class="icon fab fa-facebook-f"></span>
        </label>
        <label>
          <input type="radio" value="fa-facebook-square" formControlName="class">
          <span class="icon fab fa-facebook-square"></span>
        </label>
        <label>
          <input type="radio" value="fa-twitter-square" formControlName="class">
          <span class="icon fab fa-twitter-square"></span>
        </label>
        <label>
          <input type="radio" value="fa-youtube" formControlName="class">
          <span class="icon fab fa-youtube"></span>
        </label>
        <label>
          <input type="radio" value="fa-google" formControlName="class">
          <span class="icon fab fa-google"></span>
        </label>
        <label>
          <input type="radio" value="fa-check-square" formControlName="class">
          <span class="icon fas fa-check-square"></span>
        </label>
        <label>
          <input type="radio" value="fa-hourglass-end" formControlName="class">
          <span class="icon fas fa-hourglass-end"></span>
        </label>
        <label>
          <input type="radio" value="fa-shopping-cart" formControlName="class">
          <span class="icon fas fa-shopping-cart"></span>
        </label>
        <label>
          <input type="radio" value="fa-share-square" formControlName="class">
          <span class="icon fas fa-share-square"></span>
        </label>
        <div>
          <label>
            <input type="radio" value="image-arrow" formControlName="class">
            <img src="/images/icons/arrow.png" alt="" class="landing-card-image">
          </label>
          <label>
            <input type="radio" value="image-dollar" formControlName="class">
            <img src="/images/icons/dollar.png" alt="" class="landing-card-image">
          </label>
          <label>
            <input type="radio" value="image-free" formControlName="class">
            <img src="/images/icons/free.png" alt="" class="landing-card-image">
          </label>
          <label>
            <input type="radio" value="image-heart" formControlName="class">
            <img src="/images/icons/heart.png" alt="" class="landing-card-image">
          </label>
          <label>
            <input type="radio" value="image-increasing" formControlName="class">
            <img src="/images/icons/increasing.png" alt="" class="landing-card-image">
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Headline</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="headline"
               formControlName="headline"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="title"
               formControlName="title"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Sub Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="subTitle"
               formControlName="subTitle"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Position</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="position"
               formControlName="position"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Description</div>
    <div class="item-field">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        [formControl]="form.get('text')"
      ></angular-editor>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Is card enabled</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    [name]="'enabled' + index"
                    formControlName="isEnabled"
                    [id]="'enabled' + index"
      >
      </mat-checkbox>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Cta button</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="button"
               formControlName="button"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">CTA Button link</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="url"
               formControlName="url"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
  <div class="item-row">
    <div class="item-label item-label_pad-20">Open in new window</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    [name]="'new window' + index"
                    formControlName="targetBlank"
                    [id]="'new window' + index"
      >
      </mat-checkbox>
    </div>
  </div>
</form>
