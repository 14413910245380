import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
  ArtistModel,
  BalanceModel,
  RequestUserModel,
  UserModel,
  UsersModel
} from '../model';
import {RequestModel} from '../model/request.model';
import {ResponseTotal} from '@core/common/interfaces';
import {IArtistEditing} from '@core/model/artists-list.model';
import {DictionaryService} from '@core/services/dictionary.service';
import {RoleModel} from '@core/model/role.model';
import {IRoles} from '@core/interfaces/roles.interface';

interface IBalanceResponse {
  aggregated: any;
  rights_recouped: {
    master: number,
    publish: number;
  }
  page: number;
  limit: number;
}

@Injectable()
export class UsersService {
  apiUrl: string;
  totalRights: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  totalRights$: Observable<any> = this.totalRights.asObservable().pipe(shareReplay(1));


  constructor(private setting: SettingService,
              private dictionaryService: DictionaryService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
  }

  getBalance(slug: string): Observable<any> {
    return this.http.get<IBalanceResponse>(`${this.apiUrl}/admin/artist/${slug}/balance`)
      .pipe(map((resp: any) => {
        if (resp) {

          const balances = new BalanceModel().setFromApiModel(resp.aggregated);

          let totalRights = {
            master: resp.rights_recouped.master,
            publish: resp.rights_recouped.publish
          };

          this.totalRights.next(totalRights);

          return balances;
        }
      }));
  }

  getArtistWithBalance(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/artists/balance`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          list: resp.docs ? resp.docs.map(item => new ArtistModel().setFromApiModel(item)) : [],
          total: resp.total
        };
      }
    }));
  }

  getArtists(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/artists`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new ArtistModel().setFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getProducers(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/producers`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new ArtistModel().setFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  editCurrentUser(slug, body): Observable<IArtistEditing> {
    return this.http.put<IArtistEditing>(`${this.apiUrl}/admin/users/${slug}`, body);
  }

  _getFans(request: RequestModel): Observable<ResponseTotal<UserModel>> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/fans`, {params}).pipe(map((resp: any) => {
      if (resp.docs) {
        return {
          list: resp.docs.map(item => new UserModel().setFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getUser(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/user/${slug}/detail`).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  selectUser(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/user/${slug}/select`).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  saveRole(data): Observable<IRoles> {
    return this.http.post(`${this.apiUrl}/admin/role`, data).pipe(map((resp: any) => {
      if (resp) {
        return new RoleModel().setFromApiModel(resp);
      }
    }));
  }

  _getRole(): Observable<IRoles[]> {
    return this.http.get(`${this.apiUrl}/admin/role`).pipe(map((resp: any) => {
      if (resp) {
        return resp.map(item => new RoleModel().setFromApiModel(item));
      }
    }));
  }

  _assignRole(data, user): Observable<UserModel> {
    return this.http.put(`${this.apiUrl}/admin/user/${user.slug}/role`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _editRole(data, slug): Observable<IRoles> {
    return this.http.put(`${this.apiUrl}/admin/role/${slug}`, data).pipe(map((resp: any) => {
      if (resp) {
        return new RoleModel().setFromApiModel(resp);
      }
    }));
  }

  _deleteRole(slug): Observable<IRoles> {
    return this.http.delete(`${this.apiUrl}/admin/role/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return new RoleModel().setFromApiModel(resp);
      }
    }));
  }

  changeRole(slug): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/admin/user/${slug}/type`).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }

  changeState(slug, body): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/admin/user/${slug}/state`, body).pipe(map((resp: any) => {
      if (resp) {
        return new UserModel().setFromApiModel(resp);
      }
    }));
  }


  longNameCountry(country): Promise<string> {
    return new Promise((resolve, reject) => {
      this.dictionaryService.getCountries().subscribe(resp => {
        let longName;
        resp.forEach(c => {
          if (c.id === country) {
            longName = c.name;
          }
        });
        resolve(longName);
      });
    });


  }

  _deleteUser(slug): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/admin/users/${slug}`);
  }

  getArtistByName(params): Observable<any> {
    // return this.http.get(`${this.apiUrl}/admin/artist-by-name`, {params}).pipe(map((resp: any) => {
    //   if (resp) {
    //     return [...resp.items.map(i => new FindByNameModel().setFromApiModel(i))];
    //   }
    // }));
    return;
  }

  payoutMoney(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/accounting/withdraw`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  findValueByCriteria(request: RequestUserModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/user-find`, {params})
  }

  findUser(request: RequestUserModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/user-find`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getArtistProjects(slug): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/artist/${slug}/projects`).pipe(map((resp: any) => {
      if (resp && resp.length) {
        return resp[0];
      }
    }));
  }

  getProducerByName(searchName: string): Observable<any> {
    const params = {
      name: searchName
    };
    return this.http.get(`${this.apiUrl}/admin/producer-by-name`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return [...resp.items.map(i => new UsersModel().setFromApiModel(i))];
      }
    }));
  }

  closeBalance(slug: string): Observable<any> {
    const data = {
      slug
    };
    return this.http.post(`${this.apiUrl}/admin/close-balance`, {data}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  deleteBalance(slug: string, id: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/delete-balance`, {
      slug,
      id
    }).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  addRecoupable(request, user, id?): Observable<any> {
    const data = {
      slug: user.slug,
      balance: request
    };
    return this.http.post(`${this.apiUrl}/admin/recoupable`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  updateArtist(request, user, id): Observable<any> {
    const data = {
      slug: user.slug,
      id,
      balance: request
    };
    return this.http.put(`${this.apiUrl}/admin/recoupable`, {data}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getMintStatus(slug: string, type: 'user' | 'project'): Observable<any> {
    return this.http.get(`${this.apiUrl}/token/status`, {params: {[type]: slug}})
  }

  mintTokens(slug: string, type: 'user' | 'project'): Observable<unknown> {
    return this.http.get(`${this.apiUrl}/token/secure`, {params: {[type]: slug}});
  }
}
