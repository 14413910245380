<app-page-template header="Collectible Page">
  <app-page-content>
    <form [formGroup]="form">
      <section class="section__content">
        <div class="content__inner">
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Is Published</div>
            <div class="item-field toggle-item raw-edit item-field_align">
              <mat-slide-toggle [formControl]="isPublishedCtrl"
                                [disabled]="!collectibleItem || !collectibleItem?.auctionStatus"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Artist*</div>
            <app-find-by-name [text]="artistName"
                              [selectedArtist]="selectedArtist"
            ></app-find-by-name>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Song name*</div>
            <mat-form-field class="item-field item-field_align">
              <input matInput
                     id="songCtrl"
                     [errorStateMatcher]="errorMatcher(songCtrl)"
                     [formControl]="songCtrl"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Editions*</div>
            <mat-form-field class="item-field item-field_align">
              <input matInput
                     max="10"
                     id="editionCtrl"
                     [errorStateMatcher]="errorMatcher(editionCtrl)"
                     [formControl]="editionCtrl"
                     type="number" autocomplete="off"
              >
            </mat-form-field>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Genre*</div>

            <mat-form-field class="item-field item-field_align">
              <mat-select [formControl]="genresCtrl" multiple>
                <mat-option *ngFor="let item of genres" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Video*</div>
            <app-upload-video class="item-field" [control]="videoCtrl" [config]="configUploadVideo"></app-upload-video>
          </div>

          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Offer details*</div>
            <div class="item-field">
            <textarea class="textarea" rows="10" cols="45" name="text"
                      [formControl]="offerDetailsCtrl"
            ></textarea>
            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Text Biography*</div>
            <angular-editor
              class="item-field"
              [placeholder]="'Enter text here...'"
              [config]="editorConfig"
              [formControl]="textBiographyCtrl"
            ></angular-editor>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Text My Project*</div>
            <angular-editor [placeholder]="'Enter text here...'"
                            class="item-field"
                            [formControl]="textAlbumCtrl"
                            [config]="editorConfig"></angular-editor>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Goodies icon</div>
            <div class="item-field item-field_align">
              <mat-checkbox class="example-margin" [formControl]="goodiesIconCtrl"></mat-checkbox>
            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Limited ownership icon</div>
            <div class="item-field item-field_align">
              <mat-checkbox class="example-margin" [formControl]="limitedIconCtrl"></mat-checkbox>
            </div>
          </div>
          <!--<div class="item-row">-->
          <!--<div class="item-label header-inform-text-grey item-label_pad-20">Position*</div>-->
          <!--<mat-form-field   class="item-field item-field_align">-->
          <!--<input matInput-->
          <!--id="positionCtrl"-->
          <!--[errorStateMatcher]="errorMatcher(positionCtrl)"-->
          <!--[formControl]="positionCtrl"-->
          <!--type="text" autocomplete="off"-->
          <!--&gt;-->
          <!--</mat-form-field>-->
          <!--</div>-->
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Collectible picture*</div>
            <app-upload-image
              [config]="collectibleImage"
              [control]="collectibleImgCtrl"
              [maintainAspectRatio]="true"
              [imgURL]="collectibleImgCtrl?.value"
            ></app-upload-image>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Collection Name</div>
            <mat-form-field class="item-field item-field_align">
              <input matInput
                     id="collectionNameCtrl"
                     [errorStateMatcher]="errorMatcher(collectionNameCtrl)"
                     [formControl]="collectionNameCtrl"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Collection Rights</div>
            <mat-form-field class="item-field item-field_align">
              <input matInput
                     id="collectionNameCtrl"
                     [errorStateMatcher]="errorMatcher(collectionRightsCtrl)"
                     [formControl]="collectionRightsCtrl"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Card background*</div>
            <div class="item-field background">
              <app-upload-image
                class="background__image"
                [config]="backgroundConfig"
                [control]="selectTypeBackgroundCtrl"
                [maintainAspectRatio]="true"
                [imgURL]="selectTypeBackgroundCtrl?.value"
              ></app-upload-image>

            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Card color*</div>
            <div class="item-field background">
              <app-pick-color [color]="collectibleItem?.cardColor"></app-pick-color>
            </div>
          </div>
        </div>
      </section>

      <section class="section__content">
        <div class="section-title">SEO</div>
        <app-seo-project [seo]="collectibleItem?.seo"></app-seo-project>
      </section>

      <section class="section__content">
        <div class="section-title">NFT Content</div>
        <div class="item-row">
          <div class="item-label header-inform-text-grey item-label_pad-20">NFT Video</div>
          <app-upload-video [control]="videoNFTCtrl" [config]="videoConfig"></app-upload-video>
        </div>
        <div class="item-row">
          <div class="item-label header-inform-text-grey item-label_pad-20">NFT picture*</div>
          <div class="item-field">
            <mat-radio-group [(ngModel)]="selectTypeNFTimage" [ngModelOptions]="{standalone: true}">
              <mat-radio-button value="png" class="m20-right" name="search-and">
                <span class="radio-button-text_grey">WEBP</span>
              </mat-radio-button>
              <mat-radio-button value="gif" name="search-and"><span class="radio-button-text_grey">GIF</span>
              </mat-radio-button>
            </mat-radio-group>
            <div class="item__upload-outer">
              <app-upload-image
                *ngIf="selectTypeNFTimage === 'png'"
                [config]="imageNFTConfig"
                [control]="imgNFTCtrl"
                [maintainAspectRatio]="true"
                [imgURL]="imgNFTCtrl?.value"
              ></app-upload-image>
              <app-upload-image
                *ngIf="selectTypeNFTimage === 'gif'"
                [config]="imageNFTConfigGIF"
                [control]="imgNFTCtrl"
                [maintainAspectRatio]="true"
                [imgURL]="imgNFTCtrl?.value"
                [imgGIFURL]="collectibleItem?.nft?.imageGIFURL"
                (dateChange)="nftImageGIF($event)"
              ></app-upload-image>
            </div>
          </div>
        </div>
      </section>
      <section class="section__content auction-detail" *ngIf="collectibleItem || !slug">
        <app-teaser [project]="collectibleItem"></app-teaser>
      </section>
      <section class="section__content auction-detail">
        <app-read-more-section [readMoreContent]="collectibleItem?.readMoreContent"></app-read-more-section>
      </section>
      <section class="section__content m20-top">
        <app-project-detail-section
                                    [collectible]="true"
                                    [project]="collectibleItem"></app-project-detail-section>
      </section>
      <section class="section__content auction-detail">
        <div class="section-title">Fees</div>
        <app-nft-fee *ngIf="!!collectibleItem"
                     [form]="form"
                     [data]="collectibleItem"></app-nft-fee>
      </section>

      <section class="section__content auction-detail" *ngIf="collectibleItem?.auctionId">
        <div class="section-title">Auction Details</div>
        <div class="content__inner">
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Starting Price</div>
            <div class="item-field toggle-item raw-edit item-field_align">
              {{collectibleItem?.startingPrice}}
            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">Start Date</div>
            <div class="item-field toggle-item raw-edit item-field_align">
              {{collectibleItem?.startDate | momentDateHours}}
            </div>
          </div>
          <div class="item-row">
            <div class="item-label header-inform-text-grey item-label_pad-20">End Date</div>
            <div class="item-field toggle-item raw-edit item-field_align">
              {{collectibleItem?.endDate | momentDateHours}}
            </div>
          </div>
        </div>
      </section>
      <div class="button-wrapper flex flex-end">
        <mat-spinner *ngIf="isLoaded" [diameter]="20"></mat-spinner>
        <button class="button green default-size button--padding"
                [disabled]="collectibleItem?.status !== 'confirmed' || submitted || collectibleItem?.auctionId"
                *ngIf="listEditions?.length"
                (click)="createAuction()">
          Create auction
        </button>
        <button class="button green default-size button--padding" (click)="mintSong()"
                [disabled]="!slug || !editionCtrl?.value || listEditions?.length">
          Mint song
        </button>
        <button class="button green default-size"
                [disabled]="submitPressed || listEditions?.length"
                (click)="saveCollectible()">
          Save changes
        </button>

      </div>
      <div class="collectible-editions" *ngIf="listEditions?.length">
        <div class="section-title">
          Editions
        </div>
        <div class="collectible__table">
          <table class='table'>
            <thead>
            <tr>
              <th>#</th>
              <th>
                Wallet
              </th>
              <th>
                Status
              </th>
              <th>
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let item of listEditions; let i = index'>
              <td class="center">{{ i + 1 }}</td>
              <td class="center">{{item?.walletId}}</td>
              <td class="center">
            <span class="edition-status" *ngIf="collectibleItem?.status === 'confirmed'"
                  [class.auction]="collectibleItem?.auctionId">
            {{collectibleItem?.auctionId ? 'Start auction' : 'Minted'}}
            </span>
                <span *ngIf="collectibleItem?.status !== 'confirmed'">
            {{collectibleItem?.status}}
          </span>
              </td>
              <td class="center">
                <button
                  *ngIf="!item?.owner && collectibleItem?.auction?.finished"
                  (click)="onClickGiveAway(item)"
                  class="button green default-size">Give away
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </form>
  </app-page-content>

</app-page-template>


<app-popup-give-away *ngIf="showGiveAway"
                     [giveAwayItem]="showGiveAway"
                     (notify)="onNotify($event)"></app-popup-give-away>

<app-popup-confirmation [config]="popupConfig" *ngIf="showPopupConfirmation"
                        (notify)="onNotify($event)"></app-popup-confirmation>
<app-popup-collectible-auction *ngIf="showStartAuction"
                               (notify)="onNotify($event)"
                               [collectible]="collectibleItem"></app-popup-collectible-auction>
