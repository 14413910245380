<form class="text-block">
  <div class="item-row"   *ngIf="landingItem?.config?.title">
    <div class="item-label header-inform-text-grey item-label_pad-20"

    >{{landingItem.config.title}}
    </div>
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="albumTitle1"
             [formControl]="titleCtrl"
             type="text" autocomplete="offlandingTextQuotesCtrl"
      >
    </mat-form-field>
  </div>
  <div class="item-row"  *ngIf="landingItem?.config?.subTitle">
    <div class="item-label header-inform-text-grey item-label_pad-20"

    >{{landingItem.config.subTitle}}
    </div>
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="albumSubTitle1"
             [formControl]="subTitleCtrl"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
  <div class="item-row"  *ngIf="landingItem?.config?.description">
    <div class="item-label header-inform-text-grey item-label_pad-20"

    >{{landingItem.config.description}}
    </div>
    <angular-editor class="item-field item-field_align"
                    [placeholder]="'Enter text here...'"
                    [config]="editorConfig"
                    [formControl]="landingTextCtrl"
    ></angular-editor>
  </div>
  <div class="item-row"  *ngIf="landingItem?.config?.subDescription">
    <div class="item-label header-inform-text-grey item-label_pad-20"

    >{{landingItem.config.subDescription}}
    </div>
    <angular-editor class="item-field item-field_align"
                    [placeholder]="'Enter text here...'"
                    [config]="editorConfig"
                    [formControl]="landingTextQuotesCtrl"
    ></angular-editor>
  </div>



  <div class="item-row" *ngIf="landingItem?.config?.image">
    <div class="item-label header-inform-text-grey item-label_pad-20">{{landingItem?.config?.image}}</div>
    <app-upload-image [settings]="settings"
                      [config]="{imageFormat: 'png', ratio: 16 / 6}"
                      [control]="imgCtrl"
                      [maxWidth]="'200px'"
                      [imgURL]="imgCtrl?.value"
    ></app-upload-image>
  </div>
  <div class="item-row" *ngIf="landingItem?.config?.button">
    <div class="item-label header-inform-text-grey item-label_pad-20">Button Name</div>
    <mat-form-field class="item-field item-field_align">
    <input matInput
           id="buttonName"
           [formControl]="buttonNameCtrl"
           type="text" autocomplete="off"
    >
    </mat-form-field>
  </div>
  <div class="item-row" *ngIf="landingItem?.config?.button">
    <div class="item-label header-inform-text-grey item-label_pad-20">Button URL</div>
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="buttonURL"
             [formControl]="buttonURLCtrl"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>

</form>
