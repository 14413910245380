import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppToastrService, RoyaltiesService} from '@core/services';
import {POSITIVE_NUMBER, VOUCHER_ADMIN} from '@core/common';

@Component({
  selector: 'app-popup-generate-voucher',
  templateUrl: './popup-generate-voucher.html'
})
export class PopupGenerateVoucherComponent implements OnInit {
  form: UntypedFormGroup;
  amountCtrl: UntypedFormControl;
  code: string;
  submitted: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private royaltyServices: RoyaltiesService) {
  }

  ngOnInit(): void {
    this.amountCtrl = this.fb.control('', [Validators.required, Validators.pattern(POSITIVE_NUMBER)]);
    this.form = this.fb.group({
      amount: this.amountCtrl
    });
  }

  closePopup(): void {
    this.notify.emit({type: this.code ? 'closeVoucher' : 'close'});
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitted = true;
      const data = {
        amount: Number(this.amountCtrl.value),
        cardDesign: VOUCHER_ADMIN.cardDesign,
        receiver: VOUCHER_ADMIN.receiver,
        message: VOUCHER_ADMIN.message
      };
      this.royaltyServices.addVoucher(data).subscribe(resp => {
        this.code = resp.code;
        this.submitted = false;
      }, (err) => {
        this.toastr.showToastFromError(err);
        this.submitted = false;
      });
    }
  }
}
