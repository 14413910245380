import { Component } from '@angular/core';
import { AppToastrService, UsersService } from '../../services/index';
import { USER_CATEGORY } from '@core/common';
import { UserModel } from '@core/model';
import { IMatSelect } from '@core/common/interfaces';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.html',
  styleUrls: ['./_accounting.scss'],
})
export class AccountingComponent {
  showError: boolean;
  bankSelected: boolean;
  searchValue: string;
  searchListResult: UserModel[] = [];
  sWithdrawCategory: IMatSelect[] = USER_CATEGORY;

  constructor(
    private toastr: AppToastrService,
    private profile: UsersService
  ) {}

  withdrawMoney(item: UserModel): void {
    let bank;
    if (item.bankAccounts && item.bankAccounts.length) {
      bank = item.bankAccounts.filter((b) => b.checkItem)[0];
    }
    const data: any = {
      bank: bank.bankId,
      slug: item.slug,
      withdraw: item.amountWithdraw.replace(',', '.'),
    };
    if (Number(data.withdraw) > item.revenues.left) {
      return this.toastr.showToastFromError({
        error: { message: 'Not enough money' },
      });
    }
    if (Number(data.withdraw) < 10) {
      return this.toastr.showToastFromError({
        error: { message: 'Amount should be more 10EUR' },
      });
    }
    if (item.mangoVerificationStatus !== 'succeeded') {
      return this.toastr.showToastFromError({
        error: { message: 'User has not KYC validation' },
      });
    }

    if (isNaN(data.withdraw)) {
      return this.toastr.showToastFromError({
        error: { message: 'Not number' },
      });
    }
    this.unCheckBankAccount(item);
    item.checkItem = false;
    this.profile.payoutMoney(data).subscribe(
      (resp) => {
        if (!resp || !resp.revenues) {
          return this.toastr.showToastFromError({
            error: { message: 'User has not KYC validation' },
          });
        }
        item.revenues.left = resp.revenues.left;
        this.toastr.showToast({ title: `SUCCESS` });
      },
      (err) => {
        this.toastr.showToastFromError(err);
      }
    );
  }

  onNotify(e) {
    if (e.type === 'find-user-statement-upload') {
      this.searchListResult = e.data;
    }
    if (e.type === 'select-category') {
      this.showError = null;
      this.searchListResult = [];
    }
  }

  uncheckUser(): void {
    this.searchListResult.forEach((item) => {
      item.checkItem = false;
      item.amountWithdraw = null;
      this.unCheckBankAccount(item);
    });
    this.bankSelected = false;
  }

  selectItem(e): void {
    let cStatus = e.checkItem;
    this.uncheckUser();
    e.checkItem = cStatus;
    this.showError = cStatus && !e.bankAccounts.length;
  }

  unCheckBankAccount(user): void {
    if (user.bankAccounts && user.bankAccounts.length) {
      user.bankAccounts.forEach((bank) => {
        bank.checkItem = false;
      });
    }
  }

  selectBank(e, user): void {
    let cStatus = e.checkItem;
    e.checkItem = cStatus;
    this.bankSelected = cStatus;
  }
}
