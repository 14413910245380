import {NgModule} from '@angular/core';
import {SearchBlockComponent} from './search-block/search-block.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {SelectorComponent} from "@core/component/global/selector/selector.component";
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule
  ],
  declarations: [
    SearchBlockComponent,
    SelectorComponent
  ],
  exports: [
    SearchBlockComponent,
    SelectorComponent
  ]
})

export class GlobalTemplateModule {
}
