export const TYPE_RIGHTS = {
  MASTER: {value: 1, name: 'Master rights'},
  PUBLISH: {value: 2, name: 'Publish rights'},
  MASTER_AND_PUBLISH: {value: 3, name: 'Master & Publish rights'},
};

export const TYPE_ARTIST = {
  ARTIST: {value: 1, name: 'Artist'},
  PRODUCER: {value: 2, name: 'Producer'},
}
