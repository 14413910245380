import {Observable, Subject} from "rxjs";
import {RequestModel} from "../../model/request.model";
import {UntypedFormGroup} from "@angular/forms";
import {RequestUserModel} from "@core/model";

export abstract class AbstractBaseFindService {
  abstract form: UntypedFormGroup;
  abstract type: string;
  abstract paramDisplay: string;
  abstract typeSelect?: string;
  abstract deleted?: boolean;
  abstract list$: Observable<any>;
  abstract getList(model: RequestModel | RequestUserModel): Observable<any>;
  abstract makeEmpty(): void;
  abstract selectItem(model: any): void;
}
