import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OpportunityModel, SongModel} from '../model';
import {ProjectModel} from '@core/model/project.model';
import {RequestModel} from '@core/model/request.model';

@Injectable()
export class OpportunityService {
  apiUrl: string;
  contentUrl: string;
  color: string;

  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }

  getSubmissions(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/songs`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          opportunities: resp.opportunity.map(item => new SongModel(this.contentUrl).setFromApiModel(item)),
          projects: resp.projects.map(item => new ProjectModel().setFromApiModel(item))
        };
      }
    }));
  }

  downloadBalanceFile(param): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/balance/csv`, {id: param}, {responseType: 'arraybuffer'}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  updateSong(param, song: SongModel): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/song/${song.id}`, param).pipe(map((resp: any) => {
      if (resp) {
        return new OpportunityModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  deleteSong(song: SongModel): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/song/${song.id}`).pipe(map((resp: any) => {
      return resp;
    }));
  }

  updateOpportunitySongs(param, song): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/opportunity/${song.id}`, param).pipe(map((resp: any) => {
      if (resp) {
        return new SongModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  publishOpportunity(param, song): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/opportunity/${song.id}`, param).pipe(map((resp: any) => {
      if (resp) {
        return new OpportunityModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  updateOpportunity(data, slug?: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/project/${slug}`, data).pipe(map((resp: any) => {
      if (resp) {
        return new OpportunityModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  uploadFile(file, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/upload-image-file?${this.serialize(params)}`, file);
  }

  changeStatusSubmissions(data, song): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/song/${song.id}/status`, data).pipe(map((resp: any) => {
      if (resp) {
        return new SongModel(this.contentUrl).setFromApiModel(resp);
      }
    }));
  }

  remindAuthor(song): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/opportunity/${song.id}/remind`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _changeUpcomingStatus(data, project): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/upcoming/${project.slug}/status`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  uploadFileAudio(file, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/upload-audio-file?${this.serialize(params)}`, file);
  }

  prepareAudioFile(event): any {
    const files = event.files;
    if (files.item(0)) {
      const file = files.item(0);
      const fileUpload = new FormData();
      fileUpload.append('file', file);
      const sizeBytes = 10 * 1024 * 1024;
      const extension = ['mp3'];
      if (file.size > sizeBytes) {
        return {error: 'File size should be less than 50MB'};
      }
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        return {error: 'Allowed mp3 formats only'};
      }
      return {fileUpload, file};
    }
  }

  prepareVideoFile(event): any {
    const files = event.files;
    if (files.item(0)) {
      const file = files.item(0);
      const fileUpload = new FormData();
      fileUpload.append('file', file);
      const sizeBytes = 200 * 1024 * 1024;
      const type = file.type;
      const extension = ['mp4', 'mov', 'webm', 'gif'];
      if (file.size > sizeBytes) {
        return {error: 'File size should be less than 200MB'};
      }

      if (['video/mp4', 'video/mov', 'image/gif', 'video/quicktime', 'video/webm'].indexOf(type) < 0) {
        return {error: 'Allowed mp4, mov, gif, quicktime  formats only'};
      }
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        return {error: 'Allowed mp4, gif, mov, webm formats only'};
      }


      return {fileUpload, file};
    }
  }

  private serialize(obj): any {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
