<div class="list">
  <table class="table table-striped table-bordered table-hover dataTable">
    <thead>
      <tr>
        <th></th>
        <th>Project Title</th>
        <th>Project's slug</th>
        <th>Balance</th>
        <th>Payout Mode</th>
        <th>Payout Type</th>
        <th>Payout Date</th>
        <th>Transfer Date</th>
        <th>Payout Status</th>
        <!-- <th>Is Collectible</th> -->
        <th class="table-center th-actions">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of list; let i=index">
        <td class="center">{{i + 1}}</td>
        <td class="center">{{item.projectTitle}}</td>
        <td class="center">{{item.slug}}</td>
        <td class="center">
          <span>{{item?.balance?.eur | currency: 'EUR'}}</span>&nbsp;/&nbsp;<span
            >{{item?.balance?.usd | currency: 'USD'}}</span
          >
        </td>
        <td class="center text-capitalize">{{item?.payoutMode}}</td>
        <td class="center text-capitalize">{{item?.typePayout}}</td>
        <td class="center text-capitalize">{{item?.datePayout | date}}</td>
        <td class="center text-capitalize">{{item?.dateTransfer | date}}</td>
        <td class="center list__status" [ngClass]="getStatusStyle(item)">
          <ng-container *ngIf="item.typePayout === 'instalments'">
            -
          </ng-container>
          <ng-container *ngIf="item.typePayout === 'fullPayment'">
            <ng-container
              *ngIf="item.isFunded && !item.isFundedWithDelay; else readyToTransfer"
            >
              Pending
            </ng-container>
            <ng-template #readyToTransfer=""> Ready </ng-template>
          </ng-container>
          <ng-container *ngIf="item.status === 'CREATED'">
            <i
              class="fa fa-exclamation-circle"
              matTooltip="Mangopay transfer is created, but not complete yet. Please check transfer status."
              matTooltipPosition="above"
            ></i>
          </ng-container>
        </td>
        <!-- <td class="center">{{item?.isCollectible}}</td> -->
        <td class="center">
          <button
            class="button green default-size button-find-tokens"
            *ngIf="item.payoutMode === 'manual'"
            [disabled]="item?.transferred || item.status === 'CREATED'"
            (click)="transferMoney(item)"
          >
            Transfer
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <app-pagination
    *ngIf="filter"
    [total]="filter.total"
    [paging]="filter.paging"
    (notify)="onPageChange($event)"
  >
  </app-pagination>
</div>
