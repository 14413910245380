<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="closePopup()"></span>
    <img src="./assets/images/bell.png" class="image">
    <div class="block-title title-global title-warning">Warning!</div>
    <div class="text">{{config?.text}}<br>
    </div>
    <ng-content></ng-content>
    <div class="flex flex-align button-wrapper" *ngIf="config?.btnCancel">
      <button class="button blue text-uppercase default-size" (click)="closePopup()">
        {{config?.titleBtnCancel}}
      </button>
      <button class="button red-btn text-uppercase default-size" *ngIf="config?.btnConfirm" (click)="onSubmit()">
        {{config?.titleBtnConfirm}}
      </button>
    </div>

  </div>

</app-popup>

