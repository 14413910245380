import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class StorageService {
  readonly accessTokenKey = 'accessTokenKey';
  readonly currentUserKey = 'currentUser';
  readonly refreshTokenKey = 'refreshToken';
  readonly userImage = 'userImage';
  private localItemSubject = new BehaviorSubject<any>(null);

  constructor() {
  }

  getStorageItem(key: string) {
    return localStorage.getItem(key);
  }

  setStorageItem(key: string, value: any) {
    return (value === null) ? this.removeLocalItem(key) : this.setLocalItem(key, value);
  }

  get refreshToken(): string {
    return this.getStorageItem(this.refreshTokenKey);
  }

  set refreshToken(value: string) {
    this.setStorageItem(this.refreshTokenKey, value);
  }

  get accessToken(): string {
    return this.getStorageItem(this.accessTokenKey);
  }

  set accessToken(value: string) {
    this.setStorageItem(this.accessTokenKey, value);
  }

  get user(): any {
    return JSON.parse(this.getStorageItem(this.currentUserKey));
  }

  set user(value: any) {
    this.setStorageItem(this.currentUserKey, JSON.stringify(value));
  }

  get image(): any {
    return JSON.parse(this.getStorageItem(this.userImage));
  }

  set image(value: any) {
    this.setStorageItem(this.userImage, JSON.stringify(value));
  }

  private removeLocalItem(key: string): void {
    localStorage.removeItem(key);
  }

  private setLocalItem(key: string, data: string): void {
    localStorage.setItem(key, data);
    const resp = {};
    resp[key] = data;
    this.localItemSubject.next(resp);
  }

  localItemChanged(): Observable<any> {
    return this.localItemSubject.asObservable();
  }
}
