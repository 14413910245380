<h2 class="content-name">Burn Token</h2>
<div class="content__item">
  <app-find-by-name-project
    [showSongs]="true"
    [placeholder]="'Project'"
    (notify)="selectProject.next($event.data)"
  ></app-find-by-name-project>
  <button class="button green default-size button-find-tokens"
          [disabled]="listTokens?.length || findTokenButton"
          (click)="findTokens()">Find Project's Tokens
  </button>
  <mat-spinner
    *ngIf="findTokenButton"
    [diameter]="20"
  ></mat-spinner>
  <ul *ngIf="listTokens">
    <li class="item-header">
      <div class="user-slug">User</div>
      <div class="tokens">Tokens</div>
      <div class="user-slug"></div>
    </li>
    <li *ngFor="let item of listTokens; let index = i" class="minted-item">
      <div class="user-slug">{{item?.slug}}</div>
      <div class="tokens">{{item?.amountTokens || 0}}</div>
      <div>
        <mat-checkbox class="example-margin"
                      [name]="index"
                      [id]="index"
                      [disabled]="item.inProgress"
                      [(ngModel)]="item.checked"
                      (change)="selectCheckbox(item)"
        >
        </mat-checkbox>
        <span class="notification" *ngIf="item.inProgress">In the process of removal</span>
      </div>
    </li>
  </ul>
  <mat-form-field class="mat-item" *ngIf="selectUser.getValue()?.checked">
    <mat-label>Tokens</mat-label>
    <input matInput
           [(ngModel)]="amountToBurn"
           type="text"
    >
  </mat-form-field>
  <div class="submit-block">
    <button class="button red-bg default-size button-find-tokens"
            *ngIf="selectUser.getValue()?.checked"
            [disabled]="!amountToBurn || disableBurnButton"
            (click)="burn()">Burn
    </button>
    <mat-spinner *ngIf="disableBurnButton" [diameter]="20"></mat-spinner>
  </div>
</div>
