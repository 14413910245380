import {Component, Output, Input, EventEmitter, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CustomFieldErrorMatcher} from '@core/common';
import {AppToastrService} from '@core/services';
import {CollectibleService} from '@core/services/collectible.service';

@Component({
  selector: 'app-popup-give-away',
  templateUrl: './popup-give-away.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupGiveAwayComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() giveAwayItem: any;
  user: any;
  dateStart: string;
  submitPressed: boolean;

  constructor(private toastr: AppToastrService, private collectibleService: CollectibleService
  ) {
  }

  ngOnInit(): any {
  }

  onCloseItem(): void {
    this.notify.emit({type: 'cancel'});
  }

  selectUser(e) {
    this.user = e.data;
  }

  errorMatcher(control: UntypedFormControl) {
    let error;
    let showError = this.submitPressed;
    return new CustomFieldErrorMatcher(control, showError, error);
  }

  onSubmit(): any {
    this.submitPressed = true;
    let data = {
      email: this.user.contact.email,
      uniqueId: this.giveAwayItem.uniqueId,
      walletId: this.user.nft.walletId
    };
    this.collectibleService.giveAwayEdition(data).subscribe(resp => {
      this.submitPressed = false;
      this.notify.emit({type: 'transferEdition'});
    });

  }
}
