import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {UploadService} from '@core/services';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {UploadConfigs} from '@core/interfaces/user.interface';

@Component({
  selector: 'app-read-more-section',
  templateUrl: './read-more-section.component.html',
  styleUrls: ['./read-more-section.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreSectionComponent implements OnInit, OnChanges {
  form: FormGroup;
  selectTypeNFTimage = 'png';
  imageReadMoreConfig: UploadConfigs = {
    imageFormat: 'png',
    ratio: 1 / 1,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP'],
  };
  editorConfig: AngularEditorConfig = {
    minHeight: '100px',
    editable: true,
    height: 'auto',
    maxHeight: 'auto',
    enableToolbar: true,
    showToolbar: true,
    sanitize: true,

    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'lineHeight-10%',
        class: 'lineHeight10',
        tag: 'p',
      },
      {
        name: 'lineHeight-20%',
        class: 'lineHeight20',
        tag: 'p',
      },
      {
        name: 'lineHeight-40%',
        class: 'lineHeight40',
        tag: 'p',
      },
      {
        name: 'lineHeight-60%',
        class: 'lineHeight60',
        tag: 'p',
      },
      {
        name: 'lineHeight-80%',
        class: 'lineHeight80',
        tag: 'p',
      },
      {
        name: 'lineHeight-100%',
        class: 'lineHeight100',
        tag: 'p',
      }
    ],
  };
  childForm: FormGroup;
  @Input() readMoreContent: any;
  constructor(
              private parentForm: FormGroupDirective, private uploadService: UploadService) {}

  ngOnInit(): void {
    this.childForm = this.parentForm.form;
    this.childForm.addControl('readMoreContent', new FormGroup({
      imgReadMore: new FormControl(''),
      title: new FormControl(''),
      text: new FormControl('')
    }))

    if(this.readMoreContent){
      this.formReadGroup.patchValue(this.readMoreContent);
    }
  }
  ngOnChanges(value) {
    if(value.readMoreContent && this.childForm){
      this.formReadGroup.patchValue(this.readMoreContent);
    }
  }
  get formReadGroup(){
    return this.childForm.get('readMoreContent') as FormGroup;
  }
}
