import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


// Interfaces
import { IPaginatedList } from '@core/interfaces/filter.interface';
import { BasePromocode, BasePromocodeDetail } from '@core/interfaces/promocode.interface';

import { RequestModel } from '@core/model/request.model';

@Injectable()
export class PromocodeService {
  apiUrl: string;

  constructor(private setting: SettingService, private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
  }

  getPromocodes(config: RequestModel): Observable<IPaginatedList<BasePromocode>> {
    const params = config ? config.getApiModel() : null;
    return this.http
      .get(`${this.apiUrl}/admin/promocode`, {params}) as Observable<IPaginatedList<BasePromocode>>;
  }

  getPromocodeDetails(word: string): Observable<BasePromocodeDetail[]> {
    return this.http
      .get(`${this.apiUrl}/admin/promocode/details/${word}`)
      .pipe(filter(Boolean)) as Observable<BasePromocodeDetail[]>;
  }

  addPromocode(code: BasePromocode<Date>): Observable<BasePromocode> {
    return this.http.post(
      `${this.apiUrl}/admin/promocode`,
      code
    ) as Observable<BasePromocode>;
  }

  updatePromocode(code: Partial<BasePromocode>): Observable<BasePromocode> {
    return this.http.put(
      `${this.apiUrl}/admin/promocode`,
      code
    ) as Observable<BasePromocode>;
  }
}
