import {HttpParams} from '@angular/common/http';

export class RequestTokenModel {
  projectId: string;

  constructor(init: any) {
    Object.assign(this, init);
  }

  getApiModel(): HttpParams {
    let result = new HttpParams();
    if (this.projectId) {
      result = result.set('projectId', this.projectId.toString());
    }
    return result;
  }

}
