import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, OpportunityService, UploadService} from '../../../services/index';
import {UntypedFormControl} from '@angular/forms';
import {IGIFimages} from '../../../interfaces/config.interface';
import {UploadConfigs} from '../../../interfaces/user.interface';

moment.locale('ru');

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.html'
})
export class UploadImageComponent implements OnInit {
  date: string;
  @Input() control: UntypedFormControl;
  @Input() value: any;
  @Input() config: UploadConfigs;
  @Input() maintainAspectRatio: boolean;
  @Input() maxWidth: string;
  @Input() imgGIFURL: string;
  @Input() settings: any;
  @Input() imgURL: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  calendarTitle: string;
  dates: any[];
  GIFsettings: IGIFimages;
  image: any;
  uploadedImage: any;
  fileValue: string;
  imageUploaded: boolean;
  activeTime = 0;

  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private opportunityService: OpportunityService,
              private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
    this.prepareSetting();
    if (this.config.imageFormat === 'gif') {
      this.GIFsettings.imgURL = this.imgURL;
      this.GIFsettings.imageCoverGIF = this.imgGIFURL;
    }
  }

  savePhoto(e) {
    this.uploadImage(e);
  }

  prepareSetting(): void {
    this.GIFsettings = {
      imgURL: '',
      image: '',
      imageCoverGIF: '',
      imageCoveringURL: ''
    };
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  uploadImage(event: any): any {
    const file = this.DataURIToBlob(event.data);
    const fileUpload = new FormData();
    fileUpload.append('file', file);

    this.image = null;
    this.imgURL = null;
    this.fileValue = event.value;
    const sizeBytes = 10 * 1024 * 1024;
    if (file.size > sizeBytes) {
      this.toastr.showErrorToast({title: 'File size should be less than 10MB'});
      this.fileValue = null;
      return false;
    }
    this.opportunityService.uploadFile(fileUpload, {
      type: this.config && this.config.path ? this.config.path : 'project-images',
      contentType: file.type,
      name: event.fileName.replace(event.fileName.toString().split('.')[1], this.config.saveFormat ? this.config.saveFormat : 'webp')
    }).subscribe(res => {
      this.image = res.url;
      if (this.control) {
        this.control.setValue(res.url);
      }
      this.value = res.url;
      this.valueChange.emit({value: this.value});
      this.imgURL = res.url;
      if (this.config.imageFormat === 'gif') {
        this.GIFsettings.imageCoverGIF = res.url;
        this.GIFsettings.imageCoveringURL = res.url;
        this.dateChange.emit(this.GIFsettings);
      }
      setTimeout(() => {
        this.toastr.showToast({
          title: 'Image has been uploaded'
        });
        this.imageUploaded = true;
      }, 1000);
    }, (err) => {
      this.toastr.showToastFromError(err);
    });
  }

  saveGIFcover(event: any) {
    this.prepareSetting();
    this.onFileChange(event.data, this.config.imageFormat === 'gif');
  }

  onFileChange(event: any, config?) {
    const files = event.target.files;
    this.uploadedImage = event;
    if (files.item(0)) {
      const file = files.item(0);
      const fileUpload = new FormData();
      fileUpload.append('file', file);
      const sizeBytes = 15 * 1024 * 1024;
      const extension = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'gif', 'GIF'];
      if (file.size > sizeBytes) {
        this.toastr.showErrorToast({title: 'File size should be less than 15MB'});
        this.fileValue = null;
        return false;
      }
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        this.toastr.showErrorToast({title: 'Allowed png and jpg formats only'});
        this.fileValue = null;
        return false;
      }
      this.opportunityService.uploadFile(fileUpload, {
        type: this.config && this.config.path ? this.config.path : 'project-images',
        contentType: file.type,
        name: file.name.replace(/ /g, '-').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
      }).subscribe(res => {

        this.image = res.url;
        if (config) {
          this.GIFsettings.image = res.url;
          this.GIFsettings.imgURL = res.url;
        }
      }, (err) => {
        this.toastr.showToastFromError(err);
      });
    }
  }

  testCl(): void {
    this.fileValue = '';
    this.imgURL = null;
    this.image = null;
    this.value = '';
    this.prepareSetting();
  }
}
