<div class="wallet" [formGroup]="form" *ngIf="showBlock">
  <div class="wrapper">
    <app-form-select
      [array]="walletType"
      [selectParam]="'title'"
      [displayParam]="'name'"
      [formControlItem]="form?.get('walletType')">
      Payment type
    </app-form-select>
    <app-form-input
      [formControlItem]="form?.get('amount')"
    >Amount</app-form-input
    >
  <app-form-select
    [array]="types"
    [selectParam]="'title'"
    [displayParam]="'name'"
    [formControlItem]="form?.get('cardType')">
    Payment type
  </app-form-select>
    <app-form-select
      [array]="currencies"
      [selectParam]="'title'"
      [displayParam]="'name'"
      [formControlItem]="form?.get('currency')">
      Currency
    </app-form-select>
  </div>
  <button
    class="button blue-bgc default-size payIn"
    [disabled]="!form?.valid"
    (click)="createPayIn()"
  >
    Create PayIn
  </button>
</div>
<div>
  <button *ngIf="!showBlock"  class="button blue-bgc default-size payIn" (click)="showBlock = true">
    Deposit amount
  </button>
</div>
