<div class="wrapper">
<mat-spinner *ngIf="loadFile" [diameter]="20"></mat-spinner>
<div class="file">
<label class="upload-button button"  [class.disabledUpload] = "loadFile || disabledParam">
  <input (change)="loadReader($event.target)" type="file"
         class="desktop-upload"
         [disabled]="loadFile || disabledParam"
         maxlength="30"
         name="filefront"/>
  <span>{{buttonName}}</span>
</label>
</div>
</div>
