import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AbstractBaseFilterService} from "@core/component/filter/abstract-base-filter.service";
import {FilterReleaseService} from "@core/component/filter/filter-release.service";
import {Filter, FilterConfig} from "@core/common/interfaces";


@Component({
  selector: 'app-filter-range-date',
  templateUrl: './filter-range-date.html',
  styleUrls: ['./_filter-range-date.scss'],
  providers: [
    {
      provide: AbstractBaseFilterService,
      useClass: FilterReleaseService
    }
  ],
})
export class FilterRangeDateComponent {
  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc'
    },
    state: null,
    country: null,
    featured: null,
    name: null,
    artist: null,
    createdFrom: null,
    createdTo: null,
    updatedFrom: null,
    updatedTo: null,
    email: null,
  };
  range: UntypedFormGroup;
  @Input() config: FilterConfig;
  @Output() searchByFilter: EventEmitter<any> = new EventEmitter<any>();
  constructor(public searchService: AbstractBaseFilterService
  ) {
    this.searchService.filterObj = this.filter;
    this.searchService.$observableSearchParam.subscribe(resp => {
      this.searchByFilter.emit(resp);
    });
  }
}

