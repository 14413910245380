<div class="item-view-container" *ngIf="isLoaded">
  <div class="m20-bottom m20-top heading">
    Editing {{ editingForm.get("name").value }}
  </div>
  <mat-divider></mat-divider>
  <div class="item-view-container_block" >
    <form [formGroup]="editingForm" class="editing-form">
      <div class="heading">Overall</div>
      <mat-form-field class="mat-form-field" appearance="fill">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let item of state" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div formGroupName="note">
        <app-form-input
          [formControlItem]="editingForm?.get('note')?.get('internal')"
          >Note (internal)</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('note')?.get('external')"
          >Note (external)</app-form-input
        >
      </div>

      <button
        class="button blue default-size button__change-state"
        (click)="changeState()"
      >
        Change state
      </button>

      <mat-checkbox
        formControlName="isProducer"
        [checked]="editingForm.get('isProducer').value"
        >Is producer
      </mat-checkbox>

      <mat-checkbox
        class="m20-top"
        formControlName="globalRevenueView"
        [checked]="editingForm.get('globalRevenueView').value"
        >Global View
      </mat-checkbox>

      <mat-checkbox
        class="m20-top"
        formControlName="featured"
        [checked]="editingForm.get('featured').value"
        >Featured
      </mat-checkbox>

      <mat-divider></mat-divider>

      <div class="m20-top">
        <app-form-input
          [formControlItem]="editingForm?.get('id')"
          [isReadOnly]="true"
        >
          ID
        </app-form-input>

        <app-form-input
          [formControlItem]="editingForm?.get('slug')"
          [isReadOnly]="true"
        >
          Slug
        </app-form-input>
      </div>
      <div class="heading">Personal</div>

      <app-form-input [formControlItem]="editingForm?.get('name')"
        >Artist name</app-form-input
      >

      <div formGroupName="contact">
        <app-form-input
          [formControlItem]="editingForm?.get('contact')?.get('firstName')"
          >First name</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('contact')?.get('lastName')"
          >Last name</app-form-input
        >
      </div>

      <app-form-input [formControlItem]="editingForm?.get('message')"
        >Message</app-form-input
      >

      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Biography</mat-label>
        <textarea matInput formControlName="bio" rows="7"></textarea>
      </mat-form-field>

      <div formGroupName="contact">
        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option *ngFor="let item of gender" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Birthdate</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [formControl]="birthDateCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <app-form-input
          [formControlItem]="editingForm?.get('contact')?.get('email')"
          >Email</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('contact')?.get('telephone')"
          >Telephone</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('contact')?.get('city')"
          >City</app-form-input
        >
        <app-form-input [formControlItem]="editingForm?.get('postalCode')"
          >Postal code</app-form-input
        >
        <mat-form-field
          class="mat-form-field form-field"
          appearance="fill"
          *ngIf="regions && regions[artistDetail?.country]"
        >
          <mat-label>Region</mat-label>
          <mat-select formControlName="region" id="region">
            <mat-option
              *ngFor="let region of regions[artistDetail?.country]"
              [value]="region.id"
              >{{ region.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let item of listOfCountries" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-form-input [formControlItem]="editingForm?.get('billingAddress')"
        >billingAddress</app-form-input
      >
    </form>
  </div>

  <div class="item-view-container_block">
    <form [formGroup]="editingForm" class="editing-form">
      <app-upload-image-wrapper [config]="imageConfig">
        <app-profile-image [config]="imageConfig"
                           [data] = "artistDetail"
        ></app-profile-image>
      </app-upload-image-wrapper>

      <div class="heading">Social</div>

      <ng-container formGroupName="social">
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('facebook')"
          >Facebook</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('twitter')"
          >Twitter</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('youtube')"
          >Youtube</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('spotify')"
          >Spotify</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('instagram')"
          >Instagram</app-form-input
        >
        <app-form-input
          [formControlItem]="editingForm?.get('social').get('tiktok')"
          >Tiktok</app-form-input
        >
      </ng-container>

      <div class="heading">Other</div>

      <app-form-input [formControlItem]="editingForm?.get('state')"
        >Credits</app-form-input
      >

      <div formGroupName="counters">
        <app-form-input
          [formControlItem]="editingForm?.get('counters').get('totalEarned')"
          >Credits earned
        </app-form-input>
        <app-form-input
          [formControlItem]="editingForm?.get('counters').get('totalSpent')"
          >Credits spent
        </app-form-input>
      </div>

      <app-form-input [formControlItem]="editingForm?.get('vatNumber')"
        >VAT number</app-form-input
      >
      <app-form-input
        [formControlItem]="editingForm?.get('createdAt')"
        [isReadOnly]="true"
        >Created at</app-form-input
      >
      <app-form-input
        [formControlItem]="editingForm?.get('updatedAt')"
        [isReadOnly]="true"
        >Updated at</app-form-input
      >

      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Genres</mat-label>

        <mat-select formControlName="genresPlay" multiple>
          <mat-option *ngFor="let item of genres" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-add-multi-input
        *ngIf="artistDetail"
        [param]="artistDetail?.video || []"
      >
      </app-add-multi-input>
    </form>
  </div>
  <div class="flex flex-end m20-bottom button-wrapper">
    <button
      class="button green default-size"
      (click)="saveChanges()"
      [disabled]="editingForm.invalid"
    >
      <span>Save artist</span>
    </button>
  </div>
</div>
<app-popup-confirmation
  [config]="popupConfig"
  *ngIf="showPopupConfirmation"
  (notify)="onNotify($event)"
></app-popup-confirmation>
