<div class="search-block_item">
  <div class="search-block_item_header flex flex-between">
    <span class="bold-style">Search</span>
    <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
      <mat-label>Select category</mat-label>
      <mat-select [(ngModel)]="searchService.selectedSearchCategory" name="food" (selectionChange)="selectCategory()">
        <mat-option *ngFor="let cat of config.searchOptions" [value]="cat">
          {{cat}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search-block_item_container">
    <mat-form-field *ngIf="searchService.selectedSearchCategory" class="mat-form-field form-field" appearance="fill">
      <input matInput placeholder="Enter request" class="p10-y" [(ngModel)]="searchService.searchCategoryValue"
             (keyup.enter)="searchService.searchByCategory()">
    </mat-form-field>
    <ng-container *ngIf="searchService.selectedSearchCategory">
      <button mat-button (click)="searchService.searchByCategory()"><i class="fas fa-search"></i></button>
      <button mat-button (click)="searchService.resetSearchFiler()"><i class="fas fa-refresh"></i></button>
    </ng-container>
  </div>
</div>
