import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';

@Component({
  selector: 'app-card-component',
  templateUrl: './card.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  settings: {};
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() form: any;
  @Input() index: number;
  constructor() {}
}

