import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {catchError, filter, map, tap} from 'rxjs/operators';

// Interfaces
import {TransferToken} from '@core/interfaces/token.interface';

// Services
import {ToastrService} from 'ngx-toastr';
import {TokenService} from '@core/services/token.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'transfer-tokens',
  templateUrl: './transfer-tokens.component.html',
  styleUrls: ['./transfer-tokens.component.scss'],
})
export class TransferTokensComponent implements OnInit {
  list: any = null;
  owner: FormControl = new FormControl('');
  receiver: FormControl = new FormControl('');
  songControl: FormControl = new FormControl('');
  shareControl: FormControl = new FormControl('', {
    validators: Validators.min(0.1),
  });

  constructor(
    private toastr: ToastrService,
    private tokenService: TokenService
  ) {
  }

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this.owner.valueChanges
      .pipe(filter(Boolean), tap(console.log))
      .subscribe((slug: string) => {
        this.getTokens(slug);
      });

    this.songControl.valueChanges.pipe(filter(Boolean)).subscribe((value: any) => {
      this.shareControl.setValidators([
        Validators.min(0.1),
        Validators.max(value.shares),
      ]);
    });
  }

  private getTokens(slug: string): void {
    this.tokenService.getUserTokens(slug).pipe(
      map(resp => {
        return resp.list.filter(item => {
            return item.song.tokenGenerated || (item.isAlbum && item.songs.some(song => song.tokenGenerated))
          });
      })
    ).subscribe(
      ((list: any) => {
        this.list = list;
        if (!list.length) {
          this.showEmptyToast();
        }
      }),
      (err: HttpErrorResponse) => {
        this.showErrorToast(err);
      }
    );
  }

  private showEmptyToast(): void {
    this.toastr.error('No tokens to transfer', '', {
      positionClass: 'toast-bottom-right',
    });
  }

  private showErrorToast(err: HttpErrorResponse): void {
    this.toastr.error(err?.error?.message || 'Failed', '', {
      positionClass: 'toast-bottom-right',
    });
  }

  private showSuccessToast(): void {
    this.toastr.success('Request successful', '', {
      positionClass: 'toast-bottom-right',
    });
  }

  private reset() {
    this.list = null;
    [this.owner, this.receiver, this.songControl, this.shareControl].forEach(
      (control: FormControl) => {
        control.reset();
      }
    );
  }

  private isValid(): boolean {
    return (
      this.list?.length &&
      this.owner.value &&
      this.receiver.value &&
      this.songControl.value &&
      this.shareControl.valid
    );
  }

  transfer(): void {
    if (this.isValid()) {
      const data: TransferToken = {
        project: this.songControl.value.nft.id.toString(),
        share: this.shareControl.value,
        owner: this.owner.value,
        receiver: this.receiver.value,
      };

      this.tokenService.transferTokens(data).subscribe(
        () => {
          this.showSuccessToast();
          this.reset();
        },
        (err: HttpErrorResponse) => {
          this.showErrorToast(err);
          this.reset();
        }
      );
    } else {
      this.toastr.error('Please fill all fields', '', {
        positionClass: 'toast-bottom-right',
      });
    }
  }
}
