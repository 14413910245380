import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Consts
import {STATE_NAMES} from './common';

// Guards
import {canMatchPermission} from './core/guards/permission.guard';

// Components
import {LoginPageComponent} from '@core/pages';
import {LogoutGuard} from "@core/core/guards/logout.guard";

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },


  {
    path: STATE_NAMES.artistsList,
    canMatch: [() => canMatchPermission(STATE_NAMES.artistsList)],
    loadChildren: () =>
      import('./pages/artists/artists.module').then((m) => m.ArtistsModule),
  },
  {
    path: STATE_NAMES.opportunityGrs,
    canMatch: [() => canMatchPermission(STATE_NAMES.opportunityGrs)],
    loadChildren: () =>
      import('./pages/opportunity-grs/opportunity-grs.module').then((m) => m.OpportunityGrsModule),
  },
  {
    path: STATE_NAMES.investorsList,
    canMatch: [() => canMatchPermission(STATE_NAMES.investorsList)],
    loadChildren: () =>
      import('./pages/investors/investor.module').then((m) => m.InvestorModule),
  },
  {
    path: STATE_NAMES.albums,
    canMatch: [() => canMatchPermission(STATE_NAMES.albums)],
    loadChildren: () =>
      import('./pages/albums/albums.module').then((m) => m.AlbumsModule),
  },

  {
    path: STATE_NAMES.opportunityLinked,
    canMatch: [() => canMatchPermission(STATE_NAMES.opportunityLinked)],
    loadChildren: () =>
      import('./pages/opportunity-linked/opportunity-linked.module').then(
        (m) => m.OpportunityLinkedModule
      ),
  },
  {
    path: STATE_NAMES.opportunityBundle,
    canMatch: [() => canMatchPermission(STATE_NAMES.opportunityBundle)],
    loadChildren: () =>
      import('./pages/opportunity-bundle/opportunity-bundle.module').then(
        (m) => m.OpportunityBundleModule
      ),
  },
  {
    path: STATE_NAMES.collectible,
    canMatch: [() => canMatchPermission(STATE_NAMES.collectible)],
    loadChildren: () =>
      import('./pages/collectibles/collectibles.module').then(
        (m) => m.CollectiblesModule
      ),
  },
  {
    path: STATE_NAMES.opportunity,
    canMatch: [() => canMatchPermission(STATE_NAMES.opportunity)],
    loadChildren: () =>
      import('./pages/opportunity/opportunity.module').then(
        (m) => m.OpportunityModule
      ),
  },
  {
    path: STATE_NAMES.producersList,
    canMatch: [() => canMatchPermission(STATE_NAMES.producersList)],
    loadChildren: () =>
      import('./pages/producers/producers.module').then(
        (m) => m.ProducersModule
      ),
  },
  {
    path: STATE_NAMES.fans,
    canMatch: [() => canMatchPermission(STATE_NAMES.fans)],
    loadChildren: () =>
      import('./pages/fans/fans.module').then((m) => m.FansModule),
  },
  {
    path: STATE_NAMES.releases,
    canMatch: [() => canMatchPermission(STATE_NAMES.releases)],
    loadChildren: () =>
      import('./pages/releases/releases.module').then((m) => m.ReleasesModule),
  },
  {
    path: STATE_NAMES.news,
    canMatch: [() => canMatchPermission(STATE_NAMES.news)],
    loadChildren: () =>
      import('./pages/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: STATE_NAMES.balances,
    canMatch: [() => canMatchPermission(STATE_NAMES.balances)],
    loadChildren: () =>
      import('./pages/balances/balances.module').then((m) => m.BalancesModule),
  },
  {
    path: STATE_NAMES.auctionMarket,
    canMatch: [() => canMatchPermission(STATE_NAMES.auctionMarket)],
    loadChildren: () =>
      import('./pages/auction-market/auction-market.module').then(
        (m) => m.AuctionMarketModule
      ),
  },
  {
    path: STATE_NAMES.submissions,
    canMatch: [() => canMatchPermission(STATE_NAMES.submissions)],
    loadChildren: () =>
      import('./pages/submissions/submissions.module').then(
        (m) => m.SubmissionsModule
      ),
  },
  {
    path: STATE_NAMES.voucher,
    canMatch: [() => canMatchPermission(STATE_NAMES.voucher)],
    loadChildren: () =>
      import('./pages/voucher/voucher.module').then(
        (m) => m.VoucherModule
      ),
  },
  {
    path: STATE_NAMES.promocode,
    canMatch: [() => canMatchPermission(STATE_NAMES.promocode)],
    loadChildren: () =>
      import('./pages/promocode/promocode.module').then(
        (m) => m.PromocodeModule
      ),
  },
  {
    path: STATE_NAMES.landing,
    canMatch: [() => canMatchPermission(STATE_NAMES.landing)],
    loadChildren: () =>
      import('./pages/landing/landing.module').then(
        (m) => m.LandingModule
      ),
  },
  {
    path: STATE_NAMES.settings,
    canMatch: [() => canMatchPermission(STATE_NAMES.settings)],
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: STATE_NAMES.accounting,
    canMatch: [() => canMatchPermission(STATE_NAMES.accounting)],
    loadChildren: () =>
      import('./pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: STATE_NAMES.roles,
    canMatch: [() => canMatchPermission(STATE_NAMES.roles)],
    loadChildren: () =>
      import('./pages/roles/roles.module').then(
        (m) => m.RolesModule
      ),
  },
  {
    path: STATE_NAMES.coLabel,
    canMatch: [() => canMatchPermission(STATE_NAMES.coLabel)],
    loadChildren: () =>
      import('./pages/co-label/co-label.module').then(
        (m) => m.CoLabelModule
      ),
  },
  {
    path: 'logout',
    component: LoginPageComponent,
    canActivate: [LogoutGuard]
  },
  //
 // {path: '', component: DashboardComponent, pathMatch: 'full'},
  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
