import * as moment from 'moment';


export class PledgesInvestorModel {
  constructor() {
  }

  billing: any;
  createdAt: moment.Moment;
  date: string;
  _id: string;
  amount: number;
  slug: string;
  share: any;
  saleAmountPaid: number;
  isRefunding: boolean;
  owner: any;
  project: any;
  refundStatus: string;
  transferredToProject: boolean;

  setFromApiModel(apiModel): this {
    this._id = apiModel._id;
    this.billing = apiModel.billing;
    this.owner = apiModel.owner;
    this.saleAmountPaid = apiModel.saleAmountPaid;
    this.isRefunding = apiModel.isRefunding;
    this.createdAt = moment(apiModel.createdAt);
    this.project = apiModel.project;
    this.amount = apiModel.amount;
    this.transferredToProject = apiModel.transferredToProject;
    this.share = apiModel.share;
    this.refundStatus = apiModel.refundStatus;
    return this;
  }
}

