import {IPermissions, IRoles} from '../interfaces/roles.interface';
import {IColabel} from '@core/interfaces/colabel.interface';

export class LabelModel implements IColabel {
  constructor() {
  }

  name: string;
  country: string;
  domain: string;
  slug: string;
  isEditMode?: boolean;

  setFromApiModel(apiModel): this {
    this.name = apiModel.name;
    this.slug = apiModel.slug;
    this.country = apiModel.country;
    this.domain = apiModel.domain;
    return this;
  }
}

