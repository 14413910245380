import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.html',
  styleUrls: ['./form-input.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent {
  formControl: UntypedFormControl;

  @Input() isReadOnly: boolean;
  @Input() id: string = 'valueId';

  @Input() type: 'text' | 'number' = 'text';

  @Input() set formControlItem(value) {
    this.formControl = value;
  }
}
