import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';
import { StorageService } from '@core/services/storage.service';
import { RolePermission } from '@core/model/role.model';
import { PERMISSION } from '@core/common';
import {AuthenticationService} from "@core/services/authentication.service";

@Injectable({providedIn:'root'})
export class PermissionService {
  apiUrl: string;
  user: any;

  constructor(
    private setting: SettingService,
    private auth: AuthenticationService,
    private storageService: StorageService
  ) {
    this.apiUrl = this.setting.apiUrl;
  }

  getPagePermissions(key: string): boolean {
    this.user = this.storageService.user;

    if(!this.storageService.user){
      return false;
    }

    let permValue = PERMISSION[key];
    if (Array.isArray(permValue)) {
      let filteredArray = this.user.permission.filter((n) => {
        return permValue.indexOf(n) !== -1;
      });
      if (filteredArray.length) {
        return true;
      }
    }

    return this.user.permission.indexOf(permValue) >= 0;
  }

  isAdmin(key: string): boolean {
    let user = this.storageService.user;
    return user.permission.indexOf(key) >= 0;
  }

  getPermissions(): string[] {
    this.user = this.storageService.user;

    if (!this.user) {
      return [];
    }

    return this.user.permission || [];
    // let permission = this.user.permission ? new RolePermission().setModel(this.user.permission) : null;
  }
}
