import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LandingLogicService} from "@core/services/landing_logic.service";
import {filter, takeUntil} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {angularEditorParams} from '@core/common/angular-editor-config';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-slider-item-component',
  templateUrl: './slider-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderItemComponent implements OnInit, OnDestroy {
  positionValue: Subscription;
  sliderPosition: Subscription;
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() form: any;
  @Input() index: number;
  private destroy$ = new Subject();
  constructor(private landingLogic: LandingLogicService) {
  }

  ngOnInit() {
    let initValue = this.index + 1;
    if (this.form && this.form.get('position')) {
      this.positionValue = this.form.get('position').valueChanges
        .pipe(filter((item) => +item > 0))
        .subscribe(number => {
          this.landingLogic.setPosition({newValue: +number, oldValue: +initValue, index: this.index + 1});
        })

      this.sliderPosition = this.landingLogic.sliderPositionObservable
        .pipe(filter((item) => !!item), takeUntil(this.destroy$))
        .subscribe(number => {

          const {newValue, oldValue, index} = number;
          let value = this.form.get('position').value;
          initValue = oldValue;
          if (newValue && newValue == value && index !== (this.index + 1)) {
            this.form.get('position').setValue(+oldValue, {emitEvent: false});
            this.landingLogic.setPosition({});
          }
        })
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.positionValue) {
      this.positionValue.unsubscribe();
    }
  }
}

