<app-popup >
  <div class="inner" id="collectible">
    <span class="lnr lnr-cross default-cross" (click)="onCloseItem()"></span>
    <div class="title">Select edition</div>
    <div class="content">
      Editions: {{collectible?.edition}}
      <mat-form-field class="item-field item-field_align">
        <mat-label>Starting price*</mat-label>
        <input matInput
               id="amountCtrl"
               [formControl]="amountCtrl"
               type="text" autocomplete="off"
        >
      </mat-form-field>
      <div class="date">
        <p class="auction__param">Start auction:</p>
        <div class="date-end">
          <mat-form-field appearance="fill" class="item calendar" >
            <mat-label>Date*</mat-label>
            <input matInput [matDatepicker]="dp" [(ngModel)]="dateStart">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker  #dp
                             startView="multi-year"
                             #picker></mat-datepicker>
          </mat-form-field>
          <div class="date-time">
            <mat-form-field id="item-hour" class="item-field  item-field_align">
              <mat-label>Hour*</mat-label>
              <mat-error>Not valid hours Should be(0-24)</mat-error>
              <input matInput
                     id="amountCtrl"
                     [formControl]="startHourCtrl"
                     [errorStateMatcher]="errorMatcher(startHourCtrl)"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
            <p class="date-points">:</p>
            <mat-form-field id="item-hour" class="item-field item-hour item-field_align">
              <mat-label>Minute*</mat-label>
              <mat-error>Not valid hours. Should be(0-59)</mat-error>
              <input matInput
                     id="amountCtrl"
                     [errorStateMatcher]="errorMatcher(startMinCtrl)"
                     [formControl]="startMinCtrl"
                     type="text" autocomplete="off"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="date">
        <p class="auction__param">End auction:</p>
        <div class="date-end">
        <mat-form-field appearance="fill" class="item calendar" >
          <mat-label>Date*</mat-label>
          <input matInput [matDatepicker]="picker2" [(ngModel)]="date">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker  #picker2
                           startView="multi-year"
                           #picker></mat-datepicker>
        </mat-form-field>
        <div class="date-time">
        <mat-form-field id="item-hour" class="item-field  item-field_align">
          <mat-label>Hour*</mat-label>
          <mat-error>Not valid hours. Should be(0-24)</mat-error>
          <input matInput
                 id="amountCtrl"
                 [errorStateMatcher]="errorMatcher(endHourCtrl)"
                 [formControl]="endHourCtrl"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
          <p class="date-points">:</p>
        <mat-form-field id="item-hour" class="item-field item-hour item-field_align">
          <mat-label>Minute*</mat-label>
          <mat-error>Not valid minutes. Should be(0-59)</mat-error>
          <input matInput
                 id="amountCtrl"
                 [errorStateMatcher]="errorMatcher(endMinCtrl)"
                 [formControl]="endMinCtrl"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
        </div>
        </div>
      </div>
      <div class="flex flex-align button-wrapper">
        <button class="button blue text-uppercase wide-size" (click)="onCloseItem()">
          Cancel
        </button>
        <button class="button green text-uppercase start-auction__button"
                (click)="onSubmit()">
          Start auction
        </button>
      </div>

    </div>
  </div>

</app-popup>

