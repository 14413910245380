import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
  CustomFieldErrorMatcher,
  SOCIAL_VALIDATION,
} from '../../../common/index';
import {ConfirmPopup} from '@core/common/interfaces';
import {AppToastrService, OpportunityService} from '@core/services';
import {SongModel} from '@core/model';

interface SocialItems {
  [key: string]: string;
}

@Component({
  selector: 'app-submitted-song-popup',
  templateUrl: './submitted-song-popup.html',
  styleUrls: ['./_submitted-song-popup.scss']
})

export class SubmittedSongPopupComponent implements OnInit {
  @Input() advance: any;
  @Output() visibleChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() submittedSong: SongModel;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  listSocials: SocialItems = {};
  deleteConfig: ConfirmPopup;
  form: UntypedFormGroup;
  commentCtrl: UntypedFormControl;
  prevValue = {};
  social = JSON.parse(JSON.stringify(SOCIAL_VALIDATION));

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private opportunityService: OpportunityService
  ) {
  }

  ngOnInit(): void {
    this.prepareForm();
  }

  close(): any {
    this.visibleChange.emit({type: 'close'});
  }

  prepareForm(): void {
    this.commentCtrl = this.fb.control('');
    this.form = this.fb.group({
      comment: this.commentCtrl,
    });
    this.setForm();
  }

  checkStar(item): void {
    this.submittedSong.rating = item.toString();
  }

  setForm(): void {
    this.commentCtrl.setValue(this.submittedSong && this.submittedSong.comment ? this.submittedSong.comment : null);
  }

  saveSong(): any {
    if (this.form.valid) {
      const data = {
        rating: this.submittedSong.rating || '0',
        comment: this.commentCtrl.value || '',
        social: {
          instagram: this.listSocials['instagram'],
          tiktok: this.listSocials['tiktok'],
          youtube: this.listSocials['youtube'],
          spotify: this.listSocials['spotify'],
          soundcloud: this.listSocials['soundcloud'],
          dropbox: this.listSocials['dropbox']
        }
      };
      this.opportunityService.updateSong(data, this.submittedSong).subscribe(resp => {
        if (resp) {
          this.close();
          this.toastr.showToast({title: 'Song was saved'});
        }
      }, err => {
        this.toastr.showToastFromError(err);
      });
    }
  }

  onNotify(e) {
    if (e.type === 'delete') {
      this._deleteSong();
    }
    if (e.type === 'close') {
      this.deleteConfig = null;
    }
  }

  _deleteSong(): void {
    this.opportunityService.deleteSong(this.submittedSong).subscribe(resp => {
      this.close();
      this.deleteConfig = null;
      this.toastr.showToast({title: 'Song was deleted'});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  deleteSong(): void {
    this.deleteConfig = {
      btnConfirm: true,
      titleBtnConfirm: 'Delete',
      btnCancel: true,
      submitEmit: 'delete',
      titleBtnCancel: 'Cancel',
      text: 'Are you sure you want to delete song?'
    };
  }

  saveSocial(ctrl: UntypedFormControl, type: string): void {
    if (ctrl.valid) {
      this.social[type].edit = !this.social[type].edit;
      this.prevValue[type] = ctrl.value;
    }
  }

  errorMatcher(control: UntypedFormControl) {
    return new CustomFieldErrorMatcher(control, true);
  }

  editSocial(item: any, ctrl?: UntypedFormControl): void {
    if (this.social[item].edit) {
      if (ctrl.value) {
        ctrl.setValue(this.prevValue[item]);
      } else {
        ctrl.reset();
      }
      if (ctrl.valid) {
        this.social[item].edit = false;
      }
    } else {
      this.social[item].edit = true;
      this.prevValue[item] = ctrl.value;
    }
  }

  changeStatus(item): any {
    if (this.form.valid) {
      const data = {
        rating: this.submittedSong.rating,
        comment: this.commentCtrl.value || '',
        social: {
          instagram: this.listSocials['instagram'],
          tiktok: this.listSocials['tiktok'],
          youtube: this.listSocials['youtube'],
          spotify: this.listSocials['spotify'],
          soundcloud: this.listSocials['soundcloud'],
          dropbox: this.listSocials['dropbox']
        },
        status: item
      };

      this.opportunityService.changeStatusSubmissions(data, this.submittedSong).subscribe(resp => {
        if (resp) {
          this.close();
        }
      }, err => {
        this.toastr.showToastFromError(err);
      });
    }
  }
}
