import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';

import { config } from '@core/common/websocket-config';

// Interfaces
import { WebSocketConfig } from '@core/interfaces/websocket.interface';

// Services
import { WebsocketService } from '@core/services/websocket.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [WebsocketService],
})
export class WebsocketModule {
  public static config(
    wsConfig: WebSocketConfig
  ): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [
        { provide: config, useValue: wsConfig },
      ],
    };
  }
}
