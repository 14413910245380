import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';

@Component({
  selector: 'app-landing-multi-lang-text',
  templateUrl: './landing-multi-lang-text.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingMultiLangTextComponent implements OnInit {
  form: UntypedFormGroup;
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() text: any;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): any {
    this.form = this.fb.group({
      en: new UntypedFormGroup({
        isEnabled: new UntypedFormControl(false),
        targetBlank: new UntypedFormControl(false),
        description: new UntypedFormControl(''),
        title: new UntypedFormControl(''),
        descriptionAdditional: new UntypedFormControl(''),
        button: new UntypedFormControl(''),
        url: new UntypedFormControl(''),
      }),
      de: new UntypedFormGroup({
        isEnabled: new UntypedFormControl(false),
        targetBlank: new UntypedFormControl(false),
        description: new UntypedFormControl(''),
        title: new UntypedFormControl(''),
        descriptionAdditional: new UntypedFormControl(''),
        button: new UntypedFormControl(''),
        url: new UntypedFormControl(''),
      }),
    });
    if (this.text) {
      this.form.patchValue(this.text);
    }
  }
}

