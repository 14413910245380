import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, UploadService} from '../../../services/index';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

moment.locale('ru');

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: UploadDocumentComponent,
    multi: true
  }],
})
export class UploadDocumentComponent implements ControlValueAccessor {
//  fileValue: string;
  loadFile: boolean;
  value: any;
  onChange: (value: any) => void;
  @Input() path: string;
  @Input() buttonTitle: string = 'Upload Document';

  constructor(private uploadService: UploadService,
              private toastr: AppToastrService) {

  }

  writeValue(obj): void {
    this.value = obj;

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {

  }

  setDisabledState(): void {

  }

  onChangeInput(e) {
    this.loadFile = true;
    const file = e.files[0];
    this.uploadService.uploadFilePartially(file,this.path).then(res => {
      let longUrl = this.uploadService.getLongUrlName(res.url);
      e.value = '';
      this.value = longUrl;
      this.loadFile = false;
      this.onChange(this.value);
    }, (err) => {
      this.loadFile = false;
      this.toastr.showToastFromError(err);
    });
  }
}
