import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

// Interfaces
import { PayoutSettings } from '@core/interfaces/payout.interface';

// Services
import { SettingService } from './setting.service';
import { AppToastrService } from '@core/services/toastr.service';

@Injectable({ providedIn: 'root' })
export class PayoutService {
  apiUrl: string;

  constructor(
    private setting: SettingService,
    private toastr: AppToastrService,
    private http: HttpClient
  ) {
    this.apiUrl = this.setting.apiUrl;
  }

  getPayout(projectSlug: string): Observable<PayoutSettings> {
    return this.http.get<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset/${projectSlug}`
    );
  }

  createPayout(form: FormGroup, projectId: string): Observable<PayoutSettings> {
    const data: PayoutSettings = this.formatData(form, projectId);

    return this.http.post<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset`,
      data
    );
  }

  updatePayout(
    form: FormGroup,
    projectId: string,
    projectSlug: string
  ): Observable<PayoutSettings> {
    const data: PayoutSettings = this.formatData(form, projectId);

    return this.http.put<PayoutSettings>(
      `${this.apiUrl}/admin/payoutset/${projectSlug}`,
      data
    );
  }

  private formatData(form: FormGroup, projectId: string): PayoutSettings {
    const rawValue: any = form.getRawValue();
    const data = {
      ...rawValue,
      project: projectId,
      owner: rawValue.owner?.id || null,
      payoutMode:
        rawValue.typePayout === 'instalments' ? 'auto' : rawValue.payoutMode,
    };
    return data;
  }
}
