<div class="dialog">
  <div class="dialog-wrapper">
    <div class="dialog-header">
      <h2 class="dialog-title">Submission details:</h2>
    </div>
    <div class="dialog-content">
      <div class="item">
        <div class="item-name">
          Artist
        </div>
        <div class="item-content">
          <div class="item-col">
            <div class="item-col-raw">
              <div class="raw-item-filed">
                Name:
              </div>
              <div class="raw-item-value">
                {{submittedSong?.artistName}}
              </div>
            </div>
            <div class="item-col-raw">
              <div class="raw-item-filed">
                Gender:
              </div>
              <div class="raw-item-value">
                {{submittedSong?.artistContact?.gender}}
              </div>
            </div>
            <div class="item-col-raw">
              <div class="raw-item-filed">
                Birthdate:
              </div>
              <div class="raw-item-value">
                {{submittedSong?.artistContact?.birthdate}}
              </div>
            </div>
          </div>
          <div class="item-col">
            <div class="item-col-raw">
              <div class="raw-item-filed">
                E-mail:
              </div>
              <div class="raw-item-value">
                {{submittedSong?.artistContact?.email}}
              </div>
            </div>
            <div class="item-col-raw">
              <div class="raw-item-filed">
                Address:
              </div>
              <div class="raw-item-value value">
                {{submittedSong?.artistContact?.address}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-detailing">
        <div class="item-name">
          Song
        </div>
        <div class="song-wrapper">
          <div class="item-song">Description: {{submittedSong?.description}}</div>

        </div>
        <div class="song-wrapper">
          <div class="item-song">MP3</div>
          <div class="item-song">
            <audio controls>
              <source [src]="submittedSong?.audio" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        <app-social-links-song [socialArtist]="submittedSong?.artistSocial"
                               [listSocials]="listSocials"
                               [socialSong]="submittedSong?.songLink"></app-social-links-song>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="footer-content">
      <div class="footer-wrapper">
        <div class="footer-item">Rating:</div>
        <div class="footer-item">
            <span class="star-cb-group-popup">
              <input type="radio" id="rating-5" name="rating" [ngModel]="submittedSong.rating" value="5"/>
              <label (click)="checkStar(5)"
                     for="rating-5">5</label>
              <input type="radio" id="rating-4" name="rating" [ngModel]="submittedSong.rating" value="4"/>
              <label (click)="checkStar(4)"
                     for="rating-4">4</label>
              <input type="radio" id="rating-3" name="rating" [ngModel]="submittedSong.rating" value="3"/>
              <label (click)="checkStar(3)"
                     for="rating-3">3</label>
              <input type="radio" id="rating-2" name="rating" [ngModel]="submittedSong.rating" value="2"/>
              <label (click)="checkStar(2)"
                     for="rating-2">2</label>
              <input type="radio" id="rating-1" name="rating" [ngModel]="submittedSong.rating" value="1"/>
              <label (click)="checkStar(1)"
                     for="rating-1">1</label>
              <input type="radio" id="rating-0" name="rating" [ngModel]="submittedSong.rating" value="0"
                     class="star-cb-clear"/>
              <label (click)="checkStar(0)"
                     for="rating-0">0</label>
            </span>
        </div>
      </div>
      <div class="item-comment">
        <mat-form-field class="mat-item input-text-item">
          <mat-label>Comment</mat-label>
          <input matInput [formControl]="commentCtrl">
        </mat-form-field>
      </div>
      <div class="button-section">
        <div class="button-wrapper">
          <button type="button" class="button green" [disabled]="!form.valid" (click)="changeStatus('accepted')"
          > Accept
          </button>
          <button type="button" class="button red" [disabled]="!form.valid" (click)="changeStatus('declined')"
          > Decline
          </button>
          <button type="button" class="button blue" [disabled]="!form.valid" (click)="saveSong()"
          > Save
          </button>
          <button type="button" class="button blue" [disabled]="!form.valid" (click)="changeStatus('postpone')"
          > Postpone
          </button>
          <button type="button" class="button red delete" (click)="deleteSong()"
          > <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-popup-confirmation *ngIf="deleteConfig?.btnConfirm"  (notify)="onNotify($event)" [config]="deleteConfig"></app-popup-confirmation>
<div class="overlay" (click)="close()"></div>
