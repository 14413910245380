import {Component, Output, Input, EventEmitter} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS} from "@core/common";

@Component({
  selector: 'app-popup-upload-revenue',
  templateUrl: './popup-upload-revenue.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PopupUploadRevenueComponent {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() revenue: any;
  date: any;

  onCloseItem(): void {
    this.notify.emit({type: 'cancelUpload'});
  }

  onSubmit(): void {
    // if (isReport) {
    //   this.notify.emit({type: 'submitReport'});
    // } else {
    //   if (this.revenue.isNotDate && this.date) {
    //     this.notify.emit({type: 'submit', data: this.date});
    //   } else {
    //     this.notify.emit({type: 'submit'});
    //   }
    // }
    if (this.revenue.isNotDate && this.date) {
      this.notify.emit({type: 'submit', data: this.date});
    } else {
      this.notify.emit({type: 'submit'});
    }
  }

  chosenDate(e: Event): void {
    console.log(e);
  }
}
