<div class="filter">
  <div class="date-wrapper filter__date">
    <div class="date-from">
      <mat-form-field appearance="fill" class="item calendat">
        <mat-label>From</mat-label>
        <input matInput [matDatepicker]="picker1" [formControl]="monthFromControl"
               (dateChange)="onDateChange($event, 'from')">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="date-from">
      <mat-form-field appearance="fill" class="item calendat">
        <mat-label>To</mat-label>
        <input matInput [matDatepicker]="picker2" [formControl]="monthToControl"
               (dateChange)="onDateChange($event, 'to')">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="filter-reset" (click)="resetFilter()">
    Reset filter <span
    class="lnr lnr-cross"></span>
  </div>
</div>

<div class="table-content">
  <table class='table table-striped table-bordered table-hover dataTable'>
    <thead>
    <tr>
      <th>ID</th>
      <th>Artist name</th>
      <th>Submission name</th>
      <th>Published on
        <!--<i class="fas fa-filter m20-left" (click)="openDialog(dateDialog)"></i>-->
        <!--<ng-template #dateDialog class="date-wrapper">-->
          <!--<div class="date-from">-->
            <!--<mat-form-field appearance="fill">-->
              <!--<mat-label>Enter a date range</mat-label>-->
              <!--<mat-date-range-input [formGroup]="range" [rangePicker]="picker">-->
                <!--<input matStartDate formControlName="start" placeholder="Start date">-->
                <!--<input matEndDate formControlName="end" placeholder="End date">-->
              <!--</mat-date-range-input>-->
              <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
              <!--<mat-date-range-picker #picker></mat-date-range-picker>-->
            <!--</mat-form-field>-->

            <!--<div class="flex button-container">-->
              <!--<button mat-dialog-close mat-button>OK</button>-->
            <!--</div>-->
          <!--</div>-->
        <!--</ng-template>-->
      </th>
      <th>GRS rating</th>
      <th>User rating</th>
      <th>Plays</th>
      <th>Would invest</th>
      <th>Become a fan</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor='let song of list; let i = index'>
      <td>{{i + 1}}</td>
      <td><a class="link-blue">{{song.artistName}}</a></td>
      <td>{{song?.project?.songName}}</td>
      <td>{{song?.publishedUpcoming}}</td>
      <td>
        <app-stars-readonly [rating]="song?.rating"></app-stars-readonly>
      </td>
      <td class="text-break">{{song?.project?.userRating}}</td>
      <td>{{song?.project?.playMusic}}</td>
      <td>{{song?.project?.wouldInvest}}</td>
      <td>{{song?.project?.becomeFan}}</td>
      <td>
        <div class="actions">
          <div class="edit" (click)="editItem(song)">
            <i class="fas fa-pencil-alt"></i>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<app-accepted-song-popup *ngIf="showDialog" (visibleChange)="onNotify($event)"
                         [submittedSong]="selectedItem"
                         [status]="status"
></app-accepted-song-popup>
