import {HttpParams} from '@angular/common/http';

export class RequestModel {
  limit: number;
  _id: string;
  offset: number;
  page: number;
  dateFrom: any;
  sortBy: string;
  sortHow: string;
  sorting: any;
  paging: any;
  createdFrom: string;
  updatedFrom: string;
  updatedTo: string;
  createdTo: string;
  invoiceType: string;
  dateTo: any;
  name: string;
  tab: number;
  typeFile: string;
  country: string;
  status: string;
  firstName: string;
  state: string[];
  dates: string[];
  fileFormat: string;
  lastName: string;
  createdAt: string;
  type: string;
  date: string;
  isBundle: string;
  unsoldPledge: boolean;
  linkedProject: 'select' | 'exclude';
  projectTitle: string;
  balance: boolean;
  email: string;
  featured: string;
  projectId: string;
  allTypes: boolean;
  transferred: boolean;
  delivered: string;
  artist: string;
  projectType: string;
  action: string;
  step: number;
  kyc: string;

  constructor(init: any) {
    Object.assign(this, init);
  }

  getApiModel(): HttpParams {
    let result = new HttpParams();
    if (this.limit || this.limit === 0) {
      result = result.set('limit', this.limit.toString());
    }
    if (this.offset || this.offset === 0) {
      result = result.set('offset', this.offset.toString());
    }
    if (this.step) {
      result = result.set('step', this.step.toString());
    }
    if (this.projectType) {
      result = result.set('projectType', this.projectType.toString());
    }
    if (this.action) {
      result = result.set('action', this.action.toString());
    }
    if (this.transferred) {
      result = result.set('transferred', this.transferred.toString());
    }
    if (this._id) {
      result = result.set('_id', this._id.toString());
    }
    if (this.artist) {

      result = result.set('artist',  encodeURIComponent( this.artist.toString()));
    }
    if (this.allTypes) {
      result = result.set('allTypes', this.allTypes.toString());
    }
    if (this.tab) {
      result = result.set('tab', this.tab.toString());
    }
    if (this.unsoldPledge) {
      result = result.set('unsoldPledge', this.unsoldPledge.toString());
    }
    if (this.projectId) {
      result = result.set('projectId', this.projectId.toString());
    }
    if (this.kyc) {
      result = result.set('kyc', this.kyc.toString());
    }
    if (this.firstName) {
      result = result.set('contact.firstName', this.firstName.toString());
    }
    if (this.lastName) {
      result = result.set('contact.lastName', this.lastName.toString());
    }
    if (this.linkedProject) {
      result = result.set('linkedProject', this.linkedProject);
    }
    if (this.isBundle) {
      result = result.set('isBundle', this.isBundle.toString());
    }
    if (this.balance) {
      result = result.set('balance', this.balance.toString());
    }
    if (this.delivered) {
      result = result.set('delivered', this.delivered.toString());
    }
    if (this.date) {
      result = result.set('date', this.date.toString());
    }
    if (this.paging) {
      if (this.paging.page) {
        result = result.set('page', this.paging.page.toString());
      }
      if (this.paging.limit || this.paging.limit === 0) {
        result = result.set('limit', this.paging.limit.toString());
      }
    }
    if (this.sorting) {
      if (this.sorting.sortBy) {
        result = result.set('sort-by', this.sorting.sortBy.toString());
      }
      if (this.sorting.sortHow) {
        result = result.set('sort-how', this.sorting.sortHow.toString());
      }
    }
    if (this.sortBy) {
      result = result.set('sort-by', this.sortBy.toString());
    }
    if (this.sortHow) {
      result = result.set('sort-how', this.sortHow.toString());
    }
    if (this.page) {
      result = result.set('page', this.page.toString());
    }
    if (this.typeFile) {
      result = result.set('typeFile', this.typeFile.toString());
    }
    if (this.dateFrom) {
      result = result.set('dateFrom', this.dateFrom.toString());
    }
    if (this.dateTo) {
      result = result.set('dateTo', this.dateTo.toString());
    }
    if (this.featured) {
      result = result.set('featured', this.featured.toString());
    }
    if (this.dates) {
      result = result.set('dates', this.dates.toString());
    }
    if (this.name) {
      result = result.set('name', this.name.toString());
    }
    if (this.status) {
      result = result.set('status', this.status);
    }
    if (this.state) {
      result = result.set('state', this.state.join());
    }
    if (this.type) {
      result = result.set('type', this.type.toString());
    }
    if (this.createdAt) {
      result = result.set('createdAt', this.createdAt.toString());
    }
    if (this.createdFrom) {
      result = result.set('createdFrom', this.createdFrom.toString());
    }
    if (this.createdTo) {
      result = result.set('createdTo', this.createdTo.toString());
    }

    if (this.updatedFrom) {
      result = result.set('updatedFrom', this.updatedFrom.toString());
    }
    if (this.projectTitle) {
      result = result.set('projectTitle', this.projectTitle.toString());
    }
    if (this.updatedTo) {
      result = result.set('updatedTo', this.updatedTo.toString());
    }
    if (this.fileFormat) {
      result = result.set('fileFormat', this.fileFormat.toString());
    }
    if (this.invoiceType) {
      result = result.set('invoiceType', this.invoiceType.toString());
    }
    if (this.country) {
      result = result.set('country', this.country.toString());
    }
    if (this.email) {
      const convertStr = encodeURIComponent(this.email);
      result = result.set('email', convertStr.toString());
    }
    return result;
  }

}

export class DistributionModel extends RequestModel{
 stage: string;
 currency: 'eur' | 'usd';
  getApiModel(): HttpParams {
    let result = new HttpParams();
    if (this.stage) {
      result = result.set('stage', this.stage.toString());
    }
    if (this.currency) {
      result = result.set('currency', this.currency.toString());
    }
    return result;
  }
}
