import * as moment from 'moment';
import {IContact} from '@core/common/interfaces';
import {HttpParams} from '@angular/common/http';
import {IBankAccounts, IUserRevenues} from '@core/interfaces/user.interface';
import {IRoles} from '@core/interfaces/roles.interface';

export class RequestUserModel {
  lastName: string;
  email: string;
  name: string;
  select: string;
  mangoWalletId: string;
  admin: string;
  contactLastName: string;

  constructor(init: any) {
    Object.assign(this, init);
  }

  getApiModel(): HttpParams {
    let result = new HttpParams();
    if (this.lastName) {
      result = result.set('lastName', this.lastName.toString());
    }
    if (this.contactLastName) {
      result = result.set('contact.lastName', this.contactLastName.toString());
    }
    if (this.admin) {
      result = result.set('admin', this.admin.toString());
    }
    if (this.name) {
      result = result.set('name', this.name.toString());
    }
    if (this.select) {
      result = result.set('select', this.select.toString());
    }
    if (this.email) {
      const convertStr = encodeURIComponent(this.email);
      result = result.set('email', convertStr.toString());
    }
    if (this.mangoWalletId) {
      result = result.set('mangoWalletId', this.mangoWalletId.toString());
    }
    return result;
  }

}

export class UserModel {
  constructor() {
  }

  mangoUserCreated: boolean;
  name: string;
  image: string;
  assignedRole?: string;
  mangoVerificationStatus: string;
  postalCode: string;
  projectCreationAllowed: boolean;
  reinvestAmount: number;
  slug: string;
  state: string;
  vatNumber: string;
  billingAddress: string;
  contact: IContact;
  bio?:string;
  country: string;
  type: string;
  _id: string;
  firstName: string;
  createdAt: string;
  telephone: string;
  lastName: string;
  gender: string;
  checkItem?: boolean;
  amountWithdraw?: string;
  email: string;
  mangoUserId: string;
  id: string;
  revenues: IUserRevenues;
  bankAccounts: [IBankAccounts];
  mangoWalletId: string;
  isProducer: boolean;
  internal: string;
  genresLike: [string];
  genresPlay: [string];
  external: string;
  isPrivate: boolean;
  social: any;
  roles: IRoles;
  orders: any;
  counters: any;
  admin: boolean;
  birthdate: string;
  credits: number;
  video: string[];
  companyName: string;
  globalView: boolean;
  city: string;
  updatedAt: string;
  bithdateISO: any;

  setFromApiModel(apiModel): this {
    this.mangoUserCreated = apiModel.mangoUserCreated;
    this.name = apiModel.name;
    this.image = apiModel.image;
    this.postalCode = apiModel.postalCode;
    this.bankAccounts = apiModel.bankAccounts;
    this.projectCreationAllowed = apiModel.projectCreationAllowed;
    this.reinvestAmount = apiModel.reinvestAmount;
    this.slug = apiModel.slug;
    this.admin = apiModel.admin;
    this.video = apiModel.video;
    this.bio = apiModel.bio;
    this.mangoVerificationStatus = apiModel.mangoVerificationStatus;
    this.mangoUserId = apiModel.mangoUserId;
    this.isPrivate = apiModel.isPrivate;
    this.bithdateISO = moment(apiModel.contact.birthdate, 'YYYY/MM/DD');
    this.state = apiModel.state;
    this.roles = apiModel.__r;
    this.social = apiModel.social;
    this.orders = apiModel.orders;
    this.counters = apiModel.counters;
    this.genresLike = apiModel.genresLike;
    this.genresPlay = apiModel.genresPlay;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY HH:mm');
    this.updatedAt = moment(apiModel.updatedAt).format('DD.MM.YYYY HH:mm');
    this.vatNumber = apiModel.vatNumber;
    this.type = apiModel.__t;
    this.isProducer = apiModel.isProducer;
    this.revenues = apiModel.revenues;
    this.mangoWalletId = apiModel.mangoWalletId;
    if (apiModel.stateHistory) {
      this.external = apiModel.stateHistory.external;
      this.internal = apiModel.stateHistory.internal;
    }
    this.companyName = apiModel.companyName;
    this.id = apiModel._id || apiModel.id;
    this.billingAddress = apiModel.billingAddress;
    this.contact = apiModel.contact;
    this.country = apiModel.country;
    return this;
  }

  getStateModel(data, type?): any {
    const model = {
      state: data.state,
      note: {
        external: data.external,
        internal: data.internal
      },
      type
    };
    return model;
  }

  getApiModel(data): any {
    const model = {
      contact: data.contact,
      name: data.name,
      postalCode: data.postalCode,
      genresLike: data.genresLike,
      genresPlay: data.genresPlay,
      isProducer: data.isProducer,
      globalRevenueView: data.globalView,
      country: data.country,
      companyName: data.companyName,
      billingAddress: data.billingAddress
    };
    model.contact.birthdate = moment(model.contact.birthdate).format('YYYY/MM/DD');
    return model;
  }
}

