import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { IWallet } from '@core/interfaces/payout.interface';

@Component({
  selector: 'app-wallet-lists',
  templateUrl: './wallet-lists.html',
  styleUrls: ['./wallet-lists.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WalletListsComponent {
  @Input() list: IWallet[];
  @Input() filter: any;
  @Output() setPage: EventEmitter<any> = new EventEmitter();
  @Output() transferAmount: EventEmitter<any> = new EventEmitter();

  onPageChange(pageNumber) {
    this.setPage.emit(pageNumber + 1)
  }

  transferMoney(item: IWallet) {
    this.transferAmount.emit(item);
  }

  getStatusStyle(wallet: IWallet): string | null{
    if(wallet.status === 'CREATED') {
      return 'alert'
    } else if(wallet.typePayout === 'fullPayment' && wallet.isFunded && !wallet.isFundedWithDelay ) {
      return 'pending'
    } else if(wallet.typePayout === 'fullPayment' && wallet.isFundedWithDelay) {
      return 'ready'
    }
    return null
  }
}

