<div class="tab">
  <form [formGroup]="form">
  <div class="item-row">
    <div class="item-label item-label_pad-20">Is Enabled</div>
    <div class="item-field">
      <mat-checkbox class="example-margin"
                    [name]="'Is Enabled'"
                    formControlName="isEnabled"
                    [id]="'Is Enabled'"
                    >
      </mat-checkbox>
    </div>
  </div>

  <div class="item-row">
    <div class="item-label item-label_pad-20">Title</div>
    <div class="item-field">
      <mat-form-field class="item-field item-field_align">
        <input matInput
               id="albumTitle1"
               formControlName="title"
               type="text" autocomplete="off"
        >
      </mat-form-field>
    </div>
  </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Description</div>
      <div class="item-field">
        <angular-editor
          [placeholder]="'Enter text here...'"
          [config]="editorConfig"
          [formControl]="form.get('description')"
        ></angular-editor>
      </div>
    </div>
  </form>
  <button class="button green default-size" (click)="addSlide()">Add Card</button>
  <ng-template [ngIf]="listCards?.length">
    <div class="list-cards" *ngFor="let item of listCards; let i = index">
      <app-card-component *ngIf="form?.controls[item?.name]"
                          [index]="i"
                          [form]="form?.controls[item?.name]"></app-card-component>
      <button class="button blue default-size" (click)="deleteSlide(i)">Delete card</button>
    </div>
  </ng-template>
</div>

