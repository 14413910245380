import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {UntypedFormControl, Validators} from '@angular/forms';

@Injectable()
export class SettingService {
  constructor() {
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get contentUrl(): string {
    return environment.contentUrl;
  }

  get staticRole(): string[] {
    return environment.staticRole;
  }

  get lock(): any {
    return environment.lock;
  }
}
