import {Component, OnInit} from '@angular/core';
import {
  AppToastrService, DictionaryService, UsersService
} from '../../../services/index';
import {Dictionary} from '@core/model/dictionary.model';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ALPHA_NUMERIC, EMAIL_PATTERN, NUMBER_POSITIVE, ONLY_LETTERS_FOR_NAME, STATE_NAMES} from '@core/common';
import { UserModel} from '@core/model';
import {Gender, State} from '@core/model/artists-list.model';
import {ConfirmPopup} from '@core/common/interfaces';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';

export const MY_DATE_FORMAT = {
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-fans-item',
  templateUrl: './fans-item.html',
  styleUrls: ['./_fans-item.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}]
})

export class FansItemComponent implements OnInit {
  genres: Dictionary[];
  gender = Gender;
  countries: Dictionary[];
  fanItem: UserModel;
  form: FormGroup;
  slug: string;
  stateCtrl: FormControl;
  isProducerCtrl: FormControl;
  globalViewCtrl: FormControl;
  idCtrl: FormControl;
  slugCtrl: FormControl;
  firstNameCtrl: FormControl;
  lastNameCtrl: FormControl;
  companyNameCtrl: FormControl;
  vatNumberCtrl: FormControl;
  genderCtrl: FormControl;
  birthDateCtrl: FormControl;
  emailCtrl: FormControl;
  billingAddressCtrl: FormControl;
  postalCodeCtrl: FormControl;
  cityCtrl: FormControl;
  countryCtrl: FormControl;
  internalCtrl: FormControl;
  telephoneCtrl: FormControl;
  externalCtrl: FormControl;
  genresLikeCtrl: FormControl;
  createdAtCtrl: FormControl;
  updatedAtCtrl: FormControl;
  states = State;
  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Delete',
    btnCancel: true,
    submitEmit: 'deleteUser',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to delete user?'
  };
  isLoaded: boolean;
  showPopupConfirmation: boolean;
  regionCtrl: FormControl;
  regions: Dictionary[] = null;

  constructor(
              private dictionaryService: DictionaryService,
              private fb: UntypedFormBuilder,
              private userService: UsersService,
              private toastr: AppToastrService,
              private route: ActivatedRoute,
              private router: Router,
  ) {
    this.slug = this.route.snapshot.params.slug;
  }

  ngOnInit(): any {
    this.getItem();
    this.getGenres();
    this.getCountries();
    this.getRegions();
    this.prepareForm();
  }

  getGenres(): void {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  getCountries(): void {
    this.dictionaryService.getCountries().subscribe((res) => {
      this.countries = res;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  getRegions(): void {
    this.dictionaryService.getRegions().subscribe((res) => {
      this.regions = res;
    }, err => {
      this.toastr.showToastFromError(err);
    });

  }

  prepareForm(): void {
    this.stateCtrl = this.fb.control('', {validators: [Validators.required]});
    this.isProducerCtrl = this.fb.control(false);
    this.birthDateCtrl = this.fb.control('');
    this.globalViewCtrl = this.fb.control(false);
    this.idCtrl = this.fb.control('');
    this.slugCtrl = this.fb.control('');
    this.internalCtrl = this.fb.control('');
    this.externalCtrl = this.fb.control('');
    this.firstNameCtrl = this.fb.control('',
      {validators: [Validators.required, Validators.pattern(ONLY_LETTERS_FOR_NAME)], updateOn: 'change'});
    this.lastNameCtrl = this.fb.control('',
      {validators: [Validators.required, Validators.pattern(ONLY_LETTERS_FOR_NAME)], updateOn: 'change'});
    this.companyNameCtrl = this.fb.control('');
    this.vatNumberCtrl = this.fb.control('');

    this.genderCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.emailCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.billingAddressCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.postalCodeCtrl = this.fb.control('', {validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC)], updateOn: 'change'});
    this.cityCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.countryCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.telephoneCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});

    this.genresLikeCtrl = this.fb.control('', {validators: [Validators.required], updateOn: 'change'});
    this.createdAtCtrl = this.fb.control('');
    this.updatedAtCtrl = this.fb.control('');
    this.regionCtrl = this.fb.control('');

    this.form = this.fb.group({
      state: this.stateCtrl,
      isProducer: this.isProducerCtrl,
      globalView: this.globalViewCtrl,
      id: this.idCtrl,
      _birthdate: this.birthDateCtrl,
      slug: this.slugCtrl,
      contact: this.fb.group({
        email: this.emailCtrl,
        firstName: this.firstNameCtrl,
        lastName: this.lastNameCtrl,
        gender: this.genderCtrl,
        birthdate: this.birthDateCtrl,
        postalCode: this.postalCodeCtrl,
        city: this.cityCtrl,
        telephone: this.telephoneCtrl,
        region: this.regionCtrl,
      }),
      internal: this.internalCtrl,
      external: this.externalCtrl,
      firstName: this.firstNameCtrl,
      lastName: this.lastNameCtrl,
      companyName: this.companyNameCtrl,
      vatNumber: this.vatNumberCtrl,
      gender: this.genderCtrl,

      billingAddress: this.billingAddressCtrl,
      postalCode: this.postalCodeCtrl,

      country: this.countryCtrl,

      genresLike: this.genresLikeCtrl,
      createdAt: this.createdAtCtrl,
      updatedAt: this.updatedAtCtrl,
    });
  }

  setForm(): void {
    this.form.patchValue(this.fanItem);
    this.birthDateCtrl.setValue(this.fanItem.bithdateISO);
    // this.telephoneCtrl.setValue(this.fanItem.contact.telephone);
    this.idCtrl.disable();
    this.emailCtrl.disable();
    this.slugCtrl.disable();
    this.updatedAtCtrl.disable();
    this.createdAtCtrl.disable();
    if (this.fanItem.mangoUserCreated) {
      this.countryCtrl.disable();
      this.companyNameCtrl.disable();
      this.vatNumberCtrl.disable();
    }
  }


  getItem(): void {
    this.userService.getUser(this.slug).subscribe(resp => {
      this.fanItem = resp;
      this.setForm();
      this.isLoaded = true;
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  changeState(): void {
    const payloadData = new UserModel().getStateModel(this.form.value, 'fans');
    this.userService.changeState(this.slug, payloadData).subscribe(resp => {
      this.toastr.showToast({title: `State has been changed`});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  changeUserRole(): void {
    this.showPopupConfirmation = true;
    this.popupConfig = {
      btnConfirm: true,
      titleBtnConfirm: 'Change',
      btnCancel: true,
      submitEmit: 'changeRole',
      titleBtnCancel: 'Cancel',
      text: 'Are you sure you want to change role?'
    };
  }

  _changeRole(): void {
    this.userService.changeRole(this.slug).subscribe(resp => {
      this.fanItem = resp;
      this.setForm();
      this.toastr.showToast({title: `Role has been changed`});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }


  onNotify(e): void {
    if (e.type === 'deleteUser') {
      this.deleteUser();
    }
    if (e.type === 'changeRole') {
      this._changeRole();
    }
    this.showPopupConfirmation = false;
  }

  saveUser(isValid: boolean): void {
    if (isValid) {
      const payloadData = new UserModel().getApiModel(this.form.getRawValue());
      this.userService.editCurrentUser(this.slug, payloadData).subscribe(() => {
        this.toastr.showToast({title: `Fan has been saved`});
      }, err => {
        this.toastr.showToastFromError(err);
      });
    }
  }

  deleteUser(): void {
    this.userService._deleteUser(this.slug).subscribe(() => {
      this.router.navigate([STATE_NAMES.fans]);
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }
}
