import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-table-template',
  templateUrl: './table-template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTemplateComponent {
  @Input() headers: string[] = [];
  @Input() rows: any[] = [];


  constructor() {

  }
}
