import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  UsersService,
  AppToastrService,
  UserSettingService,
} from '../../../services/index';
import { TYPE_ARTIST } from '@core/common/recoupable_constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-balances-item',
  templateUrl: './balances-item.html',
  styleUrls: ['./_balances-item.scss']
})
export class BalancesItemComponent implements OnInit {
  list: any[];
  text: any;
  selectedArtist = {
    recoupable: [],
    royalties: [],
    royaltiesAdvances: [],
    slug: null,
  };
  showPopupConfirmation: boolean;
  baseselectedArtist: any;
  settings: any;
  typeID: any;
  advance: any;
  newArtist: boolean;
  loaded: boolean;
  loadedSearchList: boolean;
  showPopup: boolean;
  popupConfig: any;
  total = {
    totalAdvances: 0,
    totalCosts: 0,
    totalRoyalties: 0,
    grossBalance: 0,
    netBalance: 0,
  };
  balanceId: string;
  artist: any;
  totalPagination: number;
  slug: string;
  baseTotal: any;
  paginationSet = {
    offset: 0,
    limit: 20,
  };
  constructor(
    private userSettingService: UserSettingService,
    private route: ActivatedRoute,
    private userService: UsersService,
    private toastr: AppToastrService
  ) {
    this.slug = this.route.snapshot.params.slug;
  }

  ngOnInit(): any {
    this.newArtist = this.slug === 'newItem';
    if (this.slug !== 'newItem') {
      this.onChangeItem({ slug: this.slug });
    }

    this.baseTotal = JSON.parse(JSON.stringify(this.total));
    this.baseselectedArtist = JSON.parse(JSON.stringify(this.selectedArtist));
  }

  createItem(type: string): any {
    this.typeID = type === 'Advance' ? 1 : 2;
    this.showPopup = true;
  }

  editAdvance(item: any, index: number): void {
    this.typeID = item.typeID;
    this.advance = item;
    this.advance.index = index;
    this.showPopup = true;
  }

  deleteAdvance(item, index): any {
    const id = item._id;
    this.userService.deleteBalance(this.artist.slug, id).subscribe(() => {
      this.selectedArtist.recoupable.splice(index, 1);
      this.toastr.showToast({title: 'SUCCESS: Item was deleted'});
      this.onChangeItem(this.artist);
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  closeBalance(): void {
    this.userService.closeBalance(this.artist.slug).subscribe(
      (resp) => {
        this.onChangeItem(this.artist);
        this.toastr.showToast({ title: 'SUCCESS: Balance was deleted' });
      },
      (err) => {
        this.toastr.showToastFromError(err);
      }
    );
  }

  findByName(e) {
    this.onChangeItem(e.data);
  }

  onPageChange(e: Event) {}

  onChangeItem(selectedArtist: any): any {
    this.loadedSearchList = true;
    this.userService.getBalance(selectedArtist.slug).subscribe(resp => {
      this.resetAllData();
      this.artist = resp.artist;
      this.text = this.artist.name;
      this.balanceId = resp.balanceId;
      this.totalPagination = 400;
      const total = [...resp.revenues, ...resp.recoupable];
      this.list = total.sort((a, b) => Number(a.time) - Number(b.time));

      this.loaded = true;
      this.getSettings();
    }, err => {
      console.log(err);
      this.toastr.showToastFromError(err);
    });
  }

  defineTotalRightsAmount() {}

  calculateAmount(): any {
    const itemVAT = this.settings;
    const isAustria = this.artist.country === 'AT';
    const dateFrom = moment(itemVAT.periodFrom, 'YYYY/MM/DD').format('X000');
    const dateTo = moment(itemVAT.periodTo, 'YYYY/MM/DD').format('X000');
    this.list.forEach((item, index, arr) => {
      const cdate = item.time;
      const isProducer = item.isProducer == TYPE_ARTIST['PRODUCER'].value;
      if (this.artist.vatNumber && isAustria) {
        if (cdate > dateFrom && cdate < dateTo && !isProducer) {
          item.vat = itemVAT.vat;
        } else {
          item.vat = isProducer || this.artist.isPrivate === false ? 20 : 13;
        }
      } else {
        item.vat = 0;
      }
      item.vatAmount = ((item.vat / 100) * item.netAmount).toFixed(2);
      item.grossAmount = (+item.netAmount + +item.vatAmount).toFixed(2);
      item.grossRecoupable = (
        item.recoupableAmount +
        (item.recoupablePercent / 100) * Number(item.vatAmount)
      ).toFixed(2);
      if (index === 0) {
        item.netBalance = item.recoupableAmount;
        item.grossBalance = item.grossRecoupable;
      } else {
        item.netBalance = (
          Number(item.recoupableAmount) + Number(arr[index - 1].netBalance)
        ).toFixed(2);
        item.grossBalance = (
          Number(item.grossRecoupable) + Number(arr[index - 1].grossBalance)
        ).toFixed(2);
      }
    });
  }

  resetData(): void {
    this.advance = null;
  }

  resetAllData(): void {
    this.resetData();
    this.total = JSON.parse(JSON.stringify(this.baseTotal));
    this.selectedArtist = JSON.parse(JSON.stringify(this.baseselectedArtist));
  }

  showConfirmation(validation): void {
    if (!validation) {
      this.showPopupConfirmation = true;
      this.popupConfig = {
        btnConfirm: true,
        titleBtnConfirm: 'Delete',
        btnCancel: true,
        submitEmit: 'delete',
        titleBtnCancel: 'Cancel',
        text: 'Are you sure you want to delete it?',
      };
    }
  }

  onNotify(e): void {
    if (e.type === 'submit') {
      this.onChangeItem(this.artist);
    }
    if (e.type === 'edit') {
      this.onChangeItem(this.artist);
    }
    if (e.type === 'delete') {
      this.closeBalance();
    }
    this.showPopup = false;
    this.showPopupConfirmation = false;
    this.resetData();
  }

  getSettings(): void {
    this.userSettingService._getArtistVatNumber().subscribe(resp => {
      this.settings = resp;
      this.calculateAmount();

    }, err => {
      this.toastr.showToastFromError(err);
    });
  }
}
