import * as moment from 'moment';
import {BalanceModel} from './balance.model';


export class ArtistModel {
  constructor() {
  }

  name: string;
  projects: any[];
  slug: string;
  id: string;
  user: string;
  type: string;
  date: string;
  contact: any;
  balance: {
    closed: boolean,
    createdAt: moment.Moment;
    grossBalance: number,
    createdDate: string;
    list: BalanceModel[]
  };
  isProducer: boolean;
  country: string;
  counters: {
    digitalProducts: number,
    fanOf: number,
    fans: number,
    physicalProducts: number,
    totalAudios: number,
    totalEarned: number,
    totalPlayed: number,
    totalPlays: number,
    totalPledged: number,
    totalSpent: number,
    totalVideos: number,
    totalVoted: number,
    totalVotes: number
  };
  recoupableAmount: string;
  recoupedAmount: string;
  createdAt: string;
  updatedAt: string;
  featured: boolean;
  state: string;

  setFromApiModel(apiModel): this {
    this.name = apiModel.name;
    this.slug = apiModel.slug;
    this.type = apiModel.__t;
    this.id = apiModel.id;
    this.contact = apiModel.contact;
    this.counters = apiModel.counters;
    this.featured = apiModel.featured;
    this.recoupableAmount = apiModel.recoupableAmount;
    this.recoupedAmount = apiModel.recoupedAmount;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY HH:mm');
    this.updatedAt = moment(apiModel.updatedAt).format('DD.MM.YYYY HH:mm');
    this.state = apiModel.state;
    this.country = apiModel.country;
    this.balance = apiModel.balance;
    this.isProducer = apiModel.isProducer;
    if (this.balance) {
      this.balance = {
        closed: apiModel.balance.closed,
        createdAt: apiModel.balance.createdAt ? moment(Number(apiModel.balance.createdAt)) : null,
        createdDate: apiModel.balance.recoupable && apiModel.balance.recoupable.length ? apiModel.balance.recoupable[0].dateToShow : null,
        grossBalance: apiModel.balance.grossBalance * (-1),
        list: apiModel.balance.recoupable || []
      };
    }
    return this;
  }
}

