<app-page-template header="Artists">
  <div class="search-block p20-top flex flex-around">
    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Search</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedSearchCategory" name="food">
            <mat-option *ngFor="let cat of searchFilter" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container">
        <mat-form-field *ngIf="selectedSearchCategory" class="mat-form-field form-field" appearance="fill">
          <input matInput placeholder="Enter request" class="p10-y" [(ngModel)]="searchCategoryValue"
                 (keyup.enter)="searchByCategory()">
        </mat-form-field>
        <ng-container *ngIf="selectedSearchCategory">
          <button mat-button (click)="searchByCategory()"><i class="fas fa-search"></i></button>
          <button mat-button (click)="resetSearchFiler()"><i class="fas fa-refresh"></i></button>
        </ng-container>
      </div>
    </div>

    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Category filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [formControl]="mainFilter">
            <mat-option>-- None --</mat-option>
            <mat-option *ngFor="let cat of categoryMainFilter" [value]="cat">
              {{cat}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container">
        <div class="select__item">
          <mat-form-field *ngIf="mainFilter.value === 'State'"
                          class="mat-select d-inline mat-form-select" appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="stateFilter" multiple [disabled]="mainFilter.value !== 'State'">
              <mat-option *ngFor="let cat of categoryStateFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Featured'" class="mat-select d-inline mat-form-select"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="featuredFilter">
              <mat-option *ngFor="let cat of categoryFeaturedFilter" [value]="cat">
                {{cat.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="mainFilter.value === 'Country'" class="mat-select d-inline mat-form-select"
                          appearance="fill">
            <mat-label>Select category</mat-label>
            <mat-select [formControl]="countryFilter" multiple>
              <mat-option *ngFor="let cat of categoryCountryFilter" [value]="cat">
                {{cat.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <ng-container *ngIf="mainFilter.value === 'State'
                      || mainFilter.value ==='Featured'
                      ||  mainFilter.value === 'Country'">
          <button mat-button (click)="onSelectCategory()"><i class="fas fa-search"></i></button>
          <button mat-button><i class="fas fa-refresh" (click)="resetCategoryFiler()"></i></button>
        </ng-container>
      </div>

    </div>

    <div class="search-block_item">
      <div class="search-block_item_header flex flex-between">
        <span class="bold-style">Range filter</span>
        <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
          <mat-label>Select category</mat-label>
          <mat-select [(ngModel)]="selectedRangeFilter" name="food">
            <mat-option *ngFor="let cat of rangeFilter" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-block_item_container range-block">
        <mat-form-field *ngIf="selectedRangeFilter" appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <ng-container *ngIf="selectedRangeFilter">
          <button mat-button (click)="searchByRange()"><i class="fas fa-search"></i></button>
          <button mat-button (click)="resetRangeFilter()"><i class="fas fa-refresh"></i></button>
        </ng-container>

      </div>
    </div>

  </div>
  <div class="content-body" *ngIf="isLoaded">
    <table class='table table-striped table-bordered table-hover dataTable'>
      <thead>
      <tr>
        <th>#</th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="contact.email">
          <div>Email</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="name">
          <div>Name</div>
        </th>
        <th>Country</th>
        <th>State</th>
        <th>Featured</th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="audio">
          <div>Audio</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="video">
          <div>Videos</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="digitals">
          <div>Digitals</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="phisicals">
          <div>Phisicals</div>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="createdAt">
          <span class="th-sortable">Created at</span>
        </th>
        <th [(filters)]="filter" (notify)="onNotify($event)" key="updatedAt">
          <span class="th-sortable">Updated at</span>
        </th>
        <th class="table-center th-actions flex flex-between flex-align-center">
          <span>Actions</span>
          <button class="m20-left" mat-button matTooltip="Reset sorting" matTooltipPosition="above"
                  (click)="resetSorting()"><i class="fas fa-refresh"></i></button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of artistData; let i = index">
        <td>{{i + 1}}</td>
        <td class="email-block">
          <i class="fas fa-copy d-inline copy-icon" [cdkCopyToClipboard]="item?.contact?.email"></i>
          <span class="d-inline">{{item?.contact?.email}}
              </span>
        </td>
        <td>{{item.name}}</td>
        <td>{{item?.country}}</td>
        <td>{{item?.state}}</td>
        <td>{{item?.featured}}</td>
        <td>{{item?.counters?.totalAudios}}</td>
        <td>{{item?.counters?.totalVideos}}</td>
        <td>{{item?.counters?.digitalProducts}}</td>
        <td>{{item?.counters?.physicalProducts}}</td>
        <td>{{item?.createdAt}}</td>
        <td>{{item?.updatedAt}}</td>
        <td>
          <span class="icon-container" (click)="openItem(item.slug, 'view')"><i class="fas fa-file-alt"></i></span>
          <span class="icon-container" (click)="openItem(item.slug, 'edit')"><i class="fas fa-edit"></i></span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="wrapper-pagination-footer" *ngIf="total > filter?.paging?.limit">
      <app-pagination [total]="total" [paging]="filter?.paging"
                      (notify)="onPageChange($event)"></app-pagination>
    </div>
  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</app-page-template>

