import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SettingService} from '@core/services/setting.service';
import {SettingsModel} from '@core/model/settings.model';

@Injectable()
export class UserSettingService {
  apiUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }

  _getSettings(): Observable<SettingsModel> {
    return this.http.get(`${this.apiUrl}/admin/settings`).pipe(map((resp: any) => {
      return new SettingsModel().setFromApiModel(resp);
    }));
  }

  _updateSettings(data): Observable<SettingsModel> {
    return this.http.put(`${this.apiUrl}/admin/settings`, data).pipe(map((resp: any) => {
      return new SettingsModel().setFromApiModel(resp);
    }));
  }
  _getArtistVatNumber(): Observable<SettingsModel> {
    return this.http.get(`${this.apiUrl}/admin/settings/artistVat`).pipe(map((resp: any) => {
      return new SettingsModel().setFromApiModel(resp);
    }));
  }

  _deleteSettings(data): Observable<SettingsModel> {
    return this.http.delete(`${this.apiUrl}/admin/settings/${data.country}`).pipe(map((resp: any) => {
      return new SettingsModel().setFromApiModel(resp);
    }));
  }
}
