<div class="dialog">
  <div class="dialog-wrapper">
    <div class="dialog-header">
      <h2 class="dialog-title">Submission details:</h2>
    </div>
    <div class="content-header">
      <div class="header-item" [class.active]="activeTab === 1" (click)="setState(1)">Project info</div>
      <div class="header-item" [class.active]="activeTab === 2" (click)="setState(2)">Opportunity rates</div>
      <div class="header-item" [class.active]="activeTab === 3" (click)="setState(3)">Details
        <span class="red-circle" *ngIf="!offerDetailsCtrl?.value"></span>
      </div>
      <div class="header-item" [class.active]="activeTab === 4" (click)="setState(4)">Song details

      </div>
    </div>

    <div class="content-body">
      <ng-template ngIf [ngIf]="activeTab === 1">
        <div class="dialog-content">
          <div class="item-content flex-column">
            <div class="item-col-raw">
              <div class="raw-item-filed standard-font">
                ID:
              </div>
              <div class="raw-item-value standard-font value">
                {{submittedSong?.id}}
              </div>
            </div>
            <div class="item-col-raw">
              <div class="raw-item-filed standard-font">
                Artist
              </div>
              <div class="raw-item-value standard-font value">
                {{submittedSong?.artistName}}
              </div>
            </div>
            <div class="item-col-raw">
              <div class="raw-item-filed standard-font">
                Slug:
              </div>
              <div class="raw-item-value standard-font value">
                {{submittedSong?.slug}}
              </div>
            </div>
          </div>
          <div class="item-content genres" *ngIf="!isEdit && submittedSong?.project?.genresPlay?.length">
          <span class="border-box-blue blue" *ngFor="let item of submittedSong?.project?.genresPlay">
            {{item}}
          </span>
          </div>
          <div class="section-detailing">
            <div class="song-wrapper">
              <div class="item-song">MP3</div>
              <div class="item-song">
                <audio controls>
                  <source [src]="submittedSong?.audio" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div class="item-raw flex">
              <div class="item-producer-col flex-33">
              <span class="standard-font">
                Copyright music:
              </span>
                <div class="standard-font value" *ngIf="submittedSong?.project?.composers">
                  <span class="item" *ngFor="let item of submittedSong?.project?.composers">
                    {{item?.name}}<br>
                  </span>
                </div>
                <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.composers">
                  No authors
                </div>
              </div>
              <div class="item-producer-col flex-33">
               <span class="standard-font">
                Copyright Lyrics:
              </span>
                <div class="standard-font value" *ngIf="submittedSong?.project?.writers">
                  <span class="item" *ngFor="let item of submittedSong?.project?.writers">
                    {{item?.name}}<br>
                  </span>
                </div>
                <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.writers">
                  No authors
                </div>
              </div>
              <div class="item-producer-col flex-33">
              <span class="standard-font">
                Producer:
              </span>
                <div class="standard-font value" *ngIf="submittedSong?.project?.producer">
                  {{submittedSong?.project?.producer}}
                </div>
                <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.producer">
                  None
                </div>
              </div>
            </div>
            <div class="item-raw flex-column">
           <span class="standard-font">
                Cover photo
              </span>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.photo">
                No photo
              </div>
              <div class="photo" *ngIf="submittedSong?.project?.photo">
                <img [src]="submittedSong?.project?.photo">
              </div>
            </div>
            <div class="item-raw flex-column">
           <span class="standard-font">
               Text "My project"
              </span>
              <div class=" standard-font value" *ngIf="submittedSong?.project?.aboutSong">
                {{submittedSong?.project?.aboutSong}}
              </div>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.aboutSong">
                Not text yet
              </div>
            </div>
            <div class="item-raw flex-column">
           <span class="standard-font">
               Text "About"
              </span>
              <div class=" standard-font value" *ngIf="submittedSong?.project?.biography">
                {{submittedSong?.project?.biography}}
              </div>
              <div class="standard-font standard-font-italic" *ngIf="!submittedSong?.project?.biography">
                Not text yet
              </div>
            </div>

            <div class="item-raw">
           <span class="standard-font">
                Rating:
              </span>
              <div class="rt-container">
                <div class="col-rt-12">
            <span class="star-cb-group">
              <input type="radio" id="rating-5" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="5"/>
              <label
                for="rating-5">5</label>
              <input type="radio" id="rating-4" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="4"/>
              <label
                for="rating-4">4</label>
              <input type="radio" id="rating-3" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="3"/>
              <label
                for="rating-3">3</label>
              <input type="radio" id="rating-2" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="2"/>
              <label
                for="rating-2">2</label>
              <input type="radio" id="rating-1" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="1"/>
              <label
                for="rating-1">1</label>
              <input type="radio" id="rating-0" name="rating" [disabled]="true" [ngModel]="submittedSong.rating"
                     value="0"
                     class="star-cb-clear"/>
              <label
                for="rating-0">0</label>
            </span>
                </div>
              </div>
            </div>

            <div class="item-raw flex-column">
           <span class="standard-font">
                Comment:
              </span>
              <div class="standard-font value" *ngIf="submittedSong?.project?.biography">
                {{submittedSong?.comment}}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngIf [ngIf]="activeTab === 2">
        <div class="flex raw-edit raw-edit-top">
          <div class="item-edit">
            <mat-form-field>
              <mat-label>Project title*</mat-label>
              <input matInput [formControl]="projectTitleCtrl">
            </mat-form-field>
          </div>
        </div>
        <div class="flex raw-edit">
          <div class="item-edit">

            <span class="item-edit-icon">€</span>
          </div>
        </div>
        <div class="flex flex-align raw-edit share-raw">
          <div class="item-edit">
            <mat-form-field class="mat-item ">
              <mat-label>Artist share*</mat-label>
              <input matInput [formControl]="artistShareCtrl">
            </mat-form-field>
            <span class="item-edit-icon">%</span>
          </div>
          <div class="item-edit">
            <mat-form-field class="mat-item ">
              <mat-label>Producer share*</mat-label>
              <input matInput [formControl]="producerShareCtrl">
            </mat-form-field>
            <span class="item-edit-icon">%</span>
          </div>
          <div class="item-edit">
            <mat-form-field class="mat-item ">
              <mat-label>Investor share*</mat-label>
              <input matInput [formControl]="investorShareCtrl">
            </mat-form-field>
            <span class="item-edit-icon">%</span>
            <div *ngIf="showErrorPrice" class="error">Investor share should not be 0%</div>
          </div>
        </div>
        <div *ngIf="errorTotal" class="error">Total share should be 100%</div>
        <div class="list-artist">
          <div class="list-artist-item">
            <div class="flex raw-edit" *ngFor="let item of listArtists; let i = index">
              <div class="item-edit">
                <mat-form-field class="mat-item ">
                  <mat-label>Artist #{{i + 1}}</mat-label>
                  <input matInput
                         [(ngModel)]="item.name"
                         id="autocomplete{{i}}"
                         type="text" autocomplete="off"
                         (ngModelChange)="searchText(item, 'artist')"
                  >
                </mat-form-field>
                <div class="test" *ngIf="item.name">
                  <ng-container
                    *ngTemplateOutlet="slideWrapper; context: {searchItem: item.searchItem, loadedSearchList: item.loadedSearchList, index: i, isArtist: true, user: item}"></ng-container>
                </div>
                <span class="warning" *ngIf="!item.searchItem?.length && item.checkList">Artist is not found</span>
              </div>
              <div class="item-edit">
                <mat-form-field class="mat-item">
                  <!--<mat-label></mat-label>-->
                  <input matInput
                         [(ngModel)]="item.share"
                         (keyup)="checkTotalProdShare('artist', item)"
                         type="text"
                  >
                </mat-form-field>
                <span class="item-edit-icon">%</span>
              </div>

            </div>
          </div>
          <div class="list-artist-item">
            <div class="flex raw-edit" *ngFor="let item of listProducers; let i = index">
              <div class="item-edit">
                <mat-form-field class="mat-item ">
                  <mat-label>Producer #{{i + 1}}</mat-label>
                  <input matInput
                         [(ngModel)]="item.name"
                         id="autocompletre{{i}}"
                         type="text" autocomplete="off"
                         (ngModelChange)="searchText(item, 'producer')"
                  >
                </mat-form-field>
                <div class="test" *ngIf="item.name">
                  <ng-container
                    *ngTemplateOutlet="slideWrapper; context: {searchItem: item.searchItem, loadedSearchList: item.loadedSearchList, index: i, isArtist: false, user: item}"></ng-container>
                </div>
                <span class="warning" *ngIf="!item.searchItem?.length && item.checkList">Producer is not found</span>
              </div>
              <div class="item-edit">
                <mat-form-field class="mat-item">
                  <!--<mat-label></mat-label>-->
                  <input matInput
                         [(ngModel)]="item.share"
                         (keyup)="checkTotalProdShare('producer', item)"
                         type="text"
                  >
                </mat-form-field>
                <span class="item-edit-icon">%</span>
              </div>

            </div>
          </div>
        </div>
        <div *ngIf="errorProducerShare && !errorShares" class="error">Total producers share should be equal producer
          share
        </div>
        <div *ngIf="errorShares" class="error">Missed Name or Shares(%)</div>
        <div *ngIf="errorArtistShare && !errorShares" class="error">Total artists share should be equal artist share
        </div>
        <div class="flex flex-align raw-edit raw-edit-price">
          <div class="item-edit">
            <span>Price for 1%</span>
            <div *ngIf="priceForShare" class="value">{{priceForShare}}€</div>
          </div>
        </div>
        <div class="flex raw-edit">
          <div class="item-edit item-edit_margin-left">
            <mat-form-field class="mat-item">
              <mat-label>Label share distribution*</mat-label>
              <input matInput [formControl]="labelDistCtrl">
            </mat-form-field>
            <span class="item-edit-icon">%</span>
          </div>
        </div>
        <div class="toggle-item raw-edit">
          <mat-slide-toggle [disabled]="submittedSong?.project?.isPublished"
                            [checked]="royalty?.master"></mat-slide-toggle>
          <span class="item-edit-title">Royalties from Master rights (ON)</span>
        </div>
        <div class="flex flex-column item-edit-term raw-edit">
          <span class="item-edit-title input-label">Terms of royalties master rights</span>
          <mat-form-field class="mat-item">
            <input matInput [formControl]="termsMasterCtrl">
          </mat-form-field>

        </div>
        <div class="toggle-item raw-edit">
          <mat-slide-toggle [disabled]="submittedSong?.project?.isPublished"
                            [checked]="royalty?.publish"></mat-slide-toggle>
          <span class="item-edit-title">Royalties from Publishing rights (OFF)</span>
        </div>
        <div class="flex flex-column raw-edit item-edit-term">
          <span class="item-edit-title input-label">Terms of royalties publishing rights</span>
          <mat-form-field class="mat-item">
            <input matInput [formControl]="termsPublishingCtrl">
          </mat-form-field>

        </div>
        <div class="flex raw-edit">
          <div class="item-edit">
            <mat-form-field class="mat-item ">
              <mat-label>Project Duration*</mat-label>
              <input matInput [formControl]="projectDurationCtrl">
            </mat-form-field>
          </div>
        </div>
      </ng-template>
      <ng-template ngIf [ngIf]="activeTab === 3">
        <div class="flex raw-edit raw-edit-top">
          <div class="item-edit width-100">
             <span class="standard-font">
              Offer Details* <br/>
             </span>
            <textarea class="textarea" rows="10" cols="45" name="text"
                      [formControl]="offerDetailsCtrl"
            ></textarea>
          </div>
        </div>
        <div class="item-document">
             <span class="standard-font">
               Opportunity video: <br/>
             </span>
          <div class="upload-video">
            <div class="upload-section" *ngIf="!fileName">
              <label class="border-blue">
                <input (change)="uploadVideo($event.target)" type="file"
                       class="desktop-upload"
                       maxlength="30"
                       name="filefront"/>
                <i class="fas fa-music"></i>
                <span>Upload video file (.mp4, .webm)</span>
              </label>
            </div>
            <mat-spinner *ngIf="submittedVideo" [diameter]="20"></mat-spinner>
          </div>
          <video width="400" *ngIf="fileName"
                 [src]="submittedSong?.project?.videoclip"
                 height="100%"
                 controls="controls"
          ></video>
          <br/>
          <span class="file-name" *ngIf="fileName">
            <span class="icon-cancel" (click)="cancelUpload()"><i
              class="fas fa-times-circle"></i></span>{{fileName}}</span>
        </div>
        <div class="item-document">
          <span class="standard-font">
               Video Preview Picture: <br/>
             </span>
          <app-upload-image [settings]="settings"
                            [config]="{imageFormat: 'png', ratio: 16 / 6}"
                            [control]="videoImgCtrl"
                            [imgURL]="videoImgCtrl?.value"
          ></app-upload-image>
        </div>
      </ng-template>
    </div>
    <ng-template ngIf [ngIf]="activeTab === 4">
      <app-submission-fields [submittedSong]="submittedSong" [settings]="settings"
                             [isProject]="submittedSong?.project?.isUpcomingProject"
                             (notify)="onNotify($event)"></app-submission-fields>
    </ng-template>
  </div>
  <div class="dialog-footer" *ngIf="activeTab !== 4">
    <div class="footer-content">

      <div class="button-wrapper">
        <button type="button"
                class="button blue-bgc"
                *ngIf="!submittedSong?.project?.isPublished && !submittedSong?.project?.isUpcomingProject"
                (click)="publishOpportunity(true)"
                [disabled]="!form.valid || disableButton || errorShares || (videoFile && !videoImgCtrl?.value) || submittedSong?.project?.isPublished"
        >
          Publish opportunity
        </button>
        <button type="button"
                class="button blue-bgc"
                *ngIf="!submittedSong?.project?.isPublished && submittedSong?.project?.isUpcomingProject"
                (click)="updateProject()"
                [disabled]="!form.valid || disableButton || (videoFile && !videoImgCtrl?.value) || submittedSong?.project?.isPublished"
        >
          Publish project
        </button>
        <button type="button" class="button blue"
                *ngIf="!submittedSong?.project?.isPublished && !submittedSong?.project?.isUpcomingProject"
                (click)="publishOpportunity(false)"
                [disabled]="!form.valid || disableButton || (videoFile && !videoImgCtrl?.value)"
        >
          Save and close
        </button>
        <button type="button" class="button green" *ngIf="submittedSong?.project?.isPublished"
                (click)="updateOpportunity()"
                [disabled]="!form.valid || disableButton || (videoFile && !videoImgCtrl?.value)"
        >
          Update
        </button>
        <button type="button" class="button red delete" (click)="deleteSong()"
                *ngIf="!submittedSong?.project?.isPublished"
        ><i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="overlay" (click)="closeDialog()"></div>
<app-popup-confirmation *ngIf="deleteConfig?.btnConfirm" (notify)="onNotify($event)"
                        [config]="deleteConfig"></app-popup-confirmation>
<ng-template #slideWrapper let-list="searchItem" let-isArtist="isArtist" let-index="index" let-user="user">
  <ul class="listDrpDown" *ngIf="list && !loadedSearchList">
    <li class="autocomplete-item" *ngFor="let item of list" (click)="onChangeItem(item, index, isArtist, user)">
      {{item.name}}
    </li>
  </ul>
</ng-template>
