import {PATH_SAVE} from '@core/common/constants';

export const EMAIL_PATTERN = '^\\s*(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}' +
  '\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))\\s*$';
export const NUMBER_LENGTH_NINE = /^[0-9]{1,9}$/;
export const NUMBERS = /^[0-9]$/;
export const NUMBER_POSITIVE = /^[1-9]\d*$/;
export const NUMBER_POSITIVE_DOT_COMMA = /^[0-9]{1,4}([,.][0-9]{1,2})?$/;
export const ALPHA_NUMERIC = /^[a-zA-Z0-9_]{1,7}$/;
export const HOUR_MAX = /^(2[0-3]|1[0-9]|0[0-9]|[^0-9][0-9]|[0-9])$/;
export const MINUTES_MAX = /^([0-5][0-9]|[0-9])$/;
export const FACEBOOK = /^((http(s?)):\/\/(www.)?)?facebook.com\/[a-zA-Z0-9_.-]*$/;
export const INSTAGRAM = /^((http(s?)):\/\/(www.)?)?instagram.com\/.*$/;
export const YOUTUBE = /^((http(s?)):\/\/)?(www.)?((youtube.com)|(youtu.be))?\/.*$/;
export const TWITTER = /^((http(s?)):\/\/(www.)?)?twitter.com\/.*$/;
export const SPOTIFY = /^((http(s?)):\/\/(www.)?)?open.spotify.com\/.*$/;
export const POSITIVE_NUMBER = /^\d*[1-9]\d*$/;
export const TIKTOK = /(((http(s?)):\/\/)?(www.)?)?tiktok.com\/.*$/;
export const SOUNDCLOUD = /(((http(s?)):\/\/)?(www.)?)?soundcloud.com\/.*$/;
export const DROPBOX = /(((http(s?)):\/\/)?(www.)?)?dropbox.com\/.*$/;
export const ONLY_LETTERS_FOR_NAME = /^[a-zA-Z '"-]*$/;

