<form [formGroup]="detailGroup">
  <div class="section-title">Project Detail content</div>

    <div class="content-item">
      <form-item-header>Card description</form-item-header>
      <app-form-input [formControlItem]="detailGroup?.get('cardDescription')">Card description</app-form-input>
    </div>

  <div class="content-item">
    <div class="item-label header-inform-text-grey">Premiere</div>
    <div class="line">
      <div class="content-item">
        <app-form-input [formControlItem]="detailGroup?.get('dateKey')">Name of Date field</app-form-input>
      </div>

      <div class="content-item">
        <app-form-input [formControlItem]="detailGroup?.get('datePlannedRelease')">Date Planned Release</app-form-input>
      </div>

    </div>

  </div>
  <div class="content-item">
    <form-item-header>Rights</form-item-header>
    <textarea
      class="textarea"
      rows="2"
      name="text"
      [formControl]="detailGroup?.get('rights')"
    ></textarea>


  </div>
  <div class="content-item">
    <form-item-header>Rights Holder</form-item-header>
    <div class="item-field">
      <project-rights-holder
        [category]="project?.artistCategory"
        [parentForm]="form"
        [rightsHolder]="project?.details?.rightsHolder"
      ></project-rights-holder>
    </div>
  </div>
  <div class="content-item">
    <form-item-header>Teaser text</form-item-header>
    <app-form-input [formControlItem]="detailGroup?.get('teaser')">Teaser text</app-form-input>
  </div>
  <div class="content-item">
    <form-item-header>Status Detail</form-item-header>
    <app-form-input [formControlItem]="detailGroup?.get('statusDetail')">Status Detail</app-form-input>
  </div>
  <div class="content-item" *ngIf="!collectible">
    <form-item-header>Break Even</form-item-header>
    <app-form-input [formControlItem]="detailGroup?.get('breakEven')">Break Even</app-form-input>
  </div>
</form>
