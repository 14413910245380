export interface Contact {
  email: string;
  firstName: string;
  lastName: string;
  gender?: string;
  birthdate?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  telephone?: string;
  country?: string;
  region?: string;
}

export interface Counters {
  physicalProducts?: number;
  digitalProducts?: number;
  totalEarned?: number;
  totalPlays?: number;
  totalPlayed?: number;
  totalAudios?: number;
  totalVideos?: number;
  totalVotes?: number;
  totalPledged?: number;
  totalSpent?: number;
}

export interface Social {
  facebook: string;
  twitter: string;
  youtube: string;
  website: string;
  spotify: string;
  instagram: string;
  tiktok: string;
}

export interface Orders {
  purchases: Purchases;
  sales: Sales;
}

export interface Sales {
  physical: number;
  digital: number;
}

export interface Purchases {
  physical: number;
  digital: number;
}

export interface InPending {
  amount: number;
}

export interface Settings {
  activityStream: boolean;
  investmentNotifications: boolean;
  listedOnPlaylistNotifications: boolean;
  notifications: boolean;
  opportunitiesNotifications: boolean;
  releasedNotifications: boolean;
  royaltyNotifications: boolean;
}

export interface IArtistEditing {
  state: string;
  note: {
    internal: string;
    external: string;
  };
  featured: boolean;
  isProducer: boolean;
  globalRevenueView: boolean;
  id: number;
  slug: string;
  imgURL: string;
  name: string;
  postalCode: string;
  country: string;
  billingAddress: string;
  contact: Contact;
  counters: Counters;
  message: string;
  bio: string;
  social: Social;
  genresPlay: [string];
  createdAt: string;
  updatedAt: string;
  projectCreationAllowed: boolean;
  mangoUserId: string;
  vatNumber: string;
  orders: Orders;
}

export const Gender = [
  'male',
  'female',
  'not-specified'
];

export const State = [
  'pending',
  'verified',
  'deleted'
];
