import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtistModel} from '../model';
import {RequestModel} from '@core/model/request.model';
import {ReleaseModel} from '@core/model/release.model';
import {RoleModel} from '@core/model/role.model';
import {LabelModel} from '@core/model/label.model';
import {ProjectModel} from '@core/model/project.model';
import {IColabel} from '@core/interfaces/colabel.interface';

@Injectable()
export class ColabelService {
  apiUrl: string;
  contentUrl: string;

  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
    this.contentUrl = this.setting.contentUrl;
  }

  _saveLabel(data): Observable<LabelModel> {
    return this.http.post(`${this.apiUrl}/admin/co-label`, data).pipe(map((resp: any) => {
      if (resp) {
        return new LabelModel().setFromApiModel(resp);
      }
    }));
  }

  _editLabel(data: IColabel, slug: string): Observable<LabelModel> {
    return this.http.put(`${this.apiUrl}/admin/co-label/${slug}/update`, data).pipe(map((resp: any) => {
      if (resp) {
        return new LabelModel().setFromApiModel(resp);
      }
    }));
  }

  _getLabel(): Observable<LabelModel[]> {
    return this.http.get(`${this.apiUrl}/admin/co-label`).pipe(map((resp: any) => {
      if (resp) {
        return resp.map(item => new LabelModel().setFromApiModel(item));
      }
    }));
  }

  _deleteLabel(item): Observable<any> {
    return this.http.delete(`${this.apiUrl}/admin/co-label/${item.slug}`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
}
