import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, OpportunityService, UploadService} from '../../../services/index';

moment.locale('ru');

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.html'
})
export class UploadFileComponent implements OnInit {
  @Input() buttonName: any;
  @Input() url: any;
  @Input() typeReport: any;
  @Input() disabledParam: boolean;
  @Input() settings: any;
  loadFile: boolean;
  fileValue: any;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private opportunityService: OpportunityService,
              private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
  }


  uploadRevenue(e): void {
    this.loadFile = true;
    this.fileValue = e.value;
    const file = e.files[0];
    let options = {
      typeReport: this.typeReport,
      correction: ''
    };

    this.uploadService.uploadFileStream(file, options).subscribe(res => {
      this.fileValue = null;
      e.value = '';
     // this.dateChange.emit({type: this.url, data: res});
      this.toastr.showToast({title: `In process`});
      this.loadFile = false;
    }, (err) => {
      this.loadFile = false;
      this.toastr.showToastFromError(err);
    })
  }

  loadReader(e) {
    const input = e.files[0];
    const reader = new FileReader();
    reader.readAsText(input);
    reader.onload = (event) => {
      event.preventDefault();
      var string = reader.result;
      this.checkFile(e, string);
    };

  }

  checkFile(e, string) {

    if (this.buttonName === 'Upload radio revenue' && string.includes('Sales')) {
      this.toastr.showErrorToast({title: 'Wrong report type', message: 'Please download radio revenue'});
      e.value = '';
    } else if (this.buttonName === 'Upload revenue' && string.includes('Partner')) {
      this.toastr.showErrorToast({title: 'Wrong report type', message: 'Please download revenue'});
      e.value = '';
    } else {
      this.uploadRevenue(e);
    }
  }
}
