import {ISeo} from '@core/common/interfaces';

interface ArtistVat {
  period: string;
  periodFrom: string;
  periodTo: string;
  vat: string;
  vatOpt: string;
}

export interface CountryVatInterface {
  vat: number;
  isEdit?: boolean;
  vatOpt: number;
  threshold: number;
  country?: string;
  doubleTreatment: boolean;
  name: string;
}

export interface ISettingAddress {
  companyName?: string;
  address?: string;
  city?: string;
  postalCode?: string;
}


export interface IDocumetns {
  mangopayEn: string;
  mangopayDe: string;
  dataPolicyEn: string;
  dataPolicyDe: string;
  termsEn: string;
  termsDe: string;
  InformationblattEn: string;
  InformationblattDe: string;
}

export class SettingsModel {
  constructor(host?) {
  }

  reinvestURL: string;
  countriesVAT: CountryVatInterface[];
  viewNumberHomePage: number;
  voteFactor: number;
  seo: ISeo;
  companyAddress: ISettingAddress;
  enableLogo?: boolean;
  showTalents: boolean;
  maintenanceMode: boolean;
  expireVoucherYear: string;
  artistVat: ArtistVat;
  policyFiles: IDocumetns;

  setFromApiModel(apiModel): this {
    Object.assign(this, apiModel);
  //  this.countriesVAT = apiModel.countriesVAT || [];
    return this;
  }
}

