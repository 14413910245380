import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PayIn} from "@core/pages/releases/type/pay-in.type";
import {ResponsePayInInterface} from "@core/pages/releases/type/response-pay-in.type";
import {map, shareReplay} from "rxjs/operators";
import {ResponseWalletInterface} from "@core/pages/releases/type/response-wallets.type";
import {API_INTERFACE, ApiInterface} from "@core/interfaces/api.interface";


@Injectable()
export class WalletService {
  constructor(
    private fb: FormBuilder,
    @Inject(API_INTERFACE) private apiService: ApiInterface,
  ) {
  }

  initializeForm(): FormGroup {
    return this.fb.group({
      amount: ['', [Validators.required]],
      walletType: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      currency: ['', [Validators.required]]
    });
  }

  getAdminWalletBalance(): Observable<ResponseWalletInterface> {
    return this.apiService.get<ResponseWalletInterface>(`admin/wallet/balance`, {}).pipe(
      shareReplay(1)
    );
  }

  getPayIn(data: PayIn): Observable<string> {
    return this.apiService.post<ResponsePayInInterface>(`admin/wallet/payIn`, data)
      .pipe(
        map(resp => {
          if (!resp.url) {
            throw new Error('URL is empty');
          }
          return resp.url;
        }));
  }
}
