import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';

@Component({
  selector: 'app-landing-block',
  templateUrl: './landing-block.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingBlockComponent implements OnInit {
  form: UntypedFormGroup;
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() block: any;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): any {
    this.form = this.fb.group({
      isEnabled: new UntypedFormControl(false),
      photo: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      title: new UntypedFormControl(''),
    });
    if (this.block) {
      this.form.patchValue(this.block);
    }
  }
}

