import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';

// Interfaces
import { PayoutSettings } from '@core/interfaces/payout.interface';

// Services
import { PayoutService } from '@core/services/payout.service';
import {ArtistCategory} from "@core/interfaces/project.interface";

// Consts
export const PAYOUT_SETTINGS_DEFAULT: PayoutSettings = {
  project: '',
  owner: '',
  fee: 0,
  beneficiary: 'grs',
  typePayout: 'fullPayment',
  payoutMode: 'manual',
};

@Component({
  selector: 'payout-settings',
  templateUrl: './payout-settings.component.html',
  styleUrls: ['./payout-settings.component.scss', './../project-settings.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PayoutSettingsComponent implements OnInit {
  @Input() projectSlug: string;

  @Input() disabled: boolean = false;

  payoutSettings: PayoutSettings = null;

  parentForm: FormGroup;

  payoutSettingsForm: FormGroup;

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private payoutService: PayoutService
  ) {}

  ngOnInit(): void {
    this.parentForm = this.parent.form;
    if (this.projectSlug) {
      this.payoutService
        .getPayout(this.projectSlug)
        .pipe(
          filter(Boolean),
          finalize(() => {
            this.init();
          })
        )
        .subscribe(
          (res: PayoutSettings) => {
            this.payoutSettings = res;
          },
          () => {
            this.payoutSettings = { ...PAYOUT_SETTINGS_DEFAULT };
          }
        );
    } else {
      this.payoutSettings = { ...PAYOUT_SETTINGS_DEFAULT };
      this.init();
    }
  }

  private init(): void {
    this.createForm();
    this.changeControls(this.payoutSettings.typePayout);
    this.changeControls(this.payoutSettings.beneficiary);
    this.subscribeToFormChanges();
    // this.disabled && this.disable
  }

  private createForm(): void {
    this.parentForm.addControl(
      'payoutSettingsForm',
      this.fb.group({
        typePayout: [this.payoutSettings?.typePayout || 'fullPayment'],
        beneficiary: [this.payoutSettings?.beneficiary || 'grs'],
      })
    );
    this.payoutSettingsForm = this.parentForm.get(
      'payoutSettingsForm'
    ) as FormGroup;
  }

  private subscribeToFormChanges(): void {
    this.parentForm.controls.artistCategory.valueChanges.subscribe(
      (category: ArtistCategory) => {
        this.disableControl(category);
      }
    );
    this.payoutSettingsForm.controls.typePayout.valueChanges.subscribe(
      (typePayout: PayoutSettings['typePayout']) => {
        this.changeControls(typePayout);
      }
    );
    this.payoutSettingsForm.controls.beneficiary.valueChanges.subscribe(
      (beneficiary: PayoutSettings['beneficiary']) => {
        this.changeControls(beneficiary);
      }
    );
  }

  private disableControl(category: ArtistCategory): void{
    switch (category) {
      case 'diy': {
        this.changeValueBeneficiary('nonGrs', true);
        break;
      }
      case 'grs': {
        this.changeValueBeneficiary('grs');
        break;
      }
      case 'exclusive': {
        this.changeValueBeneficiary('grs');
        break;
      }
      default:
        break;
    }
  }

  private changeValueBeneficiary(value: string, disable: boolean = false){
    let control = this.payoutSettingsForm.controls.beneficiary;
    control.setValue(value);
    control[disable ? 'disable' : 'enable']();
  }

  private changeControls(value: string): void {
    switch (value) {
      case 'fullPayment':
        this.removeControls(['frequencyNumber', 'frequencyRange']);
        this.addControls(['payoutMode']);
        break;
      case 'instalments':
        this.removeControls(['payoutMode']);
        this.addControls(['frequencyNumber', 'frequencyRange']);
        break;
      case 'grs':
        this.removeControls(['owner', 'fee']);
        break;
      case 'nonGrs':
        this.addControls(['owner', 'fee']);
        break;
      default:
        break;
    }
  }

  removeControls(formControlNames: string[]): void {
    formControlNames.forEach((formControlName) => {
      this.payoutSettingsForm.contains(formControlName) &&
        this.payoutSettingsForm.removeControl(formControlName);
    });
  }

  addControls(formControlNames: string[]): void {
    formControlNames.forEach((formControlName) => {
      this.payoutSettingsForm.addControl(
        formControlName,
        this.fb.control(this.payoutSettings?.[formControlName] || '', [
          Validators.required,
        ])
      );
    });
  }
}
