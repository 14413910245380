import { Component, OnInit } from '@angular/core';
import { ArtistsService } from '@core/services/artists.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ALPHA_NUMERIC,
  EMAIL_PATTERN,
  NUMBER_POSITIVE,
  NUMBER_POSITIVE_DOT_COMMA,
  ONLY_LETTERS_FOR_NAME,
  STATE_NAMES,
} from '@core/common';
import * as moment from 'moment';
import { AppToastrService, DictionaryService } from '@core/services';
import { ConfirmPopup } from '@core/common/interfaces';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MY_DATE_FORMAT } from '@core/pages/artists/item-edit/artist-edit.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-producers-edit',
  templateUrl: './producers-edit.component.html',
  styleUrls: ['./producers-edit.component.scss'],
  providers: [
    ArtistsService,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class ProducersEditComponent implements OnInit {
  birthDateCtrl: UntypedFormControl;
  editingForm: UntypedFormGroup;
  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Delete',
    btnCancel: true,
    submitEmit: 'deleteUser',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to delete user?',
  };
  slug: string;
  listOfCountries = [];
  showPopupConfirmation: boolean;
  regions: any;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: AppToastrService,
    private dictionaryService: DictionaryService,
    private artistsService: ArtistsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.slug = this.route.snapshot.params.slug;
  }

  ngOnInit(): void {
    this.dictionaryService.getCountries();
    this.getRegions();
    this.prepareForm();
    this.dictionaryService.onGetCountries.subscribe((resp) => {
      resp.forEach((item) => this.listOfCountries.push(item));
    });
    this.getProducerData();
  }

  getProducerData(): void {
    this.artistsService
      .getCurrentProducer(this.slug.toLowerCase())
      .subscribe((producer) => {
        this.editingForm.patchValue(producer);
        this.setForm(producer);
        this.editingForm.markAllAsTouched();
      });
  }

  getRegions(): void {
    this.dictionaryService.getRegions().subscribe((res) => {
      this.regions = res;
    }, err => {
      this.toastr.showToastFromError(err);
    });

  }

  prepareForm() {
    this.birthDateCtrl = this.fb.control('', [Validators.required]);
    this.editingForm = this.fb.group({
      id: null,
      slug: null,
      contact: this.fb.group({
        email: ['', [Validators.pattern(EMAIL_PATTERN), Validators.required]],
        firstName: [
          '',
          [Validators.pattern(ONLY_LETTERS_FOR_NAME), Validators.required],
        ],
        lastName: [
          '',
          [Validators.pattern(ONLY_LETTERS_FOR_NAME), Validators.required],
        ],
        gender: [''],
        birthdate: this.birthDateCtrl,
        city: ['', Validators.required],
        telephone: ['', Validators.required],
        region: [''],
      }),
      postalCode: ['', Validators.pattern(ALPHA_NUMERIC)],
      billingAddress: ['', Validators.required],
      country: ['', Validators.required],
      mangoUserId: null,
    });
  }
  setForm(producer) {
    this.editingForm.get('contact.firstName').disable();
    this.birthDateCtrl.setValue(producer.bithdateISO);
    this.editingForm.get('contact.email').disable();
    this.editingForm.get('contact.lastName').disable();
  }

  saveChanges(): void {
    if (this.editingForm.valid) {
      const data = this.editingForm.value;
      data.contact = this.editingForm.getRawValue().contact;
      data.contact.birthdate = moment(data.contact.birthdate).format(
        'YYYY/MM/DD'
      );
      this.artistsService.editCurrentProducer(this.slug, data).subscribe(
        () => {
          this.toastr.showToast({ title: `Producer has been saved` });
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
    }
  }

  deleteUser(): void {
    this.artistsService.deleteArtist(this.slug).subscribe(
      () => {
        this.router.navigate([STATE_NAMES.producersList]);
      },
      (err) => {
        this.toastr.showToastFromError(err);
      }
    );
  }

  onNotify(e): void {
    if (e.type === 'deleteUser') {
      this.deleteUser();
    }
    this.showPopupConfirmation = false;
  }
}
