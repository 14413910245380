import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomFieldErrorMatcher, DROPBOX, INSTAGRAM, SOCIAL_VALIDATION, SOUNDCLOUD, SPOTIFY, TIKTOK, YOUTUBE} from '@core/common';
import {AppToastrService, DictionaryService, OpportunityService, UploadService} from '@core/services';
import {SongModel} from '@core/model';

interface SocialItems {
  [key: string]: string;
}

@Component({
  selector: 'app-submission-fields',
  templateUrl: './submission-fields.html'
})
export class SubmissionFieldsComponent implements OnInit {
  @Input() socialSong: SocialItems;
  @Input() settings: any;
  @Input() isProject: any;
  @Input() showCancel: boolean;
  @Input() submittedSong: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  form: UntypedFormGroup;
  listSocials: SocialItems = {};
  spotifyCtrl: UntypedFormControl;
  youtubeCtrl: UntypedFormControl;
  commentCtrl: UntypedFormControl;
  soundCloudCtrl: UntypedFormControl;
  songNameCtrl: UntypedFormControl;
  instagramCtrl: UntypedFormControl;
  publisherCtrl: UntypedFormControl;
  dropboxCtrl: UntypedFormControl;
  tiktokCtrl: UntypedFormControl;
  genresCtrl: UntypedFormControl;
  aboutArtistCtrl: UntypedFormControl;
  aboutSongCtrl: UntypedFormControl;
  producerCtrl: UntypedFormControl;
  imgCtrl: UntypedFormControl;
  composerCtrl: UntypedFormArray;
  lyricsAuthorCtrl: UntypedFormArray;
  labelCtrl: UntypedFormControl;
  submittedAudio: boolean;
  fileName: string;
  genres: any;
  selectedGenres: any[];
  listComposers: any[] = [];
  listLyrics: any[] = [];
  audioFile: string;
  AudioValue: any;
  contentUrl: string;
  social = JSON.parse(JSON.stringify(SOCIAL_VALIDATION));
  countaProducers = {
    lyricsAuthor: 0,
    composer: 0
  };

  constructor(private fb: UntypedFormBuilder,
              private toastr: AppToastrService,
              private uploadService: UploadService,
              private dictionaryService: DictionaryService,
              private opportunityService: OpportunityService) {
  }

  ngOnInit(): void {

    this.getGenres();
    this.prepareForm();
    this.contentUrl = this.settings.contentUrl;
  }

  prepareForm(): void {
    this.genresCtrl = this.fb.control('', [Validators.required]);
    this.aboutArtistCtrl = this.fb.control('', [Validators.required]);
    this.aboutSongCtrl = this.fb.control('', [Validators.required]);
    this.producerCtrl = this.fb.control('', [Validators.required]);
    this.labelCtrl = this.fb.control('', [Validators.required]);
    this.composerCtrl = this.fb.array([]);
    this.imgCtrl = this.fb.control('', [Validators.required]);
    this.lyricsAuthorCtrl = this.fb.array([]);
    this.publisherCtrl = this.fb.control('');
    this.songNameCtrl = this.fb.control('', [Validators.required]);
    this.spotifyCtrl = this.fb.control('', [Validators.pattern(SPOTIFY)]);
    this.youtubeCtrl = this.fb.control('', [Validators.pattern(YOUTUBE)]);
    this.commentCtrl = this.fb.control('');
    this.soundCloudCtrl = this.fb.control('', [Validators.pattern(SOUNDCLOUD)]);
    this.dropboxCtrl = this.fb.control('', [Validators.pattern(DROPBOX)]);
    this.instagramCtrl = this.fb.control('', [Validators.pattern(INSTAGRAM)]);
    this.tiktokCtrl = this.fb.control('', [Validators.pattern(TIKTOK)]);
    this.form = this.fb.group({
      spotify: this.spotifyCtrl,
      youtube: this.youtubeCtrl,
      soundcloud: this.soundCloudCtrl,
      dropbox: this.dropboxCtrl,
      songName: this.songNameCtrl,
      instagram: this.instagramCtrl,
      tiktok: this.tiktokCtrl,
      comment: this.commentCtrl,
      publisher: this.publisherCtrl,
      genres: this.genresCtrl,
      aboutSong: this.aboutSongCtrl,
      image: this.imgCtrl,
      aboutArtist: this.aboutArtistCtrl,
      producer: this.producerCtrl,
      composer: this.composerCtrl,
      label: this.labelCtrl,
      author: this.lyricsAuthorCtrl
    });
    this.setForm();
  }

  cancelUpdate(): void {
    this.notify.emit({type: 'closeEdit'});
  }

  setForm(): void {
    if (this.submittedSong.project) {
      // this.songNameCtrl.disable();
      this.publisherCtrl.disable();
    }

    if (this.submittedSong.project && this.submittedSong.project.photo) {
      this.imgCtrl.setValue(this.submittedSong.project.photo);
    }
    this.songNameCtrl.setValue((this.submittedSong.project && this.submittedSong.project.songName) ?
      this.submittedSong.project.songName : null);
    this.genresCtrl.setValue((this.submittedSong.project && this.submittedSong.project.genresPlay) ?
      this.submittedSong.project.genresPlay : null);
    this.labelCtrl.setValue((this.submittedSong.project && this.submittedSong.project.label) ?
      this.submittedSong.project.label : null);
    this.producerCtrl.setValue((this.submittedSong.project && this.submittedSong.project.producer) ?
      this.submittedSong.project.producer : null);
    this.aboutArtistCtrl.setValue((this.submittedSong.project && this.submittedSong.project.biography) ?
      this.submittedSong.project.biography : null);
    this.aboutSongCtrl.setValue((this.submittedSong.project && this.submittedSong.project.aboutSong) ?
      this.submittedSong.project.aboutSong : null);
    if (this.submittedSong.project && this.submittedSong.project.composers && this.submittedSong.project.composers.length) {
      this.submittedSong.project.composers.forEach(item => {
        this.composerCtrl.push(new UntypedFormControl(item.name ? item.name : item, [Validators.required]));
        this.listComposers.push(item);
        this.countaProducers.composer += 1;
      });
    } else {
      this.composerCtrl.push(new UntypedFormControl('', [Validators.required]));
      this.countaProducers.composer = 1;
    }
    if (this.submittedSong.project && this.submittedSong.project.writers && this.submittedSong.project.writers.length) {
      this.submittedSong.project.writers.forEach(item => {
        this.lyricsAuthorCtrl.push(new UntypedFormControl(item.name ? item.name : item, [Validators.required]));
        this.listLyrics.push(item);
        this.countaProducers.lyricsAuthor += 1;
      });
    } else {
      this.lyricsAuthorCtrl.push(new UntypedFormControl('', [Validators.required]));
      this.countaProducers.lyricsAuthor = 1;
    }

    this.fileName = this.submittedSong.audio ? this.submittedSong.audio.split('/project-soundtracks/')[1] : null;
    this.audioFile = this.submittedSong.audio ? this.submittedSong.audio.split('/project-soundtracks/')[1] : null;
  }

  getGenres(): void {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;
    });
  }

  checkStar(item): void {
    this.submittedSong.rating = item.toString();
  }

  changedGenres(): void {
    if (this.genresCtrl.value.length < 4) {
      this.selectedGenres = this.genresCtrl.value;
    } else {
      this.genresCtrl.setValue(this.selectedGenres);
    }
  }

  uploadAudio(event: any): boolean | void {
    this.AudioValue = event.value;
    const data = this.opportunityService.prepareAudioFile(event);
    if (data.error) {
      this.toastr.showErrorToast({title: data.error});
      this.submittedAudio = false;
      return false;
    }
    this.opportunityService.uploadFileAudio(data.fileUpload, {
      type: '/project-soundtracks',
      contentType: data.file.type,
      name: data.file.name.replace(/ /g, '-').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
    }).subscribe(res => {
      this.fileName = data.file.name;
      this.audioFile = res.url;
      this.toastr.showToast({
        title: 'mp3 has been uploaded'
      });
    }, (err) => {
      this.toastr.showToastFromError(err);
      this.cancelUpload();
    });

  }

  cancelUpload(): void {
    this.AudioValue = null;
    this.fileName = null;
    this.audioFile = null;
  }

  addAuthor(control: any, type: string): void {
    if (this.countaProducers[type] < 6) {
      control.push(new UntypedFormControl('', [Validators.required]));
      this.countaProducers[type] += 1;
    }
  }

  removeControl(index: number, control: any, type: string): void {
    if (this.countaProducers[type] !== 1) {
      control.removeAt(index);
      this.countaProducers[type] -= 1;
    }
  }

  addComposerAndWriters(control): any[] {
    let arr = [];
    const LIST: any = control.controls;
    LIST.forEach(item => {
      arr.push({
        name: item.value,
        slug: item.slug ? item.slug : '',
        user: item.user ? item.user : ''
      });
    });
    return arr;
  }

  updateSong(): any {
    const composer = this.addComposerAndWriters(this.composerCtrl);
    const lyrics = this.addComposerAndWriters(this.lyricsAuthorCtrl);

    const data = {
      audio: this.audioFile,
      rating: this.submittedSong.rating,
      photo: this.uploadService.getShortUrlName(this.imgCtrl.value, 'url'),
      genresPlay: this.genresCtrl.value,
      composers: composer,
      aboutArtist: this.aboutArtistCtrl.value,
      producer: this.producerCtrl.value,
      aboutSong: this.aboutSongCtrl.value,
      songName: this.songNameCtrl.value,
      writers: lyrics,
      label: this.labelCtrl.value,
      comment: this.commentCtrl.value,
      songLink: {
        youtube: this.listSocials['youtube'],
        spotify: this.listSocials['spotify'],
        soundcloud: this.listSocials['soundcloud'],
        dropbox: this.listSocials['dropbox']
      },
    };
    if (!this.submittedSong.project) {
      data['songName'] = this.songNameCtrl.value;
      data['publisher'] = this.publisherCtrl.value;
    }
    const requestData = new SongModel().getApiModel(data);
    if (!this.isProject) {
      this.opportunityService.updateOpportunitySongs(requestData, this.submittedSong).subscribe(resp => {
        if (resp) {
          this.toastr.showToast({title: this.submittedSong.project ? 'Project was updated' : 'Project was created'});
          this.notify.emit({type: 'updated', data: resp});
          this.submittedSong.project = resp;
          this.submittedSong.rating = data.rating;
          this.submittedSong.audio = `${this.contentUrl}/project-soundtracks/${this.audioFile}`;
        }
      }, err => {
        console.log(err);
        this.toastr.showToastFromError(err);
      });
    } else {
      this.notify.emit({type: 'proceed', data});
    }
  }

  errorMatcher(control: UntypedFormControl) {
    return new CustomFieldErrorMatcher(control, true);
  }
}
