<div class="content__elem content__elem_m-right content-datepicker">
  <mat-form-field appearance="fill" class="mat-input">
    <mat-label>Input date</mat-label>
    <input matInput [matDatepicker]="dp2" [(ngModel)]="valueSelected">
    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
    <mat-datepicker startView="multi-year"
                    #dp2
                    (monthSelected)="chosenMonthHandler($event, dp2)"
                    #picker></mat-datepicker>
  </mat-form-field>
</div>
