import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import * as moment from "moment";

@Component({
  selector: 'app-project-detail-section',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDetailsComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() project: any;
  @Input() collectible: boolean;

  constructor(private parentForm: FormGroupDirective) {

  }

  ngOnInit(): void {
    this.createForm();
    this.project && this.setValue();
  }

  ngOnChanges(value) {
    if (value.project && this.form) {
      this.setValue();
    }
  }

  createForm(){
    this.form = this.parentForm.form;

    this.form.addControl('details', new FormGroup({
      statusDetail: new FormControl(''),
      rights: new FormControl(''),
      breakEven: new FormControl(''),
      cardDescription: new FormControl(''),
      datePlannedRelease: new FormControl(''),
      teaser: new FormControl(''),
      dateKey: new FormControl(''),
    }))
  }

  setValue(){
    this.detailGroup.patchValue(this.project.details);
  }
  get detailGroup() {
    return this.form.get('details') as FormGroup;
  }
}
