import {Component, Input} from '@angular/core';
import * as moment from 'moment';
import {AppToastrService, UploadService} from '../../../services/index';
import {ControlContainer, UntypedFormControl} from '@angular/forms';
import {IGIFimages} from '../../../interfaces/config.interface';
import {UploadConfigs} from '../../../interfaces/user.interface';
import {IUploadImage} from "@core/component/upload/image.interface";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {UPLOAD_TOKEN} from "@core/component/upload/upload.token";
import {UploadImageService} from "@core/component/upload/upload-image.service";
import {ARTIST_IMAGES} from "@core/pages/artists/constant/artist";

moment.locale('ru');

@Component({
  selector: 'app-profile-image',
  templateUrl: './image-profile.html',
  styleUrls: ['./image-profile.scss'],
  providers: [{
    provide: UPLOAD_TOKEN,
    useExisting: ImageProfileComponent
  }]
})
export class ImageProfileComponent implements IUploadImage {
  date: string;
  @Input() config: UploadConfigs;
  @Input() data: any;
  @Input() maintainAspectRatio: boolean;
  calendarTitle: string;
  dates: any[];
  GIFsettings: IGIFimages;
  image: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileName: any = '';
  imageLoaded = false;
  uploadedImage: any;
  fileValue: string;
  imageUploaded: boolean;
  activeTime = 0;
  public form;

  constructor(private uploadImageService: UploadImageService,
              private controlContainer: ControlContainer,
              private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control;
    if (this.data) {
      this.form.addControl('image', new UntypedFormControl(''));
      this.form.get('image').patchValue(this.data.image);
    }
  }

  savePhoto(e) {
    this.prepareFile(e);
  }

  onRemove() {
    this.form.get('image').setValue('');
  }

  private dataURIToBlob(dataURI: string) {
    return this.uploadImageService.convertURIToBlob(dataURI);
  }

  private uploadFile(fileUpload, dataConfig) {
    this.uploadImageService.loadProfileImage(fileUpload, dataConfig)
      .subscribe(res => {
        this.image = `${ARTIST_IMAGES.path}/${res.url}`;
        this.form.get('image').setValue(res.url);
        if (this.config.imageFormat === 'gif') {
          this.GIFsettings.imageCoverGIF =  this.image;
          this.GIFsettings.imageCoveringURL =  this.image;
        }
        setTimeout(() => {
          this.toastr.showToast({
            title: 'Image has been uploaded'
          });
          this.imageUploaded = true;
        }, 1000);
      }, (err) => {
        this.toastr.showToastFromError(err);
      })
  }

  private prepareFile(event: any): any {
    const file = this.dataURIToBlob(this.croppedImage);
    const fileUpload = new FormData();
    fileUpload.append('file', file);

    this.image = null;
    this.fileValue = event.value;
    const sizeBytes = 10 * 1024 * 1024;
    if (file.size > sizeBytes) {
      this.toastr.showErrorToast({title: 'File size should be less than 10MB'});
      this.fileValue = null;
      return false;
    }

    const dataConfig = {
      type: `/${ARTIST_IMAGES.path}`,
      contentType: file.type,
      slug: this.data.slug,
      name: this.fileName.replace(this.fileName.toString().split('.')[1], this.config.saveFormat ? this.config.saveFormat : 'webp')
    }
    this.uploadFile(fileUpload, dataConfig);
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoadedw(image?: any): void {
    this.imageLoaded = true;
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(e): void {
    this.toastr.showErrorToast({message: 'File extension should be jpg, jpeg, png, webp'});
  }

  uploadImg(event: any) {
    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }
}
