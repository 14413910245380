<div class="upload-video">
  <div class="upload-section" *ngIf="!formControl?.value">
    <label class="border-blue">
      <input (change)="uploadVideo($event.target)" type="file"
             class="desktop-upload"
             maxlength="30"
             name="filefront"/>
      <i class="fas fa-video"></i>
      <span>Upload video file (.mp4, webm, .gif)</span>
    </label>
  </div>
  <mat-spinner *ngIf="submittedVideo" [diameter]="20"></mat-spinner>
</div>
<mat-error *ngIf="formControl?.hasError('required') && formControl?.invalid">
  This field is required
</mat-error>
<ng-container *ngIf="formControl?.value as value">
  <img *ngIf="getGif(value)"
       class="upl__image"
       [src]="value | convertImageUrl"
  />

  <video width="400" *ngIf="!getGif(value)"
         [src]="value | convertImageUrl"
         height="100%"
         controls="controls"
  ></video>
  <br/>
  <div class="file-name flex">
    <div class="icon-cancel" (click)="cancelUpload()"><i
      class="fas fa-times-circle"></i></div>
    <div class="name">{{value}}</div>
  </div>
</ng-container>

