import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractBaseFilterService} from '../../abstract-base-filter.service';
import {FilterReleaseService} from '../../filter-release.service';
import {FilterConfig} from '../../../../common/interfaces';
import {DictionaryService} from "@core/services";


export class EmptyFilter {
  country: string;

  constructor() {
    this.country = null;
  }
}

@Component({
  selector: 'app-base-filter-category',
  templateUrl: './base-filter-category.html',
  styleUrls: ['./_base-filter-category.scss'],
  providers: [
    {
      provide: AbstractBaseFilterService,
      useClass: FilterReleaseService
    }
  ],
})
export class BaseFilterCategoryComponent {
  searchCategoryValue: string;
  filter: EmptyFilter = new EmptyFilter();
  categoryList: any[] = [];
  @Input() config: FilterConfig;
  @Output() searchByFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public searchService: AbstractBaseFilterService,  private dictionaryService: DictionaryService,) {
    this.searchCategoryValue = this.searchService.searchCategoryValue;
    this.searchService.filterObj = this.filter;
    this.searchService.$observableSearchParam.subscribe(resp => {
      this.searchByFilter.emit(resp);
    });

  }

  loadCategory() {
    this.searchService.filterObj = new EmptyFilter();
    if(this.searchService.selectedSearchCategory === 'Country'){
      this.getCountries();
    }
  }

  getCountries(): void {
    this.dictionaryService.getCountries().subscribe((res) => {
      this.categoryList = res;
    });
  }
}

