import {UsersModel} from './users.model';
import * as moment from 'moment';
import {IPledges} from '@core/model/pledges.model';
import {ISongShare} from '@core/interfaces/artist-share.interface';
import {ColabelStatus} from '@core/interfaces/colabel.interface';
import {ISeo} from '@core/common/interfaces';
import {IOfferTeaser} from '@core/model/collectible.model';
import {environment} from "../../environments/environment";
import {IProjectDuration} from "@core/common/project-interfaces";
import {ICurrency} from "@core/interfaces/currency.interface";
import { FormGroup } from '@angular/forms';


interface Song {
  project: any;
  slug: string;
  name: string;
  genre: string[];
  publisher: string;
  producer: string;
  featureFm: string;
  composers: string[];
  writers: string[];
  publishingYear: number;
  label: string;
  soundtrackView: string;
}

interface INft {
  videoURL: string;
  imageURL: string;
  albumSongs?: number;
  imageGIFURL: string;
}

interface ILegacyHit {
  legacyDescription: string;
  legacyRights: string;
  isLegacyHit: boolean;
}

interface IReadMoreContent {
  imgReadMore: string;
  title: string;
  text: string;
}

export interface IColabel {
  status?: ColabelTypes;
  id?: number;
  domain?: string;
}

export interface ILinkedProject {
  isAlbum?: boolean;
  projectTitle?: string;
  slug?: string;
  _id?: string;
}

export type ColabelTypes = 'submit' | 'approve' | 'new';

export class ProjectModel {
  constructor() {
    this.imgUrl = environment.contentUrl;
  }

  _id: string;
  albumStatus: string;
  artist: UsersModel;
  artistCategory: string;
  receiverUnsoldShare: string;
  artistShare: number;
  artistsShare: Array<{
    name: string,
    share: number,
    slug: string,
    user: string
  }>;
  backgroundImage: string;
  biography: string;
  breakEven: string;
  cardArtistTitle: string;
  cardColor: string;
  cardDescription: string;
  coLabel: IColabel;
  comment: string;
  createdAt: string;
  date: string;
  dateFunded: string;
  datePlannedRelease: string;
  dedicatedForArtwork: boolean;
  dedicatedForMarketing: boolean;
  dedicatedForProduction: boolean;
  dedicatedForVideoClip: boolean;
  disableProjectRefund: boolean;
  fundingGoalPerCurrency: ICurrency<number>;
  genre: string;
  hasLinkedProject: boolean;
  image: string;
  imgUrl: string;
  isAlbum: boolean;
  isFeatured: boolean;
  isFunded: boolean;
  isFundedWithDelay: boolean;
  isPublished: boolean;
  isSpotlight: boolean;
  isUpcomingProject: boolean;
  keepItAll: boolean;
  label: string;
  labelShareDistribution: number;
  legacyHit: ILegacyHit;
  linkedProducer: string;
  linkedProducerData: {
    _id: string;
    slug: string;
    name: string;
  };
  linkedProject: ILinkedProject;
  mangoUserId: string;
  mangoWalletId: string;
  myOffersDescription: string;
  myOffersDetails: string;
  myProjectDescription: string;
  offerTeaser: IOfferTeaser[];
  pledges: IPledges[];
  pledgesTotal: ICurrency<number>;
  priceForShare: ICurrency<number>;
  producerShare: number;
  producersShare: ISongShare[];
  projectDuration: number;
  projectDurationTime: IProjectDuration;
  projectTitle: string;
  rating: string;
  readMoreContent: IReadMoreContent;
  release: {
    releasedSongs: any[],
    isAlbum: boolean
  };
  releaseCreated: boolean;
  royaltiesFromMaster: boolean;
  royaltiesFromPublishingRights: boolean;
  saleAmount: number;
  rightsHolder: string;
  saleAmountTotal: number;
  seo: ISeo;
  showCountry: boolean;
  slug: string;
  soldShare: number;
  song: Song;
  songs: any;
  soundtrack: string;
  statusDetail: string;
  teaser: string;
  termOfRoyaltiesMasterRights: number;
  termOfRoyaltiesPublishingRights: number;
  termRoyaltiesMater: string;
  termRoyaltiesPublish: string;
  time: string;
  totalInvestorsShares: number;
  totalShares: number;
  unSoldShare: number;
  unSoldShareInner: number;
  updatedAt: string;
  video: string;

  setFromApiModel(apiModel): this {
    const artistArr = Array.isArray(apiModel.artist);
    if (artistArr) {
      this.artist = new UsersModel().setFromApiModel(apiModel.artist[0]);
    } else {
      this.artist = apiModel.artist ? new UsersModel().setFromApiModel(apiModel.artist) : null;
    }
    this.artistCategory = apiModel.artistCategory;
    this.isSpotlight = apiModel.isSpotlight;
    this._id = apiModel._id;
    this.rightsHolder = apiModel.rightsHolder;
    this.linkedProducer = apiModel?.linkedProducer?._id;
    this.linkedProducerData = apiModel.linkedProducer;
    this.hasLinkedProject = apiModel.hasLinkedProject;
    this.cardColor = apiModel.cardColor || '';
    this.isSpotlight = apiModel.isSpotlight;
    this.fundingGoalPerCurrency = apiModel.fundingGoalPerCurrency;
    this.projectDurationTime = apiModel.projectDurationTime;
    this.disableProjectRefund = apiModel.release && apiModel.release.releasedSongs && apiModel.release.releasedSongs.length;
    this.projectTitle = apiModel.projectTitle;
    this.showCountry = apiModel.showCountry;
    this.saleAmount = apiModel.saleAmount;
    this.backgroundImage = apiModel.backgroundImage ? apiModel.backgroundImage : null;
    this.albumStatus = apiModel.albumStatus;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY');
    this.updatedAt = moment(apiModel.updatedAt).format('DD.MM.YYYY');
    this.isAlbum = apiModel.isAlbum;
    this.isFeatured = apiModel.isFeatured;
    this.isPublished = apiModel.isPublished;
    this.unSoldShare = apiModel.unSoldShare;
    this.comment = apiModel.comment;
    this.readMoreContent = apiModel.readMoreContent;
    if (apiModel.coLabel) {
      this.coLabel = {
        status: apiModel.coLabel.status,
        domain: apiModel.coLabel.domain,
      };
      switch (apiModel.coLabel.status) {
        case ColabelStatus.SUBMIT:
          this.coLabel.id = 1;
          break;
        case ColabelStatus.APPROVE:
          this.coLabel.id = 2;
          break;
        case ColabelStatus.NEW:
          this.coLabel.id = 3;
          break;
      }
    }
    this.rating = apiModel.rating || '';
    if (apiModel.legacyHit) {
      this.legacyHit = apiModel.legacyHit;
    }

    this.label = apiModel.label;
    this.artistsShare = apiModel.artistsShare;
    this.producersShare = apiModel.producersShare ? apiModel.producersShare.map(pr => {
      pr.selectCountry = !!pr.country;
      return pr;
    }) : [];
    this.isUpcomingProject = true;
    this.time = moment(apiModel.createdAt).format('x');
    this.genre = apiModel.genre;
    this.releaseCreated = apiModel.releaseCreated;
    this.termOfRoyaltiesMasterRights = apiModel.termOfRoyaltiesMasterRights;
    this.termOfRoyaltiesPublishingRights = apiModel.termOfRoyaltiesPublishingRights;
    this.seo = apiModel.seo;
    this.dedicatedForArtwork = apiModel.dedicatedForArtwork;
    this.dedicatedForMarketing = apiModel.dedicatedForMarketing;
    this.dedicatedForProduction = apiModel.dedicatedForProduction;
    this.dedicatedForVideoClip = apiModel.dedicatedForVideoClip;
    this.image = apiModel.image;
    // this.videoImage = apiModel.videoImage && apiModel.videoImage !== 'undefined' ? apiModel.videoImage : null;
    this.video = apiModel.video;
    if (apiModel.pledges && apiModel.pledges.length) {
      this.pledges = apiModel.pledges;
    }
    this.dateFunded = apiModel.dateFunded;
    this.saleAmountTotal = apiModel.saleAmountTotal;
    this.teaser = apiModel.teaser;
    this.projectDurationTime = apiModel.projectDurationTime;
    this.cardDescription = apiModel.cardDescription;
    this.statusDetail = apiModel.statusDetail;
    this.breakEven = apiModel.breakEven;
    this.datePlannedRelease = apiModel.datePlannedRelease;
    this.pledgesTotal = apiModel.pledgesTotal;
    this.totalShares = apiModel.totalShares;
    this.keepItAll = apiModel.keepItAll;
    this.termRoyaltiesPublish = apiModel.termRoyaltiesPublish;
    this.artistShare = apiModel.artistShare;
    this.cardArtistTitle = apiModel.cardArtistTitle;
    this.producerShare = apiModel.producerShare;
    this.biography = apiModel.biography;
    this.isFunded = apiModel.isFunded;
    this.isFundedWithDelay = apiModel.isFundedWithDelay;
    this.priceForShare = apiModel.priceForShare;
    this.projectDuration = apiModel.projectDuration;
    this.projectDurationTime = apiModel.projectDurationTime;
    this.slug = apiModel.slug;
    this.linkedProject = apiModel.linkedProject;
    this.song = apiModel.song;
    if (apiModel.offerTeaser) {
      this.offerTeaser = apiModel.offerTeaser.map(item => {
        item.icon = item.icon ? `${this.imgUrl}/${item.icon}` : '';
        return item;
      });
    }
    this.labelShareDistribution = apiModel.labelShareDistribution;
    this.mangoUserId = apiModel.mangoUserId;
    this.mangoWalletId = apiModel.mangoWalletId;
    this.soundtrack = apiModel.soundtrack;
    this.myOffersDescription = apiModel.myOffersDescription;
    this.termRoyaltiesMater = apiModel.termRoyaltiesMater;
    this.myOffersDetails = apiModel.myOffersDetails;
    this.myProjectDescription = apiModel.myProjectDescription;
    this.royaltiesFromMaster = apiModel.royaltiesFromMaster;
    this.royaltiesFromPublishingRights = apiModel.royaltiesFromPublishingRights;
    this.songs = apiModel.songs;
    if (this.songs && this.songs.length) {
      this.songs.forEach(item => {
        item.producersShare = item.producersShare ? item.producersShare.map(pr => {
          pr.selectCountry = !!pr.country;
          return pr;
        }) : [];
      });
    }
    this.totalInvestorsShares = apiModel.totalInvestorsShares;
    this.soldShare = this.artistShare + this.producerShare + (this.totalInvestorsShares - this.unSoldShare);
    if (this.unSoldShare) {
      this.unSoldShareInner = this.unSoldShare;
    }
    return this;
  }
}
