import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { ICurrency } from '@core/interfaces/currency.interface';

@Component({
  selector: 'app-popup-deliver-revenue',
  templateUrl: './popup-deliver-revenue.html',
})
export class PopupDeliverRevenueComponent {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() proceed: EventEmitter<any> = new EventEmitter<any>();
  @Input() revenue: ICurrency<number>;
  @Input() currency: 'eur' | 'usd' = 'eur';
  buttonSubmit: boolean;

  onCloseItem(): void {
    this.cancel.emit({ type: 'cancel' });
  }

  onSubmit(): void {
    if (!this.buttonSubmit) {
      this.buttonSubmit = true;
      this.proceed.emit({ type: 'confirmDelivery' });
    }
  }
}
