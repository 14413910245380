
<mat-tab-group mat-align-tabs="start" (selectedTabChange)="onChangeTab($event)">
  <mat-tab label="Wallets">
    <app-wallet-lists
      [list]="(list$ | async)?.list"
      (transferAmount)="transferMoney($event)"
    >
    </app-wallet-lists>
  </mat-tab>
  <mat-tab [label]="'History'">
   <app-table-template
     [rows]="(list$ | async)?.list"
     [headers]="headerTitle"></app-table-template>
    <div
      class="wrapper-pagination-footer"
      *ngIf="(list$ | async)?.total > filter?.paging?.limit && (list$ | async)?.list?.length"
    >
      <app-pagination
        [total]="(list$ | async)?.total"
        [paging]="filter.paging"
        (notify)="onPageChange($event)"
      ></app-pagination>
    </div>
  </mat-tab>

</mat-tab-group>
