import {UsersModel} from './users.model';
import * as moment from 'moment';
import {REVENUE_GROUPS} from '@core/common/typeInvestors';
import {ReleaseRevenueHistory} from '@core/common/interfaces';
import {HttpParams} from '@angular/common/http';

interface Song {
  project: any;
  slug: string;
  name: string;
}

export class ReleaseModel {
  constructor(host?) {
    this.imgUrl = host;
  }

  _id: string;
  allProjectReleased: boolean;
  artist: UsersModel;
  canBeDeleted: boolean;
  createdAt: string;
  date: string;
  imgUrl: string;
  isAlbum: boolean;
  isFunded: boolean;
  linkedProject: string;
  linkedRelease: string;
  projectTitle: string;
  releaseSongs: any[];
  report: string;
  revenue: number;
  revenueDelivered: number;
  revenueDeliveredUsd: number;
  revenueHistory: [ReleaseRevenueHistory];
  revenueUsd: number;
  slug: string;
  song: Song;
  songAlreadyReleased: any[];
  songs_with_revenues: any;
  songs: any;
  takenDown: boolean;

  setFromApiModel(apiModel): this {
    const artistArr = Array.isArray(apiModel.artist);
    this.artist = artistArr ?
      new UsersModel().setFromApiModel(apiModel.artist[0]) : new UsersModel().setFromApiModel(apiModel.artist);
    this._id = apiModel._id;
    this.canBeDeleted = apiModel.canBeDeleted;
    this.allProjectReleased = apiModel.allProjectReleased;
    this.createdAt = apiModel.createdAt;
    this.isAlbum = apiModel.isAlbum;
    this.report = apiModel.report;
    this.linkedRelease = apiModel.linkedRelease;

    if(apiModel.song.project){
      this.songs = apiModel.song.project.songs;
      this.isFunded = apiModel.song.project.isFunded;
      this.releaseSongs = apiModel.song.project.songs;
      this.projectTitle = apiModel.song.project.projectTitle;
      this.linkedProject = apiModel.song.project.linkedProject;
    }

    if (apiModel.releasedSongs && this.isAlbum) {
      this.songAlreadyReleased = apiModel.releasedSongs.map(item => {
        if (item.date.indexOf('.') >= 0) {
          item.date = moment(item.date, 'DD.MM.YYYY').format('YYYY/MM/DD');
        }
        return {
          name: item.name,
          songId: item.songId,
          date: moment(item.date, 'YYYY/MM/DD').format('YYYY/MM/DD'),
          showDate: moment(item.date, 'YYYY/MM/DD').format('YYYY/MM/DD')
        };
      }) || [];
    }
    this.date = apiModel.date;

    this.revenue = apiModel.revenue;
    this.revenueUsd = apiModel.revenueUsd;
    this.revenueHistory = apiModel.revenueHistory;
    if (this.revenueHistory && this.revenueHistory.length) {
      this.songs_with_revenues = {};
      this.revenueHistory.forEach(item => {
        if (apiModel.isAlbum && item.songId) {
          this.songs_with_revenues[item.songId] = true;
        }
        item.deliveryGroup = item.deliveryGroup ? REVENUE_GROUPS.filter(it => it.id === Number(item.deliveryGroup))[0] : 1;
      });
    }

    this.revenueDelivered = apiModel.revenueDelivered;
    this.revenueDeliveredUsd = apiModel.revenueDeliveredUsd;
    this.slug = apiModel.slug;
    this.song = apiModel.song;

    this.takenDown = apiModel.takenDown;
    return this;
  }
}


