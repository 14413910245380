<div class="view__wrapper">
  <div class="view__header">
    <h5 class="view__title main-title">Release</h5>
    <div class="status">
      <div
        class="released-album"
        [class.active]="releaseItem?.allProjectReleased && releaseItem?.isAlbum"
        *ngIf="releaseItem?.allProjectReleased && releaseItem?.isAlbum"
      >
        Album was released
      </div>
      <div
        class="released-album"
        [class.orange]="
          !releaseItem?.allProjectReleased &&
          releaseItem?.songAlreadyReleased?.length &&
          releaseItem?.isAlbum
        "
        *ngIf="
          !releaseItem?.allProjectReleased &&
          releaseItem?.songAlreadyReleased?.length &&
          releaseItem?.isAlbum
        "
      >
        Song was released
      </div>
    </div>
  </div>
  <div class="view__content">
    <ng-template ngIf [ngIf]="!viewMode">
      <div class="item-row search">
        <div class="item-label header-inform-text-grey">Artist*</div>
        <div class="search-field">
          <app-find-by-name
            [text]="text"
            [selectedArtist]="selectedArtist"
            [slug]="releaseItem"
            (notify)="onNotify($event)"
          ></app-find-by-name>
        </div>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey">
          {{ releaseItem && releaseItem?.isAlbum ? "Album" : "Song" }}*
        </div>
        <mat-form-field class="item-field" *ngIf="!releaseItem || !slug">
          <mat-select
            [disabled]="!selectedArtist?.slug"
            [formControl]="songCtrl"
            (selectionChange)="selectSong($event)"
          >
            <mat-option
              *ngFor="let item of selectedArtist?.projects"
              [value]="item.slug"
            >
              {{
                item?.projectDetail?.isAlbum ||
                item?.projectDetail.linkedProject
                  ? item?.projectDetail?.projectTitle
                  : item.name
              }}
              {{ item?.projectDetail.linkedProject ? "(Linked project)" : "" }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="item-field"
          *ngIf="slug && !releaseItem?.isAlbum"
        >
          <input
            matInput
            [formControl]="songCtrl"
            id="autocomplete"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field class="item-field" *ngIf="slug && releaseItem?.isAlbum">
          <input
            matInput
            [(ngModel)]="releaseItem.song.project.projectTitle"
            [disabled]="true"
            id="projectTitle"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <p
        class="warning linked-project-text"
        *ngIf="selectedProject?.projectDetail?.linkedProject"
      >
        It is linked project
      </p>
      <div
        class="item-row"
        *ngIf="selectedProject?.projectDetail?.isAlbum || releaseItem?.isAlbum"
      >
        <div class="item-label header-inform-text-grey">Release type</div>
        <div class="item-field item-field_align">
          <mat-radio-group
            [(ngModel)]="isSeparateSong"
            [ngModelOptions]="{ standalone: true }"
            (change)="selectAlbumSong()"
          >
            <mat-radio-button
              value="album"
              class="m20-right"
              *ngIf="
                selectedProject?.projectDetail?.isFunded ||
                releaseItem?.isFunded
              "
              name="search-and"
            >
              <span class="radio-button-text_grey">Release Album</span>
            </mat-radio-button>
            <mat-radio-button
              value="song"
              name="search-and"
              *ngIf="!releaseItem?.allProjectReleased"
              ><span class="radio-button-text_grey">Release Album's Song</span>
            </mat-radio-button>
          </mat-radio-group>
          <button
            class="button green default-size m20-left"
            *ngIf="
              isSeparateSong === 'song' &&
              (selectedProject || (slug && !is_left_song))
            "
            (click)="addReleaseSong()"
          >
            Add Release Song
          </button>
        </div>
      </div>
      <div
        class="item-row m20-top m20-bottom"
        *ngIf="isSeparateSong === 'song' && (showSongsTable || slug)"
      >
        <div class="item-label header-inform-text-grey"></div>
        <div class="item-field">
          <div class="item-table-header flex">
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              Release Song*
            </div>
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              Date release*
            </div>
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              Actions
            </div>
          </div>
          <div
            class="item-row-content flex"
            *ngFor="let item of releaseItem?.songAlreadyReleased"
          >
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              <span class="item-value item-value_color-light">{{
                item.name
              }}</span>
            </div>
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              <span class="item-value item-value_color-light">{{
                item?.showDate
              }}</span>
            </div>
            <div
              class="item-cell item-cell_center flex-25 item-cell_padding-all"
            >
              <div
                *ngIf="!item?.no_delete"
                class="delete actions-item"
                (click)="deleteSongRelease(item)"
              >
                <i class="fa fa-trash-o"></i>
              </div>
              <div *ngIf="item?.no_delete" class="delete actions-item">
                Distributed
              </div>
            </div>
          </div>
          <div
            class="item-row-content flex"
            *ngFor="let song of form.get('albumSongs').controls; let i = index"
          >
            <mat-form-field
              class="flex-25 item-cell item-cell_center item-cell_padding"
            >
              <mat-select [formControl]="song.controls['song']">
                <mat-option *ngFor="let item of projectSongs" [value]="item._id"
                  >{{ item?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              class="item-cell item-cell_center item-row-cell_padding flex-25"
            >
              <mat-form-field appearance="fill" class="item calendar">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [formControl]="song.controls['date']"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="flex-25 actions item-cell item-cell_center">
              <div class="delete actions-item" (click)="removeSong(item, i)">
                <i class="fa fa-trash-o"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-row" *ngIf="slug || releaseNamesCtrl?.value">
        <div class="item-label header-inform-text-grey">Song release name</div>
        <mat-form-field class="content-item-w">
          <input
            matInput
            [formControl]="releaseNamesCtrl"
            id="autocomplete"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="item-row" *ngIf="isSeparateSong === 'album'">
        <div class="item-label header-inform-text-grey">Release Date*</div>
        <div class="form-row">
          <mat-form-field appearance="fill" class="item calendar">
            <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="item-row" *ngIf="slug">
        <div class="item-label header-inform-text-grey">
          Total gross revenue
        </div>
        <mat-form-field class="content-item-w">
          <input
            matInput
            [formControl]="totalGrossCtrl"
            id="autocomplete"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="item-row" *ngIf="slug">
        <div class="item-label header-inform-text-grey">
          Total Gross revenue Delivered
        </div>
        <mat-form-field class="content-item-w">
          <input
            matInput
            [formControl]="totalGrossDeliveredCtrl"
            id="autocomplete"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div
        class="item-row upload-item"
        *ngIf="
          (selectedProject?.projectDetail?.isAlbum ||
            !selectedProject?.projectDetail?.isAlbum) &&
          isSeparateSong === 'album'
        "
      >
        <div class="item-label header-inform-text-grey">Report</div>
        <app-upload-document
          [buttonTitle]="'Upload revenue'"
          [formControl]="fileReportCtrl"
          *ngIf="
            !selectedProject?.projectDetail?.linkedProject &&
            !fileReportCtrl?.value
          "
        >
        </app-upload-document>

        <div class="url">{{ fileReportCtrl?.value }}</div>
      </div>
      <div class="item-row">
        <div class="item-label header-inform-text-grey">
          Take down from stores
        </div>
        <div class="form-row">
          <mat-checkbox
            class="example-margin"
            [name]="'all'"
            [id]="'all'"
            [(ngModel)]="checkItem"
          >
          </mat-checkbox>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="viewMode">
      <table class="table table-striped table-bordered table-hover dataTable">
        <thead>
          <tr class="border-bottom-color-c7cbce">
            <th>#</th>
            <th width="50%">Key</th>
            <th width="50%">Value</th>
          </tr>
        </thead>
        <tbody class="numeration-wrapper">
          <tr>
            <td class="numeration">1</td>
            <td class="white-space-nowrap">Artist</td>
            <td>
              <a class="link-blue">{{ releaseItem?.artist?.name }}</a>
            </td>
          </tr>
          <tr>
            <td class="numeration">2</td>
            <td class="white-space-nowrap">
              {{ releaseItem?.isAlbum ? "Album" : "Song" }}
            </td>
            <td>
              <a class="link-blue">{{
                releaseItem?.isAlbum
                  ? releaseItem?.song?.project?.projectTitle
                  : releaseItem?.song?.name
              }}</a>
            </td>
          </tr>
          <tr>
            <td class="numeration">3</td>
            <td class="white-space-nowrap">Song release name</td>
            <td>{{ releaseItem?.song?.project?.song?.releaseNames }}</td>
          </tr>
          <tr>
            <td class="numeration">4</td>
            <td class="white-space-nowrap">Release date</td>
            <td>{{ releaseItem?.date }}</td>
          </tr>
          <tr>
            <td class="numeration">5</td>
            <td class="white-space-nowrap">Gross revenue</td>
            <td>
              <span>{{ releaseItem?.revenue | currency : "EUR" }}</span>
              <span *ngIf="releaseItem?.revenueUsd">
                / {{ releaseItem.revenueUsd | currency : "USD" }}</span
              >
            </td>
          </tr>
          <tr>
            <td class="numeration">6</td>
            <td class="white-space-nowrap">Gross revenue Delivered</td>
            <td>
              <span>{{
                releaseItem?.revenueDelivered | currency : "EUR"
              }}</span>
              <span *ngIf="releaseItem?.revenueDeliveredUsd">
                / {{ releaseItem.revenueDeliveredUsd | currency : "USD" }}</span
              >
            </td>
          </tr>
          <tr>
            <td class="numeration">7</td>
            <td class="white-space-nowrap">Taken down from stores</td>
            <td>{{ releaseItem?.takenDown ? "Yes" : "No" }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <div class="view__buttons flex flex-end">
      <button
        class="button green default-size"
        *ngIf="!viewMode"
        (click)="saveRelease()"
        [disabled]="submitted"
      >
        Save changes
      </button>
      <button
        class="button blue default-size m20-left"
        *ngIf="viewMode"
        (click)="editDocument()"
      >
        Edit document
      </button>
      <button
        class="button blue default-size m20-left"
        *ngIf="!viewMode && slug"
        (click)="toggleRegime()"
      >
        View mode
      </button>
      <button
        class="button blue default-size m20-left"
        *ngIf="releaseItem?.linkedRelease"
        (click)="redirectTolinkedRelease()"
      >
        Main release
      </button>
    </div>
    <app-release-revenue
      [releaseItem]="releaseItem"
      [onlyView]="!!releaseItem?.linkedRelease"
      [viewMode]="viewMode"
      [disableTransfer]="disableTransfer"
      (notify)="onNotify($event)"
    ></app-release-revenue>
  </div>
</div>
