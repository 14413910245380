import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  UntypedFormGroup
} from "@angular/forms";
import {PAYMENT_TYPE} from "@core/common";
import {AppToastrService} from "@core/services";
import {CURRENCIES, WALLET_TYPE} from "@core/pages/releases/component/content";
import {PayIn} from "@core/pages/releases/type/pay-in.type";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {WalletService} from "@core/pages/releases/service/wallet.service";

@UntilDestroy()
@Component({
  selector: 'app-create-pay-in',
  templateUrl: './create-pay-in.html',
  styleUrls: ['./_create-pay-in.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePayInComponent implements OnInit {
  form: UntypedFormGroup;
  showBlock: boolean;
  types = PAYMENT_TYPE;
  walletType = WALLET_TYPE;
  currencies = CURRENCIES;

  constructor(private toastr: AppToastrService,
              private depositWalletService: WalletService,
  ) {
  }

  ngOnInit() {
    this.form = this.depositWalletService.initializeForm();
  }

  createPayIn() {
    if (this.form?.valid) {
      const data: PayIn = {...this.form.getRawValue(), url: location.href}
      this.depositWalletService.getPayIn(data).pipe(
        catchError((err) => {
          this.toastr.showToastFromError(err);
          return throwError(err);
        }),
        untilDestroyed(this)
      ).subscribe((url: string) => {
        location.replace(url);
      });
    }
  }
}
