import {Component, OnInit, ViewContainerRef} from '@angular/core';
//import {TransitionService} from '@uirouter/angular';
import {StorageService, AuthenticationService, RoutingService} from 'app/services/index';
import {STATE_NAMES} from 'app/common/index';
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./_header.scss'],
})
export class HeaderComponent implements OnInit {
  homeState: string;
  user: any = {};
  image: string;
  menuActive = false;
  links: any[] = [];
  currentState: string;
  height: number;

  constructor(//private stateService: StateService,
              private routingService: RoutingService,
              private authenticationService: AuthenticationService,
              private el: ViewContainerRef,
              private router: Router,
             // private transitionService: TransitionService,
              private storage: StorageService) {
    this.onUserChange();
    this.onStateChange();
  }

  ngOnInit() {
    this.prepareData();
  }

  prepareData() {
    this.user = this.storage.user;
    this.image = this.user ? this.user.image : null;
    this.homeState = STATE_NAMES.home;
    this.height = this.links.filter(i => !i.hide).length * 44;
  }

  onUserChange() {
    this.storage.localItemChanged().subscribe((res) => {
      if (res && res.hasOwnProperty('currentUser')) {
        this.user = this.storage.user;
        this.image = this.user ? this.user.image : null;
        if (this.links && this.links.length) {
          this.links[1].hide = this.user && !this.user.projectCreationAllowed;
          this.height = this.links.filter(i => !i.hide).length * 44;
        }

        this.prepareData();
      }
    });
  }

  onStateChange() {
   // this.currentState = this.stateService.current.name;
   //  this.transitionService.onSuccess({}, transition => {
   //    this.currentState = transition.router.stateService.current.name;
   //  });
  }

  goToLogin() {
    this.router.navigate(['logout']);
      // this.authenticationService.logout();
  }
}
