import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AppToastrService, UploadService} from '../../../services/index';
import {Observable, Subject} from "rxjs";
import {distinctUntilChanged, map, shareReplay} from "rxjs/operators";

@Component({
  selector: 'app-upload-audio',
  templateUrl: './upload-audio.html',
  styleUrls: ['./_upload-audio.scss'],
})
export class UploadAudioComponent implements OnInit {
  date: string;
  @Input() audioCtrl: AbstractControl;
  @Input() settings: any;
  @Input() config: any;
  dates: any[];
  audioValue: unknown;
  destroy$ = new Subject();
  uploadProgress$: Observable<number>;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
    this.uploadProgress$ = this.uploadService.uploadProgress$.pipe(
      distinctUntilChanged(),
      map(progress => {
        let percent = 0;
        switch (true) {
          case progress.loaded == 0:
            percent = 100;
            break;
          case progress.loaded !== progress.total:
            percent = Math.round(progress.loaded / progress.total) * 100;
            break;
          case progress.loaded === progress.total:
            percent = 90;
            break;
        }
        return percent;
      }),
      shareReplay(1)
    );

  }

  uploadAudio(event: Event): boolean | void {
    let target = event.target as HTMLInputElement;
    this.audioValue = target.value;

    const data = this.uploadService.prepareAudioFile(target);

    if (data.error) {
      this.toastr.showErrorToast({title: data.error});
      return false;
    }

    this.uploadService.uploadFileAudioAdmin(data.fileUpload, {
      type: 'project-soundtracks',
      contentType: data.file.type,
      name: data.file.name.replace(/ /g, '-').replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
    })
      .subscribe((res) => {
        this.audioCtrl.setValue(res.url);
      }, (err) => {
        this.toastr.showToastFromError(err);
      });
  }

  cancelUpload(): void {
    this.audioCtrl.reset();
    this.audioValue = null;
  }
}
