import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

// Services
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent implements OnInit {
  showList: boolean = false;
  count: number = 0;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.notifications$
      .pipe(map((list: string[]) => list.length))
      .subscribe((count: number) => {
        this.count = count;
        if (!this.count) {
          this.showList = false;
        }
      });
  }

  toggleList(): void {
    if (!this.count) {
      return;
    }
    this.showList = !this.showList;
  }
}
