import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment/moment";

@Pipe({name: 'momentDate'})
export class MomentDatePipe implements PipeTransform {
  transform(value, format?: string) {
    if (value) {
      if(moment.isMoment(value)){
        return value.format(format || 'DD.MM.YYYY');
      }
      return moment(value).format(format || 'DD.MM.YYYY');
    }
  }
}
