<mat-sidenav-container class="notifications-list__container">
  <mat-sidenav
    #sidenav
    mode="side"
    class="notifications-list__list"
    [fixedInViewport]="true"
    [fixedTopGap]="50"
    [fixedBottomGap]="50"
    [disableClose]="true"
    position="end"
    [opened]="toggle"
  >
    <div
      class="notifications-list__item notification-item"
      *ngFor="let item of list; let i = index"
    >
      <p class="notification-item__text">{{ item }}</p>
      <i
        class="fas fa-trash notification-item__icon"
        (click)="removeItem(item)"
      ></i>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
