<div class="item-view-container">
  <div class="m20-bottom m20-top heading">Editing producer</div>
  <mat-divider></mat-divider>
  <div class="item-view-container_block">
    <form [formGroup]="editingForm" class="editing-form">
      <div class="heading">Overall</div>

      <mat-form-field
        class="mat-form-field form-field readonly"
        appearance="fill"
      >
        <mat-label>ID</mat-label>
        <input matInput readonly formControlName="id" />
      </mat-form-field>

      <mat-form-field
        class="mat-form-field form-field readonly"
        appearance="fill"
      >
        <mat-label>Slug</mat-label>
        <input matInput readonly formControlName="slug" />
      </mat-form-field>

      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Mango User ID</mat-label>
        <input matInput readonly formControlName="mangoUserId" />
      </mat-form-field>

      <div class="heading">Personal</div>

      <div formGroupName="contact">
        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" />
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" />
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Birthdate</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [formControl]="birthDateCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>Telephone</mat-label>
          <input matInput formControlName="telephone" />
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>

        <mat-form-field class="mat-form-field form-field" appearance="fill" *ngIf="regions && regions[editingForm.get('country').value]">
          <mat-label>Region</mat-label>
          <mat-select formControlName="region" id="region"
          >
            <mat-option *ngFor="let region of regions[editingForm.get('country').value]" [value]="region.id">{{region.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Billing Address</mat-label>
        <input matInput formControlName="billingAddress" />
      </mat-form-field>
      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Postal code</mat-label>
        <input matInput formControlName="postalCode" />
      </mat-form-field>
      <mat-form-field class="mat-form-field form-field" appearance="fill">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let item of listOfCountries" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="flex flex-end m20-bottom button-wrapper">
    <button
      class="button green default-size"
      (click)="saveChanges()"
      [disabled]="editingForm?.invalid"
    >
      <span>Save producer</span>
    </button>
    <!--<button type="button" class="button red default-size delete" (click)="showPopupConfirmation = true"-->
    <!--&gt;-->
    <!--Delete User-->
    <!--</button>-->
  </div>
</div>

<app-popup-confirmation
  [config]="popupConfig"
  *ngIf="showPopupConfirmation"
  (notify)="onNotify($event)"
></app-popup-confirmation>
