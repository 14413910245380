//import {StateService, UrlService, StateOrName, RawParams} from '@uirouter/angular';
import {Injectable} from '@angular/core';
import {STATE_NAMES} from 'app/common/state-names';
import {AuthenticationService} from '@core/services/authentication.service';


@Injectable()
export class RoutingService {
  constructor(//private stateService: StateService,
             // private urlService: UrlService
             ) {

  }

  get params(): any {
    return {};
  }
  //
  go(to: any, rawParams?: any, reload = false) {
   // this.stateService.go(to, rawParams, {reload});
  }
  //
  goToLoginPage() {
    // if (this.stateService.current.name !== STATE_NAMES.login) {
    //   this.stateService.go(STATE_NAMES.login);
    // }
  }
  //
  //
  queryString(key: string): any {
   // return this.urlService.parts().search[key];
  }
}
