import 'reflect-metadata';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UIRouterModule } from '@uirouter/angular';
import { AngularEditorModule } from '@kolkov/angular-editor';

import {
  DashboardComponent,
  DefaultMasterPageComponent,
  LoginPageComponent,
  UpcomingComponent,
} from './index';
import { AuthenticationInterceptor, ResponseInterceptor } from '../common';
import { ComponentsModule } from '../component/components.module';
import {
  AppToastrService,
  ArtistsService,
  AuthenticationService,
  DictionaryService,
  LandingService,
  OpportunityService,
  ProjectService,
  ReleasesService,
  RoutingService,
  RoyaltiesService,
  StorageService,
  UploadService,
  UserSettingService,
  UsersService,
} from '../services';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TokenService } from '@core/services/token.service';
import { ColabelService } from '@core/services/colabel.service';
import { SettingService } from '@core/services/setting.service';
import { CollectibleService } from '@core/services/collectible.service';
import { MatTabsModule } from '@angular/material/tabs';
import { PageTemplateModule } from '@core/pages/page-template/page-template.module';
import { GlobalTemplateModule } from '@core/component/global/global-template.module';
import { PopUpModule } from '@core/component/global/popup-items/pop-up.module';

import { BaseService } from '@core/services/templates/base.service';
import { FilterPageModule } from '@core/component/filter/filter.module';
import { FindProjectService } from '@core/component/find-user-template/find-project.service';
import { InvestorsBaseService } from '@core/services/templates/investors-base.service';
import { TokenModule } from '@core/component/nft-token-component/token.module';
import { ShareModule } from '@core/share.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AccountingModule } from '@core/pages/accounting/accounting.module';
import { ProjectFeaturesService } from '@core/services/project-features.service';
import {UploadModule} from "@core/component/upload/upload.module";
import {UiModule} from "@core/component/ui/ui.module";

import { ProjectSettingsModule } from '@core/component/projects-parts/project-settings/project-settings.module';
//import {MangopayModule} from "@core/component/mangopay/mangopay.module";

@NgModule({
  exports: [
    DefaultMasterPageComponent,
    DashboardComponent,
    LoginPageComponent,
    UpcomingComponent,
  ],
  declarations: [

    DashboardComponent,
    DefaultMasterPageComponent,
    LoginPageComponent,
    UpcomingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    UIRouterModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatInputModule,
    UiModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    ComponentsModule,
    GlobalTemplateModule,
    PopUpModule,
    PageTemplateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    AccountingModule,
    UploadModule,
    ToastrModule.forRoot(),
    AngularEditorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatDividerModule,
    MatTabsModule,
    FilterPageModule,
    MatListModule,
    MatRadioModule,
    MatExpansionModule,
    MatDialogModule,
    PipesModule,
    MatTooltipModule,
    TokenModule,
    ShareModule,
    ProjectSettingsModule,
   // MangopayModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    StorageService,

    SettingService,
    AuthenticationService,
    RoutingService,
    RoyaltiesService,
    UsersService,
    FindProjectService,
    UserSettingService,
    AppToastrService,
    OpportunityService,
    InvestorsBaseService,
    DictionaryService,
    ReleasesService,
    UploadService,
    ProjectService,

    LandingService,
    ArtistsService,
    ProjectFeaturesService,
    BaseService,
    TokenService,
    ColabelService,
    CollectibleService,
  ],
})
export class PagesModule {
  constructor() {}
}
