import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {AppToastrService} from "@core/services";
import {BehaviorSubject} from "rxjs";
import {TokenService} from "@core/services/token.service";
import {RequestTokenModel} from "@core/model/request.-tokenmodel";

export interface IListTokens {
  slug: string;
  amountTokens: string;
  checked?: boolean;
  inProgress: boolean;
  nft: {
    accountId: string;
  }

}

@Component({
  selector: 'app-burn-token',
  templateUrl: './burn-token.html',
  styleUrls: ['./_burn-token.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurnTokenComponent {
  findTokenButton: boolean;
  selectProject = new BehaviorSubject<any>({});
  selectUser = new BehaviorSubject<IListTokens>({} as IListTokens);
  listTokens: IListTokens[];
  amountToBurn: string;
  disableBurnButton: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private tokenService: TokenService,
    private toastr: AppToastrService
  ) {
  }

  findTokens(): boolean {
    let project = this.selectProject.getValue();
    if (!project) {
      return false;
    }
    this.findTokenButton = true;
    const request = new RequestTokenModel({projectId: project.song.nft.id})
    this.tokenService.getTokens(request).subscribe(resp => {
      this.listTokens = resp;
      this.cd.detectChanges();
      this.findTokenButton = false;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  burn(): boolean {
    let project = this.selectProject.getValue();
    if (!project) {
      return false;
    }
    if (isNaN(Number(this.amountToBurn)) || Number(this.amountToBurn) <= 0) {
      return false;
    }
    this.disableBurnButton = true;
    let data = {
      projectId: project.song.nft.id,
      user: this.selectUser.getValue().slug,
      amount: this.amountToBurn,
      accountId: this.selectUser.getValue().nft.accountId
    }


    this.listTokens = this.listTokens.map(item => {
      return { ...item, inProgress: !!item.checked }
    });

    this.tokenService.burnTokens(data).subscribe(() => {
      this.selectUser.next(null);
      this.selectProject.next(null);
      this.disableBurnButton = false;
      this.cd.detectChanges();
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  selectCheckbox(token) {
    this.listTokens.forEach(item => {
      if (item !== token) {
        item.checked = false;
      }
      if (item.checked) {
        this.selectUser.next(item);
      }
    });
  }
}
