<div class="wrapper-component">
  <div class="top-part content__row">
    <div class="content__search search" *ngIf="!sStatementAllUsers" [formGroup]="form">
      <div class="search__elem search__elem_m-right">
        <mat-form-field class="mat-input">
          <mat-label>Search by</mat-label>
          <mat-select [formControl]="form?.get('type')" name="value7">
            <mat-option *ngFor="let cat of sStatementCategory" [value]="cat.value">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-find-by-name-universal *ngIf="form?.get('type')?.value"
        [type]="form?.get('type')?.value === 'email' ? form.get('type').value : 'contactLastName'"
        [paramDisplay]="'slug'"
        [placeholder]="'User'"
        [formControlCustom]="form?.get('user')"
        [saveParam]="'slug'"
        [errorText]="'User is not found'"
      ></app-find-by-name-universal>
    </div>
    <div class="content__elem search-all-users content__elem_m-right">
      <mat-checkbox class="example-margin"
                    [name]="'allUsers'"
                    [id]="'allUsers'"
                    [(ngModel)]="sStatementAllUsers">
      </mat-checkbox>
      <div class="content__show">
        Select All users
      </div>
    </div>
  </div>
  <div class="bottom-part content__row">
    <app-pick-date
      *ngIf="findUserStatement || sStatementAllUsers"
      (selectDate)="selectMonth($event, '_statementDate')"></app-pick-date>
    <div class="content__elem_m-right">
      <button class="button green default-size" [disabled]="showSpinnerStatement || !_statementDate"
              (click)="downloadStatements()"> Download
      </button>
    </div>
    <div class="content__spinner spinner">
      <mat-spinner *ngIf="showSpinnerStatement" [diameter]="20"></mat-spinner>
    </div>
  </div>
</div>
<div class="content__row url" *ngIf="urlStatementZipFile">
          <span>File Zip has been generated. You can
            download it from <a href="{{urlStatementZipFile}}" class="link-blue" target="_blank">link</a></span>
</div>
