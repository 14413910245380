import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';

@Component({
  selector: 'app-landing-video',
  templateUrl: './landing-video.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingVideoComponent implements OnInit {
  form: UntypedFormGroup;
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() video: any;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): any {
    this.form = this.fb.group({
      isEnabled: new UntypedFormControl(false),
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      link: new UntypedFormControl(''),
    });
    if (this.video) {
      this.form.patchValue(this.video);
    }
  }
}

