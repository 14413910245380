import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Filter} from '@core/common/interfaces';
import {AppToastrService, AuthenticationService, DictionaryService, RoutingService, UsersService} from '@core/services';
import {STATE_NAMES} from '@core/common';
import {RequestModel} from '@core/model/request.model';
import {IArtistEditing} from '@core/model/artists-list.model';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-producers-list',
  templateUrl: './producers-list.component.html',
  styleUrls: ['./producers-list.component.scss'],
  // providers: [ArtistsService]
})
export class ProducersListComponent implements OnInit {

  searchFilter: any[] = [
    {
      name: 'Email',
      value: 'email'
    },
    {
      name: 'Name',
      value: 'name'
    }];

  rangeFilter: any[] = [
    {
      name: 'Created at',
      value: 'createdAt'
    },
    {
      name: 'Updated at',
      value: 'updatedAt'
    }
  ];

  selectedSearchCategory: string = '';
  searchCategoryValue: string = '';
  selectedRangeFilter: string = '';

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc'
    },
    state: null,
    country: null,
    featured: null,
    name: null,
    createdFrom: null,
    createdTo: null,
    updatedFrom: null,
    updatedTo: null,
    email: null,
  };

  total: number;

  mainFilter = new UntypedFormControl();
  stateFilter = new UntypedFormControl();
  featuredFilter = new UntypedFormControl();
  countryFilter = new UntypedFormControl();

  categoryMainFilter: string[] = ['State', 'Featured', 'Country'];

  categoryStateFilter = [
    {value: 'pending', viewValue: 'Pending'},
    {value: 'verified', viewValue: 'Verified'},
    {value: 'deleted', viewValue: 'Deleted'}
  ];

  categoryFeaturedFilter = [
    {value: 'true', viewValue: 'True'},
    {value: 'false', viewValue: 'False'}
  ];

  categoryCountryFilter = [];
  artistData: IArtistEditing[];

  constructor(
    private userService: UsersService,
    private dictionaryService: DictionaryService,
    private toastr: AppToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getList();
    this.dictionaryService.getCountries();
    this.dictionaryService.onGetCountries
      .subscribe(resp => {
        this.categoryCountryFilter = resp;
      });
  }

  searchByCategory(): void {
    this.filter.name = null;
    this.filter.email = null;
    if (this.selectedSearchCategory === 'name') this.filter.name = this.searchCategoryValue;
    else {
      this.filter.email = this.searchCategoryValue;
    }

    this.getList();
  }

  searchByRange(): void {
    if (this.selectedRangeFilter === 'updatedAt') {
      this.filter.updatedFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.updatedTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.createdFrom = null;
      this.filter.createdTo = null;
    } else {
      this.filter.createdFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.createdTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.updatedFrom = null;
      this.filter.updatedTo = null;
    }

    this.getList();
  }


  onNotify(e): void {
    if (e.type === 'sorting') {
      this.getList();
    }
  }


  openItem(slug: string, type: 'view' | 'edit'): void {
    this.router.navigate([type, slug], { relativeTo: this.route })
  }

  onSelectCategory() {
    delete this.filter.state;
    delete this.filter.country;
    delete this.filter.featured;

    if (this.mainFilter.value === 'State') {
      const arr = this.stateFilter.value.map(item => {
        return item.value;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = arr;
    }
    if (this.mainFilter.value === 'Featured') {
      this.filter[this.mainFilter.value.toLowerCase()] = this.featuredFilter.value.value;
    }
    if (this.mainFilter.value === 'Country') {
      const country = this.countryFilter.value.map(item => {
        return item.id;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = country;
    }
    this.getList();
  }

  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }

  getList(): void {
    const request = new RequestModel(this.filter);
      this.userService.getProducers(request).subscribe(resp => {
        this.artistData = resp.list;
        this.total = resp.total;
      }, err => {
        this.toastr.showToastFromError(err);
      });
  }


  resetSearchFiler(): void {
    this.filter.name = null;
    this.filter.email = null;
    this.searchCategoryValue = null;
    this.selectedSearchCategory = null;

    this.getList();
  }

  resetCategoryFiler(): void {
    this.mainFilter.setValue(null);
    this.stateFilter.setValue(null);
    this.featuredFilter.setValue(null);
    this.countryFilter.setValue(null);
    this.filter.state = null;
    this.filter.country = null;
    this.filter.featured = null;
    this.getList();
  }

  resetRangeFilter(): void {
    this.selectedRangeFilter = null;
    this.filter.updatedFrom = null;
    this.filter.updatedTo = null;
    this.filter.createdFrom = null;
    this.filter.createdTo = null;

    this.range.get('start').patchValue(null);
    this.range.get('end').patchValue(null);

    this.getList();
  }

  resetSorting(): void {
    this.filter.sorting = {
      sortBy: 'createdAt',
      sortHow: 'desc'
    };

    this.getList();
  }
}
