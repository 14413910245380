<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onCloseItem()"></span>
    <div class="title">Upload information</div>
    <div class="content">
      <div class="date m20-bottom" *ngIf="revenue?.isNotDate">
        <div class="date-text">Date was not found. Please provide date</div>
      <mat-form-field appearance="fill" class="item calendar" >
        <mat-label>Input date</mat-label>
        <input matInput [matDatepicker]="dp" [(ngModel)]="date"  (ngModelChange)="chosenDate($event)">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker  #dp
                         startView="multi-year"
                        #picker></mat-datepicker>
      </mat-form-field>
      </div>
    <div class="matches" *ngIf="revenue?.uniqMatches && revenue?.uniqMatches?.length">
      <div class="block-title title-global title-warning">Warning!</div>
      <div class="text">List Artist's names are not matched:</div>
      <div class="list-wrapper list">
          <div class="item" *ngFor="let item of revenue?.uniqMatches; let i = index">
           {{i + 1}}. {{item}}
          </div>
      </div>
    </div>
    <div class="summary">
      <div class="item">TOTAL MATCHED RELEASES: {{revenue?.totalRelease}}</div>
      <div class="item">TOTAL AMOUNT: {{revenue?.amount}}</div>
      <div class="item" *ngIf="revenue?.exchangeRate?.from">EXCHANGE RATE {{revenue?.exchangeRate?.from === 'USD' ? 'USD/EUR' : ''}}: {{revenue?.exchangeRate?.rate}}</div>
    </div>
    <div class="flex flex-align button-wrapper">
      <button class="button blue text-uppercase wide-size" (click)="onCloseItem()">
        Cancel
      </button>
      <button class="button green text-uppercase wide-size"
              [disabled]="revenue?.isNotDate && !date"
              (click)="onSubmit()">
        Proceed
      </button>
    </div>

  </div>
  </div>

</app-popup>

