import { Injectable } from '@angular/core';
import { AbstractBaseFindService } from './abstract-base-find.service';
import { UsersService } from '../../services';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { RequestUserModel } from '@core/model';

@Injectable()
export class FindUserService implements AbstractBaseFindService {
  public form: UntypedFormGroup;
  public typeSelect: string = 'base';
  public type: string;
  // public list: any;
  //  public deleted: boolean;
  public paramDisplay: string;
  public list$: Observable<any>;

  constructor(
    private _userService: UsersService,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      searchValue: ['', [Validators.required]],
    });

    this.list$ = this.form.get('searchValue').valueChanges.pipe(
      filter((v: any) => v !== null && v.length > 2),
      debounceTime(500),
      switchMap((value) => {
        return this.searchText(value);
      }),
      shareReplay(1)
    );
  }

  makeEmpty() {}

  getList(model: RequestUserModel) {
    return this._userService.findValueByCriteria(model).pipe(
      map((resp: any) => {
        if (resp) {

          let filtered = this.filterListBy(resp);

          filtered.forEach((item) => {
            item.showDisplayName = this.paramDisplay
              ? item[this.paramDisplay]
              : item.contact.email;
          });

          return filtered;
        }
      })
    );
  }

  searchText(value): Observable<any> {
    return this.getList(
      new RequestUserModel({ [this.type]: value, select: this.typeSelect })
    );
  }

  selectItem(selected) {};

  private filterListBy (array){

    switch (this.typeSelect){
      case 'bank' : {
        return array.filter((item) => {
          return !!item.bankAccounts?.length;
        });
      }
      case 'billing' : {
        return array.filter((item) => {
          return (
            item.contact?.firstName &&
            item.contact?.lastName &&
            item.country &&
            item.billingAddress &&
            item.postalCode
          );
        });
      }
      case 'unsoldOwner' : {
       return array.filter((item) => item.nft?.walletId);
      }
      case 'mangoUser' : {
        return array.filter((item) => item.mangoUserId);
      }
      default: {
        return array;
      }
    }
  }
}
