import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppToastrService} from "@core/services";
import {catchError} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ResponseWalletInterface} from "@core/pages/releases/type/response-wallets.type";
import { WalletService } from '../../service/wallet.service';


@UntilDestroy()
@Component({
  selector: 'app-wallet-balance',
  templateUrl: './wallet-balance.html',
  styleUrls: ['./_wallet-balance.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletBalanceComponent implements OnInit{
  private walletInit: ResponseWalletInterface = {
    deposit: {usd: '0', eur: '0'},
    refund: {usd: '0', eur: '0'}
  }
  walletBalance$: Observable<ResponseWalletInterface>;
  constructor(private toastr: AppToastrService,
              private walletService: WalletService,
             ) {
  }
  ngOnInit() {
   this.walletBalance$ = this.walletService.getAdminWalletBalance()
     .pipe(
       catchError((err) => {
      //   this.toastr.showToastFromError(err);
         return of(this.walletInit);
       }),
       untilDestroyed(this))
  }
}
