import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl, FormGroup
} from '@angular/forms';
import {ArtistCategory} from "@core/interfaces/project.interface";
import {IRightsHolder} from "@core/model/project-base-main.model";

@Component({
  selector: 'project-rights-holder',
  templateUrl: './project-rights-holder.component.html',
  styleUrls: ['./project-rights-holder.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProjectRightsHolderComponent implements OnInit {
  typeControl: AbstractControl;
  public form;
  @Input() category: 'diy' | 'grs' | 'exclusive';
  @Input() parentForm: any;
  @Input() rightsHolder: IRightsHolder;
  constructor() {
  }

  ngOnInit(): void {
    this.createForm();
    if (this.category) {
      this.changeControls(this.category);
      this.patchForm()
    } else {
      this.subscribeFormChange();
    }
  }

  patchForm() {
    this.rightHolderGroup.patchValue(this.rightsHolder);
  }

  private subscribeFormChange() {
    this.parentForm.controls.artistCategory.valueChanges.subscribe(
      (category: ArtistCategory) => {
        this.changeControls(category);
      }
    );
  }

  private removeControls(controlName: string): void {
    this.rightHolderGroup.contains(controlName) &&
    this.rightHolderGroup.removeControl(controlName);
  }

  private addControls(controlName: string): void {
    this.rightHolderGroup.addControl(controlName, new FormControl(''));
  }
  get rightHolderGroup() {
    return this.detailsGroup?.get('rightsHolder') as FormGroup;
  }

  get detailsGroup() {
    return this.parentForm?.get('details') as FormGroup;
  }

  private createForm(): void {
    this.detailsGroup.addControl('rightsHolder', new FormGroup({
      user: new FormControl(''),
      company: new FormControl(''),
    }))
  }


  private changeControls(category: ArtistCategory): void{
    switch (category) {
      case 'diy': {
        this.removeControls('company');
        this.addControls('user');
        break;
      }
      case 'grs': {
        this.removeControls('company');
        this.addControls('user');
        break;
      }
      case 'exclusive': {
        this.removeControls('user');
        this.addControls('company');
        break;
      }
      default:
        break;
    }
  }
}
