export {DefaultMasterPageComponent} from './default-master/default-master.component';
export {DashboardComponent} from './dashboard/dashboard.component';
export {LoginPageComponent} from './login/login.component';
export {BalancesListComponent} from './balances/list/balances-list.component';
export {BalancesItemComponent} from './balances/item/balances-item.component';

export {UpcomingComponent} from './submissions/upcoming/upcoming.component';
export {NewsItemComponent} from './news/item/news-item.component';
export {NewsListComponent} from './news/list/news-list.component';
export {ReleaseListComponent} from './releases/list/release-list.component';
export {ReleaseItemComponent} from './releases/item/release-item.component';
export {ArtistsListComponent} from './artists/list/artists-list.component';
export {ProducersListComponent} from './producers/producers-list/producers-list.component';
export {ProducersEditComponent} from './producers/producers-edit/producers-edit.component';
export {ProducersViewComponent} from './producers/producers-view/producers-view.component';
export {FansListComponent} from './fans/list/fans-list.component';
export {FansItemComponent} from './fans/item/fans-item.component';
export {FansViewComponent} from './fans/view/fans-view.component';
export {CollectiblesListComponent} from './collectibles/list/collectibles-list.component';
export {CollectiblesItemComponent} from './collectibles/item/collectibles-item.component';

export {AuctionMarketListComponent} from './auction-market/list/auction-market-list.component';

