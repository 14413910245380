<div class="section-content m20-top">
  <div class="section-title">Funding</div>
  <div class="fundingGoal" [formGroup]="fundingGoal">
    <div class="content-item">
      <div class="item-label header-inform-text-grey">Funding Goal*</div>
      <div class="item-field funding-goal">
        <app-form-input [formControlItem]="fundingGoal?.get('eur')">EUR</app-form-input>
        <span>/</span>
        <app-form-input [formControlItem]="fundingGoal?.get('usd')">USD</app-form-input>
      </div>
    </div>
  </div>
  <form-item-wrapper>
    <form-item-header>Min. to display (%)</form-item-header>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('displayShare')">Share to display</app-form-input>
    </div>
  </form-item-wrapper>
  <div class="content-item">
    <app-project-duration
      [projectDurationTime]="project?.projectDurationTime"
    ></app-project-duration>
  </div>
  <form-item-wrapper>
    <form-item-header>Total Investors Shares* (%)</form-item-header>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('investorShare')">Total Investors Shares* (%)</app-form-input>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Enable sales</form-item-header>
    <div class="item-field flex-align--bottom">
      <mat-checkbox class="example-margin" [formControl]="form?.get('isSale')">
      </mat-checkbox>
      <span
        class="error enable-sales-error"
        *ngIf="form?.get('isSale')?.value && !form?.get('investorShare')?.value"
      >
        Select "Total Investors Shares (%)"
      </span>
    </div>
  </form-item-wrapper>
  <form-item-wrapper
    *ngIf="form?.get('isSale')?.value && form?.get('investorShare')?.value"
  >
    <form-item-header>Percent of Sale* (%)</form-item-header>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('amountPercentSale')">Percent of Sale* (%)</app-form-input>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Price for 1% in €</form-item-header>
    <div class="item-field">
      <div class="value">
        <div class="priceForShare" [class.success]="form?.get('isSale')?.value">
          {{ priceForShare.eur || 0 | currency : "€" }} /
          {{ priceForShare.usd || 0 | currency : "$" }}
        </div>
      </div>
      <div class="error" *ngIf="errorPricePerShare">
        Price for Share is a float number. It can cause errors at the project
        funding
      </div>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Dedicated for production</form-item-header>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('dedicatedProduction')"
      ></mat-checkbox>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Dedicated for artwork</form-item-header>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('dedicatedArtwork')"
      ></mat-checkbox>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Dedicated for marketing & promo</form-item-header>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('dedicatedPromo')"
      ></mat-checkbox>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Dedicated for videoclip</form-item-header>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('dedicatedvideoclip')"
      ></mat-checkbox>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Transfer Unsold Shares</form-item-header>
    <div class="item-field settings__assign-unsold">
      <mat-slide-toggle [formControl]="enableAssignUnsold"></mat-slide-toggle>
      <app-find-by-name-universal-2
        [formControl]="form?.get('receiverUnsoldShare')"
        selectBy="unsoldOwner"
        class="item-field"
      >
      </app-find-by-name-universal-2>
    </div>
  </form-item-wrapper>
  <form-item-wrapper>
    <form-item-header>Label share distribution (%)*</form-item-header>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('labelDistribution')">Label share distribution (%)*</app-form-input>
    </div>
  </form-item-wrapper>

  <div class="content-item">
    <div class="item-label header-inform-text-grey">
      Royalties from Master Rights
    </div>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('royaltiesMater')"
      ></mat-checkbox>
    </div>
  </div>
  <div class="content-item">
    <div class="item-label header-inform-text-grey">
      Term of Royalties Master Rights
    </div>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('termRoyaltiesMater')"> Term of Royalties Master Rights</app-form-input>
    </div>
  </div>
  <div class="content-item">
    <div class="item-label header-inform-text-grey">
      Royalties from Publishing Rights
    </div>
    <div class="item-field flex-align--bottom">
      <mat-checkbox
        class="example-margin"
        [formControl]="form?.get('royaltiesPublish')"
      ></mat-checkbox>
    </div>
  </div>
  <div class="content-item">
    <div class="item-label header-inform-text-grey">
      Term of Royalties Publishing Rights
    </div>
    <div class="item-field">
      <app-form-input [formControlItem]="form?.get('termRoyaltiesPublish')">Term of Royalties Publishing Rights*</app-form-input>
    </div>
  </div>
</div>
