import {Component, Output, Input, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomFieldErrorMatcher, HOUR_MAX, MINUTES_MAX} from '@core/common';
import * as moment from 'moment';
import {AppToastrService} from '@core/services';

@Component({
  selector: 'app-popup-collectible-auction',
  templateUrl: './popup-collectible-auction.html'
})
export class PopupCollectibleAuctionComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() collectible: any;
  date: string;
  dateStart: string;
  submitPressed: boolean;
  form: UntypedFormGroup;
  amountCtrl: UntypedFormControl;
  startHourCtrl: UntypedFormControl;
  endHourCtrl: UntypedFormControl;
  startMinCtrl: UntypedFormControl;
  endMinCtrl: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder, private toastr: AppToastrService
  ) {
  }

  ngOnInit(): any {
    this.amountCtrl = this.fb.control('', [Validators.required]);
    this.startHourCtrl = this.fb.control('', [Validators.required, Validators.pattern(HOUR_MAX)]);
    this.startMinCtrl = this.fb.control('', [Validators.required, Validators.pattern(MINUTES_MAX)]);
    this.endHourCtrl = this.fb.control('', [Validators.required, Validators.pattern(HOUR_MAX)]);
    this.endMinCtrl = this.fb.control('', [Validators.required, Validators.pattern(MINUTES_MAX)]);
    this.form = this.fb.group({
      amount: this.amountCtrl,
      startHour: this.startHourCtrl,
      startMin: this.startMinCtrl,
      endMin: this.endMinCtrl,
      endHour: this.endHourCtrl,
    });
  }

  onCloseItem(): void {
    this.notify.emit({type: 'cancel'});
  }

  errorMatcher(control: UntypedFormControl) {
    let error;
    let showError = this.submitPressed;
    return new CustomFieldErrorMatcher(control, showError, error);
  }

  onSubmit(): any {
    this.submitPressed = true;

    if (this.form.valid) {
      let startDate = moment(this.dateStart).format('DD/MM/YYYY');
      let endDate = moment(this.date).format('DD/MM/YYYY');
      let startDateWithTime = moment(`${startDate} ${this.startHourCtrl.value}:${this.startMinCtrl.value}`, 'DD/MM/YYYY HH:mm').toISOString();
      let endDateWithTime = moment(`${endDate} ${this.endHourCtrl.value}:${this.endMinCtrl.value}`, 'DD/MM/YYYY HH:mm').toISOString();

      let diff = (moment(endDateWithTime).diff(moment(startDateWithTime))) / (1000 * 3600);
      if (diff < 1) {
        this.toastr.showToastFromError({error: {message: 'Auction must last min 60min'}});
      }
      if (diff >= 720) {
        this.toastr.showToastFromError({error: {message: 'Auction must last less 30 days'}});
      }
      if (moment(startDateWithTime).format('x') > moment(endDateWithTime).format('x')) {
        this.toastr.showToastFromError({error: {message: 'End Date must be more than Start Date'}});
        return false;
      }
      this.notify.emit({
        type: 'startAuction', data: {
          editions: this.collectible.edition,
          amount: this.amountCtrl.value,
          dateStart: startDateWithTime,
          dateEnd: endDateWithTime,
        }
      });
    }
  }
}
