<div class="item-row">
  <div class="item-label item-label_pad-20">Slide (1920x640)</div>
  <div class="item-field">
    <app-upload-image [config]="{imageFormat: 'png',
                                 ratio: 16 / 6,
                                 path: 'landing-images',
                                 allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP']}"
                      [control]="form?.controls?.image"
                      [imgURL]="form?.controls?.image?.value"
    ></app-upload-image>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Slide for mobile (1020x640)</div>
  <div class="item-field">
    <app-upload-image [config]="{imageFormat: 'png',
                                 ratio: 16 / 6,
                                 path: 'landing-images',
                                 allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP']}"
                      [control]="form?.controls?.imageMobile"
                      [imgURL]="form?.controls?.imageMobile?.value"
    ></app-upload-image>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Slider Title</div>
  <div class="item-field">
    <angular-editor
    [placeholder]="'Enter text here...'"
    [config]="editorConfig"
    [formControl]="form?.controls?.mainTitle"
  ></angular-editor>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Position to display</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="position"
             [formControl]="form?.controls?.position"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Subtext</div>
  <div class="item-field">
    <angular-editor
    [placeholder]="'Enter text here...'"
    [config]="editorConfig"
    [formControl]="form?.controls?.title"
  ></angular-editor>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">CTA Button text</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="buttonText"
             [formControl]="form?.controls?.text"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">CTA Button link</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="buttonLink"
             [formControl]="form?.controls?.url"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">2nd CTA Button text</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="buttonText"
             [formControl]="form?.controls?.text2"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">2nd CTA Button link</div>
  <div class="item-field">
    <mat-form-field class="item-field item-field_align">
      <input matInput
             id="buttonLink"
             [formControl]="form?.controls?.url2"
             type="text" autocomplete="off"
      >
    </mat-form-field>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Open in new window</div>
  <div class="item-field">
    <mat-checkbox class="example-margin"
                  [name]="'openNewWindow'  + index"
                  [id]="'openNewWindow'  + index"
                  [formControl]="form?.controls?.targetBlank"
    >
    </mat-checkbox>
  </div>
</div>
Display to:
<div class="item-row">
  <div class="item-label item-label_pad-20">Logged in users</div>
  <div class="item-field">
    <mat-checkbox class="example-margin"
                  [name]="'showLoggedUser' + index"
                  [id]="'showLoggedUser' + index"
                  [formControl]="form?.controls?.logged"
    >
    </mat-checkbox>
  </div>
</div>
<div class="item-row">
  <div class="item-label item-label_pad-20">Visitors</div>
  <div class="item-field">
    <mat-checkbox class="example-margin"
                  [name]="'showVisitors' + index"
                  [id]="'showVisitors' + index"
                  [formControl]="form?.controls?.visitors"
    >
    </mat-checkbox>
  </div>
</div>


