<div class="main-wrapper">
  <div class="header-page">
    <h5>Edit news card</h5>
  </div>
  <div class="content-page">
    <div class="section-content">
      <div class="content-item">
        <div class="item-label header-inform-text-grey">News Title*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="newsTitleCtrl" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Created On</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [readonly]="createdOnCtrl" [formControl]="createdOnCtrl" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Relate to Artist</div>
        <app-find-by-name [text]="artistName"
        ></app-find-by-name>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Is Published</div>
        <div class="toggle-item raw-edit">
          <mat-slide-toggle [formControl]="isPublishedCtrl"></mat-slide-toggle>
        </div>
      </div>
     <div class="content-item">
        <div class="item-label header-inform-text-grey">Position*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="position" [formControl]="positionCtrl" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Publish At</div>
        <div class="item-field">
          <mat-form-field appearance="fill" class="item calendar">
            <input matInput [matDatepicker]="picker" [formControl]="publishedAtCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="section-content m20-top">
      <div class="section-title">Content</div>
      <div class="content-item">
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Picture*</div>
        <app-upload-image [settings]="settingS" [config]="imageNewsConfig" [control]="newsImgCtrl"
          [maintainAspectRatio]="true" [imgURL]="newsImgCtrl?.value"></app-upload-image>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Text My Project*</div>
        <angular-editor [placeholder]="'Enter text here...'" [formControl]="textNewsCtrl" [config]="editorConfig">
        </angular-editor>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Facebook</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="facebook" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Instagram</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="instagram" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Twitter</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="twitter" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Youtube</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="youtube" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Spotify Artist</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="spotifyArtist" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Web link</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="albumTitle" [formControl]="webLink" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Link text</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput id="TextLink" [formControl]="webLinkTextCtrl" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="button-wrapper flex flex-end project__submit"><button type="button"
        class="button green default-size button-submit" (click)="saveDocument(form.valid)"
        [disabled]="!form?.valid">Save project
      </button>
    </div>
  </div>
</div>
