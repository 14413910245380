import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs'

import {
  FindByNameUniversalComponent
} from "@core/component/find-user-template/find-by-name-universal/find-by-name-universal.component";
import { FindByNameUniversal2Component } from '@core/component/find-user-template/find-by-name-universal-2/find-by-name-universal2.component';
import {
  FindByNameProjectComponent
} from "@core/component/find-user-template/find-by-name-project/find-by-name-project.component";
import {FindUserStatementUploadComponent} from "@core/component/find-user-template/find-user-statement-upload/find-user-statement-upload.component";
import {PickDateComponent} from "@core/component/pick-date/pick-date.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {SaleCodeWordComponent} from "@core/pages/promocode/sale-code-word/sale-code-word.component";
import {MatSelectModule} from "@angular/material/select";
import {FindByNameComponent} from '@core/component/find-user-template/find-by-name-artist/find-by-name.component';
import {FindProducerByNameComponent} from "@core/component/find-user-template/find-producer-by-name/find-producer-by-name.component";

import {PaginationComponent} from "@core/component/global/pagination/pagination.component";

import {FundingGoalDirective} from "@core/directives/funding-goal.directive";
import {GlobalTemplateModule} from "@core/component/global/global-template.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {UiModule} from "@core/component/ui/ui.module";
@NgModule({
  exports: [
    FindByNameUniversalComponent,
    FindByNameUniversal2Component,
    FindByNameProjectComponent,
    SaleCodeWordComponent,
    FindByNameComponent,
    FundingGoalDirective,
    FindUserStatementUploadComponent,
    PickDateComponent,
    FindProducerByNameComponent,
    PaginationComponent,

  ],
  declarations: [
    FindByNameUniversalComponent,
    FindByNameUniversal2Component,
    FindByNameProjectComponent,
    SaleCodeWordComponent,
    FundingGoalDirective,
    FindUserStatementUploadComponent,
    FindByNameComponent,
    PickDateComponent,
    FindProducerByNameComponent,
    PaginationComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    GlobalTemplateModule,
    MatAutocompleteModule,
    MatTabsModule,
  ],
  providers: [
    DecimalPipe
  ]
})
export class ShareModule {
}
