import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-pick-date',
  templateUrl: './pick-date.html'
})
export class PickDateComponent {
  valueSelected: moment.Moment;
  @Input() color: string;
  @Output() selectDate: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>): void {
    this.valueSelected = moment(normalizedMonth);
    this.selectDate.emit({data: normalizedMonth, type: 'date-month'});
    datepicker.close();
  }
}
