import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppToastrService} from '../../../services';
import {AccountingService} from '../../../services/accounting.service';
import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {RequestModel} from '@core/model/request.model';
import * as moment from 'moment';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {FilterInterface} from '@core/component/global/pagination/pagination.component';
import {IWallet} from '@core/interfaces/payout.interface';
import {PaginatedList} from '@core/common/interfaces';

@Component({
  selector: 'app-projects-wallets',
  templateUrl: './wallet-proejcts-lists.html',
  styleUrls: ['./wallet-proejcts-lists.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletProjectsListsComponent implements OnInit {
  headerTitle = ['Project Title', 'Slug', 'Payout Mode', 'Payout Type', 'Payout Date', 'Transaction'];
  data$ = new Subject<any>();
  tab$ = new Subject<string>();
  activeTab: string;
  paginationParam = {
    page: 1,
    limit: 20
  }
  filter: FilterInterface;
  private _listSubject = new BehaviorSubject<any>(null);

  constructor(
    private accounting: AccountingService,
    private toastr: AppToastrService
  ) {
  }

  ngOnInit(): void {
    this.initFetch();
  }

  initFetch() {
    combineLatest([
      this.data$,
      this.tab$
    ]).pipe(
      map(([pagination, tab]) => {
        this.activeTab = tab;
        return tab === 'History' ? {...pagination, transferred: true} : pagination;
      }),
      switchMap((resp) => {
        const request = new RequestModel(resp);
        return this.accounting.getFundedProjects(request);
      }),
      map(resp => {
        if (this.activeTab === 'History') {
          return this.prepareHistoryList(resp);
        }
        return resp;
      }),
      shareReplay(1)
    )
      .subscribe((result) => {
        this.filter = {
          paging: {
            limit: result.limit,
            offset: (result.page - 1) * result.limit,
          },
          total: result.total,
        };
        this._listSubject.next(result);
      });

    this.data$.next(this.paginationParam);
    this.tab$.next('Active');
  }

  get list$(): Observable<PaginatedList<IWallet>> {
    return this._listSubject.asObservable();
  }

  onPageChange(e) {
    const merge = {...this.paginationParam, page: e + 1};
    this.data$.next(merge);
  }

  onChangeTab(e: MatTabChangeEvent) {
    let tab: any = e.tab;
    this.tab$.next(tab.textLabel);
  }

  transferMoney(item: IWallet) {
    if (!item.transferred) {
      item.transferred = true;
      this.accounting.transferWalletAmount(item).subscribe(
        (resp) => {
          this.toastr.showToast({title: 'SUCCESS: Money was transferred'});
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
    }
  }

  private prepareHistoryList(response) {
    response.list = response.list.map(r => {
      return {
        ['Project Title']: r.projectTitle,
        ['Slug']: r.slug,
        ['Payout Date']: moment(r.datePayout).format('DD.MM.YYYY HH:mm'),
        ['Payout Mode']: r.payoutMode,
        ['Payout Type']: r.typePayout,
        ['Transaction']: r.transactionDetail
      }
    })
    return response;
  }
}
