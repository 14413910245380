import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {AbstractBaseFilterService} from '@core/component/filter/abstract-base-filter.service';
import * as moment from "moment/moment";


@Injectable()

export class FilterReleaseService implements AbstractBaseFilterService {
  public selectedSearchCategory = '';
  public selectedFilterCategory: any;
  public searchCategoryValue = '';
  public selectedRangeFilter = '';
  public filter: any;
  public range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  public $searchParam = new Subject<any>();
  public $observableSearchParam = this.$searchParam.asObservable();

  constructor() {
  }

  set filterObj(value) {
    this.filter = value;
  }
  filterByCategory(){
    this.filter.country = null;
    if (this.selectedFilterCategory) {
      if (this.selectedSearchCategory === 'Country') {
        this.filter.country = this.selectedFilterCategory.id;
      }

    }
    this.$searchParam.next(this.filter);
  }

  searchByCategory(): void {
    if (this.selectedSearchCategory && this.searchCategoryValue) {
      this.filter.name = null;
      this.filter.projectTitle = null;
      if (this.selectedSearchCategory === 'Song Name') {
        this.filter.name = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'First Name') {
        this.filter.firstName = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Email') {
        this.filter.email = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Last Name') {
        this.filter.lastName = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Artist Name') {
        this.filter.artist = this.searchCategoryValue;
      }
      if (this.selectedSearchCategory === 'Project Title') {
        this.filter.projectTitle = this.searchCategoryValue;
      }

    }
    this.$searchParam.next(this.filter);
  }

  searchByRange(): void {
    if (this.selectedRangeFilter === 'UpdatedAt') {
      this.filter.updatedFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.updatedTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.createdFrom = null;
      this.filter.createdTo = null;
    } else {
      this.filter.createdFrom = moment(this.range.get('start').value).format('YYYY/MM/DD');
      this.filter.createdTo = moment(this.range.get('end').value).format('YYYY/MM/DD');
      this.filter.updatedFrom = null;
      this.filter.updatedTo = null;
    }
    this.$searchParam.next(this.filter);
  }

  resetSearchFiler(): void {
    this.searchCategoryValue = null;
    this.selectedSearchCategory = null;
    this.filter.projectTitle = null;
    this.filter.name = null;
    this.filter.firstName = null;
    this.filter.lastName = null;
    this.filter.email = null;
    this.filter.artist = null;
    this.$searchParam.next(this.filter);
  }
  resetCategoryFiler(){
    this.selectedSearchCategory = null;
    this.selectedFilterCategory = null;
    this.filter.country = null;
    this.$searchParam.next(this.filter);
  }
  resetRangeFilter(): void {
    this.selectedRangeFilter = null;
    this.range.get('start').patchValue(null);
    this.range.get('end').patchValue(null);
    this.filter.updatedFrom = null;
    this.filter.updatedTo = null;
    this.filter.createdFrom = null;
    this.filter.createdTo = null;
    this.$searchParam.next(this.filter);
  }
}

