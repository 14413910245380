import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalTemplateModule} from '@core/component/global/global-template.module';

import {FilterReleaseService} from '@core/component/filter/filter-release.service';
import {BaseTemplateSearchComponent} from '@core/component/filter/base-templates/base-template-search/base-template-search.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {
  FilterRangeDateComponent
} from "@core/component/filter/base-templates/base-filter-range-date/filter-range-date.component";
import {
  BaseFilterCategoryComponent
} from "@core/component/filter/base-templates/base-filter-category/base-filter-category.component";
import {UiModule} from "@core/component/ui/ui.module";


@NgModule({
  exports: [
    FilterRangeDateComponent,
    BaseTemplateSearchComponent,
    BaseFilterCategoryComponent
  ],
  declarations: [
    FilterRangeDateComponent,
    BaseTemplateSearchComponent,
    BaseFilterCategoryComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,

    GlobalTemplateModule,

  ],
  providers: [
    FilterReleaseService,
    DecimalPipe
  ]
})
export class FilterPageModule {
}
