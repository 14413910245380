<div
  class="notification"
  [matBadge]="count"
  matBadgeColor="accent"
  [matBadgeHidden]="!count"
  matBadgeSize="small"
  (click)="toggleList()"
>
  <i class="fas fa-bell notification__icon" [class.iddle]="!count"></i>
</div>
<notification-list [toggle]="showList"></notification-list>
