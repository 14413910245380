<form [formGroup]="childForm">
<div class="item-field list-item">
  <div class="control" *ngIf="formArrayList?.controls?.length">
    <div class="list-controls" *ngFor="let control of formArrayList.controls; let i = index">

      <mat-form-field class="mat-item">
        <input matInput
               [disabled]="true"
               type="text" autocomplete="off"
               [formControl]="control"
        >
      </mat-form-field>
      <div *ngIf="i > 0" class="remove-control" (click)="removeControl(i)">
        <i class="fas fa-times-circle"></i></div>
    </div>
  </div>
  <div class="add" *ngIf="formArrayList?.controls?.length !== 11"
       (click)="addControlValue()">
    + ADD ({{11 - formArrayList?.controls?.length}} max)
  </div>
</div>
</form>
