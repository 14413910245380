import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-stars-readonly',
  templateUrl: './stars-readonly.html'
})
export class StarsReadonlyComponent {
  @Input() rating: any;
  stars = [1, 2, 3, 4, 5];

  constructor() {
  }
}
