import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {Gender, State, IArtistEditing} from '@core/model/artists-list.model';
import {AppToastrService, ArtistsService, DictionaryService, RoutingService, UsersService} from '@core/services';
import {
  ALPHA_NUMERIC,
  EMAIL_PATTERN,
  FACEBOOK,
  INSTAGRAM,
  ONLY_LETTERS_FOR_NAME, PATH_SAVE,
  SPOTIFY, STATE_NAMES,
  TIKTOK,
  TWITTER,
  YOUTUBE
} from '@core/common';
import * as moment from 'moment';
import {ConfirmPopup} from '@core/common/interfaces';
import {UserModel} from '@core/model';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {AddMultiInputComponent} from '@core/component/parts/add-multi-input/add-multi-input.component';
import {RequestModel} from '@core/model/request.model';
import {ActivatedRoute} from "@angular/router";
import { Dictionary } from '@core/model/dictionary.model';
import {UploadConfigs} from "@core/interfaces/user.interface";
export const MY_DATE_FORMAT = {
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-item-edit',
  templateUrl: './artist-edit.component.html',
  styleUrls: ['./artist-edit.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}]
})
export class ArtistEditComponent implements OnInit {
  birthDateCtrl: UntypedFormControl;
  editingForm: UntypedFormGroup;

  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Delete',
    btnCancel: true,
    submitEmit: 'deleteUser',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to delete user?'
  };
  imageConfig = {
    imageFormat: 'png',
    ratio: 16 / 6,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP'],
  };
  showPopupConfirmation: boolean;
  gender = Gender;
  artistDetail: any;
  state = State;
  isLoaded: boolean;
  genres: any[] = [];
  regions: any;
  @ViewChild(AddMultiInputComponent, {static: false}) addLinks: AddMultiInputComponent;
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: AppToastrService,
    private userService: UsersService,
    private dictionaryService: DictionaryService,
    private artistsService: ArtistsService,
    private routingService: RoutingService,
  ) {
    this.slug = this.route.snapshot.paramMap.get('slug');
  }

  slug: string;
  listOfCountries = [];
 // birthDate = new FormControl(new Date());

  ngOnInit(): void {
    this.dictionaryService.getCountries();
    this.prepareForm();
    this.dictionaryService.onGetCountries.subscribe(resp => {
      this.countryList.push(resp);
      resp.forEach(item => this.listOfCountries.push(item));
    });

    this.editingForm.controls.createdAt.valueChanges.subscribe(item => item);
    this.getGenres();
    this.getRegions();

    if (this.slug) {
      this.getArtistData();
    } else {
      this.isLoaded = true;
    }


    this.editingForm.markAllAsTouched();

  }

  prepareForm() {
    this.birthDateCtrl = this.fb.control('' , [Validators.required]);
    this.editingForm = this.fb.group({
      state: '',
      featured: false,
      isProducer: false,
      globalRevenueView: false,
      id: null,
      slug: '',
      imgURL: '',
      name: ['', Validators.required],
      country: ['', Validators.required],
      contact: this.fb.group({
        email: ['', [Validators.pattern(EMAIL_PATTERN), Validators.required]],
        firstName: ['', [Validators.pattern(ONLY_LETTERS_FOR_NAME), Validators.required]],
        lastName: ['', [Validators.pattern(ONLY_LETTERS_FOR_NAME), Validators.required]],
        gender: ['', Validators.required],
        birthdate: this.birthDateCtrl,
        city: ['', Validators.required],
        telephone: ['', Validators.required],
        region: '',
      }),
      postalCode: ['', [Validators.pattern(ALPHA_NUMERIC), Validators.required]],
      billingAddress: ['', Validators.required],
      counters: this.fb.group({
        totalEarned: '',
        totalSpent: ''
      }),
      note: this.fb.group({
        internal: '',
        external: ''
      }),
      message: '',
      bio: '',
      genresPlay: ['', Validators.required],
      social: this.fb.group({
        facebook: ['', Validators.pattern(FACEBOOK)],
        twitter: ['', Validators.pattern(TWITTER)],
        youtube: ['', Validators.pattern(YOUTUBE)],
        website: ['', Validators.pattern(FACEBOOK)],
        spotify: ['', Validators.pattern(SPOTIFY)],
        instagram: ['', Validators.pattern(INSTAGRAM)],
        tiktok: ['', Validators.pattern(TIKTOK)],
      }),
      createdAt: '',
      updatedAt: '',
      vatNumber: '',
      _countryList: this.fb.array([])
    });
  }

  getGenres() {
    this.dictionaryService.getGenres().subscribe((res) => {
      this.genres = res;
    });
  }

  getRegions(): void {
    this.dictionaryService.getRegions().subscribe((res) => {
      this.regions = res;
    }, err => {
      this.toastr.showToastFromError(err);
    });

  }

  get countryList(): UntypedFormArray {
    return this.editingForm.get('_countryList').value as UntypedFormArray;
  }

  get genresPlayValue(): any {
    return this.editingForm.get('genresPlay');
  }

  saveChanges(): void {
    const data = this.editingForm.value;
    let videoLinks = this.addLinks ? this.addLinks.inputCtrl.value : [];
    data.contact = this.editingForm.getRawValue().contact;
    data.video = videoLinks || [];
    data.contact.birthdate = moment(data.contact.birthdate).format('YYYY/MM/DD');
    this.artistsService.editCurrentArtist(this.slug.toLowerCase(), data)
      .subscribe(() => {
        this.toastr.showToast({title: `Artist has been saved`});
      }, err => {
        this.toastr.showToastFromError(err);
      });
  }

  changeState(): void {
    const payloadData = new UserModel().getStateModel(this.editingForm.value, 'artist');
    this.userService.changeState(this.slug, payloadData).subscribe(resp => {
      this.toastr.showToast({title: `State has been changed`});
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }


  getArtistData(): void {
    const request = new RequestModel({artist: true, kyc: true});
    this.artistsService.getCurrentArtist(this.slug.toLowerCase(), request).subscribe((artist: any) => {
      this.artistDetail = artist;
      this.editingForm.patchValue(artist);
      this.setForm(artist);
      this.isLoaded = true;
    });
  }

  setForm(artist) {
    this.editingForm.get('billingAddress').patchValue(artist.billingAddress);
    this.editingForm.get('postalCode').patchValue(artist.postalCode);
    this.editingForm.get('contact.firstName').disable();
    this.editingForm.get('contact.email').disable();
    this.editingForm.get('contact.lastName').disable();
    this.birthDateCtrl.setValue(artist.bithdateISO);
    this.genresPlayValue.value = artist.genresPlay;
    const setUpdatedAt = artist.updatedAt;
    this.editingForm.get('updatedAt').patchValue(setUpdatedAt);

    const setCreatedAt =artist.createdAt;
    this.editingForm.get('createdAt').patchValue(setCreatedAt);
  }

  onNotify(e): void {
    if (e.type === 'deleteUser') {
      this.deleteUser();
    }
    this.showPopupConfirmation = false;
  }

  deleteUser(): void {
    this.artistsService.deleteArtist(this.slug).subscribe(() => {
      this.routingService.go(STATE_NAMES.artistsList);
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }
}
