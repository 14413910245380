import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UsersService,
  AppToastrService,
  UploadService,
  ProjectService,
} from '../../../services/index';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  NUMBER_POSITIVE,
  ROUTE_ALIAS,
} from '@core/common';
import { SettingService } from '@core/services/setting.service';
import { NewsModel } from '@core/model/news.model';
import * as moment from 'moment';
import { ISearchName } from '@core/interfaces/user.interface';
import { UserModel } from '@core/model';
import { FindByNameComponent } from '@core/component/find-user-template/find-by-name-artist/find-by-name.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.html',
  styleUrls: ['./_news-item.scss'],
})
export class NewsItemComponent implements OnInit {
  list: any[];
  showPopupConfirmation: boolean;

  newsItem: NewsModel;
  reportUrl: string;
  artist: UserModel;
  form: FormGroup;
  facebook: FormControl;
  twitter: FormControl;
  instagram: FormControl;
  spotifyArtist: FormControl;
  youtube: FormControl;
  webLinkTextCtrl: FormControl;
  webLink: FormControl;
  newsTitleCtrl: FormControl;
  createdOnCtrl: FormControl;
  isPublishedCtrl: FormControl;
  newsImgCtrl: FormControl;
  publishedAtCtrl: FormControl;
  textNewsCtrl: FormControl;
  positionCtrl: FormControl;
  searchArtist: ISearchName[];
  checkList: boolean;
  loadedSearchList: boolean;
  isSubmitted: boolean;
  disableSave: boolean;
  loadFile: boolean;
  fileName: string;
  slug: string;
  params: any;
  imageNewsConfig = {
    imageFormat: 'png',
    ratio: 16 / 6,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP'],
  };
  artistName: string;
  contentUrl: string;
  editorConfig: AngularEditorConfig = {
    minHeight: '100px',
    editable: true,
    height: 'auto',
    maxHeight: 'auto',
    enableToolbar: true,
    showToolbar: true,
    sanitize: true,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'lineHeight-10%',
        class: 'lineHeight10',
        tag: 'p',
      },
      {
        name: 'lineHeight-20%',
        class: 'lineHeight20',
        tag: 'p',
      },
      {
        name: 'lineHeight-40%',
        class: 'lineHeight40',
        tag: 'p',
      },
      {
        name: 'lineHeight-60%',
        class: 'lineHeight60',
        tag: 'p',
      },
      {
        name: 'lineHeight-80%',
        class: 'lineHeight80',
        tag: 'p',
      },
      {
        name: 'lineHeight-100%',
        class: 'lineHeight100',
        tag: 'p',
      },
    ],
  };
  settingS: any;
  @ViewChild(FindByNameComponent, { static: false })
  artistfindByName: FindByNameComponent;
  constructor(
    private uploadService: UploadService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private settings: SettingService,
    private fb: FormBuilder,
    private userService: UsersService,
    private toastr: AppToastrService
  ) {
    const slug: string = this.route.snapshot.params.slug;
    if (slug !== 'newItem') {
      this.slug = slug;
    }
  }

  ngOnInit(): any {
    this.settingS = this.settings;
    this.prepareForm();
    if (this.slug) {
      this.getItem(this.slug);
    }
  }

  prepareForm(): void {
    const date = new Date();
    this.isPublishedCtrl = this.fb.control(false);
    this.newsTitleCtrl = this.fb.control('', {
      validators: [Validators.required],
    });
    this.createdOnCtrl = this.fb.control(moment(date).format('YYYY/MM/DD'));
    this.publishedAtCtrl = this.fb.control('');
    this.newsImgCtrl = this.fb.control('', {
      updateOn: 'blur',
    });
    this.positionCtrl = this.fb.control('', [
      Validators.pattern(NUMBER_POSITIVE),
      Validators.required,
    ]);
    this.facebook = this.fb.control('');
    this.webLinkTextCtrl = this.fb.control('');
    this.twitter = this.fb.control('');
    this.instagram = this.fb.control('');
    this.youtube = this.fb.control('');
    this.spotifyArtist = this.fb.control('');
    this.webLink = this.fb.control('');
    this.textNewsCtrl = this.fb.control('', {
      validators: [Validators.required],
      updateOn: 'blur',
    });
    this.form = this.fb.group({
      title: this.newsTitleCtrl,
      isPublished: this.isPublishedCtrl,
      createdAt: this.createdOnCtrl,
      publishedAt: this.publishedAtCtrl,
      newsflash: this.positionCtrl,
      webLinkText: this.webLinkTextCtrl,
      imgUrl: this.newsImgCtrl,
      textNews: this.textNewsCtrl,
      facebook: this.facebook,
      twitter: this.twitter,
      instagram: this.instagram,
      youtube: this.youtube,
      spotify: this.spotifyArtist,
      webLink: this.webLink,
    });
  }

  searchText(): void {
    this.loadedSearchList = false;
    this.checkList = false;
    if (this.artistName && this.artistName.length > 1) {
      this.userService.getArtistByName(this.artistName).subscribe(
        (resp) => {
          this.searchArtist = resp;
          setTimeout(() => {
            this.checkList = true;
          }, 400);
        },
        (err) => {
          this.toastr.showToastFromError(err);
          setTimeout(() => {
            this.checkList = true;
          }, 400);
        }
      );
    }
  }

  setForm(): void {
    this.newsTitleCtrl.setValue(this.newsItem.title);
    this.createdOnCtrl.setValue(this.newsItem.createdAt);
    this.positionCtrl.setValue(this.newsItem.newsflash);
    this.publishedAtCtrl.setValue(this.newsItem.publishedAt);
    this.webLinkTextCtrl.setValue(this.newsItem.link.text);
    this.isPublishedCtrl.setValue(this.newsItem.isPublished);
    if (this.newsItem.imgUrl) {
      this.newsImgCtrl.setValue(this.newsItem.imgUrl);
    }
    this.textNewsCtrl.setValue(this.newsItem.textNews);
    this.artistName = this.newsItem.artist ? this.newsItem.artist.name : '';
    this.facebook.setValue(this.newsItem.social.facebook);
    this.instagram.setValue(this.newsItem.social.instagram);
    this.twitter.setValue(this.newsItem.social.twitter);
    this.youtube.setValue(this.newsItem.social.youtube);
    this.spotifyArtist.setValue(this.newsItem.social.spotify);
    this.webLink.setValue(this.newsItem.link.url);
  }

  cancelUpload(): void {
    this.fileName = null;
  }

  getItem(slug): void {
    this.projectService.getNewsItem(slug).subscribe(
      (resp) => {
        this.newsItem = resp;
        this.setForm();
      },
      (err) => {
        console.log(err);
        this.toastr.showToastFromError(err);
      }
    );
  }

  saveDocument(valid: boolean): void {
    this.isSubmitted = true;
    if (valid) {
      let artist = this.artistfindByName.getArtist();
      const data = new NewsModel(null, this.uploadService).update(
        this.form,
        this.newsItem,
        artist
      );
      this.projectService[this.slug ? '_updateNewsItem' : 'saveNewsItem'](
        data,
        this.slug
      ).subscribe(
        (resp) => {
          this.loadFile = false;
          this.toastr.showToast({ title: 'SUCCESS: News was saved' });
          if (this.slug) {
            this.router.navigate([ROUTE_ALIAS.newsEdit, this.slug]);
          }
        },
        (err) => {
          console.log(err);
          this.toastr.showToastFromError(err);
        }
      );
    }
  }
}
