<div class="main-wrapper">
  <div class="header-page">
    <h5>Fan</h5>
  </div>

  <div class="content-page" *ngIf="isLoaded">
    <div class="section-content">
      <div class="content-item">
        <div class="item-label header-inform-text-grey">State</div>
        <div class="item-field">
          <mat-form-field class="item">
            <mat-select id="language" [formControl]="stateCtrl"
            >
              <mat-option *ngFor="let item of states" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Note(internal)</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="albumTitle"
                   [formControl]="internalCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Note(external)</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="albumTitle"
                   [formControl]="externalCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item change-state">
        <button class="button blue default-size button__change-state" (click)="changeState()">Change state
        </button>
      </div>

      <div class="content-item">
        <div class="item-label header-inform-text-grey">ID</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="albumTitle"
                   [formControl]="idCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item item__change-role">
        <div class="item-label header-inform-text-grey">Is Artist</div>
        <div class="item-field">
          {{fanItem?.type === 'Artist' ? 'Yes' : 'No'}}
        </div>
        <button class="button blue default-size" *ngIf="fanItem?.type !== 'Artist'" (click)="changeUserRole()">Change
          role to Artist
        </button>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Slug</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="albumTitle"
                   [formControl]="slugCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Is producer</div>
        <div class="item-field">
          <mat-checkbox [formControl]="isProducerCtrl">
          </mat-checkbox>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Global View</div>
        <div class="item-field">
          <mat-checkbox class="m20-top" [formControl]="globalViewCtrl"
          >
          </mat-checkbox>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">User image</div>
      </div>
    </div>
    <div class="section-content m20-top">
      <div class="section-title">Personal information</div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">First Name*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="firstNameCtrl"
                   [formControl]="firstNameCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Last Name*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="lastNameCtrl"
                   [formControl]="lastNameCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item" *ngIf="!fanItem?.isPrivate">
        <div class="item-label header-inform-text-grey">Company Name</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="companyNameCtrl"
                   [formControl]="companyNameCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item" *ngIf="!fanItem?.isPrivate">
        <div class="item-label header-inform-text-grey">VAT number</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="vatNumberCtrl"
                   [formControl]="vatNumberCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Gender*</div>
        <div class="item-field">
          <mat-form-field class="item">
            <mat-select id="language" [formControl]="genderCtrl"
            >
              <mat-option *ngFor="let item of gender" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Birthdate*</div>
        <div class="item-field">
          <mat-form-field class="mat-form-field form-field" appearance="fill">
            <input matInput [matDatepicker]="picker" [formControl]="birthDateCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Email</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="emailCtrl"
                   [formControl]="emailCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Address*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="billingAddressCtrl"
                   [formControl]="billingAddressCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Zip-code*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="postalCodeCtrl"
                   [formControl]="postalCodeCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">City*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="cityCtrl"
                   [formControl]="cityCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Country*</div>
        <div class="item-field">
          <mat-form-field class="item">
            <mat-select id="countries" [formControl]="countryCtrl"
            >
              <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item" *ngIf="regions && regions[fanItem?.country]">
        <div class="item-label header-inform-text-grey">Region*</div>
        <div class="item-field">
          <mat-form-field class="item">
            <mat-select id="regions" [formControl]="regionCtrl"
            >
              <mat-option *ngFor="let region of regions[fanItem?.country]" [value]="region.id">{{region.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Phone*</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="telephoneCtrl"
                   [formControl]="telephoneCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>

      <div class="content-item">
        <div class="item-label header-inform-text-grey">Genres like*</div>
        <div class="item-field">
          <mat-form-field>

            <mat-select [formControl]="genresLikeCtrl" multiple
            >
              <mat-option *ngFor="let item of genres" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Created at</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="createdAtCtrl"
                   [formControl]="createdAtCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label header-inform-text-grey">Updated at</div>
        <div class="item-field">
          <mat-form-field>
            <input matInput
                   id="updatedAtCtrl"
                   [formControl]="updatedAtCtrl"
                   type="text" autocomplete="off"
            >
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-wrapper flex flex-end">
      <button class="button green default-size" (click)="saveUser(form.valid)"
              [disabled]="!form.valid"
      >Save changes
      </button>
      <button class="button red default-size m20-left" (click)="showPopupConfirmation = true">Delete User
      </button>
    </div>
  </div>
  <div class="spinner">
    <mat-spinner *ngIf="!isLoaded" [diameter]="40"></mat-spinner>
  </div>
</div>
<app-popup-confirmation [config]="popupConfig" *ngIf="showPopupConfirmation"
                        (notify)="onNotify($event)"></app-popup-confirmation>
