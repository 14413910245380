import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { RequestModel } from '@core/model/request.model';
import { Filter, ResponseTotal } from '@core/common/interfaces';
import { UserModel } from '@core/model';
import { TypeFilter } from '@core/model/common.model';

// Services
import {
  AppToastrService,
  DictionaryService,
  UsersService,
} from '@core/services';

@Component({
  selector: 'app-fans-list',
  templateUrl: './fans-list.html',
  styleUrls: ['./_fans-list.scss'],
})
export class FansListComponent implements OnInit {
  fansList: UserModel[];
  host: string;
  total: number;
  searchFilter: any[] = [
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Name',
      value: 'name',
    },
  ];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  selectedSearchCategory = '';
  searchCategoryValue = '';
  selectedRangeFilter = '';
  mainFilter = new UntypedFormControl();
  stateFilter = new UntypedFormControl();
  featuredFilter = new UntypedFormControl();
  countryFilter = new UntypedFormControl();
  isLoaded: boolean;
  categoryMainFilter: string[] = ['State', 'Featured', 'Country'];

  categoryStateFilter = [
    { value: 'pending', viewValue: 'Pending' },
    { value: 'verified', viewValue: 'Verified' },
    { value: 'deleted', viewValue: 'Deleted' },
  ];

  categoryFeaturedFilter = [
    { value: 'true', viewValue: 'True' },
    { value: 'false', viewValue: 'False' },
  ];
  rangeFilter: any[] = [
    {
      name: 'Created at',
      value: 'createdAt',
    },
    {
      name: 'Updated at',
      value: 'updatedAt',
    },
  ];
  categoryCountryFilter = [];
  filter: Filter = new TypeFilter();

  constructor(
    private toastr: AppToastrService,
    private dictionaryService: DictionaryService,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getCountries();
  }

  openItem(item?: any, isView?: boolean): void {
    const slug: string = item.slug;
    const page: string = isView ? 'view' : 'edit';
    this.router.navigate([page, slug], { relativeTo: this.route })
  }

  getCountries(): void {
    this.dictionaryService.getCountries().subscribe((res) => {
      this.categoryCountryFilter = res;
    });
  }

  onNotify(e): void {
    if (e.type === 'sorting') {
      this.getList();
    }
  }

  getList(): void {
    this.isLoaded = false;
    const request = new RequestModel(this.filter);
    this.userService._getFans(request).subscribe(
      (resp: ResponseTotal<UserModel>) => {
        this.fansList = resp.list;
        this.total = resp.total;
        this.isLoaded = true;
      },
      (err) => {
        this.isLoaded = true;
        this.toastr.showToastFromError(err);
      }
    );
  }

  resetFilter(type: string): void {
    if (type === 'Category') {
      this.mainFilter.setValue(null);
      this.stateFilter.setValue(null);
      this.featuredFilter.setValue(null);
      this.countryFilter.setValue(null);
      delete this.filter.state;
      delete this.filter.country;
      delete this.filter.featured;
      this.getList();
    }
    if (type === 'Search') {
      this.searchCategoryValue = null;
      this.selectedSearchCategory = null;
      this.filter.name = this.searchCategoryValue;
      this.filter.email = this.searchCategoryValue;
      this.getList();
    }
    if (type === 'Range') {
      this.selectedRangeFilter = '';
      this.range.get('start').patchValue(null);
      this.range.get('end').patchValue(null);
      this.searchByRange();
    }
  }

  onSelectCategory() {
    delete this.filter.state;
    delete this.filter.country;
    delete this.filter.featured;

    if (this.mainFilter.value === 'State') {
      const arr = this.stateFilter.value.map((item) => {
        return item.value;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = arr;
    }
    if (this.mainFilter.value === 'Featured') {
      this.filter[this.mainFilter.value.toLowerCase()] =
        this.featuredFilter.value.value;
    }
    if (this.mainFilter.value === 'Country') {
      const country = this.countryFilter.value.map((item) => {
        return item.id;
      });
      this.filter[this.mainFilter.value.toLowerCase()] = country;
    }
    this.getList();
  }

  searchByRange(): void {
    if (this.selectedRangeFilter === 'updatedAt') {
      this.filter.sorting.sortBy = 'updatedAt';
      this.filter.updatedFrom = moment(this.range.get('start').value).format(
        'YYYY/MM/DD'
      );
      this.filter.updatedTo = moment(this.range.get('end').value).format(
        'YYYY/MM/DD'
      );
      this.filter.createdFrom = null;
      this.filter.createdTo = null;
    }
    if (this.selectedRangeFilter === 'createdAt') {
      this.filter.sorting.sortBy = 'createdAt';
      this.filter.createdFrom = moment(this.range.get('start').value).format(
        'YYYY/MM/DD'
      );
      this.filter.createdTo = moment(this.range.get('end').value).format(
        'YYYY/MM/DD'
      );
      this.filter.updatedFrom = null;
      this.filter.updatedTo = null;
    }
    if (!this.selectedRangeFilter) {
      this.filter.sorting.sortBy = 'createdAt';
      this.filter.createdFrom = null;
      this.filter.createdTo = null;
      this.filter.updatedFrom = null;
      this.filter.updatedTo = null;
    }
    this.getList();
  }

  searchByCategory(): void {
    this.filter = new TypeFilter();
    if (this.selectedSearchCategory === 'name') {
      this.filter.name = this.searchCategoryValue;
    } else {
      this.filter.email = this.searchCategoryValue;
    }

    this.getList();
  }

  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }
}
