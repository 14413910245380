import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {angularEditorParams} from '@core/common/angular-editor-config';
import {UploadService} from "@core/services";
import {logger} from "codelyzer/util/logger";

@Component({
  selector: 'app-landing-free-text',
  templateUrl: './landing-free-text.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingFreeTextComponent implements OnInit {
  form: UntypedFormGroup;
  listBlocks = [];
  index = 0;
  editorConfig: AngularEditorConfig = angularEditorParams;
  @Input() text: any;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): any {
    this.form = this.fb.group({
      isEnabled: new UntypedFormControl(false),
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      targetBlank: new UntypedFormControl(false),
      button: new UntypedFormControl(''),
      url: new UntypedFormControl(''),
    });
    if (this.text) {
      this.setForm();
    }
  }

  setForm(): void {
    this.form.controls['title'].setValue(this.text.title);
    this.form.controls['isEnabled'].setValue(this.text.isEnabled);
    this.form.controls['description'].setValue(this.text.description);
    this.form.controls['targetBlank'].setValue(this.text.targetBlank);
    this.form.controls['button'].setValue(this.text.button);
    this.form.controls['url'].setValue(this.text.url);
    if (this.text.list) this.text.list.forEach(item => {
      this.index += 1;
      let nameControl = 'freeText' + this.index;
      this.listBlocks.push({id: this.index, name: nameControl});
      this.form.addControl(nameControl, this.fb.group({
        title: new UntypedFormControl(item.title),
        description: new UntypedFormControl(item.description)
      }));
    });
  }

  addBlock() {
    this.index += 1;
    let nameControl = 'freeText' + this.index;
    this.form.addControl(nameControl, this.fb.group({
      title: new UntypedFormControl(''),
      description: new UntypedFormControl('')
    }));
    this.listBlocks.push({id: this.index, name: nameControl});
  }


  getListFreeText() {
    let list = [];
    for (let i = 1; i <= this.index; i++) {
      let form = this.form.get('freeText' + i).value;
      if (form.title) {
        list.push(form);
      }
    }
    list = list.filter(item => item);
    return {
      title: this.form.get('title').value,
      isEnabled: this.form.get('isEnabled').value,
      description: this.form.get('description').value,
      targetBlank: this.form.get('targetBlank').value,
      button: this.form.get('button').value,
      url: this.form.get('url').value,
      list: list
    };
  }

  deleteBlock(index: number) {
    this.listBlocks.splice(index, 1);
  }
}

