import {Injectable} from '@angular/core';
import {SettingService} from './setting.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {VoucherModel} from '../model/voucher.model';
import {RespPaginator} from '../common/interfaces';
import {RequestModel} from '@core/model/request.model';
import {ReportType} from "@core/interfaces/report.type";


@Injectable()
export class RoyaltiesService {

  apiUrl: string;

  constructor(private setting: SettingService,
              private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
  }

  getReportDistribution(type: ReportType): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/distribution/report?type=${type}`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getVouchers(request: RequestModel): Observable<RespPaginator> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/vouchers`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          list: resp.docs.map(i => new VoucherModel().setFromApiModel(i)),
          total: resp.total,
          totalAmount: resp.totalAmount,
          remainAmount: resp.remainAmount,
          refundAmount:  resp.refundAmount,
          refundCount:  resp.refundCount,
          totalSoldCount:  resp.totalSoldCount
        };
      }
    }));
  }

  createRefund(id: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/voucher/refund/${id}`, {}).pipe(map((resp: any) => {
      return resp;
    }));
  }

  addVoucher(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/admin/voucher`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }
}
