import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { RequestModel } from '@core/model/request.model';

// Interfaces
import {
  IUnsoldShareRequest,
  IUnsoldShareBundleRequest,
  IUnsoldShareResponse,
} from '@core/interfaces/usold-shares.interface';

// Services
import { SettingService } from '@core/services/setting.service';
import {ProjectBaseMainModel} from "@core/model/project-base-main.model";

import {AlbumModel} from "@core/model/album.model";
import {ProjectLinkedModel} from "@core/model/project-linked.model";
import {ProjectBundleModel} from "@core/model/project-bundle.model";

@Injectable()
export class UnsoldSharesService {
  apiUrl: string;

  private unsoldShares = new BehaviorSubject<any>('');

  constructor(private setting: SettingService, private http: HttpClient) {
    this.apiUrl = this.setting.apiUrl;
  }

  getPledges(request: RequestModel): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/admin/pledges`, { params }).pipe(
      map((resp: any) => {
        let users = resp.map((item) => {
          return {
            user: item.owner.contact.email,
            share: item.share,
          };
        });
        return users;
      })
    );
  }

  getUserUnsoldShares() {
    return this.unsoldShares.asObservable();
  }

  fetchUnsoldShare(
    project: ProjectBundleModel | AlbumModel | ProjectBaseMainModel | ProjectLinkedModel
  ): Observable<IUnsoldShareResponse[]> {
    return this.http
      .get(`${this.apiUrl}/admin/unsold-shares?project=${project.slug}`)
      .pipe(
        map((resp: { list: IUnsoldShareResponse[] }) => resp?.list),
        tap((list: IUnsoldShareResponse[]) => {
          this.unsoldShares.next(list);
        })
      );
  }

  updateUnsoldShares(
    slug: string,
    body: IUnsoldShareRequest | IUnsoldShareBundleRequest
  ): Observable<any> {
    return this.http
      .put(`${this.apiUrl}/admin/project/${slug}/unsold`, body)
      .pipe(
        map((resp: any) => {
          if (resp.docs) {
            resp.docs.map((item) => {
              item.showDisplayName = item.projectTitle;
              return item;
            });
            return resp.docs;
          }
        })
      );
  }
}
