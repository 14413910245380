import {Observable} from "rxjs";
import {RequestModel} from "../../model/request.model";
import {UntypedFormGroup} from "@angular/forms";

export abstract class AbstractBaseFilterService {
  abstract selectedSearchCategory: string;
  abstract selectedFilterCategory: any;
  abstract searchCategoryValue: string;
  abstract selectedRangeFilter: string | null;
  abstract filter: any;
  abstract range: UntypedFormGroup;
  abstract filterObj;
  abstract $observableSearchParam: Observable<any>;
  abstract searchByCategory(): void;

  abstract filterByCategory(): void;
  abstract searchByRange(): void;
  abstract resetRangeFilter(): void;
  abstract resetSearchFiler(): void;
  abstract resetCategoryFiler(): void;
}
