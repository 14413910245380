<div class="upload__image-wrapper">
  <ng-template
    [ngIf]="config?.imageFormat === 'png' || config?.imageFormat === 'icon'"
  >
    <app-image-cropper
      [maintainAspectRatio]="maintainAspectRatio"
      [config]="config"
      (notify)="savePhoto($event)"
      *ngIf="!imgURL"
    ></app-image-cropper>
    <div class="wrapper" *ngIf="!config?.example && imgURL">
      <img class="upl__image" src="{{imgURL  | convertImageUrl}}" />
      <span (click)="testCl()" class="upl__name" *ngIf="imgURL"
        ><i class="fas fa-times-circle img-close"></i
        ><span>{{imgURL | convertImageUrl}}</span></span
      >
    </div>
    <div class="wrapper" *ngIf="config?.example">
      <div class="card-template" *ngIf="imgURL">
        <img class="upl__image" src="{{imgURL | convertImageUrl}}" />
      </div>
      <span (click)="testCl()" class="upl__name" *ngIf="imgURL"
        ><i class="fas fa-times-circle img-close"></i
        ><span>{{imgURL | convertImageUrl}}</span></span
      >
    </div>
  </ng-template>

  <div *ngIf="config?.imageFormat === 'gif'">
    <app-image-cropper
      [maintainAspectRatio]="true"
      [config]="{
        imageFormat: 'png',
        ratio: config.ratio,
        allowFormats: config.allowFormats,
        disabled: true
      }"
      [saveOnback]="true"
      *ngIf="!GIFsettings?.imageCoverGIF"
      (notify)="savePhoto($event)"
      (saveParallel)="saveGIFcover($event)"
    ></app-image-cropper>
    <div class="wrapper" *ngIf="GIFsettings?.imageCoverGIF">
      <img
        class="upl__image"
        src="{{(GIFsettings?.imgURL || imgURL) | convertImageUrl}}"
      />
      <span
        (click)="testCl()"
        class="upl__name"
        *ngIf="GIFsettings?.imgURL || imgURL"
        ><i class="fas fa-times-circle img-close"></i
        ><span
          >{{(GIFsettings?.imgURL || imgURL) | convertImageUrl}}</span
        ></span
      >
    </div>
  </div>
</div>
