import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SettingService} from "@core/services/setting.service";
import {ApiInterface} from "@core/interfaces/api.interface";

@Injectable({providedIn: 'root'})

export class ApiService implements ApiInterface {
  apiUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }

  get<T>(endpoint: string, params: any): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.get<T>(url, {params});
  }

  post<T>(endpoint: string, data: any = {}): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post<T>(url, data);
  }

  put<T>(endpoint: string, data: any): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.put<T>(url, data);
  }
}
