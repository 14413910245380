<h2 class="content-name">Mint tokens</h2>
<p>Mint user's tokens</p>
<div class="content__item">
  <app-find-by-name-universal
    [type]="'email'"
    [formControlCustom]="user"
    [placeholder]="'Email'"
    [saveParam]="'slug'"
    [errorText]="'User is not found'"
  ></app-find-by-name-universal>

  <div class="submit-block p20-top">
    <button
      class="button green default-size button-find-tokens"
      [disabled]="userMintBlocked || !user.value"
      (click)="requestMint('user')"
    >
      Mint user's tokens
    </button>
  </div>
  <p class="p20-top">Mint project's tokens</p>
  <app-find-by-name-project
    [placeholder]="'Project'"
    (notify)="onSelectedProject($event)"
  ></app-find-by-name-project>
  <div class="submit-block p20-top">
  <button
    class="button green default-size button-find-tokens"
    [disabled]="projectMintBlocked"
    (click)="requestMint('project')"
  >
    Mint project's tokes
  </button>
  </div>
</div>
