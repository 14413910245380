import {Component, Input, OnInit} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomFieldErrorMatcher, DROPBOX, INSTAGRAM, SOCIAL_VALIDATION, SOUNDCLOUD, SPOTIFY, TIKTOK, YOUTUBE} from '@core/common';

interface SocialItems {
  [key: string]: string;
}

@Component({
  selector: 'app-social-links-song',
  templateUrl: './social-links-song.html'
})
export class SocialLinksSongComponent implements OnInit {
  @Input() socialSong: SocialItems;
  @Input() listSocials: any;
  @Input() socialArtist: any;
  form: UntypedFormGroup;
  spotifyCtrl: UntypedFormControl;
  youtubeCtrl: UntypedFormControl;
  soundCloudCtrl: UntypedFormControl;
  instagramCtrl: UntypedFormControl;
  dropboxCtrl: UntypedFormControl;
  tiktokCtrl: UntypedFormControl;
  social = JSON.parse(JSON.stringify(SOCIAL_VALIDATION));
  prevValue = {};

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.prepareForm();
  }

  prepareForm(): void {
    this.spotifyCtrl = this.fb.control('', [Validators.pattern(SPOTIFY)]);
    this.youtubeCtrl = this.fb.control('', [Validators.pattern(YOUTUBE)]);
    this.soundCloudCtrl = this.fb.control('', [Validators.pattern(SOUNDCLOUD)]);
    this.dropboxCtrl = this.fb.control('', [Validators.pattern(DROPBOX)]);
    this.instagramCtrl = this.fb.control('', [Validators.pattern(INSTAGRAM)]);
    this.tiktokCtrl = this.fb.control('', [Validators.pattern(TIKTOK)]);
    this.form = this.fb.group({
      spotify: this.spotifyCtrl,
      youtube: this.youtubeCtrl,
      soundcloud: this.soundCloudCtrl,
      dropbox: this.dropboxCtrl,
      instagram: this.instagramCtrl,
      tiktok: this.tiktokCtrl,
    });
    this.setForm();
  }

  setForm(): void {
    if (this.socialArtist) {
      this.spotifyCtrl.setValue(this.socialArtist.spotify ? this.socialArtist.spotify : null);
      this.listSocials['spotify'] = this.socialArtist.spotify ? this.socialArtist.spotify : '';
      this.youtubeCtrl.setValue(this.socialArtist.youtube ? this.socialArtist.youtube : null);
      this.listSocials['youtube'] = this.socialArtist.youtube ? this.socialArtist.youtube : '';
      this.soundCloudCtrl.setValue(this.socialArtist.soundcloud ? this.socialArtist.soundcloud : null);
      this.listSocials['soundcloud'] = this.socialArtist.soundcloud ? this.socialArtist.soundcloud : '';
      this.dropboxCtrl.setValue(this.socialArtist.dropbox ? this.socialArtist.dropbox : null);
      this.listSocials['dropbox'] = this.socialArtist.dropbox ? this.socialArtist.dropbox : '';
      this.instagramCtrl.setValue(this.socialArtist.instagram);
      this.listSocials['instagram'] = this.socialArtist.instagram ? this.socialArtist.instagram : '';
      this.tiktokCtrl.setValue(this.socialArtist.tiktok);
      this.listSocials['tiktok'] = this.socialArtist.tiktok ? this.socialArtist.tiktok : '';
    }

  }

  errorMatcher(control: UntypedFormControl) {
    return new CustomFieldErrorMatcher(control, true);
  }

  editSocial(item: any, ctrl?: UntypedFormControl): void {
    if (this.social[item].edit) {
      if (ctrl.value) {
        ctrl.setValue(this.prevValue[item]);
      } else {
        ctrl.reset();
      }
      if (ctrl.valid) {
        this.social[item].edit = false;
      }
    } else {
      this.social[item].edit = true;
      this.prevValue[item] = ctrl.value;
      if (this.listSocials[item]) {
        this.listSocials[item] = ctrl.value;
      }
    }
  }

  saveSocial(ctrl: UntypedFormControl, type: string): void {
    if (ctrl.valid) {
      this.social[type].edit = !this.social[type].edit;
      this.prevValue[type] = ctrl.value;
      this.listSocials[type] = ctrl.value;
    }
  }
}
