<app-page-template header="Balance Page">
  <div class="search">
    <span>Producer or Artist*</span>
    <div *ngIf="!newArtist">{{text}}</div>
    <app-find-by-name *ngIf="newArtist" (notify)="findByName($event)"></app-find-by-name>
  </div>
  <div class="content__wrapper" *ngIf="loaded">
    <div class="buttons-wrapper">
      <button type="button" class="button blue-bgc" (click)="createItem('Advance')">Create New Advance</button>
      <button type="button" class="button blue-bgc" (click)="createItem('Cost')">Create New Recoupable cost</button>
      <div class="recoupable-rights">
       <p> Master Rights recouped: <span>{{(userService.totalRights$ | async)?.master | currency: 'EUR' }}</span></p>
       <p> Publish Rights recouped: <span>{{(userService.totalRights$ | async)?.publish | currency: 'EUR'}}</span></p>
      </div>
    </div>
    <table class='table table-striped table-bordered table-hover dataTable' *ngIf="list?.length">
      <thead>
      <tr>
        <th>Type</th>
        <th>Title</th>
        <th>Date</th>
        <th>Net Amount(€)</th>
        <th>Recoupable(%)</th>
        <th>Net recoupable(€)</th>
        <th>VAT(%)</th>
        <th>VAT(€)</th>
        <th>Gross amount(€)</th>
        <th>Gross recoupable(€)</th>
        <th>Net balance(€)</th>
        <th>Gross balance(€)</th>
        <th>Rights</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let advance of list; let i = index'
          [ngClass]="advance.itemId === 1?'royalties-item':'advance-item'">
        <td class="highlighted">{{advance?.typeName}}</td>
        <td>{{advance?.title}}</td>
        <td>{{advance?.dateToShow}}</td>
        <td>{{advance?.netAmount | number:'.2-2'}}</td>
        <td>{{advance?.recoupablePercent}}%</td>
        <td class="highlighted">{{advance?.recoupableAmount | number:'.2-2'}}</td>
        <td>{{advance?.vat}}%</td>
        <td>{{advance?.vatAmount | currency: 'EUR'}}</td>
        <td>{{advance?.grossAmount | currency: 'EUR'}}</td>
        <td [ngClass]="advance?.grossRecoupable < 0?'negative-number':'positive-number'">
          {{advance?.grossRecoupable | currency: 'EUR'}}
        </td>
        <td [ngClass]="advance?.netBalance < 0?'negative-number':'positive-number'">
          <span *ngIf="i > 0 || list.length === 1">{{advance?.netBalance | currency: 'EUR'}}</span>
        </td>
        <td [ngClass]="advance?.grossBalance < 0?'negative-number':'positive-number'">
          <span
          *ngIf="i > 0 || list.length === 1">{{advance?.grossBalance | currency: 'EUR'}}</span>
        </td>
        <td>
         {{advance.rightsView}}
        </td>
        <td class='actions' *ngIf="!advance.deleting">
          <div class="flex-wrapper" *ngIf="advance.itemId === 3">
            <a class='btn btn-success'
               (click)='editAdvance(advance, i)' *ngIf="!advance.delivered"
            >
              <i class='fa fa-edit'></i>
            </a>
            <a class='btn btn-warning'
               (click)='deleteAdvance(advance, i)' *ngIf="!advance.delivered">
              <i class='fa fa-trash-o'></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="buttons-wrapper" *ngIf="list?.length">
      <button type="button" class="button red-btn default-size" (click)="showConfirmation(!list.length)"
              [disabled]="!list.length"

      >Delete balance
      </button>
    </div>
  </div>
</app-page-template>


<app-advances-popup *ngIf="showPopup"
                    [settings]="settings"
                    [balanceId]="balanceId"
                    [typeID]="typeID"
                    [artist]="artist"
                    [advance]="advance"
                    (notify)="onNotify($event)"
></app-advances-popup>
<app-popup-confirmation [config]="popupConfig" *ngIf="showPopupConfirmation" (notify)="onNotify($event)">
</app-popup-confirmation>
