

<form [formGroup]="form" class="tab">
  <div class="section" formGroupName="en">
    <h3>English</h3>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Is Enabled</div>
      <div class="item-field">
        <mat-checkbox class="example-margin"
                      formControlName="isEnabled"
                      [name]="'Is Enabled'"
                      [id]="'Is Enabled'"
        >
        </mat-checkbox>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Title</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="DeTitleEng"
                 formControlName="title"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Description</div>
      <div class="item-field">
        <angular-editor
          [placeholder]="'Enter text here...'"
          [config]="editorConfig"
          [formControl]="form.get('en').get('description')"
        ></angular-editor>
      </div>
    </div>



    <div class="item-row">
      <div class="item-label item-label_pad-20">Cta button</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="buttonEng"
                 formControlName="button"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">CTA Button link</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="urlEng"
                 formControlName="url"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Open in new window</div>
      <div class="item-field">
        <mat-checkbox class="example-margin"
                      formControlName="targetBlank"
                      [name]="'windowen'"
                      [id]="'windowen'"
        >
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="section" formGroupName="de">
    <h3>German</h3>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Is Enabled</div>
      <div class="item-field">
        <mat-checkbox class="example-margin"
                      formControlName="isEnabled"
                      [name]="'Is Enabledde'"
                      [id]="'Is Enabledde'"
        >
        </mat-checkbox>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Title</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="DeTitle"
                 formControlName="title"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Description</div>
      <div class="item-field">
        <angular-editor
          [placeholder]="'Enter text here...'"
          [config]="editorConfig"
          [formControl]="form.get('de').get('description')"
        ></angular-editor>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Cta button</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="button"
                 formControlName="button"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">CTA Button link</div>
      <div class="item-field">
        <mat-form-field class="item-field item-field_align">
          <input matInput
                 id="url"
                 formControlName="url"
                 type="text" autocomplete="off"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="item-row">
      <div class="item-label item-label_pad-20">Open in new window</div>
      <div class="item-field">
        <mat-checkbox class="example-margin"
                      formControlName="targetBlank"
                      [name]="'windowDe'"
                      [id]="'windowDe'"
        >
        </mat-checkbox>
      </div>
    </div>
  </div>
</form>

