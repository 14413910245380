import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalTemplateModule } from '@core/component/global/global-template.module';
import {TableComponent} from "@core/component/ui/table/table.component";
import {FormInputComponent} from "@core/component/ui/form-input/form-input.component";
import {MaterialModule} from "@core/material.module";
import {FormItemHeaderComponent} from "@core/component/ui/form-item-header/form-item-header.component";
import {FormItemWrapperComponent} from "@core/component/ui/form-item-wrapper/form-item-wrapper.component";
import {FormcontrolArrayComponent} from "@core/component/ui/formcontrol-array/formcontrol-array.component";
import {MatDatepickerComponent} from "@core/component/ui/mat-datepicker/mat-datepicker.component";
import {FormSelectComponent} from "@core/component/ui/form-select/form-select.component";
import {TableTemplateComponent} from "@core/component/ui/table-template/table-template.component";

@NgModule({
  exports: [
    TableComponent,
    FormInputComponent,
    FormSelectComponent,
    FormItemHeaderComponent,
    FormItemWrapperComponent,
    TableTemplateComponent,
    FormcontrolArrayComponent,
    MatDatepickerComponent
  ],
  declarations: [
    TableComponent,
    FormInputComponent,
    FormSelectComponent,
    FormItemHeaderComponent,
    TableTemplateComponent,
    FormItemWrapperComponent,
    FormcontrolArrayComponent,
    MatDatepickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    GlobalTemplateModule,
  ],
  providers: [DecimalPipe],
})
export class UiModule {}
