import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppToastrService, OpportunityService, UploadService} from '../../services/index';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileName: any = '';
  imageLoaded = false;
  @Input() maintainAspectRatio: boolean;
  @Input() config: any;

  @Input() uploadedImage: any;
  @Input() saveOnback: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveParallel: EventEmitter<any> = new EventEmitter<any>();

  constructor(private opportunityService: OpportunityService,
              private uploadService: UploadService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
  }

  savePhoto(): void {
    this.notify.emit({
      data: this.croppedImage,
      fileName: this.fileName
    });
  }

  fileChangeEvent(event: any): any {
    const allFormats = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'gif', 'GIF', 'webp', 'WEBP'];
    const extension = this.config.allowFormats ? this.config.allowFormats : allFormats;

    if (extension.indexOf(event.target.files[0].name.split('.').slice(-1)[0]) < 0) {
      let uniqExt = this.config.allowFormats.map(v => v.toLowerCase()).filter((v, i, a) => a.indexOf(v) === i);
      this.toastr.showErrorToast({
        title: `Allowed ${this.config.allowFormats ? uniqExt : 'jpg, png, jpeg, webp, gif'} formats only`
      });
      return false;
    }
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
    if (this.saveOnback) {
      this.saveParallel.emit({
        data: event
      });
    }
  }

  imageCroppedw(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoadedw(image?: any): void {
    this.imageLoaded = true;
  }

  cropperReadyw(): void {
    // cropper ready
  }

  loadImageFailedw(e): void {
    this.toastr.showErrorToast({message: 'File extension should be jpg, jpeg, png, webp'});
  }
}
