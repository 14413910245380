import {Component, OnInit} from '@angular/core';
import {
  StorageService,
  LandingService,
  RoutingService,
  AuthenticationService, AppToastrService
} from '../../../services/index';
import {RequestModel} from '@core/model/request.model';
import {LandingModel} from '@core/model/landing.model';
import {STATE_NAMES} from '@core/common';
import {PermissionService} from '@core/services/permission.service';
import {SettingService} from '@core/services/setting.service';
import {CollectibleService} from '@core/services/collectible.service';
import {ICollectible} from '@core/interfaces/collectible.interface';
import {ConfirmPopup, IResponse} from '@core/common/interfaces';

interface Filter {
  paging: {
    page: number,
    offset: number,
    limit: number
  };
  sorting: {
    sortBy: string,
    sortHow: string
  };
}

@Component({
  selector: 'app-collectible-list',
  templateUrl: './collectibles-list.html',
  styleUrls: ['./_collectibles-list.scss']
})
export class CollectiblesListComponent implements OnInit {
  list: ICollectible[];
  host: string;
  isLoaded: boolean;
  total: number;
  popupConfig: ConfirmPopup = {
    btnConfirm: true,
    titleBtnConfirm: 'Delete',
    btnCancel: true,
    submitEmit: 'deleteItem',
    titleBtnCancel: 'Cancel',
    text: 'Are you sure you want to delete song?'
  };
  deletedItem: ICollectible;
  showPopupConfirmation: boolean;
  filter: Filter = {
    paging: {
      page: 1,
      offset: 0,
      limit: 20,
    },
    sorting: {
      sortBy: 'createdAt',
      sortHow: 'desc'
    }
  };

  constructor(private storage: StorageService,
              private toastr: AppToastrService,
              private authService: AuthenticationService,
              private permissionsService: PermissionService,
              private settingService: SettingService,
              private routingService: RoutingService,
              private collectibleService: CollectibleService,
  ) {
  }

  ngOnInit(): void {
    this.getList();
  }

  onPageChange(pageNumber: number): void {
    this.filter.paging.page = pageNumber + 1;
    this.filter.paging.offset = pageNumber * this.filter.paging.limit;
    this.getList();
  }

  openItem(item?: any): void {
    const param: any = {
      slug: item ? item.slug : 'newItem'
    };
    this.routingService.go(STATE_NAMES.collectibleItem, param);
  }

  deleteItem(item?: any): void {
    this.deletedItem = item;
    this.showPopupConfirmation = true;
  }

  onNotify(e): void {
    if (e.type === 'sorting') {
      this.getList();
    }
    if (e.type === this.popupConfig.submitEmit) {
      this._deleteItem();
      this.showPopupConfirmation = false;
    }
  }

  _deleteItem() {
    this.collectibleService._deleteCollectibles(this.deletedItem.slug).subscribe(resp => {
      this.getList();
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  getList(): void {
    const request = new RequestModel(this.filter);
    this.collectibleService._getCollectibles(request).subscribe(resp => {
      this.list = resp.list;
      this.isLoaded = true;
      this.total = resp.total;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }
}
