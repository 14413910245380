<span class="prev item" [class.disabled]="current === 1" (click)="setCurrent(current - 1, current === 1)">PREV</span>

<span class="item" *ngIf="current > 2" (click)="setCurrent(1)">1</span>
<span class="splitter" *ngIf="current > 3">...</span>

<span class="item" *ngIf="current > 1" (click)="setCurrent(current - 1)">{{current - 1}}</span>
<span class="item active">{{current}}</span>
<span class="item" *ngIf="current < all" (click)="setCurrent(current + 1)">{{current + 1}}</span>

<span class="splitter" *ngIf="current < (all-2)">...</span>
<span class="item" *ngIf="current < (all-1)" (click)="setCurrent(all)">{{all}}</span>

<span class="next item" [class.disabled]="current === all" (click)="setCurrent(current + 1, current === all)">NEXT</span>
