import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UploadAudioComponent} from "@core/component/upload/upload-audio/upload-audio.component";
import {UploadVideoComponent} from "@core/component/upload/upload-video/upload-video.component";
import {UploadImageComponent} from "@core/component/upload/upload-image/upload-image.component";
import {UiModule} from "@core/component/ui/ui.module";
import {PipesModule} from "@core/pipes/pipes.module";
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {UploadImageWrapperComponent} from "@core/component/upload/upload-image-wrapper/upload-image-wrapper.component";
import {ImageProfileComponent} from "@core/component/upload/image-profile/image-profile.component";
import {UploadImageService} from "@core/component/upload/upload-image.service";
import {MaterialModule} from "@core/material.module";
import {UploadDocumentComponent} from "@core/component/upload/upload-document/upload-document.component";

@NgModule({
  exports: [
    UploadAudioComponent,
    UploadVideoComponent,
    UploadImageComponent,
    UploadImageWrapperComponent,
    UploadDocumentComponent,
    ImageProfileComponent
  ],
  declarations: [
    ImageCropperComponent,
    UploadAudioComponent,
    UploadVideoComponent,
    UploadImageComponent,
    UploadDocumentComponent,
    UploadImageWrapperComponent,
    ImageProfileComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    MaterialModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    UploadImageService
  ]
})
export class UploadModule {
}
