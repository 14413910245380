import * as moment from 'moment';
import {STATUS_SUBMISSION} from '../common/constants';
import {OpportunityModel} from './opportunity.model';

interface SongLink {
  youtube: string;
  spotify: string;
  soundcloud: string;
  dropbox: string;
}

interface AristSocials {
  facebook: string;
  twitter: string;
  youtube: string;
  website: string;
  instagram: string;
  soundcloud: string;
  spotify: string;
  tiktok: string;
}

interface AristContact {
  birthdate: string;
  city: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  region: string;
}

export class SongModelGen {
  constructor() {
  }

  isUpdate: boolean;
}

export class PublishedProjectModel {
  constructor(host?) {
    this.imgUrl = host;
  }

  imgUrl: string;
  artistName: string;
  audio: string;

  createdAt: string;
  description: string;
  id: string;
  songLink: Partial<SongLink>;
  artistContact: Partial<AristContact>;
  status: {
    id: number,
    text: string
  };
  slug: string;
  artistSocial: Partial<AristSocials>;
  updatedAt: string;
  project: any;
  rating: any;
  publishedUpcoming: string;
  comment: string;
  genres: any;
  songName: string;
  producer: string;
  projectSlug: string;
  time: string;
  artist: any;
  song: any;

  setFromApiModel(apiModel): this {
    this.artistName = apiModel.artist ? apiModel.artist.name : null;
    this.artist = apiModel.artist;
    this.slug = apiModel.artist ? apiModel.artist.slug : null;
    this.publishedUpcoming = moment(apiModel.publishedUpcoming).format('DD.MM.YYYY HH:MM');
    this.artistContact = apiModel.artist ? apiModel.artist.contact : null;
    this.audio = apiModel.soundtrack.indexOf('project-soundtracks') >= 0 ? `${this.imgUrl}/${apiModel.soundtrack}` : `${this.imgUrl}/project-soundtracks/${apiModel.soundtrack}`;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY HH:MM');
    this.time = moment(apiModel.createdAt).format('x');
    this.description = apiModel.description;
    this.songName = apiModel.song.name;
    this.id = apiModel._id;
    this.projectSlug = apiModel.slug;
    this.comment = apiModel.comment;
    this.artistSocial = apiModel.artist ? apiModel.artist.social : null;
    this.project = apiModel;
    this.song = apiModel.song;
    this.project.genresPlay = apiModel.song.genre;
    this.project.composers = apiModel.song.composers.map(item => {
      return {
        name: item.name ? item.name : item,
        user: item.user,
        slug: item.slug
      };
    });
    this.project.aboutSong = apiModel.myProjectDescription;
    this.project.biography = apiModel.biography;
    this.project.writers = apiModel.song.writers.map(item => {
      return {
        name: item.name ? item.name : item,
        user: item.user,
        slug: item.slug
      };
    });
    this.project.label = apiModel.song.label;
    this.project.isPublished = apiModel.isPublished;
    this.project.videoclip = apiModel.videoclip ? `${this.imgUrl}/${apiModel.videoclip}` : null;
    // if (apiModel.videoImage && apiModel.videoImage.split(this.imgUrl)[1]) {
    //   this.project.videoImage = apiModel.videoImage.indexOf('project-images') >= 0 ? `${this.imgUrl}/${apiModel.videoImage}` : `${this.imgUrl}/images/${apiModel.videoImage}`;
    // } else {
    //   this.project.videoImage = null;
    // }
    // this.project.videoImage = apiModel.videoImage ? `${this.imgUrl}/${apiModel.videoclip}` : null;
    this.project.songName = apiModel.song.name;
    this.project.producer = apiModel.song.producer;
    this.project.photo = apiModel.image.indexOf('project-images') >= 0 ? `${this.imgUrl}/${apiModel.image}` : apiModel.image.indexOf('images/') >= 0 ? `${this.imgUrl}/${apiModel.image}` : `${this.imgUrl}/images/${apiModel.image}`;

    this.status = STATUS_SUBMISSION[apiModel.status];
    this.rating = apiModel.rating || 0;
    this.updatedAt = moment(apiModel.updatedAt).format('DD.MM.YYYY HH:MM');
    return this;
  }
}

export class SongModel extends SongModelGen {
  constructor(host?) {
    super();
    this.imgUrl = host;
  }

  imgUrl: string;
  artistName: string;
  audio: string;

  createdAt: string;
  description: string;
  id: string;
  songLink: Partial<SongLink>;
  artistContact: Partial<AristContact>;
  status: {
    id: number,
    text: string
  };
  slug: string;
  artistSocial: Partial<AristSocials>;
  updatedAt: string;
  project: any;
  rating: any;
  publishedUpcoming: string;
  comment: string;
  genres: any;
  songName: string;
  producer: string;
  projectSlug: string;
  time: string;

  setFromApiModel(apiModel): this {
    this.artistName = apiModel.artist ? apiModel.artist.name : null;
    this.slug = apiModel.artist ? apiModel.artist.slug : null;
    this.publishedUpcoming = moment(apiModel.publishedUpcoming).format('DD.MM.YYYY HH:MM');
    this.artistContact = apiModel.artist ? apiModel.artist.contact : null;
    this.audio = `${this.imgUrl}/project-soundtracks/${apiModel.audio}`;
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY HH:MM');
    this.time = moment(apiModel.createdAt).format('x');
    this.description = apiModel.description;
    this.songName = apiModel.songName;
    this.id = apiModel._id;
    this.projectSlug = apiModel.projectSlug;
    this.comment = apiModel.comment;
    this.artistSocial = apiModel.artist ? apiModel.artist.social : null;
    if (apiModel.upcomingProject) {
      this.project = new OpportunityModel(this.imgUrl).setFromApiModel(apiModel.upcomingProject);
    }
    this.songLink = apiModel.songLink;
    this.status = STATUS_SUBMISSION[apiModel.status] || 'published';
    this.rating = apiModel.rating ? apiModel.rating.toString() : '0';
    this.updatedAt = moment(apiModel.updatedAt).format('DD.MM.YYYY HH:MM');
    return this;
  }

  getApiModel(data): any {
    const model = {
      audio: data.audio,
      photo: data.photo,
      genresPlay: data.genresPlay,
      publisher: data.publisher,
      artistName: data.artistName,
      songName: data.songName,
      composers: data.composers,
      biography: data.aboutArtist,
      producer: data.producer,
      aboutSong: data.aboutSong,
      writers: data.writers,
      label: data.label,
      rating: data.rating,
      comment: data.comment,
      id: data.id
    };
    return model;
  }
}

export enum Status {
  UPCOMING = 'upcoming',
  ACCEPTED = 'accepted',
  SUBMITTED = 'submitted',
  DECLINE = 'declined',
  REJECTED = 'rejected',
  POSTPONE = 'postpone',
  OPPORTUNITY = 'opportunity'
}

