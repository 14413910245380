import {NgModule} from '@angular/core';
import {MomentDatePipe} from './moment-date.pipe';
import {MomentDatePipeHours} from '@core/pipes/moment-date-hours.pipe';
import {FirstLetterUppercasePipe} from '@core/pipes/first-letter-uppercase.pipe';
import {ImageUrlPipe} from "@core/pipes/image-url.pipe";
import {FormatCurrencyPipe} from "@core/pipes/currency.pipe";

@NgModule({
  imports: [],
  declarations: [
    MomentDatePipe,
    MomentDatePipeHours,
    ImageUrlPipe,
    FirstLetterUppercasePipe,
    FormatCurrencyPipe
  ],
  exports: [
    MomentDatePipe,
    MomentDatePipeHours,
    ImageUrlPipe,
    FirstLetterUppercasePipe,
    FormatCurrencyPipe
  ]
})

export class PipesModule {
}
