import {UploadService} from '@core/services';
import * as moment from 'moment';
import {Moment} from 'moment';

export class NewsModel {
  constructor(host?, uploadService?) {
    this.imgUrl = host;
    this.uploadService = uploadService;
  }

  uploadService: UploadService;
  createdAt: string;
  id: string;
  imgUrl: string;
  isPublished: boolean;
  link: {
    text: string;
    url: string;
  };
  artist: {
    name: string;
    _id: string;
  };
  newsDescription: string;
  newsflash: any;
  publishedAt: string | moment.Moment;
  slug: string;
  position: number;
  textNews: string;
  social: {
    facebook: string;
    instagram: string;
    spotify: string;
    twitter: string;
    youtube: string;
  };
  title: string;
  updateAt: string;

  _v: number;
  _id: string;

  setFromApiModel(apiModel): this {
    this.createdAt = moment(apiModel.createdAt).format('DD.MM.YYYY HH:mm');
    this.id = apiModel._id;
    this.social = apiModel.social;
    this.imgUrl = apiModel.image || '';
    this.isPublished = apiModel.isPublished;
    this.slug = apiModel.slug;
    this.artist = apiModel.artist;
    this.link = apiModel.link;
    this.position = apiModel.position || 1;
    this.textNews = apiModel.newsDescription;
    this.newsflash = apiModel.newsflash;
    this.publishedAt = apiModel.publishedAt ? moment(apiModel.publishedAt, 'YYYY/MM/DD').toISOString() : '';
    this.title = apiModel.title;
    this.updateAt = moment(apiModel.updateAt).format('DD.MM.YYYY HH:mm');
    this._v = apiModel._v;
    this._id = apiModel._id;
    return this;
  }

  update(data, newsItem, selectedArtist) {
    let obj: any = {
      createdAt: data.value.createdAt || newsItem.createdAt,
      image: data.value.imgUrl ? data.value.imgUrl : '',
      isPublished: data.value.isPublished,
      link: {
        url: data.value.webLink,
        text: data.value.webLinkText
      },
      artist: null,
      publishedAt: data.value.publishedAt ? moment(data.value.publishedAt).format('YYYY/MM/DD') : '',
      newsDescription: data.value.textNews,
      newsflash: data.value.newsflash,
      social: {
        facebook: data.value.facebook,
        instagram: data.value.instagram,
        spotify: data.value.spotify,
        twitter: data.value.twitter,
        youtube: data.value.youtube,
      },
      title: data.value.title || newsItem.title,
    };
    if (selectedArtist) {
      obj.artist = selectedArtist.id;
    } else if(newsItem && newsItem.artist){
      obj.artist = newsItem.artist._id;
    }
    return obj;
  }
}
