<app-popup-template (closeModal)="onCloseItem()"
                    title="GiveAway"
                    [showCross]="true">
    <ng-container id="content">
      Select User
      <app-find-by-name-universal
                        (notify)="selectUser($event)"
                        [placeholder]="'Email'"
                        [type]="'email'"
                        [errorText]="'User is not found'"
                        class="item-select"
      ></app-find-by-name-universal>
    </ng-container>
    <ng-container id="footer">
      <div class="wrapper">
      <button
        [disabled]="!user || !user?.nft?.walletId || submitPressed"
        (click)="onSubmit()"
        class="button green default-size">Give away</button>
        <mat-spinner *ngIf="submitPressed" [diameter]="20"></mat-spinner>
      </div>
      <p class="error-message" *ngIf="user && !user?.nft?.walletId">User does not have NFT's wallet</p>
    </ng-container>

</app-popup-template>

