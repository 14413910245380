import { Component, OnInit } from '@angular/core';
import { ArtistsService } from '@core/services/artists.service';
import { UsersService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-producers-view',
  templateUrl: './producers-view.component.html',
  providers: [ArtistsService],
})
export class ProducersViewComponent implements OnInit {
  slug: string;

  artistData: any;

  constructor(
    private usersService: UsersService,
    private artistsService: ArtistsService,
    private route: ActivatedRoute
  ) {
    this.slug = this.route.snapshot.params.slug;
  }

  ngOnInit(): void {
    this.artistsService
      .getCurrentProducer(this.slug.toLowerCase())
      .subscribe((artist) => {
        this.artistData = artist;
        if (this.artistData.country) {
          this.usersService
            .longNameCountry(this.artistData.country)
            .then((c) => {
              this.artistData.country = c;
            });
        }
      });
  }
}
