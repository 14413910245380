<label class="upload-button button"  [class.disabledUpload] = "loadFile">
  <input (change)="onChangeInput($event.target)" type="file"
         class="desktop-upload"
         [disabled]="loadFile"
         maxlength="30"
         name="filefront"/>
  <span>{{buttonTitle}}</span>

</label>
<mat-spinner *ngIf="loadFile" [diameter]="20"></mat-spinner>
