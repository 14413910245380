<div class="nav-header">
  <div class="menu-toggle-wrapper">
    <a
      class="menu-toggle lnr lnr-menu"
      (click)="isCollapsed = !isCollapsed"
    ></a>
  </div>
  <img src="./assets/images/logo.png" *ngIf="!isCollapsed" />
</div>

<div class="nav-wrapper">
  <nav class="main-navigation" *ngIf="links">
    <ng-container *ngFor="let link of links">
      <a
        class="link"
        [routerLink]="[link.state]"
        routerLinkActive="active"
      >
        <i class="fa {{link.className}}" aria-hidden="true"></i>
        <span class="text">{{link.text}}</span>
      </a>
    </ng-container>
  </nav>
</div>
<div *ngIf="!links" class="navigation-loader loader">
  <span class="lnr lnr-sync"></span>
</div>
