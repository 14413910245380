import {Component, OnChanges, Output, Input, EventEmitter, HostBinding} from '@angular/core';

export interface FilterInterface {
  paging: Paging;
  total: number;
}

export interface Paging {
  limit: number;
  offset?: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnChanges {
  current: number;
  all: number;

  @Input() total: number;
  @Input() paging: Paging;
  @HostBinding('class.active') @Input() active = true;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: any) {
    if (changes.total || changes.paging) {
      this.prepareData();
    }
  }

  prepareData() {
    this.current = (this.paging.offset / this.paging.limit) + 1;
    this.all = Math.ceil(this.total / this.paging.limit);
  }

  setCurrent(index: number, isDisabled?: boolean) {
    if (!isDisabled && this.active) {
      this.current = index;
      this.notify.emit(index - 1);
    }
  }
}
