<div class="item-content-soc">
  <div class="social-item">
    <div class="social-item-icon">
              <span class="social spotify">
                     <i class="fab fa-spotify"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.spotify?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>Spotify</mat-label>
          <input matInput [formControl]="spotifyCtrl" [errorStateMatcher]="errorMatcher(spotifyCtrl)">

        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('spotify', spotifyCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(spotifyCtrl, 'spotify')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.spotify?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="spotifyCtrl?.value" *ngIf="spotifyCtrl?.value"  target="_blank">Spotify link</a>
        </div>
        <div class="edit" (click)="editSocial('spotify', spotifyCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="social-item">
    <div class="social-item-icon">
              <span class="social youtube">
                     <span class="circle"></span>
                     <i class="fab fa-youtube"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.youtube?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>Youtube</mat-label>
          <input matInput [formControl]="youtubeCtrl"  [errorStateMatcher]="errorMatcher(youtubeCtrl)">
        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('youtube', youtubeCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(youtubeCtrl, 'youtube')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.youtube?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="youtubeCtrl?.value" *ngIf="youtubeCtrl?.value"  target="_blank">Youtube link</a>
        </div>
        <div class="edit" (click)="editSocial('youtube', youtubeCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="social-item">
    <div class="social-item-icon">
              <span class="social soundcloud">
                     <span class="circle"></span>
                      <i class="fab fa-soundcloud"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.soundcloud?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>Soundcloud</mat-label>
          <input matInput [formControl]="soundCloudCtrl" [errorStateMatcher]="errorMatcher(soundCloudCtrl)">
        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('soundcloud', soundCloudCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(soundCloudCtrl, 'soundcloud')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.soundcloud?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="soundCloudCtrl?.value" *ngIf="soundCloudCtrl?.value" target="_blank">Soundcloud link</a>
        </div>
        <div class="edit" (click)="editSocial('soundcloud', soundCloudCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="social-item">
    <div class="social-item-icon">
               <span class="social dropbox">
                     <span class="circle"></span>
                      <i class="fab fa-dropbox"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.dropbox?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>Dropbox</mat-label>
          <input matInput [formControl]="dropboxCtrl" [errorStateMatcher]="errorMatcher(dropboxCtrl)">
        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('dropbox', dropboxCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(dropboxCtrl, 'dropbox')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.dropbox?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="dropboxCtrl?.value" *ngIf="dropboxCtrl?.value"  target="_blank">Dropbox link</a>
        </div>
        <div class="edit" (click)="editSocial('dropbox', dropboxCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="social-item" *ngIf="socialArtist">
    <div class="social-item-icon">
               <span class="social instagram">
                     <span class="circle"></span>
                     <i class="fab fa-instagram"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.instagram?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>Instagram</mat-label>
          <input matInput [formControl]="instagramCtrl" [errorStateMatcher]="errorMatcher(instagramCtrl)">
        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('instagram', instagramCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(instagramCtrl, 'instagram')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.instagram?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="instagramCtrl?.value" *ngIf="instagramCtrl?.value"  target="_blank">Instagram link</a>
        </div>
        <div class="edit" (click)="editSocial('instagram', instagramCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="social-item" >
    <div class="social-item-icon">
              <span class="social tiktok">
                     <span class="circle"></span>
                     <i class="fab fa-tiktok"></i>
                  </span>
    </div>
    <ng-template ngIf [ngIf]="social?.tiktok?.edit">
      <div class="social-item-url">
        <mat-form-field class="mat-item">
          <mat-label>TikTok</mat-label>
          <input matInput [formControl]="tiktokCtrl" [errorStateMatcher]="errorMatcher(tiktokCtrl)">
        </mat-form-field>
        <div class="button-wrapper">
          <div class="remove-control" (click)="editSocial('tiktok', tiktokCtrl)">
            <i class="fas fa-times-circle"></i>
          </div>
          <div class="save-control" (click)="saveSocial(tiktokCtrl, 'tiktok')">
            <i class="fas fa-save" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngIf [ngIf]="!social?.tiktok?.edit">
      <div class="wrapper-link">
        <div class="link">
          <a [href]="tiktokCtrl?.value" *ngIf="tiktokCtrl?.value"  target="_blank">TikTok link</a>
        </div>
        <div class="edit" (click)="editSocial('tiktok', tiktokCtrl)">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </div>
    </ng-template>
  </div>
</div>
