<div class="search-block_item">
  <div class="search-block_item_header flex flex-between">
    <span class="bold-style">Range filter</span>
    <mat-form-field class="mat-select d-inline mat-form-select" appearance="fill">
      <mat-label>Select category</mat-label>
      <mat-select [(ngModel)]="searchService.selectedRangeFilter" name="food">
        <mat-option *ngFor="let cat of config.rangeOptions" [value]="cat">
          {{cat}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search-block_item_container range-block">
    <mat-form-field *ngIf="searchService.selectedRangeFilter" appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="searchService.range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <ng-container *ngIf="searchService.selectedRangeFilter">
      <button mat-button (click)="searchService.searchByRange()"><i class="fas fa-search"></i></button>
      <button mat-button (click)="searchService.resetRangeFilter()"><i class="fas fa-refresh"></i></button>
    </ng-container>

  </div>
</div>
